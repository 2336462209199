import IconButton from "presentation/components/button/icon_button";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import Debouncer from "presentation/utils/debouncer/debouncer";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import InputFormatter, {TextEditingValue} from "presentation/utils/input_formatters/input_formatter";
import NumberHelper from "config/helper/number_helper";

type InputModeType = "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";

const LayoutContainer = styled.div`
    width: 100%;
    position: relative;
`;

const TextFieldContainer = styled.input<{
    $disabled: boolean;
    $error: boolean;
    $hasSuffix: boolean;
}>`
    width: 100%;
    padding: ${(props) => props.$hasSuffix ? "15.2px 56px 15.2px 16px" : "15.2px 16px"};
    border: 0 solid;
    box-shadow: ${(props) =>
            props.$disabled
                    ? `0 0 0 1.5px ${Palette.gray100} inset`
                    : props.$error
                            ? `0 0 0 2px ${Palette.red500} inset`
                            : `0 0 0 1.5px ${Palette.gray200} inset`};
    border-radius: 12px;
    outline: none;
    background-color: ${(props) =>
            props.$disabled
                    ? Palette.gray100
                    : props.$error
                            ? Palette.red100
                            : Palette.none};
    ${Fonts.detail3Medium};
    line-height: 1;
    color: ${(props) => (props.disabled ? Palette.gray400 : Palette.gray800)};
    caret-color: ${(props) =>
            props.$error ? Palette.red500 : Palette.primary500};
    cursor: ${(props) => (props.$disabled ? "not-allowed" : "sms")};
    transition: all 0.2s ease-in-out;

    &:focus {
        box-shadow: ${(props) =>
                `0 0 0 2px ${
                        props.$error ? Palette.red500 : Palette.primary500
                } inset`};
    }

    &::-webkit-input-placeholder {
        ${Fonts.detail3Medium};
        color: ${Palette.gray400};
    }

    &:focus::-webkit-input-placeholder {
        color: ${Palette.gray400};
        transition: all 0.2s ease-in-out;
    }
`;

const SuffixPositionContainer = styled.div.attrs<{ $visible: boolean }>(
    (props) => ({
        style: {
            pointerEvents: props.$visible ? "auto" : "none",
            opacity: props.$visible ? 1 : 0,
        },
    })
)`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate3d(0, -50%, 0);
    transition: opacity 0.3s ease-in-out;
`;

export const TextField = ({
                              className,
                              secure = false,
                              enabled = true,
                              error = false,
                              value,
                              onValueChange,
                              focused,
                              onFocusChange,
                              placeholder,
                              inputMode = "text",
                              maxLength,
                              hasSuffix = false,
                              autoScroll = true,
                              enterPressDebouncerDelayInMS = 50,
                              autoComplete = "off",
                              inputFormatter,
                              onEnterPress,
                          }: {
    className?: string;
    secure?: boolean;
    enabled?: boolean;
    error?: boolean;
    value: string;
    onValueChange?: (value: string) => void;
    focused?: boolean;
    onFocusChange?: (focused: boolean) => void;
    placeholder?: string;
    inputMode?: InputModeType;
    maxLength?: number;
    hasSuffix?: boolean;
    autoScroll?: boolean;
    enterPressDebouncerDelayInMS?: number;
    autoComplete?: string;
    inputFormatter?: InputFormatter;
    onEnterPress?: () => void;
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const suffixRef = useRef<HTMLButtonElement>(null);
    const debouncerRef = useRef(new Debouncer());
    const valueSetOutsideRef = useRef(false);
    const [state, setState] = useState<TextEditingValue>({
        text: value,
        selection: {
            start: value.length,
            end: value.length,
        },
    });

    useEffect(() => {
        valueSetOutsideRef.current = true;
        setState({
            text: value,
            selection: {
                start: NumberHelper.clamp(state.selection.start, 0, value.length),
                end: NumberHelper.clamp(state.selection.end, 0, value.length),
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        if (focused) {
            inputRef.current?.focus();
            if (autoScroll) {
                inputRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        } else if (!focused) {
            inputRef.current?.blur();
        }
    }, [focused, autoScroll]);

    useEffect(() => {
        const input = inputRef.current;

        if (!onEnterPress || !focused || !input) return;

        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                debouncerRef.current.run(
                    () => addPostFrameCallback(() => onEnterPress()),
                    enterPressDebouncerDelayInMS
                );
            }
        };

        input.addEventListener("keydown", onKeyDown);

        return () => input.removeEventListener("keydown", onKeyDown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused, onEnterPress, inputRef]);

    useEffect(() => {
        const suffix = suffixRef.current;
        if (!hasSuffix || !focused || !value.length || !suffix) return;

        const onClear = () => setState({
            text: "",
            selection: {
                start: 0,
                end: 0,
            },
        });

        suffix.addEventListener("mousedown", onClear);

        return () => suffix.removeEventListener("mousedown", onClear);
    }, [hasSuffix, focused, suffixRef, value.length, onValueChange]);

    useEffect(() => {
        if (valueSetOutsideRef.current) return;
        if (onValueChange && state.text.trim() !== value.trim()) onValueChange(state.text.trim());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.text]);

    useEffect(() => {
        inputRef.current?.setSelectionRange(
            state.selection.start,
            state.selection.end,
        );
    }, [state.selection.start, state.selection.end]);

    const onBlur = () => {
        if (onFocusChange) onFocusChange(false);
        const newText = value.trim().replaceAll(/\b/g, "");
        setState({
            text: newText,
            selection: {
                start: newText.length,
                end: newText.length,
            },
        });
    };

    const onFocus = () => {
        if (onFocusChange) onFocusChange(true);
    };

    const onValidatedValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newText = event.currentTarget.value;
        const newSelectionStart = event.currentTarget.selectionStart ?? newText.length;
        const newSelectionEnd = event.currentTarget.selectionEnd ?? newText.length;

        const newState = {
            text: newText,
            selection: {
                start: newSelectionStart,
                end: newSelectionEnd,
            },
        };

        if (!maxLength || newState.text.length <= maxLength) {
            valueSetOutsideRef.current = false;
            if (inputFormatter) {
                const formatted = inputFormatter.formatEditUpdate(state, newState);
                setState(formatted);
                return;
            }

            setState(newState);
        }
    };

    const inputType = secure ? "password" : "text";

    const suffixVisible = !!value.length && (focused ?? false);

    return (
        <LayoutContainer>
            <TextFieldContainer
                className={className}
                ref={inputRef}
                disabled={!enabled}
                type={inputType}
                value={state.text}
                onChange={onValidatedValueChange}
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={placeholder ?? ""}
                inputMode={inputMode}
                autoComplete={autoComplete}
                title={state.text}
                $disabled={!enabled}
                $error={error}
                $hasSuffix={hasSuffix}
            />
            {hasSuffix && (
                <SuffixPositionContainer $visible={suffixVisible}>
                    <IconButton
                        ref={suffixRef}
                        asset={SVGAssets.DeleteCircle}
                        width={"20px"}
                        height={"20px"}
                        color={Palette.black20}
                        hoverColor={"light"}
                        tabIndex={-1}
                    />
                </SuffixPositionContainer>
            )}
        </LayoutContainer>
    );
};
