import styled from "styled-components";
import {useRef} from "react";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import {animated, useSpring} from "@react-spring/web";
import CSSTextAlignType from "presentation/utils/types/css/text_align_type";

const LayoutContainer = styled(animated.button)`
    width: 100%;
    min-height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: ${Palette.none};
    border: none;
    position: relative;
    overflow-x: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const LabelContainer = styled(animated.p)`
    min-width: max-content;
    ${Fonts.detail2Medium};
    text-align: left;
    white-space: nowrap;
    position: absolute;
    top: 50%;
`;

const ShootPrescriptionMedicationDrugField = ({
                                                  center,
                                                  revisionNeeded,
                                                  value,
                                                  onClick,
                                              }: {
    center: boolean;
    revisionNeeded: boolean;
    value?: string;
    onClick: () => void;
}) => {
    const layoutRef = useRef<HTMLButtonElement>(null);

    const buttonProps = useSpring({
        justifyContent: center ? "center" : "flex-start",
        immediate: true,
    });
    const labelImmediateProps = useSpring({
        textAlign: (center ? "center" : "left") as CSSTextAlignType,
        left: center ? "50%" : "0",
        transform: center ? "translate3d(-50%, -50%, 0)" : "translate3d(0, -50%, 0)",
        immediate: true,
    });
    const labelProps = useSpring({
        color: revisionNeeded ? Palette.red500 : Palette.gray800,
    });

    return <LayoutContainer
        ref={layoutRef}
        style={buttonProps}
        onClick={onClick}
        title={value}
    >
        <LabelContainer style={{
            ...labelImmediateProps,
            ...labelProps,
        }}>
            {value ?? S.shootPrescriptionMedicationDrugField.emptyLabel}
        </LabelContainer>
    </LayoutContainer>;
};

export default ShootPrescriptionMedicationDrugField;
