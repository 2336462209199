import Palette from "presentation/theme/palette";
import styled from "styled-components";

const DividerContainer = styled.div`
    width: 100%;
    min-width: 32px;
    min-height: 1px;
    margin-top: 16px;
    margin-bottom: 8px;
    background-color: ${Palette.gray200};
`;

const LNBDivider = () => {
    return <DividerContainer/>;
};

export default LNBDivider;
