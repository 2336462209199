import React, {PropsWithChildren, useEffect} from "react";
import {useLocation} from "react-router-dom";
import useSecureStorage from "presentation/utils/hooks/use_secure_storage";
import AppRouter from "presentation/routes/app_router";
import AppRoute, {AppRouteMap} from "presentation/routes/model/app_route";

const AppRouterPage = ({
                           redirectToMainOnAuthenticated,
                           redirectToLoginOnUnauthenticated,
                           children,
                       }: PropsWithChildren<{
    redirectToMainOnAuthenticated: boolean;
    redirectToLoginOnUnauthenticated: boolean;
}>) => {
    const {pathname} = useLocation();
    const secureStorage = useSecureStorage();

    const isAuthenticated = secureStorage.isAuthenticated();

    useEffect(() => {
        const route = AppRouteMap.fromPathname(pathname);
        const isCert = route === AppRoute.Cert;

        if (isCert) secureStorage.setCertEntered({certEntered: true});
        const certEntered = secureStorage.getCertEntered();

        const isOCR = pathname.includes("/ocr");
        if (!isCert && certEntered && isOCR) {
            secureStorage.setCertEntered({certEntered: false});
            secureStorage.deleteHospital();
            AppRouter.navigate(AppRoute.Login, {replace: true});
        }
    }, [pathname, secureStorage]);

    useEffect(() => {
        if (redirectToMainOnAuthenticated && redirectToLoginOnUnauthenticated) return;

        if (isAuthenticated && redirectToMainOnAuthenticated) {
            AppRouter.navigate(AppRoute.Main, {
                replace: true,
            });
        }
        if (!isAuthenticated && redirectToLoginOnUnauthenticated) {
            AppRouter.navigate(AppRoute.Login, {
                replace: true,
            });
        }
    }, [
        pathname,
        redirectToMainOnAuthenticated,
        redirectToLoginOnUnauthenticated,
        isAuthenticated,
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>{children}</>;
};

export default AppRouterPage;