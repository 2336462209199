type PrescriptionFieldRevisionStatus = {
    confidenceLow: boolean;
    needFullSSN: boolean;
};

export const PrescriptionFieldRevisionStatusMap = {
    revisionNeeded: (d: PrescriptionFieldRevisionStatus): boolean => {
        return d.confidenceLow || d.needFullSSN;
    },
};

export default PrescriptionFieldRevisionStatus;