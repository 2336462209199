import {createBrowserRouter} from "react-router-dom";
import {appRouteMapping} from "./app_route_mapping";

const router = createBrowserRouter(appRouteMapping);

const AppRouter = {
    ...router,
    popIfAvailable() {
        if (router.state.location.key === "default") return;

        router.navigate(-1);
    },
};

export default AppRouter;
