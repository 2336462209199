import useLifeCycle from "presentation/utils/hooks/use_life_cycle";
import ShootCameraView from "presentation/pages/shoot/view/shoot_camera_view";
import {useContext, useEffect, useRef, useState} from "react";
import ShootLoadingView from "presentation/pages/shoot/view/shoot_loading_view";
import ShootEmptyPrescriptionView from "presentation/pages/shoot/view/shoot_empty_prescription_view";
import {useRecoilValue} from "recoil";
import styled from "styled-components";
import {useListenableState} from "presentation/utils/hooks/use_listenable_state";
import {shootListenableState} from "presentation/states/shoot/shoot_state";
import {ShootShowRealtimeScreen} from "presentation/states/shoot/shoot_listenable_state";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import RealtimeScreenDialog from "presentation/components/dialog/realtime_screen_dialog/realtime_screen_dialog";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";
import ShootPrescriptionsView from "presentation/pages/shoot/view/shoot_prescriptions_view";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import {MainPageContext} from "presentation/pages/main/main_page";
import shootPrescriptionsVisibleSelector from "presentation/states/shoot/selector/shoot_prescriptions_visible_selector";
import getPlatformType from "presentation/utils/functions/get_platform_type";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import S from "presentation/theme/s";

const LayoutContainer = styled.div.attrs<{ $minWidth: string; }>((props) => ({
    style: {
        minWidth: props.$minWidth,
    },
}))`
    min-height: max(100vh, 720px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;

const ShootPage = () => {
    const theme = useThemeContext();
    const isMobile = useMobileQuery();
    const showDialog = useShowDialog();
    const showNotificationDialog = useShowNotificationDialog();
    const dismissDialog = useDismissDialog();
    const {cameraDeviceID, setCameraDeviceID} = useContext(MainPageContext);

    const prescriptionsVisible = useRecoilValue(shootPrescriptionsVisibleSelector);

    const videoRef = useRef<nullable<HTMLVideoElement>>(null);
    const overflowPropertyRef = useRef<optional<string>>(undefined);
    const [platformSupported, setPlatformSupported] = useState(false);

    useLifeCycle({
        onPreInit: () => {
            const platformType = getPlatformType();

            if (platformType === "mobile") {
                showNotificationDialog({
                    className: "unsupported-platform-dialog",
                    type: "negative",
                    title: S.shootPage.unsupportedPlatformDialog.title,
                    message: S.shootPage.unsupportedPlatformDialog.message,
                    hasNegativeButton: false,
                });
                return;
            } else {
                setPlatformSupported(true);
            }
        },
    });

    useListenableState(shootListenableState, (state) => {
        if (state instanceof ShootShowRealtimeScreen) {
            showDialog({
                className: "realtime-screen-dialog",
                component: <RealtimeScreenDialog videoRef={videoRef}/>
            });
        }
    });

    const onCameraConnectionChange = (deviceID?: string) => setCameraDeviceID(deviceID);
    const cameraEnabled = !!cameraDeviceID;

    useEffect(() => {
        if (prescriptionsVisible) {
            const {style} = document.body;
            if (!style) return;

            overflowPropertyRef.current = window.getComputedStyle(document.body).getPropertyValue("overflow");

            style.setProperty("overflow", "hidden");
        }

        return () => {
            const {style} = document.body;
            if (!style) return;

            if (overflowPropertyRef.current) style.setProperty("overflow", overflowPropertyRef.current);
        };
    }, [prescriptionsVisible]);

    useEffect(() => {
        dismissDialog("realtime-screen-dialog");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraDeviceID]);

    const lnbWidthInPx = isMobile ? theme.componentTheme.lnbMinWidthInPx : theme.componentTheme.lnbMaxWidthInPx;
    const minWidth = prescriptionsVisible ? `calc(100vw - ${lnbWidthInPx}px)` : "auto";

    return <LayoutContainer $minWidth={minWidth}>
        {platformSupported && <ShootCameraView
            ref={videoRef}
            onCameraConnectionChange={onCameraConnectionChange}
        />}
        {!cameraEnabled && !prescriptionsVisible && <ShootLoadingView/>}
        {cameraEnabled && !prescriptionsVisible && <ShootEmptyPrescriptionView/>}
        {prescriptionsVisible && <ShootPrescriptionsView/>}
    </LayoutContainer>;
};

export default ShootPage;
