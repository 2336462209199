import PrescriptionDateField from "domain/model/prescription/prescription_date_field";
import PrescriptionField from "domain/model/prescription/prescription_field";
import PrescriptionMedication from "domain/model/prescription/prescription_medication";
import PrescriptionExtraRemarksField from "domain/model/prescription/prescription_extra_remarks_field";
import BBox from "domain/model/common/bbox";
import InsuranceType from "domain/model/prescription/insurance_type";
import PrescriptionDiseaseCodeField from "domain/model/prescription/prescription_disease_code_field";

type Prescription = {
    id: string;
    insuranceType: InsuranceType;
    issueDate: PrescriptionDateField;
    issueNumber: PrescriptionField;
    patientName: PrescriptionField;
    patientSSN: PrescriptionField;
    nursingAgencyName: PrescriptionField;
    nursingAgencyNumber: PrescriptionField;
    doctorLicenseNumber: PrescriptionField;
    diseaseCodes: PrescriptionDiseaseCodeField[];
    selfPayCode: PrescriptionField;
    extraRemarks: PrescriptionExtraRemarksField;
    medications: PrescriptionMedication[];
    extraBBoxes: BBox[];
    fileKey: string;
    imageURL: string;
};

export const PrescriptionMap = {
    validForSending: (prescription: Prescription): boolean => {
        return prescription.medications.every((m) =>
            !!m.drugCode.value
            && !!m.drugName.value
            && !!m.singleDosage.value
            && !!m.dailyDosage.value
            && !!m.medicationPeriodInDays.value
        );
    },
};

export default Prescription;