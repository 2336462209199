import styled from "styled-components";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";

const LayoutContainer = styled.div.attrs<{ $roundLeft: boolean; $roundRight: boolean; }>((props) => ({
    style: {
        borderTopLeftRadius: props.$roundLeft ? "12px" : "0",
        borderBottomLeftRadius: props.$roundLeft ? "12px" : "0",
        borderTopRightRadius: props.$roundRight ? "12px" : "0",
        borderBottomRightRadius: props.$roundRight ? "12px" : "0",
    },
}))`
    height: 2.0625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Palette.gray100};
`;

const LabelContainer = styled.div`
    ${Fonts.detail1};
    text-align: center;
    color: ${Palette.gray400};
`;

const ShootPrescriptionMedicationHeaderBox = ({
                                                  roundLeft = false,
                                                  roundRight = false,
                                                  label,
                                              }: {
    roundLeft?: boolean;
    roundRight?: boolean;
    label: string;
}) => {
    return <LayoutContainer $roundLeft={roundLeft} $roundRight={roundRight}>
        <LabelContainer>{label}</LabelContainer>
    </LayoutContainer>;
};

export default ShootPrescriptionMedicationHeaderBox;
