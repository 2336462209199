import DateTimeHelper from "config/helper/date_time_helper";
import PrescriptionDateField from "domain/model/prescription/prescription_date_field";
import DateTime from "presentation/utils/class/date_time";

type PrescriptionDateFieldDTO = {
    value?: string;
    needCheck: boolean;
};

export const PrescriptionDateFieldDTOMap = {
    toDomainModel: (d: PrescriptionDateFieldDTO): PrescriptionDateField => {
        const date = DateTimeHelper.fromServerDateOrNull(d.value) ?? DateTime.now().min;

        return {
            value: date,
            initialValue: date,
            revisionStatus: {
                confidenceLow: d.needCheck,
                needFullSSN: false,
            },
        };
    },
}

export default PrescriptionDateFieldDTO;