import styled from "styled-components";
import {PropsWithChildren} from "react";

const LayoutContainer = styled.div.attrs<{ $flex: number }>((props) => ({
    style: {
        flex: props.$flex,
    },
}))``;

const Flex = ({flex = 1, children}: PropsWithChildren<{ flex?: number; }>) => {
    return <LayoutContainer $flex={flex}>{children}</LayoutContainer>;
};

export default Flex;
