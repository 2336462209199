import styled from "styled-components";
import Palette from "presentation/theme/palette";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";

const LayoutContainer = styled.button.attrs<{
    $stretch: boolean;
}>((props) => ({
    style: {
        width: props.$stretch ? "100%" : "40px",
        justifyContent: props.$stretch ? "flex-start" : "center",
        paddingLeft: props.$stretch ? "14px" : "none",
    },
}))`
    min-height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${Palette.white100};
    border-top: none;
    border-right: 1px solid ${Palette.gray100};
    border-bottom: 1px solid ${Palette.gray100};
    border-left: none;
    cursor: pointer;
`;

const ShootPrescriptionAddDiseaseCodeButton = ({
                                                   stretch,
                                                   onClick
                                               }: {
    stretch: boolean;
    onClick: () => void;
}) => {
    return <LayoutContainer
        onClick={onClick}
        $stretch={stretch}
    >
        <SVG
            asset={SVGAssets.Plus}
            width={"24px"}
            height={"24px"}
            color={Palette.gray800}
        />
    </LayoutContainer>;
};

export default ShootPrescriptionAddDiseaseCodeButton;
