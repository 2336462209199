import styled from "styled-components";
import {useRecoilValue, useSetRecoilState} from "recoil";
import shootPrescriptionsSelector from "presentation/states/shoot/selector/shoot_prescriptions_selector";
import shootState from "presentation/states/shoot/shoot_state";
import ShootConstants from "presentation/states/shoot/constants/shoot_constants";
import {Fragment, useMemo, useState} from "react";
import ShootPrescriptionRowTitleBox from "presentation/pages/shoot/components/shoot_prescription_row_title_box";
import S from "presentation/theme/s";
import Flex from "presentation/components/common/flex";
import ShootPrescriptionAddDiseaseCodeButton
    from "presentation/pages/shoot/components/shoot_prescription_add_disease_code_button";
import ShootPrescriptionTextField from "presentation/pages/shoot/components/shoot_prescription_text_field";
import {PrescriptionFieldRevisionStatusMap} from "domain/model/prescription/prescription_field_revision_status";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ShootPrescriptionDiseaseCodesView = () => {
    const {prescription} = useRecoilValue(shootPrescriptionsSelector);
    const setState = useSetRecoilState(shootState);

    const [focuses, setFocuses] = useState(Array.from({
        length: prescription.diseaseCodes.length,
    }, () => false));

    const {rows, empty} = useMemo(() => {
        const rowCount = 1 + Math.ceil(prescription.diseaseCodes.length / ShootConstants.prescription.maxDiseaseCodesInRow);

        let codeIndex = 0;
        return {
            rows: Array.from({
                length: rowCount,
            }, (_, index) => prescription.diseaseCodes.slice(
                index * ShootConstants.prescription.maxDiseaseCodesInRow,
                (index + 1) * ShootConstants.prescription.maxDiseaseCodesInRow
            ).map((c) => ({
                ...c,
                index: codeIndex++,
            }))),
            empty: !prescription.diseaseCodes.length,
        };
    }, [prescription.diseaseCodes]);

    const onAddDiseaseCodeClick = () => {
        setFocuses((prev) => [...prev, true]);
        setState((prev) => {
            const prescriptions = [...prev.prescriptions];
            const index = prescriptions.findIndex((p) => p.id === prescription.id);
            if (index < 0) return prev;
            if (prescriptions[index].diseaseCodes.length >= ShootConstants.prescription.maxDiseaseCodesCount) return prev;

            const id = prescriptions[index].diseaseCodes.reduce((prev, cur) => Math.max(prev, cur.id), 0) + 1;

            prescriptions[index] = {
                ...prescriptions[index],
                diseaseCodes: [
                    ...prescriptions[index].diseaseCodes,
                    {
                        id,
                        value: "",
                        revisionStatus: {
                            confidenceLow: false,
                            needFullSSN: false,
                        },
                    },
                ],
            };

            return {
                ...prev,
                prescriptions,
            };
        });
    };

    const onDiseaseCodeChange = (id: number) => (diseaseCode: string) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        const diseaseCodes = [...prescriptions[index].diseaseCodes];
        const diseaseCodeIndex = diseaseCodes.findIndex((c) => c.id === id);

        if (diseaseCodeIndex < 0) return prev;

        diseaseCodes[diseaseCodeIndex] = {
            ...diseaseCodes[diseaseCodeIndex],
            value: diseaseCode,
            revisionStatus: {
                ...diseaseCodes[diseaseCodeIndex].revisionStatus,
                confidenceLow: false,
            },
        };

        prescriptions[index] = {
            ...prescriptions[index],
            diseaseCodes,
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    const onFocusChange = (codeIndex: number) => (focused: boolean) => setFocuses((prev) => {
        const newFocuses = [...prev];
        newFocuses[codeIndex] = focused;
        return newFocuses;
    });

    if (empty) {
        return <RowContainer>
            <ShootPrescriptionRowTitleBox title={S.shootPage.shootPrescriptionView.diseaseCodesView.diseaseCodeTitle}/>
            <ShootPrescriptionAddDiseaseCodeButton
                stretch={true}
                onClick={onAddDiseaseCodeClick}
            />
        </RowContainer>
    }

    return <LayoutContainer>
        {rows.map((codes, rowIndex) => <RowContainer key={rowIndex}>
                {
                    !rowIndex && <ShootPrescriptionRowTitleBox
                        title={S.shootPage.shootPrescriptionView.diseaseCodesView.diseaseCodeTitle}
                    />
                }
                {
                    codes.map((code, columnIndex) =>
                        <Fragment key={code.id}>
                            <Flex>
                                <ShootPrescriptionTextField
                                    hasTopBorder={false}
                                    hasRightBorder={code.index < prescription.diseaseCodes.length - 1}
                                    hasLeftBorder={!!code.index && !columnIndex}
                                    revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(code.revisionStatus)}
                                    value={code.value}
                                    onValueChange={onDiseaseCodeChange(code.id)}
                                    focused={focuses.at(code.index) ?? false}
                                    onFocusChange={onFocusChange(code.index)}
                                    maxLength={InputFormatters.diseaseCodeFormatter.maxLength}
                                    inputFormatter={InputFormatters.diseaseCodeFormatter}
                                />
                            </Flex>
                            {
                                code.index === prescription.diseaseCodes.length - 1 &&
                                code.index !== ShootConstants.prescription.maxDiseaseCodesCount - 1 &&
                                <ShootPrescriptionAddDiseaseCodeButton
                                    stretch={false}
                                    onClick={onAddDiseaseCodeClick}
                                />
                            }
                        </Fragment>
                    )
                }
            </RowContainer>
        )}
    </LayoutContainer>;
};

export default ShootPrescriptionDiseaseCodesView;
