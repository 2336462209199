import DropdownButtonItem from "presentation/components/button/dropdown_button/components/dropdown_button_item";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {animated, useSpring} from "@react-spring/web";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";

const itemGapInPx = 8;
const dropdownPaddingInPx = 8;

const LayoutContainer = styled.div.attrs<{ $grow: boolean; }>((props) => ({
    style: {
        width: props.$grow ? "100%" : "max-content",
    },
}))`
    position: relative;
`;

const ButtonContainer = styled.button.attrs<{
    $enabled: boolean;
    $hovered: boolean;
    $selecting: boolean;
    $width: string;
    $heightInPx: number;
}>((props) => ({
    style: {
        width: props.$width,
        height: `${props.$heightInPx}px`,
        backgroundColor: props.$enabled ? Palette.white100 : Palette.gray50,
        boxShadow: `0 0 0 1.5px ${
            props.$enabled ? ((props.$hovered || props.$selecting)
                    ? Palette.primary500 : Palette.gray200)
                : Palette.gray100
        } inset`,
        cursor: props.$enabled ? "auto" : "not-allowed",
    },
}))`
    padding: 0 16px;
    border-radius: 12px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 30;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out, background-color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
`;

const DropdownContainer = styled.ul.attrs<{
    $selecting: boolean;
    $width: string;
    $heightInPx: number;
    $paddingInPx: number;
}>((props) => ({
    style: {
        width: props.$width,
        opacity: props.$selecting ? 1 : 0,
        top: props.$selecting ? "calc(100% + 8px)" : "calc(90%)",
        pointerEvents: props.$selecting ? "auto" : "none",
    },
}))`
    height: ${(props) => `${props.$heightInPx}px`};
    padding: ${(props) => `${props.$paddingInPx}px`};
    background-color: ${Palette.white100};
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 8px;
    position: absolute;
    z-index: 31;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    overflow-y: scroll;
    transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out,
    top 0.3s ease-in-out;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const ButtonLabelContainer = styled(animated.p)`
    ${Fonts.detail3Medium};
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
`;

const DropdownButton = ({
                            enabled = true,
                            minWidthInPx,
                            maxWidthInPx,
                            buttonHeightInPx = 40,
                            itemHeightInPx = 36,
                            selectedIndex,
                            labels,
                            emptyLabel,
                            maxIndexVisible = 5,
                            onSelectedIndexChange,
                        }: {
    enabled?: boolean;
    minWidthInPx?: number;
    maxWidthInPx?: number;
    buttonHeightInPx?: number;
    itemHeightInPx?: number;
    selectedIndex?: number;
    labels: string[];
    emptyLabel?: string;
    maxIndexVisible?: number;
    onSelectedIndexChange: (index: number) => void;
}) => {
    const isMobile = useMobileQuery();

    const buttonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null);
    const [hovered, setHovered] = useState(false);
    const [selecting, setSelecting] = useState(false);

    const labelProps = useSpring({
        color: enabled ? Palette.gray800 : Palette.gray300,
    });

    const {dropdownMaxHeightInPx} = useMemo(() => {
        const dropdownMaxHeightInPx =
            (itemHeightInPx + itemGapInPx) *
            Math.min(labels.length, maxIndexVisible) -
            itemGapInPx +
            2 * dropdownPaddingInPx;
        return {dropdownMaxHeightInPx};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxIndexVisible, labels.length]);

    const onClick = useCallback((e: MouseEvent) => {
        if (!enabled) return;

        if (buttonRef.current && buttonRef.current.contains(e.target as Node)) {
            setSelecting(true);
            return;
        }

        if (
            dropdownRef.current &&
            dropdownRef.current.contains(e.target as Node)
        ) {
            return;
        }

        setSelecting(false);
    }, [enabled, buttonRef, dropdownRef]);

    useLayoutEffect(() => {
        window.addEventListener("mousedown", onClick);

        return () => window.removeEventListener("mousedown", onClick);
    }, [onClick]);

    useEffect(() => {
        const button = buttonRef.current;
        if (!button) return;

        const onMouseEnter = () => setHovered(true);

        const onMouseLeave = () => setHovered(false);

        button.addEventListener("mouseenter", onMouseEnter);
        button.addEventListener("mouseleave", onMouseLeave);

        return () => {
            button.removeEventListener("mouseenter", onMouseEnter);
            button.removeEventListener("mouseleave", onMouseLeave);
        };
    }, [buttonRef]);

    useEffect(() => {
        if (!selecting) return;

        dropdownRef.current?.scrollTo({
            top:
                (selectedIndex ?? 0) * (itemHeightInPx + itemGapInPx) +
                dropdownPaddingInPx -
                itemGapInPx,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selecting]);

    const selectedLabel = selectedIndex !== undefined ?
        labels.at(selectedIndex) ?? labels.at(0) : emptyLabel ?? "";

    const onItemClick = (index: number) => () => {
        onSelectedIndexChange(index);
        setSelecting(false);
    };

    const grow = minWidthInPx === undefined && maxWidthInPx === undefined;
    const minWidth = minWidthInPx !== undefined ? `${minWidthInPx}px` : undefined;
    const maxWidth = maxWidthInPx !== undefined ? `${maxWidthInPx}px` : undefined;
    const width = grow ? "100%" : ((isMobile ? (minWidth ?? maxWidth) : (maxWidth ?? minWidth)) ?? "auto");

    const {props: fadeInProps} = useFadeInTransition(selectedLabel ?? "");

    const svgColor = enabled ? Palette.gray800 : Palette.gray300;

    return (
        <LayoutContainer title={selectedLabel} $grow={grow}>
            <ButtonContainer
                ref={buttonRef}
                $enabled={enabled}
                $hovered={hovered}
                $selecting={selecting}
                $width={width}
                $heightInPx={buttonHeightInPx}
            >
                <ButtonLabelContainer style={{
                    ...labelProps,
                    ...fadeInProps,
                }}>{selectedLabel}</ButtonLabelContainer>
                <SVG
                    asset={SVGAssets.Down}
                    width={"24px"}
                    height={"24px"}
                    color={svgColor}
                />
            </ButtonContainer>
            <DropdownContainer
                ref={dropdownRef}
                $selecting={selecting}
                $width={width}
                $heightInPx={dropdownMaxHeightInPx}
                $paddingInPx={dropdownPaddingInPx}
            >
                {labels.map((label, index) => (
                    <DropdownButtonItem
                        key={label}
                        selected={index === selectedIndex}
                        heightInPx={itemHeightInPx}
                        label={label}
                        onClick={onItemClick(index)}
                    />
                ))}
            </DropdownContainer>
        </LayoutContainer>
    );
};

export default DropdownButton;
