import Prescription from "domain/model/prescription/prescription";
import PrescriptionMedicationRequestDTO, {
    PrescriptionMedicationRequestDTOMap
} from "data/dto/prescription/prescription_medication_request_dto";
import StringHelper from "config/helper/string_helper";
import DateTimeHelper from "config/helper/date_time_helper";
import PrescriptionMedicationType from "domain/model/prescription/prescription_medication_type";

type PrescriptionRequestDTO = {
    patientCategory: optional<string>;
    patientName: optional<string>;
    issuanceDate: string;
    issuanceNumber: optional<string>;
    patientRrn: optional<string>;
    selfPayCode: optional<string>;
    medicalInstName: optional<string>;
    nursingInstNumber: optional<string>;
    licenseNumber: optional<string>;
    diseaseCodes: string[];
    prescriptionRef: optional<string>;
    internalPrescriptionContents: PrescriptionMedicationRequestDTO[];
    injectionPrescriptionContents: PrescriptionMedicationRequestDTO[];
    expenseCalculated: boolean;
    patientExpense: optional<number>;
    dispensingExpense: optional<number>;
    billingExpense: optional<number>;
    fileKey: string;
}

export const PrescriptionRequestDTOMap = {
    fromDomainModel: (d: Prescription): PrescriptionRequestDTO => {
        return {
            patientCategory: d.insuranceType,
            patientName: StringHelper.toUndefinedIfEmpty(d.patientName.value),
            issuanceDate: DateTimeHelper.toServerDate(d.issueDate.value),
            issuanceNumber: StringHelper.toUndefinedIfEmpty(d.issueNumber.value),
            patientRrn: StringHelper.toUndefinedIfEmpty(d.patientSSN.value),
            selfPayCode: StringHelper.toUndefinedIfEmpty(d.selfPayCode.value),
            medicalInstName: StringHelper.toUndefinedIfEmpty(d.nursingAgencyName.value),
            nursingInstNumber: StringHelper.toUndefinedIfEmpty(d.nursingAgencyNumber.value),
            licenseNumber: StringHelper.toUndefinedIfEmpty(d.doctorLicenseNumber.value),
            diseaseCodes: d.diseaseCodes.map((c) => StringHelper.toUndefinedIfEmpty(c.value)).filter((c) => !!c).map((c) => c!),
            prescriptionRef: d.extraRemarks.value,
            internalPrescriptionContents: d.medications.filter((m) => m.type === PrescriptionMedicationType.Normal)
                .map(PrescriptionMedicationRequestDTOMap.fromDomainModel),
            injectionPrescriptionContents: d.medications.filter((m) => m.type === PrescriptionMedicationType.Injection)
                .map(PrescriptionMedicationRequestDTOMap.fromDomainModel),
            expenseCalculated: false,
            patientExpense: undefined,
            dispensingExpense: undefined,
            billingExpense: undefined,
            fileKey: d.fileKey,
        };
    },
}

export default PrescriptionRequestDTO;