import styled from "styled-components";
import PageTitle from "presentation/components/common/page_title";
import S from "presentation/theme/s";
import PageLayout from "presentation/components/common/page_layout";
import {FormEvent, useCallback, useEffect, useRef, useState} from "react";
import FilledButton from "presentation/components/button/filled_button";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import SizedBox from "presentation/components/common/sized_box";
import SectionTitle from "presentation/components/common/section_title";
import {TextField} from "presentation/components/text_field/text_field";
import {useRecoilValue, useSetRecoilState} from "recoil";
import certVerificationSelector from "presentation/states/cert/selector/cert_verification_selector";
import certState, {CertSMSVerificationState} from "presentation/states/cert/cert_state";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import DateTime from "presentation/utils/class/date_time";
import CertConstants from "presentation/states/cert/constants/cert_constants";
import CertRepository from "data/repository/cert_repository";
import useRepository from "presentation/utils/hooks/use_repository";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import ErrorCode from "domain/model/common/error_code";
import useShowErrorDialog from "presentation/utils/hooks/use_show_error_dialog";
import useReadRecoilState from "presentation/utils/hooks/use_read_recoil_state";
import {delayed} from "presentation/utils/functions/delayed";
import AppRouter from "presentation/routes/app_router";
import {PrescriptionHistoryPageArgs} from "presentation/pages/prescription_history/prescription_history_page";
import AppRoute from "presentation/routes/model/app_route";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";

const LayoutContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
`;

const CountdownLabelContainer = styled.p`
    min-width: 34px;
    ${Fonts.detail1};
    color: ${Palette.primary500};
    text-align: center;
`;

const FixedContainer = styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 100;
`;

const CertVerificationSMSView = () => {
    const theme = useThemeContext();
    const repository = useRepository(CertRepository);
    const setLoading = useSetLoading();
    const showErrorDialog = useShowErrorDialog();

    const {verification} = useRecoilValue(certVerificationSelector);
    const setState = useSetRecoilState(certState);
    const readState = useReadRecoilState(certState);
    const state = verification as CertSMSVerificationState;

    const idRef = useRef<optional<NodeJS.Timer>>(undefined);
    const [focused, setFocused] = useState(true);
    const [countdownSeconds, setCountdownSeconds] = useState<number>(CertConstants.maxCountdownSeconds);

    const reset = (immediate: boolean) => {
        clearInterval(idRef.current);

        return setTimeout(() => setState((prev) => ({
            ...prev,
            verification: undefined,
        })), immediate ? 0 : 5 * 1000);
    };

    useEffect(() => {
        idRef.current = setInterval(() => {
            const diff = DateTime.now().subtract(state.startTime, "second");

            setCountdownSeconds(CertConstants.maxCountdownSeconds - diff);

            if (diff <= 0) clearInterval(idRef.current);
        }, 1000);

        return () => {
            clearInterval(idRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!countdownSeconds) return;

        const id = reset(false);

        return () => {
            clearTimeout(id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countdownSeconds]);

    const formatCountdownSeconds = useCallback(
        (countdownSeconds: number) => {
            if (countdownSeconds <= 0) return "00:00";

            const minutes = Math.floor(countdownSeconds / 60);
            const seconds = Math.floor(countdownSeconds % 60);

            return `${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")}`;
        },
        []
    );

    const onCodeChange = (code: string) => setState((prev) => {
        if (!prev.verification) return prev;

        return {
            ...prev,
            verification: {
                ...(prev.verification as CertSMSVerificationState),
                code,
            },
        };
    });

    const onClick = () => repository({
        handler: async (repository) => {
            setLoading(true, {
                darken: true,
                spinner: true,
            });

            await delayed(3 * 1000);

            const state = await readState();
            const verification = (state.verification as CertSMSVerificationState);

            if (!verification.userData) {
                showErrorDialog({
                    title: S.certPage.error.errorDialogTitle,
                    message: S.certPage.error.invalidInputErrorMessage,
                });
                return;
            }

            const userInfo = {
                name: state.user.name,
                ssnPrefix: state.user.ssnPrefix,
                ssnSuffix: state.user.ssnSuffix,
                phoneNumber: state.user.phoneNumber,
            };

            const prescriptions = await repository.verifySMSCert({
                name: state.user.name,
                ssnPrefix: state.user.ssnPrefix,
                ssnSuffix: state.user.ssnSuffix,
                phoneNumber: state.user.phoneNumber,
                isp: state.user.isp,
                code: verification.code,
                userData: verification.userData,
            });

            AppRouter.navigate(AppRoute.PrescriptionHistory, {
                state: {
                    userInfo,
                    prescriptions,
                } satisfies PrescriptionHistoryPageArgs,
            });
        },
        onDomainError: async (errorCode, showUnknownError) => {
            switch (errorCode) {
                case ErrorCode.InvalidInput:
                    showErrorDialog({
                        title: S.certPage.error.errorDialogTitle,
                        message: S.certPage.error.invalidInputErrorMessage,
                        onDismissed: () => reset(true),
                    });
                    break;

                case ErrorCode.TextCertVerificationError:
                    showErrorDialog({
                        title: S.certPage.error.errorDialogTitle,
                        message: S.certPage.error.verificationErrorMessage,
                    });
                    break;

                case ErrorCode.WebCrawlingError:
                    showErrorDialog({
                        title: S.certPage.error.errorDialogTitle,
                        message: S.certPage.error.webCrawlingErrorMessage,
                        onDismissed: () => reset(true),
                    });
                    break;

                default:
                    showUnknownError({
                        onDismissed: () => reset(true),
                    });
                    break;
            }
        },
        onFinally: () => setLoading(false),
    });

    const onSubmit = (e: FormEvent) => e.preventDefault();

    const buttonEnabled = countdownSeconds > 0 && state.code.length === CertConstants.maxCodeLength;

    const buttonWidth = `calc(min(${theme.componentTheme.maxPageWidthInRem}, 100vw) - ${theme.componentTheme.pageHorizontalPaddingInPx * 2}px)`;

    useEffect(() => {
        if (state.code.length === CertConstants.maxCodeLength) {
            setFocused(false);
            onClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.code]);

    return <PageLayout>
        <LayoutContainer onSubmit={onSubmit} autoComplete={"off"}>
            <PageTitle title={S.certPage.verification.sms.title}/>
            <SizedBox height={"36px"}/>
            <SectionTitle title={S.certPage.verification.sms.codeSection.title}>
                <RowContainer>
                    <TextField
                        value={state.code}
                        onValueChange={onCodeChange}
                        focused={focused}
                        onFocusChange={setFocused}
                        placeholder={S.certPage.verification.sms.codeSection.placeholder}
                        inputMode={"numeric"}
                        maxLength={CertConstants.maxCodeLength}
                        inputFormatter={InputFormatters.decimalOnlyFormatter}
                    />
                    <CountdownLabelContainer>
                        {formatCountdownSeconds(countdownSeconds)}
                    </CountdownLabelContainer>
                </RowContainer>
            </SectionTitle>
            <SizedBox height={"100px"}/>

            <FixedContainer>
                <FilledButton
                    enabled={buttonEnabled}
                    width={buttonWidth}
                    label={S.certPage.verification.sms.buttonLabel}
                    onClick={onClick}
                />
            </FixedContainer>
        </LayoutContainer>
    </PageLayout>;
};

export default CertVerificationSMSView;
