enum ErrorCode {
    Unknown = "000000",
    ServerSentError = "0000000",
    Cancelled = "000001",
    NetworkError = "000002",
    InvalidPassword = "000003",
    InvalidUser = "000005",
    WebCrawlingError = "200001",
    TextCertVerificationError = "90101",
    SimpleCertInputError = "90102",
    SimpleCertVerificationIncomplete = "90103",
    SimpleCertExpired = "90104",
    InvalidInput = "300001",
}

export default ErrorCode;
