import styled from "styled-components";
import {useRecoilValue, useSetRecoilState} from "recoil";
import shootPrescriptionsSelector from "presentation/states/shoot/selector/shoot_prescriptions_selector";
import shootState from "presentation/states/shoot/shoot_state";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import NumberHelper from "config/helper/number_helper";
import {animated} from "@react-spring/web";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import SizedBox from "presentation/components/common/sized_box";
import ShootPrescriptionMedicationHeader
    from "presentation/pages/shoot/components/shoot_prescription_medication_header";
import ShootPrescriptionMedicationRow from "presentation/pages/shoot/components/shoot_prescription_medication_row";
import PrescriptionMedication from "domain/model/prescription/prescription_medication";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

const HeaderContainer = styled(animated.h4)`
    min-width: max-content;
    ${Fonts.detail3};
`;

const ShootPrescriptionMedicationsView = () => {
    const {prescription} = useRecoilValue(shootPrescriptionsSelector);
    const setState = useSetRecoilState(shootState);

    const headerLabel = S.shootPage.shootPrescriptionView.medicationsView.headerTitle(
        NumberHelper.toStringWithDividers(prescription.medications.length)
    );
    const {props: headerLabelFadeInProps} = useFadeInTransition(headerLabel);

    const onMedicationChange = (medication: PrescriptionMedication) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        const medications = [...prescriptions[index].medications];
        const medicationIndex = medications.findIndex((c) => c.id === medication.id);

        if (medicationIndex < 0) return prev;

        medications[medicationIndex] = medication;

        prescriptions[index] = {
            ...prescriptions[index],
            medications,
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    const onDeleteButtonClick = (medication: PrescriptionMedication) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        const medications = prescriptions[index].medications.filter((m) => m.id !== medication.id);

        prescriptions[index] = {
            ...prescriptions[index],
            medications,
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    return <LayoutContainer>
        <HeaderContainer style={headerLabelFadeInProps}>
            {headerLabel}
        </HeaderContainer>
        <SizedBox height={"8px"}/>
        <ShootPrescriptionMedicationHeader/>
        <SizedBox height={"2px"}/>
        {
            prescription.medications.map((m, index) =>
                <ShootPrescriptionMedicationRow
                    key={m.id}
                    hasBottomMargin={index < prescription.medications.length - 1}
                    medication={m}
                    onMedicationChange={onMedicationChange}
                    onDeleteButtonClick={onDeleteButtonClick}
                />
            )
        }
    </LayoutContainer>;
};

export default ShootPrescriptionMedicationsView;
