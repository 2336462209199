import useLifeCycle from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import useSearchParams from "presentation/utils/hooks/use_search_params";
import {useState} from "react";
import ViewStatusType from "domain/model/common/view_status_type";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import certState from "presentation/states/cert/cert_state";
import Page from "presentation/components/common/page";
import CertVerificationView from "presentation/pages/cert/verification/cert_verification_view";
import CertUserView from "presentation/pages/cert/user/cert_user_view";
import certUserViewVisibleSelector from "presentation/states/cert/selector/cert_user_view_visible_selector";
import CertConstants from "presentation/states/cert/constants/cert_constants";
import HospitalRepository from "data/repository/hospital_repository";
import {ISPType, ISPTypes} from "domain/model/cert/isp_type";
import CertType, {CertTypes} from "domain/model/cert/cert_type";
import {InputValidationStatusTypeMap} from "domain/model/common/input_validation_status_type";
import InputValidator from "presentation/utils/input_validator/input_validator";

const CertPage = () => {
    const repository = useRepository(HospitalRepository);
    const searchParam = useSearchParams();

    const userViewVisible = useRecoilValue(certUserViewVisibleSelector);
    const setState = useSetRecoilState(certState);
    const resetState = useResetRecoilState(certState);

    const [status, setStatus] = useState(ViewStatusType.Loaded);

    useLifeCycle({
        onPreInit: () => repository({
            handler: async (repository) => {
                const hospital = searchParam("hospital");

                if (!hospital) {
                    setStatus(ViewStatusType.Error);
                    return;
                }

                repository.setHospital({hospital});

                const name = searchParam("PatientName") ?? "";
                const ssnPrefix = searchParam("PatientJumin1") ?? "";
                const ssnSuffix = searchParam("PatientJumin2") ?? "";
                const phoneNumber = searchParam("Phone") ?? "";
                const isp = searchParam("Isp") ?? ISPType.SKT;
                const certType = searchParam("CertType") ?? CertType.Kakao;

                const validName = name.length <= CertConstants.maxNameLength;
                const validSsnPrefix =
                    ssnPrefix.length <= CertConstants.maxSsnPrefixLength &&
                    InputValidationStatusTypeMap.isValid(InputValidator.checkDecimal(ssnPrefix));
                const validSsnSuffix =
                    ssnSuffix.length <= CertConstants.maxSsnSuffixLength &&
                    InputValidationStatusTypeMap.isValid(InputValidator.checkDecimal(ssnSuffix));
                const validPhoneNumber =
                    phoneNumber.length <= CertConstants.maxPhoneNumberLength &&
                    InputValidationStatusTypeMap.isValid(InputValidator.checkDecimal(phoneNumber));
                const validISP = !!ISPTypes.find((t) => t === isp as ISPType);
                const validCertType = !!CertTypes.find((t) => t === certType as CertType);

                setState((prev) => ({
                    ...prev,
                    user: {
                        ...prev.user,
                        name: validName ? name : "",
                        ssnPrefix: validSsnPrefix ? ssnPrefix : "",
                        ssnSuffix: validSsnSuffix ? ssnSuffix : "",
                        phoneNumber: validPhoneNumber ? phoneNumber : "",
                        isp: validISP ? isp as ISPType : ISPType.SKT,
                        certType: validCertType ? certType as CertType : CertType.Kakao,
                    },
                }));

                setStatus(ViewStatusType.Loaded);
            },
        }),
        onDispose: () => resetState(),
    });

    return <Page status={status}>
        {userViewVisible && <CertUserView/>}
        {!userViewVisible && <CertVerificationView/>}
    </Page>
};

export default CertPage;
