import secureStorageInstance from "data/local/secure_storage/secure_storage";
import httpClientInstance from "data/network/http_client/http_client";
import BaseRepository from "data/repository/base_repository";
import CommonError from "domain/model/common/common_error";
import DevLogType from "domain/model/common/dev_log_type";
import DomainError from "domain/model/common/domain_error";
import ErrorCode from "domain/model/common/error_code";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import useShowErrorDialog from "presentation/utils/hooks/use_show_error_dialog";
import devLog from "data/utils/functions/dev_log";
import isAsync from "presentation/utils/functions/is_async";

const useRepository = <Repository extends BaseRepository>(
    repository: new (...args: any[]) => Repository
) => {
    const showErrorDialog = useShowErrorDialog();
    const showNotificationDialog = useShowNotificationDialog();

    return async <T>({
                         handler,
                         onDomainError,
                         onFinally,
                     }: {
        handler: (repository: Repository) => Promise<T>;
        onDomainError?: ((
            errorCode: ErrorCode,
            showUnknownError: (props?: { title?: string; message?: string; onDismissed?: (() => void); }) => void
        ) => Promise<void>) | ((
            errorCode: ErrorCode,
            showUnknownError: (props?: { title?: string; message?: string; onDismissed?: (() => void); }) => void
        ) => void);
        onFinally?: () => void;
    }): Promise<optional<T>> => {
        const instance = new repository(
            httpClientInstance,
            secureStorageInstance
        );

        try {
            return await handler(instance);
        } catch (e) {
            if (e instanceof CommonError) {
                switch (e.errorCode) {
                    case ErrorCode.ServerSentError:
                        showNotificationDialog({
                            type: "negative",
                            title: e.title,
                            message: e.message,
                            hasNegativeButton: false,
                        });
                        break;

                    default:
                        break;
                }
                return;
            }

            if (!(e instanceof DomainError)) {
                devLog({
                    logType: DevLogType.Error,
                    message: e,
                    format: false,
                });
                return;
            }

            if (e.errorCode === ErrorCode.Cancelled) return;

            if (!onDomainError) {
                switch (e.errorCode) {
                    default:
                        showErrorDialog();
                        return;
                }
            }


            if (isAsync(onDomainError)) {
                await onDomainError?.(e.errorCode, showErrorDialog);
            } else {
                onDomainError?.(e.errorCode, showErrorDialog);
            }
        } finally {
            onFinally?.();
        }
    };
};

export default useRepository;
