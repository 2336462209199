import Palette from "presentation/theme/palette";
import styled from "styled-components";

const LayoutContainer = styled.div<{
    $width: string;
    $height: string;
    $backgroundColor: string;
}>`
    min-width: ${(props) => props.$width};
    max-width: ${(props) => props.$width};
    min-height: ${(props) => props.$height};
    max-height: ${(props) => props.$height};
    background-color: ${(props) => props.$backgroundColor};
    transition: min-width 0.3s ease-in-out, max-width 0.3s ease-in-out,
        min-height 0.3s ease-in-out, max-height 0.3s ease-in-out;
`;

const SizedBox = ({
                      width = "0px",
                      height = "0px",
                      backgroundColor = Palette.none,
                  }: {
    width?: string;
    height?: string;
    backgroundColor?: string;
}) => {
    return (
        <LayoutContainer
            $width={width}
            $height={height}
            $backgroundColor={backgroundColor}
        />
    );
};

export default SizedBox;
