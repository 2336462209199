import {AnimationTheme, ComponentTheme, FontTheme,} from "presentation/theme/theme";
import {createContext, PropsWithChildren} from "react";
import animationTheme from "presentation/theme/animation";
import {fontTheme} from "presentation/theme/fonts";
import componentTheme from "presentation/theme/component";

type ThemeContextProps = {
    animationTheme: AnimationTheme;
    fontTheme: FontTheme;
    componentTheme: ComponentTheme;
};

export const ThemeContext = createContext<ThemeContextProps>({
    animationTheme,
    fontTheme,
    componentTheme,
});

const ThemeProvider = ({
                           animationTheme,
                           fontTheme,
                           componentTheme,
                           children,
                       }: PropsWithChildren<ThemeContextProps>) => {
    return (
        <ThemeContext.Provider
            value={{animationTheme, fontTheme, componentTheme}}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
