enum AppRoute {
    AutoLogin = "/ocr/autoLogin",
    Cert = "/cert",
    Login = "/ocr/login",
    Main = "/ocr",
    MyPage = "/ocr/myPage",
    PrescriptionHistory = "/prescriptionHistory",
    Shoot = "/ocr/shoot",
    SimpleCert = "/simpleCert",
    TextCert = "/textCert",
}

export const AppRoutes = Object.values(AppRoute);

export const AppRouteMap = {
    fromPathname: (pathname: string): optional<AppRoute> => {
        return AppRoutes.find((r) => r === pathname);
    },
};

export default AppRoute;
