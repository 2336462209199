import {animated} from "@react-spring/web";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import styled from "styled-components";

const LayoutContainer = styled.div`
    width: 100%;
    flex-grow: 1;
    position: relative;
`;

const NormalValueContainer = styled(animated.p)`
    width: max-content;
    height: max-content;
    ${Fonts.detail3Medium};
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
`;

const HighlightedLayoutContainer = styled.div`
    width: fit-content;
    height: 1.75rem;
    border-radius: 8px;
    box-shadow: 0 0 0 1px ${Palette.primary300} inset;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Palette.primary100};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
`;

const HighlightedValueContainer = styled(animated.p)`
    width: max-content;
    height: max-content;
    padding: 0 8px;
    ${Fonts.detail3};
    text-align: center;
    color: ${Palette.primary500};
`;

const CalendarCellSuffix = ({
                                highlighted,
                                value,
                            }: {
    highlighted: boolean;
    value?: string;
}) => {
    const {props} = useFadeInTransition(value ?? "");

    return (
        <LayoutContainer>
            {highlighted ? (
                <HighlightedLayoutContainer>
                    <HighlightedValueContainer style={props}>
                        {value}
                    </HighlightedValueContainer>
                </HighlightedLayoutContainer>
            ) : (
                <NormalValueContainer style={props}>
                    {value}
                </NormalValueContainer>
            )}
        </LayoutContainer>
    );
};

export default CalendarCellSuffix;
