import styled from "styled-components";
import useDeviceChange from "presentation/utils/hooks/use_device_change";
import useCamera from "presentation/utils/hooks/use_camera";
import useMediaStream from "presentation/utils/hooks/use_media_stream";
import {
    ChangeEvent,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState
} from "react";
import {MainPageContext} from "presentation/pages/main/main_page";
import {animated, useSpring} from "@react-spring/web";
import useRepository from "presentation/utils/hooks/use_repository";
import PrescriptionRepository from "data/repository/prescription_repository";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import {useSetRecoilState} from "recoil";
import shootState from "presentation/states/shoot/shoot_state";
import ShootConstants from "presentation/states/shoot/constants/shoot_constants";
import {AudioAssets} from "presentation/theme/assets";

const LayoutContainer = styled.div`
    width: 100%;
    position: fixed;
    pointer-events: none;
    overflow: hidden;
    visibility: hidden;
`;

const FileUploadContainer = styled.input`
    display: none;
    width: 0;
    height: 0;
`;

const ShootCameraView = forwardRef<HTMLVideoElement, {
    onCameraConnectionChange: (cameraDeviceID?: string) => void;
}>(({
        onCameraConnectionChange,
    }, forwardRef) => {
    const context = useContext(MainPageContext);
    const getCamera = useCamera();
    const getMediaStream = useMediaStream();
    const repository = useRepository(PrescriptionRepository);
    const setLoading = useSetLoading();

    const setState = useSetRecoilState(shootState);

    const fileUploadRef = useRef<HTMLInputElement>(null);
    const videoRef = useRef<nullable<HTMLVideoElement>>(null);
    const canvasRef = useRef<nullable<HTMLCanvasElement>>(null);
    const processingRef = useRef(false);
    const [audio, setAudio] = useState<nullable<HTMLAudioElement>>(null);
    const [cameraDeviceID, setCameraDeviceID] = useState<optional<string>>(undefined);
    const [videoDimensions, setVideoDimensions] = useState<{
        width: optional<number>;
        height: optional<number>;
    }>({width: undefined, height: undefined});

    useImperativeHandle(forwardRef, () => videoRef.current!, []);

    useEffect(() => {
        const audio = new Audio(AudioAssets.ShutterSound);
        setAudio(audio);
    }, []);

    useDeviceChange(async () => {
        const camera = await getCamera(ShootConstants.camera);

        if (!camera) {
            setCameraDeviceID(undefined);
            return;
        }

        try {
            const deviceID = camera.data.deviceId;
            const stream = await getMediaStream({
                deviceID,
                ...ShootConstants.camera,
            });

            const video = videoRef.current;
            if (!video) return;
            video.srcObject = stream;
            video.muted = true;
            video.play()
                .then(() => {
                    setVideoDimensions({
                        width: video.videoWidth,
                        height: video.videoHeight,
                    });
                    setCameraDeviceID(deviceID);
                })
                .catch(() => setCameraDeviceID(undefined));
        } catch (e) {
            console.log(e);
        }
    });

    const convertImage = useCallback((blob: Blob) => repository({
            handler: async (repository) => {
                setLoading(true, {
                    darken: true,
                    spinner: true,
                });

                const prescription = await repository.convertPrescription({prescription: blob});

                setState((prev) => ({
                    ...prev,
                    prescriptions: prev.prescriptions.concat(prescription),
                    selectedPrescriptionID: prescription.id,
                }));
            },
            onFinally: () => {
                setLoading(false);
                processingRef.current = false;
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const shoot = useCallback(() => {
        if (!audio) return;
        if (processingRef.current) return;
        processingRef.current = true;
        setLoading(true, {
            darken: true,
            spinner: true,
        });

        if (context.userPreferences.cameraSoundEnabled) {
            audio.play();
        }

        const canvas = canvasRef.current;
        const video = videoRef.current;
        if (!canvas || !video) {
            setLoading(false);
            processingRef.current = false;
            return;
        }

        const canvasContext = canvas.getContext("2d");
        if (!canvasContext) {
            setLoading(false);
            processingRef.current = false;
            return;
        }

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        canvas.width = videoHeight;
        canvas.height = videoWidth;

        canvasContext.translate(videoWidth / 2, videoHeight / 2);
        canvasContext.rotate(270 * Math.PI / 180);
        canvasContext.translate(-videoWidth / 2, -videoHeight / 2);
        canvasContext.drawImage(
            video,
            0,
            0,
            videoWidth,
            videoHeight,
            -videoHeight * 0.167,
            -videoHeight * 0.167,
            videoWidth,
            videoHeight,
        );
        canvas.toBlob((blob) => {
            if (!blob) return;
            convertImage(blob);
        }, "image/jpeg", 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audio, context.userPreferences.cameraSoundEnabled, convertImage]);

    useLayoutEffect(() => {
        context.setOnShootButtonClick(() => shoot);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shoot]);

    useEffect(() => {
        const onKeyUp = (e: KeyboardEvent) => {
            e.stopPropagation();
            e.preventDefault();
            if (e.code !== "F10") return;
            fileUploadRef.current?.click();
        };

        window.addEventListener("keyup", onKeyUp);

        return () => {
            window.removeEventListener("keyup", onKeyUp);
        };
    }, []);

    useEffect(() => {
        onCameraConnectionChange(cameraDeviceID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onCameraConnectionChange, cameraDeviceID]);

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        convertImage(file);
    };

    const canvasProps = useSpring({
        width: videoDimensions.height,
        height: videoDimensions.width,
        immediate: true,
    });

    return <LayoutContainer>
        <FileUploadContainer ref={fileUploadRef} type={"file"} onChange={onFileChange}/>
        <video ref={videoRef}/>
        <animated.canvas ref={canvasRef} style={canvasProps}/>
    </LayoutContainer>;
});

export default ShootCameraView;
