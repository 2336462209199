import styled from "styled-components";
import {animated, useSpring} from "@react-spring/web";
import useAnimatedMount from "presentation/utils/hooks/use_animated_mount";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import Palette from "presentation/theme/palette";
import FilledButton from "presentation/components/button/filled_button";
import S from "presentation/theme/s";
import SVGAssets from "presentation/theme/assets";
import IconButton from "presentation/components/button/icon_button";
import {useSetListenableState} from "presentation/utils/hooks/use_set_listenable_state";
import shootState, {shootListenableState} from "presentation/states/shoot/shoot_state";
import {ShootShowRealtimeScreen} from "presentation/states/shoot/shoot_listenable_state";
import {useRecoilValue, useSetRecoilState} from "recoil";
import shootPrescriptionsSelector from "presentation/states/shoot/selector/shoot_prescriptions_selector";
import useDownload from "presentation/utils/hooks/use_download";
import ShootPrescriptionHeader from "presentation/pages/shoot/components/shoot_prescription_header";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import useReadRecoilState from "presentation/utils/hooks/use_read_recoil_state";
import SizedBox from "presentation/components/common/sized_box";
import ShootPrescriptionView from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_view";
import useRepository from "presentation/utils/hooks/use_repository";
import PrescriptionRepository from "data/repository/prescription_repository";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import useShowToast from "presentation/utils/hooks/use_show_toast";
import NumberHelper from "config/helper/number_helper";
import {useContext, useRef} from "react";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import ShootPrescriptionImage from "presentation/pages/shoot/components/shoot_prescription_image";
import {PrescriptionMap} from "domain/model/prescription/prescription";
import {MainPageContext} from "presentation/pages/main/main_page";

const LayoutContainer = styled(animated.div)`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

const PrescriptionsLayoutContainer = styled.div`
    flex: 626;
    min-width: 626px;
    height: 100vh;
    padding: 8px;
    background-color: ${Palette.gray200};
    overflow-y: auto;

    scrollbar-color: ${Palette.none} ${Palette.none};
    transition: scrollbar-color 0.3s ease-in-out;

    &:hover {
        scrollbar-color: ${Palette.gray300} ${Palette.none};
    }
`;

const ImageViewLayoutContainer = styled.div`
    flex: 546;
    min-width: 546px;
    height: 100vh;
    background-color: ${Palette.black100};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const ImageHeaderContainer = styled.div`
    width: 100%;
    padding: 8px 16px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
`;

const ShootPrescriptionsView = () => {
    const context = useContext(MainPageContext);
    const theme = useThemeContext();
    const isMobile = useMobileQuery();
    const repository = useRepository(PrescriptionRepository);
    const setListenableState = useSetListenableState(shootListenableState);
    const download = useDownload();
    const showNotificationDialog = useShowNotificationDialog();
    const setLoading = useSetLoading();
    const showToast = useShowToast();

    const {prescriptions, selectedPrescriptionID, prescription} = useRecoilValue(shootPrescriptionsSelector);
    const setState = useSetRecoilState(shootState);
    const readState = useReadRecoilState(shootState);

    const imageHeaderRef = useRef<HTMLDivElement>(null);
    const {height: headerHeight} = useResizeObserver(imageHeaderRef, [], 0);

    const imageMaxHeight = `calc(100vh - ${headerHeight})`;
    const lnbWidthInPx = isMobile ? theme.componentTheme.lnbMinWidthInPx : theme.componentTheme.lnbMaxWidthInPx;

    const {props: animatedMountProps} = useAnimatedMount();
    const layoutProps = useSpring({
        minWidth: 1280 - lnbWidthInPx,
        width: "100%",
    });

    const onSelectedPrescriptionIDChange = (prescriptionID: string) => setState((prev) => ({
        ...prev,
        selectedPrescriptionID: prescriptionID
    }));

    const onDeletePrescription = (id: string) => setState((prev) => {
        const getNextIndex = (index: number) => index + 1 < prev.prescriptions.length ? index + 1 : 0;
        const newPrescriptions = prev.prescriptions.filter((p) => p.id !== id);
        const deletePrescriptionIndex = prev.prescriptions.findIndex((p) => p.id === id);
        let count = 0;
        let index = deletePrescriptionIndex;
        let selectedPrescription = undefined;
        while (count < prev.prescriptions.length) {
            const nextIndex = getNextIndex(index);
            selectedPrescription = newPrescriptions.find((p) => p.id === prev.prescriptions[nextIndex].id);
            if (selectedPrescription !== undefined) break;

            count++;
            index++;
        }

        return {
            ...prev,
            prescriptions: newPrescriptions,
            selectedPrescriptionID: selectedPrescription?.id,
        };
    });

    const onDeleteButtonClick = async (selectedPrescriptionID: string) => {
        const state = await readState();
        showNotificationDialog({
            className: "delete-prescription-dialog",
            type: "negative",
            title: S.shootPage.shootPrescriptionsView.deletePrescriptionDialog.title((state.prescriptions.find((p) => p.id === selectedPrescriptionID)?.patientName.value ?? "")),
            message: S.shootPage.shootPrescriptionsView.deletePrescriptionDialog.message,
            hasNegativeButton: false,
            onPositiveButtonClick: () => onDeletePrescription(selectedPrescriptionID),
        });
    };

    const onSendButtonClick = (selectedPrescriptionID: string) => repository({
        handler: async (repository) => {
            setLoading(true, {
                darken: true,
                spinner: true,
            });

            const state = await readState();
            const prescription = state.prescriptions.find((p) => p.id === selectedPrescriptionID);
            if (!prescription) return;
            if (!PrescriptionMap.validForSending(prescription)) return;

            await repository.sendPrescription({prescription});

            showToast({
                title: S.shootPage.shootPrescriptionsView.sendSuccessToast.title,
                message: S.shootPage.shootPrescriptionsView.sendSuccessToast.message(
                    prescription.issueDate.value.format(S.shootPage.shootPrescriptionsView.sendSuccessToast.issueDateFormat),
                    prescription.patientName.value,
                    prescription.nursingAgencyName.value,
                ),
            });
            onDeletePrescription(prescription.id);
        },
        onFinally: () => setLoading(false),
    });

    const onDeleteAllButtonClick = async () => {
        const state = await readState();
        showNotificationDialog({
            className: "delete-all-prescriptions-dialog",
            type: "negative",
            title: S.shootPage.shootPrescriptionsView.deleteAllPrescriptionsDialog.title(state.prescriptions.length.toString()),
            message: S.shootPage.shootPrescriptionsView.deleteAllPrescriptionsDialog.message,
            hasNegativeButton: false,
            onPositiveButtonClick: () => setState((prev) => ({
                ...prev,
                prescriptions: [],
                selectedPrescriptionID: undefined,
            })),
        })
    };

    const onSendAllButtonClick = () => repository({
        handler: async (repository) => {
            setLoading(true, {
                darken: true,
                spinner: true,
            });

            const state = await readState();

            const validForSendingPrescriptions = state.prescriptions.filter((p) => PrescriptionMap.validForSending(p));

            if (!validForSendingPrescriptions.length) {
                showNotificationDialog({
                    className: "no-valid-for-sending-prescriptions-available-dialog",
                    type: "negative",
                    title: S.shootPage.shootPrescriptionsView.noValidForSendingPrescriptionsAvailableDialog.title,
                    message: S.shootPage.shootPrescriptionsView.noValidForSendingPrescriptionsAvailableDialog.message,
                    hasNegativeButton: false,
                });
                return;
            }

            const failedFileKeys = await repository.sendPrescriptions({prescriptions: validForSendingPrescriptions});

            const success = state.prescriptions.filter((p) => !failedFileKeys.includes(p.fileKey));
            const failed = state.prescriptions.filter((p) => failedFileKeys.includes(p.fileKey));

            success.forEach((p) => {
                showToast({
                    title: S.shootPage.shootPrescriptionsView.sendSuccessToast.title,
                    message: S.shootPage.shootPrescriptionsView.sendSuccessToast.message(
                        p.issueDate.value.format(S.shootPage.shootPrescriptionsView.sendSuccessToast.issueDateFormat),
                        p.patientName.value,
                        p.nursingAgencyName.value,
                    ),
                });
                onDeletePrescription(p.id);
            });

            if (!!failed.length) {
                showNotificationDialog({
                    type: "negative",
                    title: S.shootPage.shootPrescriptionsView.sendErrorDialog.title,
                    message: S.shootPage.shootPrescriptionsView.sendErrorDialog.message(NumberHelper.toStringWithDividers(failed.length)),
                    hasNegativeButton: false,
                });
            }
        },
        onFinally: () => setLoading(false),
    });

    const onRealtimeScreenButtonClick = () => setListenableState(new ShootShowRealtimeScreen());

    const soundButtonIconAsset = context.userPreferences.cameraSoundEnabled ? SVGAssets.SoundControl : SVGAssets.SoundControlMute;

    const onSoundButtonClick = () => context.setUserPreferences((prev) => ({
        ...prev,
        cameraSoundEnabled: !prev.cameraSoundEnabled,
    }));

    const onDownloadButtonClick = () => download(prescription.imageURL, "prescription.jpeg");

    return <LayoutContainer style={{...animatedMountProps, ...layoutProps}}>
        <PrescriptionsLayoutContainer>
            <ShootPrescriptionHeader
                selectedPrescriptionID={selectedPrescriptionID}
                prescriptions={prescriptions}
                onSelectedPrescriptionIDChange={onSelectedPrescriptionIDChange}
                onDeleteButtonClick={onDeleteButtonClick}
                onSendButtonClick={onSendButtonClick}
                onDeleteAllButtonClick={onDeleteAllButtonClick}
                onSendAllButtonClick={onSendAllButtonClick}
            />
            <SizedBox height={"8px"}/>
            <ShootPrescriptionView key={prescription.id}/>
        </PrescriptionsLayoutContainer>
        <ImageViewLayoutContainer>
            <ImageHeaderContainer ref={imageHeaderRef}>
                <FilledButton
                    type={"gray"}
                    sizeType={"medium"}
                    label={S.shootPage.shootPrescriptionsView.realtimeScreenButtonLabel}
                    leftIconAsset={SVGAssets.LiveDot}
                    iconColor={Palette.red500}
                    onClick={onRealtimeScreenButtonClick}
                />
                <IconButton
                    asset={soundButtonIconAsset}
                    width={"24px"}
                    height={"24px"}
                    color={Palette.gray50}
                    hoverColor={"dark"}
                    onClick={onSoundButtonClick}
                />
                <IconButton
                    asset={SVGAssets.Download}
                    width={"24px"}
                    height={"24px"}
                    color={Palette.gray50}
                    hoverColor={"dark"}
                    onClick={onDownloadButtonClick}
                />
            </ImageHeaderContainer>
            <ShootPrescriptionImage
                maxHeight={imageMaxHeight}
                src={prescription.imageURL}
            />
        </ImageViewLayoutContainer>
    </LayoutContainer>;
};

export default ShootPrescriptionsView;
