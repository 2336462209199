import InputFormatter, {TextEditingValue} from "presentation/utils/input_formatters/input_formatter";

export default class DosageFormatter extends InputFormatter {
    formatEditUpdate(oldValue: TextEditingValue, newValue: TextEditingValue): TextEditingValue {
        if (this.isEraseOperation(oldValue, newValue)) return newValue;

        const newText = newValue.text;
        if (!/^(\d{0,3})$|^(\d{0,3})(\.)(\d{0,2})?$/g.test(newText)) {
            return oldValue;
        }

        return newValue;
    }
};