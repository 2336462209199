import PrescriptionExtraRemarksField from "domain/model/prescription/prescription_extra_remarks_field";
import PrescriptionExtraRemarksType from "domain/model/prescription/prescription_extra_remarks_type";
import NullHelper from "data/helper/null_helper";

type PrescriptionExtraRemarksFieldDTO = {
    value?: PrescriptionExtraRemarksType;
    needCheck: boolean;
};

export const PrescriptionExtraRemarksFieldDTOMap = {
    toDomainModel: (d: PrescriptionExtraRemarksFieldDTO): PrescriptionExtraRemarksField => {
        return {
            value: NullHelper.fromServerNullable(d.value),
            initialValue: NullHelper.fromServerNullable(d.value),
            revisionStatus: {
                confidenceLow: d.needCheck,
                needFullSSN: false,
            },
        };
    },
}

export default PrescriptionExtraRemarksFieldDTO;