import ListenableState from "presentation/states/base/listenable_state";
import {useLayoutEffect} from "react";
import {Subject} from "rxjs";

export const useListenableState = <State extends ListenableState>(
    listenableState: Subject<State>,
    listener: (state: State) => void
) => {
    useLayoutEffect(() => {
        const subscription = listenableState.subscribe(listener);

        return () => {
            subscription.unsubscribe();
        };
    }, [listenableState, listener]);
};
