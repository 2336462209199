import styled from "styled-components";
import Palette from "presentation/theme/palette";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import {animated} from "@react-spring/web";

const LayoutContainer = styled.div`
    width: 100%;
    min-height: max-content;
    padding: 24px;
    border-radius: 24px;
    background-color: ${Palette.white100};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
`;

const IconContainer = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${Palette.primary100};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const NameContainer = styled(animated.h6)`
    ${Fonts.body3};
`;

const MyPageUserInfoCard = ({
                                name
                            }: {
    name: string;
}) => {
    const {props} = useFadeInTransition(name);

    return <LayoutContainer>
        <IconContainer>
            <SVG
                asset={SVGAssets.Avatar}
                width={"48px"}
                height={"48px"}
                color={Palette.primary300}
            />
        </IconContainer>
        <NameContainer style={props}>{name}</NameContainer>
    </LayoutContainer>;
};

export default MyPageUserInfoCard;
