import styled from "styled-components";
import Fonts from "presentation/theme/fonts";
import SVGAssets, {SVGAsset} from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import SVG from "presentation/components/common/svg";

export type ToastType = "success" | "error";

const iconPresets: Record<
    ToastType,
    {
        iconAsset: SVGAsset;
        iconColor: string;
    }
> = {
    "success": {
        iconAsset: SVGAssets.CheckCircle,
        iconColor: Palette.lightGreen,
    },
    "error": {
        iconAsset: SVGAssets.Warning,
        iconColor: Palette.red500,
    },
};


const LayoutContainer = styled.div`
    min-width: max-content;
    padding: 12px 16px;
    border-radius: 16px;
    background-color: ${Palette.black75};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
`;

const ContentLayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const TitleContainer = styled.h4`
    min-width: max-content;
    ${Fonts.body1};
    color: ${Palette.white100};
    white-space: pre-wrap;
`;

const MessageContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail1};
    color: ${Palette.white100};
    white-space: pre-wrap;
`;

const Toast = ({
                   type = "success",
                   title,
                   message,
                   iconAsset,
               }: {
    type?: ToastType;
    title: string;
    message: string;
    iconAsset?: SVGAsset;
}) => {
    const _iconAsset = iconAsset ?? iconPresets[type].iconAsset;
    const _iconColor = iconPresets[type].iconColor;

    return <LayoutContainer>
        <SVG
            asset={_iconAsset}
            width={"24px"}
            height={"24px"}
            color={_iconColor}
        />
        <ContentLayoutContainer>
            <TitleContainer>{title}</TitleContainer>
            <MessageContainer>{message}</MessageContainer>
        </ContentLayoutContainer>
    </LayoutContainer>;
};

export default Toast;
