import Debouncer from "presentation/utils/debouncer/debouncer";
import {useEffect, useRef, useState} from "react";

const useWindowResizeObserver = (
    deps: readonly unknown[],
    debounce: number = 200
) => {
    const debouncerRef = useRef(new Debouncer());
    const [size, setSize] = useState({
        widthInPx: window.outerWidth,
        heightInPx: window.outerHeight,
        innerWidthInPx: window.innerWidth,
        innerHeightInPx: window.innerHeight,
    });

    useEffect(() => {
        const debouncer = debouncerRef.current;

        const onResize = () =>
            debouncer.run(() => {
                const widthInPx = window.outerWidth;
                const heightInPx = window.outerHeight;
                const innerWidthInPx = window.innerWidth;
                const innerHeightInPx = window.innerHeight;

                setSize({widthInPx, heightInPx, innerWidthInPx, innerHeightInPx});
            }, debounce);

        window.addEventListener("resize", onResize);

        onResize();

        return () => window.removeEventListener("resize", onResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const widthInPx = window.outerWidth;
        const heightInPx = window.outerHeight;
        const innerWidthInPx = window.innerWidth;
        const innerHeightInPx = window.innerHeight;

        setSize({widthInPx, heightInPx, innerWidthInPx, innerHeightInPx});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, debounce]);

    return size;
};

export default useWindowResizeObserver;
