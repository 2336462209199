import S from "presentation/theme/s";

enum PrescriptionExtraRemarksType {
    PowderedMedicine = "가루약",
}

export const PrescriptionExtraRemarksTypes = Object.values(PrescriptionExtraRemarksType);

export const PrescriptionExtraRemarksTypeMap = {
    label: (type: PrescriptionExtraRemarksType): string => {
        switch (type) {
            case PrescriptionExtraRemarksType.PowderedMedicine:
                return S.prescriptionExtraRemarksType.powderedMedicineLabel;
            default:
                throw new Error("Invalid PrescriptionExtraRemarksType");
        }
    },
};

export default PrescriptionExtraRemarksType;