import ArrowIconButton from "presentation/components/button/arrow_icon_button";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/class/date_time";
import styled from "styled-components";

const LayoutContainer = styled.div`
    min-width: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
`;

const TitleContainer = styled.h6`
    width: 7rem;
    ${Fonts.title2};
    text-align: center;
`;

const CalendarMonthHeader = ({
                                 calendarStartDate,
                                 calendarEndDate,
                                 yearMonth,
                                 onYearMonthChange,
                             }: {
    calendarStartDate?: DateTime,
    calendarEndDate?: DateTime,
    yearMonth: DateTime;
    onYearMonthChange: (yearMonth: DateTime, direction: number) => void;
}) => {
    const previousButtonEnabled = !calendarStartDate ||
        yearMonth.getFirstDayInMonth().isAfter(calendarStartDate.getFirstDayInMonth())
    const nextButtonEnabled = !calendarEndDate ||
        yearMonth.getFirstDayInMonth().isBefore(calendarEndDate.getFirstDayInMonth());

    const previousButtonClick = () => onYearMonthChange(
        yearMonth.copyWith({
            month: yearMonth.month - 1,
        }),
        -1,
    );
    const nextButtonClick = () => onYearMonthChange(
        yearMonth.copyWith({
            month: yearMonth.month + 1,
        }),
        1,
    );

    return (
        <LayoutContainer>
            <ArrowIconButton
                enabled={previousButtonEnabled}
                asset={SVGAssets.Back}
                onClick={previousButtonClick}
            />
            <TitleContainer>
                {yearMonth.format(S.calendarMonthHeader.dateFormat)}
            </TitleContainer>
            <ArrowIconButton
                enabled={nextButtonEnabled}
                asset={SVGAssets.Next}
                onClick={nextButtonClick}
            />
        </LayoutContainer>
    );
};

export default CalendarMonthHeader;
