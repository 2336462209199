import {useLocation} from "react-router-dom";
import useLifeCycle from "presentation/utils/hooks/use_life_cycle";
import UserInfo from "domain/model/user/user_info";
import PrescriptionHistory from "domain/model/prescription_history/prescription_history";
import Page from "presentation/components/common/page";
import PageLayout from "presentation/components/common/page_layout";
import PageTitle from "presentation/components/common/page_title";
import S from "presentation/theme/s";
import SizedBox from "presentation/components/common/sized_box";
import styled from "styled-components";
import PrescriptionTile from "presentation/pages/prescription_history/components/prescription_tile";
import {Fragment} from "react";
import FilledButton from "presentation/components/button/filled_button";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import useRepository from "presentation/utils/hooks/use_repository";
import HospitalRepository from "data/repository/hospital_repository";
import SVGAssets from "presentation/theme/assets";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import AppRouter from "presentation/routes/app_router";
import AppRoute from "presentation/routes/model/app_route";

export type PrescriptionHistoryPageArgs = {
    userInfo: UserInfo;
    prescriptions: PrescriptionHistory[];
};

const FixedContainer = styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 100;
`;

const PrescriptionHistoryPage = () => {
    const {userInfo, prescriptions} = useLocation().state as PrescriptionHistoryPageArgs;
    const theme = useThemeContext();
    const repository = useRepository(HospitalRepository);
    const setLoading = useSetLoading();
    const showNotificationDialog = useShowNotificationDialog();

    useLifeCycle({});

    const buttonWidth = `calc(min(${theme.componentTheme.maxPageWidthInRem}, 100vw) - ${theme.componentTheme.pageHorizontalPaddingInPx * 2}px)`;
    const onClick = () => repository({
        handler: async (repository) => {
            setLoading(true, {
                darken: true,
                spinner: true,
            });

            await repository.sendToHospital({
                userInfo,
                prescriptions,
            });

            const hospital = await repository.getHospital();

            const path = `${AppRoute.Cert}?hospital=${hospital ?? ""}`;

            showNotificationDialog({
                className: "success-dialog",
                type: "positive",
                title: S.prescriptionHistoryPage.successDialog.title,
                message: S.prescriptionHistoryPage.successDialog.message,
                hasNegativeButton: false,
                onDismissed: () => AppRouter.navigate(path, {
                    replace: true,
                }),
            })
        },
        onFinally: () => setLoading(false),
    });

    return <Page>
        <PageLayout>
            <PageTitle title={S.prescriptionHistoryPage.title}/>
            <SizedBox height={"16px"}/>
            {
                prescriptions.map((p, index) =>
                    <Fragment key={index}>
                        <PrescriptionTile data={p}/>
                        <SizedBox height={"24px"}/>
                    </Fragment>
                )
            }
            <SizedBox height={"100px"}/>
            <FixedContainer>
                <FilledButton
                    width={buttonWidth}
                    label={S.prescriptionHistoryPage.buttonLabel}
                    onClick={onClick}
                    rightIconAsset={SVGAssets.Next}
                />
            </FixedContainer>
        </PageLayout>
    </Page>;
};

export default PrescriptionHistoryPage;
