import {animated, useTransition} from "@react-spring/web";
import FocusTrap from "focus-trap-react";
import Loading from "presentation/components/common/loading";
import uiLoadingStateSelector from "presentation/states/ui/selector/ui_loading_state_selector";
import Palette from "presentation/theme/palette";
import {useRecoilValue} from "recoil";
import styled from "styled-components";
import CSSVisibilityType from "presentation/utils/types/css/visibility_type";

const FocusTrapButtonContainer = styled.button`
    width: 0;
    height: 0;
    background-color: ${Palette.none};
    border: none;
    position: absolute;
`;

const LayoutContainer = styled(animated.div)`
    position: fixed;
    inset: 0;
    background-color: ${Palette.none};
    z-index: 2147483647;
`;

const LoadingProvider = () => {
    const loadingState = useRecoilValue(uiLoadingStateSelector);

    const transition = useTransition(loadingState.loading, {
        from: {
            visibility: "hidden" as CSSVisibilityType,
            opacity: 0,
            backgroundColor: loadingState.darken ? Palette.black5 : Palette.none,
        },
        enter: {
            visibility: "visible" as CSSVisibilityType,
            opacity: 1,
            backgroundColor: loadingState.darken ? Palette.black5 : Palette.none,
        },
        leave: {
            visibility: "hidden" as CSSVisibilityType,
            opacity: 0,
            backgroundColor: loadingState.darken ? Palette.black5 : Palette.none,
        },
    });

    return (
        <FocusTrap
            active={loadingState.loading}
            focusTrapOptions={{
                escapeDeactivates: false,
                clickOutsideDeactivates: false,
                preventScroll: true,
            }}
        >
            <div>
                <FocusTrapButtonContainer/>
                {transition((props, loading) =>
                    loading ? (
                        <LayoutContainer style={props}>
                            <Loading visible={loadingState.spinner} showLabel={false}/>
                        </LayoutContainer>
                    ) : null
                )}
            </div>
        </FocusTrap>
    );
};

export default LoadingProvider;
