import SizedBox from "presentation/components/common/sized_box";
import {LNBItemType, LNBItemTypeMap, LNBItemTypes,} from "presentation/components/navigation/lnb/lnb_model";
import LNBLogo from "presentation/components/navigation/lnb/lnb_logo";
import AppRouter from "presentation/routes/app_router";
import Palette from "presentation/theme/palette";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import styled from "styled-components";
import AppRoute from "presentation/routes/model/app_route";
import {animated, useSpring} from "@react-spring/web";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import LNBShootButton from "presentation/components/navigation/lnb/lnb_shoot_button";
import LNBDivider from "presentation/components/navigation/lnb/lnb_divider";
import LNBItem from "presentation/components/navigation/lnb/lnb_item";

export const LNBContainer = styled(animated.div)`
    height: 100%;
    background-color: ${Palette.white100};
`;

const LayoutContainer = styled(LNBContainer)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 0 8px;
    background-color: ${Palette.white100};
    border-right: 1px solid ${Palette.gray200};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 5;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const ItemMarginContainer = styled.div.attrs<{ $marginBottom: string; }>((props) => ({
    style: {
        marginBottom: props.$marginBottom,
    },
}))``;

const LNB = ({
                 selectedItem,
                 shootButtonEnabled,
                 onShootButtonClick
             }: {
    selectedItem?: LNBItemType;
    shootButtonEnabled: boolean;
    onShootButtonClick?: () => void;
}) => {
    const theme = useThemeContext();
    const isMobile = useMobileQuery();

    const layoutProps = useSpring({
        minWidth: (isMobile ? theme.componentTheme.lnbMinWidthInPx : theme.componentTheme.lnbMaxWidthInPx) - 1,
    });

    const onLogoClick = () =>
        AppRouter.navigate(AppRoute.Shoot, {
            replace: true,
        });

    const onClick = (item: LNBItemType) => () => {
        const route = LNBItemTypeMap.endpoint(item);

        if (!!route) {
            AppRouter.navigate(route, {
                replace: true,
            });
            return;
        }
    };

    return (
        <LayoutContainer style={layoutProps}>
            <LNBLogo onClick={onLogoClick}/>
            <SizedBox height={"36px"}/>
            <LNBShootButton enabled={shootButtonEnabled} onClick={onShootButtonClick}/>
            <SizedBox height={"36px"}/>
            <LNBDivider/>
            <SizedBox height={"36px"}/>
            {
                LNBItemTypes.map((item, index) => <ItemMarginContainer
                        key={item}
                        $marginBottom={index === LNBItemTypes.length - 1 ? "0" : "8px"}
                    >
                        <LNBItem
                            selected={selectedItem === item}
                            icon={LNBItemTypeMap.icon(item)}
                            label={LNBItemTypeMap.label(item)}
                            onClick={onClick(item)}
                        />
                    </ItemMarginContainer>
                )
            }
        </LayoutContainer>
    );
};

export default LNB;
