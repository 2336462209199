import styled from "styled-components";
import {animated} from "@react-spring/web";
import useAnimatedMount from "presentation/utils/hooks/use_animated_mount";
import {useMemo} from "react";
import PrescriptionDrugSearch from "domain/model/prescription/prescription_drug_search";
import DrugSearchResultTile from "presentation/components/dialog/drug_search_dialog/components/drug_search_result_tile";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import OutlinedButton from "presentation/components/button/outlined_button";
import SVGAssets from "presentation/theme/assets";

const LayoutContainer = styled.div`
    width: 100%;
    height: 280px;
    position: relative;
    overflow-y: auto;
    scrollbar-color: ${Palette.none} ${Palette.none};
    transition: scrollbar-color 0.3s ease-in-out;

    &:hover {
        scrollbar-color: ${Palette.gray300} ${Palette.none};
    }
`;

const ListContainer = styled(animated.ul)`
    width: 100%;
    height: max-content;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 16px;
`;

const PositionContainer = styled(animated.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const LabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail3Medium};
    color: ${Palette.gray500};
    text-align: center;
    white-space: pre-wrap;
`;

const DrugSearchResults = ({
                               search,
                               onDrugSelect,
                               onDrugCustomInputButtonClick,
                           }: {
    search: PrescriptionDrugSearch;
    onDrugSelect: (drugCode: string, drugName: string) => void;
    onDrugCustomInputButtonClick: () => void;
}) => {
    const queryEmpty = !search.query.length;
    const {listKey, emptyKey, queryEmptyKey} = useMemo(() => ({
        listKey: `${!queryEmpty}${search.searchResults.map((result) => `${result.drugCode}${result.drugName}`).join("-")}`,
        emptyKey: `${!queryEmpty}${!!search.searchResults.length}`,
        queryEmptyKey: queryEmpty.toString(),
    }), [queryEmpty, search]);

    const {props: listProps} = useAnimatedMount(listKey);
    const {props: emptyProps} = useAnimatedMount(emptyKey);
    const {props: queryEmptyProps} = useAnimatedMount(queryEmptyKey);

    const onClick = (drugCode: string, drugName: string) => () => onDrugSelect(drugCode, drugName);
    const emptyLabel = S.drugSearchDialog.notFoundDrugLabel(search.query);

    return <LayoutContainer>
        {
            !queryEmpty && !!search.searchResults.length && <ListContainer style={listProps}>
                {
                    search.searchResults.map(
                        (s, index) => <DrugSearchResultTile
                            key={index}
                            drugCode={s.drugCode}
                            drugName={s.drugName}
                            onClick={onClick(s.drugCode, s.drugName)}
                        />
                    )
                }
            </ListContainer>
        }
        {
            !queryEmpty && !search.searchResults.length && <PositionContainer style={emptyProps}>
                <LabelContainer>{emptyLabel}</LabelContainer>
                <OutlinedButton
                    sizeType={"medium"}
                    label={S.drugSearchDialog.drugCustomInputButtonLabel}
                    leftIconAsset={SVGAssets.PlusCircle}
                    iconColor={Palette.primary500}
                    iconHoverColor={Palette.primary300}
                    onClick={onDrugCustomInputButtonClick}
                />
            </PositionContainer>
        }
        {
            queryEmpty && <PositionContainer style={queryEmptyProps}>
                <LabelContainer>{S.drugSearchDialog.queryEmptyLabel}</LabelContainer>
            </PositionContainer>
        }
    </LayoutContainer>;
};

export default DrugSearchResults;
