import {atom} from "recoil";

type LoginState = {
    passwordInputRequested: boolean;
    userID: string;
};

const loginState = atom<LoginState>({
    key: "loginState",
    default: {
        passwordInputRequested: false,
        userID: "",
    },
});

export default loginState;
