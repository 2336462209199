import {toast} from "react-toastify";
import {SVGAsset} from "presentation/theme/assets";
import Toast, {ToastType} from "presentation/components/toast/toast";

const useShowToast = () => {
    return ({
                type = "success",
                title,
                message,
                iconAsset,
            }: {
        type?: ToastType;
        title: string;
        message: string;
        iconAsset?: SVGAsset;
    }) => toast(<Toast
        type={type}
        title={title}
        message={message}
        iconAsset={iconAsset}
    />);
};

export default useShowToast;