import PrescriptionField from "domain/model/prescription/prescription_field";
import BBoxDTO, {BBoxDTOMap} from "data/dto/prescription/bbox_dto";
import BaseDataFilter, {DataFilter} from "data/data_filter/base_data_filter";

type PrescriptionFieldDTO = {
    value?: string;
    bbox?: BBoxDTO;
    needCheck: boolean;
};

export const PrescriptionFieldDTOMap = {
    toDomainModel: (d: PrescriptionFieldDTO, dataFilter?: DataFilter): PrescriptionField => {
        const filteredValue = (dataFilter ?? new BaseDataFilter()).filter(d.value);

        return {
            value: filteredValue ?? "",
            initialValue: filteredValue,
            bbox: BBoxDTOMap.toDomainModel(d.bbox),
            revisionStatus: {
                confidenceLow: d.needCheck,
                needFullSSN: false,
            },
        };
    },
}

export default PrescriptionFieldDTO;