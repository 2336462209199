const useDownload = () => {
    return async (url: string, fileName: string,) => {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/octet-stream",
            },
        });

        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("download", fileName);
        a.setAttribute("href", blobURL);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
};

export default useDownload;