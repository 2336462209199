import ListenableState from "presentation/states/base/listenable_state";
import {Subject} from "rxjs";

export const useSetListenableState = <State extends ListenableState>(
    listenableState: Subject<State>
) => {
    return (state: State) => {
        listenableState.next(state);
    };
};
