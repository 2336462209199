import BBox from "domain/model/common/bbox";

type BBoxDTO = {
    x: number;
    y: number;
    w: number;
    h: number;
};

export const BBoxDTOMap = {
    toDomainModel: (d?: BBoxDTO): optional<BBox> => {
        if (!d) return undefined;

        return {
            x: d.x,
            y: d.y,
            width: d.w,
            height: d.h,
        };
    },
}

export default BBoxDTO;