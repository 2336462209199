import CertPage from "presentation/pages/cert/cert_page";
import PrescriptionHistoryPage from "presentation/pages/prescription_history/prescription_history_page";
import SimpleCertPage from "presentation/pages/simple_cert/simple_cert_page";
import TextCertPage from "presentation/pages/text_cert/text_cert_page";
import RouteMap from "presentation/routes/model/route_map";
import ErrorRouteMap from "presentation/routes/model/error_route_map";
import AppRoute from "presentation/routes/model/app_route";
import NestedRouteMap from "presentation/routes/model/nested_route_map";
import RedirectRouteMap from "presentation/routes/model/redirect_route_map";
import MainPage from "presentation/pages/main/main_page";
import LoginPage from "presentation/pages/login/login_page";
import ShootPage from "presentation/pages/shoot/shoot_page";
import MyPage from "presentation/pages/my_page/my_page";
import AutoLoginPage from "presentation/pages/auto_login/auto_login_page";

export const appRouteMapping = [
    new NestedRouteMap({
        route: AppRoute.Main,
        page: <MainPage/>,
        redirectToMainOnAuthenticated: false,
        redirectToLoginOnUnauthenticated: true,
        children: [
            new RedirectRouteMap({route: AppRoute.Shoot}),
            new RouteMap({
                route: AppRoute.Shoot,
                page: <ShootPage/>,
                redirectToLoginOnUnauthenticated: true,
                redirectToMainOnAuthenticated: false,
            }),
            new RouteMap({
                route: AppRoute.MyPage,
                page: <MyPage/>,
                redirectToLoginOnUnauthenticated: true,
                redirectToMainOnAuthenticated: false,
            }),
        ],
    }),
    new RouteMap({
        route: AppRoute.AutoLogin,
        page: <AutoLoginPage/>,
        redirectToLoginOnUnauthenticated: false,
        redirectToMainOnAuthenticated: false,
    }),
    new RouteMap({
        route: AppRoute.Cert,
        page: <CertPage/>,
        redirectToMainOnAuthenticated: false,
        redirectToLoginOnUnauthenticated: false,
    }),
    new RouteMap({
        route: AppRoute.Login,
        page: <LoginPage/>,
        redirectToMainOnAuthenticated: true,
        redirectToLoginOnUnauthenticated: false,
    }),
    new RouteMap({
        route: AppRoute.PrescriptionHistory,
        page: <PrescriptionHistoryPage/>,
        redirectToMainOnAuthenticated: false,
        redirectToLoginOnUnauthenticated: false,
    }),
    new RouteMap({
        route: AppRoute.SimpleCert,
        page: <SimpleCertPage/>,
        redirectToMainOnAuthenticated: false,
        redirectToLoginOnUnauthenticated: false,
    }),
    new RouteMap({
        route: AppRoute.TextCert,
        page: <TextCertPage/>,
        redirectToMainOnAuthenticated: false,
        redirectToLoginOnUnauthenticated: false,
    }),
    new ErrorRouteMap(),
].map((route) => route.toObject());
