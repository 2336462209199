import S from "presentation/theme/s";

enum PrescriptionDeductibleRateType {
    FullyDeductible = "급여",
    Nondeductible = "비급여",
    FullyImposed = "100% 본인",
    EightyPercentImposed = "80% 본인",
    FiftyPercentImposed = "50% 본인",
    ThirtyPercentImposed = "30% 본인",
}

export const PrescriptionDeductibleRateTypes = Object.values(PrescriptionDeductibleRateType);

export const PrescriptionDeductibleRateTypeMap = {
    label: (type: PrescriptionDeductibleRateType): string => {
        switch (type) {
            case PrescriptionDeductibleRateType.FullyDeductible:
                return S.prescriptionDeductibleRateType.fullyDeductibleLabel;
            case PrescriptionDeductibleRateType.Nondeductible:
                return S.prescriptionDeductibleRateType.nondeductibleLabel;
            case PrescriptionDeductibleRateType.FullyImposed:
                return S.prescriptionDeductibleRateType.fullyImposedLabel;
            case PrescriptionDeductibleRateType.EightyPercentImposed:
                return S.prescriptionDeductibleRateType.eightyPercentImposedLabel;
            case PrescriptionDeductibleRateType.FiftyPercentImposed:
                return S.prescriptionDeductibleRateType.fiftyPercentImposedLabel;
            case PrescriptionDeductibleRateType.ThirtyPercentImposed:
                return S.prescriptionDeductibleRateType.thirtyPercentImposedLabel;
            default:
                throw new Error("Invalid PrescriptionDeductibleRateType");
        }
    },
    abbreviatedLabel: (type: PrescriptionDeductibleRateType): string => {
        switch (type) {
            case PrescriptionDeductibleRateType.FullyDeductible:
                return S.prescriptionDeductibleRateType.fullyDeductibleAbbreviatedLabel;
            case PrescriptionDeductibleRateType.Nondeductible:
                return S.prescriptionDeductibleRateType.nondeductibleAbbreviatedLabel;
            case PrescriptionDeductibleRateType.FullyImposed:
                return S.prescriptionDeductibleRateType.fullyImposedAbbreviatedLabel;
            case PrescriptionDeductibleRateType.EightyPercentImposed:
                return S.prescriptionDeductibleRateType.eightyPercentImposedAbbreviatedLabel;
            case PrescriptionDeductibleRateType.FiftyPercentImposed:
                return S.prescriptionDeductibleRateType.fiftyPercentImposedAbbreviatedLabel;
            case PrescriptionDeductibleRateType.ThirtyPercentImposed:
                return S.prescriptionDeductibleRateType.thirtyPercentImposedAbbreviatedLabel;
            default:
                throw new Error("Invalid PrescriptionDeductibleRateType");
        }
    },
};

export default PrescriptionDeductibleRateType;