import {atom} from "recoil";
import {ISPType, ISPTypes} from "domain/model/cert/isp_type";
import CertType from "domain/model/cert/cert_type";
import DateTime from "presentation/utils/class/date_time";

type CertUserState = {
    name: string;
    ssnPrefix: string;
    ssnSuffix: string;
    phoneNumber: string;
    isp: ISPType;
    certType: CertType;
    termsCollapsed: boolean;
    kakaoTermsAllAgreed: boolean;
    ispTermsAllAgreed: Record<ISPType, boolean>;
};

export type CertKakaoVerificationState = {
    stepInfo: string;
};

export type CertSMSVerificationState = {
    startTime: DateTime;
    code: string;
    userData?: string;
};

type CertState = {
    user: CertUserState;
    verification?: CertKakaoVerificationState | CertSMSVerificationState;
};

const certState = atom<CertState>({
    key: "certState",
    default: {
        user: {
            name: "",
            ssnPrefix: "",
            ssnSuffix: "",
            phoneNumber: "",
            isp: ISPType.SKT,
            certType: CertType.Kakao,
            termsCollapsed: true,
            kakaoTermsAllAgreed: true,
            ispTermsAllAgreed: ISPTypes.reduce((acc, cur) => ({
                ...acc,
                [cur]: true,
            }), {} as Record<ISPType, boolean>),
        },
        verification: undefined,
    },
});

export default certState;