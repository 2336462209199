import Prescription from "domain/model/prescription/prescription";
import PrescriptionInsuranceTypeFieldDTO, {
    PrescriptionInsuranceTypeFieldDTOMap
} from "data/dto/prescription/prescription_insurance_type_field_dto";
import PrescriptionDateFieldDTO, {PrescriptionDateFieldDTOMap} from "data/dto/prescription/prescription_date_field_dto";
import PrescriptionFieldDTO, {PrescriptionFieldDTOMap} from "data/dto/prescription/prescription_field_dto";
import DataFilters from "data/data_filter/data_filters";
import PrescriptionDiseaseCodeFieldsDTO, {
    PrescriptionMultiFieldDTOMap
} from "data/dto/prescription/prescription_disease_code_fields_dto";
import PrescriptionExtraRemarksFieldDTO, {
    PrescriptionExtraRemarksFieldDTOMap
} from "data/dto/prescription/prescription_extra_remarks_field_dto";
import BBoxDTO, {BBoxDTOMap} from "data/dto/prescription/bbox_dto";
import PrescriptionMedicationResponseDTO, {
    PrescriptionMedicationResponseDTOMap
} from "data/dto/prescription/prescription_medication_response_dto";
import PrescriptionMedicationType from "domain/model/prescription/prescription_medication_type";
import {EnvironmentConstants} from "config/environment_constants";
import {InsuranceTypeMap} from "domain/model/prescription/insurance_type";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";

type PrescriptionResponseDTO = {
    patientCategory: PrescriptionInsuranceTypeFieldDTO;
    issuanceDate: PrescriptionDateFieldDTO;
    issuanceNumber: PrescriptionFieldDTO;
    patientName: PrescriptionFieldDTO;
    patientRrn: PrescriptionFieldDTO;
    medicalInstName: PrescriptionFieldDTO;
    nursingInstNumber: PrescriptionFieldDTO;
    licenseNumber: PrescriptionFieldDTO;
    diseaseCodes: PrescriptionDiseaseCodeFieldsDTO;
    selfPayCode: PrescriptionFieldDTO;
    prescriptionRef: PrescriptionExtraRemarksFieldDTO;
    internalPrescriptionContents: PrescriptionMedicationResponseDTO[];
    injectionPrescriptionContents: PrescriptionMedicationResponseDTO[];
    extraPersonalInfoBboxList: BBoxDTO[];
    fileKey: string;
};

export const PrescriptionResponseDTOMap = {
    toDomainModel: (d: PrescriptionResponseDTO): Prescription => {
        let medicationID = 1;

        const insuranceType = PrescriptionInsuranceTypeFieldDTOMap.toDomainModel(d.patientCategory);
        const patientSSN = PrescriptionFieldDTOMap.toDomainModel(d.patientRrn, DataFilters.ssnDataFilter);

        return {
            id: d.fileKey,
            insuranceType,
            issueDate: PrescriptionDateFieldDTOMap.toDomainModel(d.issuanceDate),
            issueNumber: PrescriptionFieldDTOMap.toDomainModel(d.issuanceNumber, DataFilters.issueNumberDataFilter),
            patientName: PrescriptionFieldDTOMap.toDomainModel(d.patientName),
            patientSSN: {
                ...patientSSN,
                revisionStatus: {
                    ...patientSSN.revisionStatus,
                    needFullSSN: InsuranceTypeMap.needFullSSN(insuranceType) && patientSSN.value.length < InputFormatters.ssnFormatter.maxLength,
                },
            },
            nursingAgencyName: PrescriptionFieldDTOMap.toDomainModel(d.medicalInstName),
            nursingAgencyNumber: PrescriptionFieldDTOMap.toDomainModel(d.nursingInstNumber, DataFilters.nursingAgencyNumberDataFilter),
            doctorLicenseNumber: PrescriptionFieldDTOMap.toDomainModel(d.licenseNumber, DataFilters.doctorLicenseNumberDataFilter),
            diseaseCodes: PrescriptionMultiFieldDTOMap.toDomainModel(d.diseaseCodes),
            selfPayCode: PrescriptionFieldDTOMap.toDomainModel(d.selfPayCode),
            extraRemarks: PrescriptionExtraRemarksFieldDTOMap.toDomainModel(d.prescriptionRef),
            medications: d.internalPrescriptionContents.map((e) => PrescriptionMedicationResponseDTOMap.toDomainModel(e, medicationID++, PrescriptionMedicationType.Normal))
                .concat(d.injectionPrescriptionContents.map((e) => PrescriptionMedicationResponseDTOMap.toDomainModel(e, medicationID++, PrescriptionMedicationType.Injection))),
            extraBBoxes: !!d.extraPersonalInfoBboxList.length ? d.extraPersonalInfoBboxList.map((e) => BBoxDTOMap.toDomainModel(e)!) : [],
            fileKey: d.fileKey,
            imageURL: EnvironmentConstants.bucketBaseURL + d.fileKey,
        };
    },
}

export default PrescriptionResponseDTO;