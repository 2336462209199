import ObjectConvertable from "presentation/routes/model/object_convertable";
import AccessErrorView from "presentation/components/error/access_error_view";
import GeneralErrorView from "presentation/components/error/general_error_view";

export default class ErrorRouteMap implements ObjectConvertable {
    toObject() {
        return {
            path: "*",
            element: <AccessErrorView/>,
            errorElement: <GeneralErrorView/>,
        };
    }
}
