import BaseRepository from "data/repository/base_repository";
import Prescription from "domain/model/prescription/prescription";
import PrescriptionResponseDTO, {PrescriptionResponseDTOMap} from "data/dto/prescription/prescription_response_dto";
import PrescriptionDrugSearchResultDTO, {
    PrescriptionDrugSearchResultDTOMap
} from "data/dto/prescription/prescription_drug_search_result_dto";
import PrescriptionDrugSearch from "domain/model/prescription/prescription_drug_search";
import {PrescriptionRequestDTOMap} from "data/dto/prescription/prescription_request_dto";
import PrescriptionDrugSearchResult from "domain/model/prescription/prescription_drug_search_result";

export default class PrescriptionRepository extends BaseRepository {
    convertPrescription = async ({prescription}: { prescription: Blob }): Promise<Prescription> => await this.handler({
        request: async (client, signals) => {
            const formData = new FormData();
            formData.append("prescription", prescription, "prescription.jpeg");

            const response = await client.post<PrescriptionResponseDTO>("/jph/ocr", formData, {
                signal: signals.at(0),
                headers: {
                    "Content-Type": "multipart/form-data;charset=UTF-8",
                },
            });

            return PrescriptionResponseDTOMap.toDomainModel(response.data);
        },
    });

    searchDrug = async ({
                            drugCode,
                            drugName
                        }: {
        drugCode: string;
        drugName: string;
    }): Promise<PrescriptionDrugSearch> => await this.handler({
        concurrency: 2,
        request: async (client, signals) => {
            const responses = await Promise.all([
                !!drugCode.length ?
                    client.post<PrescriptionDrugSearchResultDTO[]>(`/jph/drugInfo/drugCode`, {
                        drugCode: drugCode.trim(),
                    }, {
                        signal: signals.at(0),
                    }) : undefined,
                !!drugName.length ?
                    client.post<PrescriptionDrugSearchResultDTO[]>(`/jph/drugInfo/drugName`, {
                        drugName: drugName.trim(),
                    }, {
                        signal: signals.at(1),
                    }) : undefined,
            ].filter((r) => !!r).map((r) => r!));

            let drugCodeResult: PrescriptionDrugSearchResult[];
            let drugNameResult: PrescriptionDrugSearchResult[];

            try {
                const drugCodeResponse = responses.at(0);
                drugCodeResult = drugCodeResponse?.data.map(PrescriptionDrugSearchResultDTOMap.toDomainModel) ?? [];
            } catch {
                drugCodeResult = [];
            }

            try {
                const drugNameResponse = responses.at(1);
                drugNameResult = drugNameResponse?.data.map(PrescriptionDrugSearchResultDTOMap.toDomainModel) ?? [];
            } catch {
                drugNameResult = [];
            }

            if (drugCodeResult.length) {
                return {
                    query: drugCode,
                    searchResults: drugCodeResult,
                };
            }
            if (drugNameResult.length) {
                return {
                    query: drugName,
                    searchResults: drugNameResult,
                };
            }

            return {
                query: drugCode,
                searchResults: [],
            };
        },
    });

    sendPrescription = async ({
                                  prescription
                              }: {
        prescription: Prescription;
    }): Promise<void> => await this.handler({
        request: async (client, signals) => {
            await client.post("/jph/ocr/send", PrescriptionRequestDTOMap.fromDomainModel(prescription), {
                signal: signals.at(0),
            });
        },
    });

    sendPrescriptions = async ({
                                   prescriptions
                               }: {
        prescriptions: Prescription[];
    }): Promise<string[]> => await this.handler({
        concurrency: prescriptions.length,
        request: async (client, signals) => {
            const fileKeys = [];

            let index = 0;
            for (const p of prescriptions) {
                try {
                    await client.post(
                        "/jph/ocr/send",
                        PrescriptionRequestDTOMap.fromDomainModel(p),
                        {
                            signal: signals.at(index++),
                        },
                    );
                } catch {
                    fileKeys.push(p.fileKey);
                }
            }

            return fileKeys;
        },
    });
};