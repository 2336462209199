import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import IconButton from "presentation/components/button/icon_button";

const DialogCloseButton = ({onClick}: { onClick: () => void; }) => {
    return <IconButton
        asset={SVGAssets.Close}
        width={"24px"}
        height={"24px"}
        color={Palette.gray800}
        hoverColor={"light"}
        onClick={onClick}
    />;
};

export default DialogCloseButton;
