import ObjectConvertable from "presentation/routes/model/object_convertable";
import AppRouterPage from "presentation/routes/app_router_page";
import {ReactElement} from "react";
import GeneralErrorView from "presentation/components/error/general_error_view";

export default class RouteMap implements ObjectConvertable {
    route: string;
    page: ReactElement;
    redirectToMainOnAuthenticated: boolean;
    redirectToLoginOnUnauthenticated: boolean;

    constructor({
                    route,
                    page,
                    redirectToMainOnAuthenticated,
                    redirectToLoginOnUnauthenticated,
                }: {
        route: string;
        page: ReactElement;
        redirectToMainOnAuthenticated: boolean;
        redirectToLoginOnUnauthenticated: boolean;
    }) {
        this.route = route;
        this.page = page;
        this.redirectToMainOnAuthenticated = redirectToMainOnAuthenticated;
        this.redirectToLoginOnUnauthenticated =
            redirectToLoginOnUnauthenticated;
    }

    toObject(): Object {
        return {
            path: this.route,
            element: (
                <AppRouterPage
                    redirectToMainOnAuthenticated={this.redirectToMainOnAuthenticated}
                    redirectToLoginOnUnauthenticated={this.redirectToLoginOnUnauthenticated}
                >
                    {this.page}
                </AppRouterPage>
            ),
            errorElement: <GeneralErrorView/>,
        };
    }
}
