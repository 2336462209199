import styled from "styled-components";
import {animated} from "@react-spring/web";
import useAnimatedMount from "presentation/utils/hooks/use_animated_mount";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import CardButton from "presentation/components/button/card_button";
import {StaticSVGAssets} from "presentation/theme/assets";
import SizedBox from "presentation/components/common/sized_box";
import {useSetListenableState} from "presentation/utils/hooks/use_set_listenable_state";
import {shootListenableState} from "presentation/states/shoot/shoot_state";
import {ShootShowRealtimeScreen} from "presentation/states/shoot/shoot_listenable_state";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import UserGuideDialog from "presentation/components/dialog/user_guide_dialog/user_guide_dialog";
import ShootUserPreferencesButton from "presentation/pages/shoot/components/shoot_user_preferences_button";
import {useContext, useEffect} from "react";
import {MainPageContext} from "presentation/pages/main/main_page";
import Palette from "presentation/theme/palette";
import useRepository from "presentation/utils/hooks/use_repository";
import UserRepository from "data/repository/user_repository";

const LayoutContainer = styled(animated.div)`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const NoticeLayoutContainer = styled.div`
    flex-grow: 1;
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const NoticeContainer = styled.p`
    min-width: max-content;
    ${Fonts.body1Medium};
    text-align: center;
`;

const CardButtonsLayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

const UserPreferencesCardContainer = styled.div`
    min-width: 29.375rem;
    background-color: ${Palette.white100};
    padding: 30px 36px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

const ShootEmptyPrescriptionView = () => {
    const context = useContext(MainPageContext);
    const repository = useRepository(UserRepository);
    const setListenableState = useSetListenableState(shootListenableState);
    const showDialog = useShowDialog();

    useEffect(() => {
        repository({
            handler: async (repository) => {
                const guideShown = await repository.getGuideShown();
                if (guideShown) return;

                await repository.setGuideShown();
                setTimeout(() => onUserGuideButtonClick(), 1000);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {props} = useAnimatedMount();

    const onCameraSoundButtonClick = () => context.setUserPreferences((prev) => ({
        ...prev,
        cameraSoundEnabled: !prev.cameraSoundEnabled,
    }));

    const onRealtimeScreenButtonClick = () => setListenableState(new ShootShowRealtimeScreen());

    const onUserGuideButtonClick = () => showDialog({
        className: "user-guide-dialog",
        component: <UserGuideDialog/>,
    });

    return <LayoutContainer style={props}>
        <SizedBox height={"60px"}/>
        <UserPreferencesCardContainer>
            <ShootUserPreferencesButton
                toggleOn={context.userPreferences.cameraSoundEnabled}
                title={S.shootPage.shootEmptyPrescriptionView.cameraSoundTitle}
                description={S.shootPage.shootEmptyPrescriptionView.cameraSoundDescription}
                onClick={onCameraSoundButtonClick}
            />
        </UserPreferencesCardContainer>
        <NoticeLayoutContainer>
            <NoticeContainer>
                {S.shootPage.shootEmptyPrescriptionView.noticeLabel}
            </NoticeContainer>
        </NoticeLayoutContainer>
        <CardButtonsLayoutContainer>
            <CardButton
                iconAsset={StaticSVGAssets.Live}
                label={S.shootPage.shootEmptyPrescriptionView.realtimeScreenButtonLabel}
                onClick={onRealtimeScreenButtonClick}
            />
            <CardButton
                iconAsset={StaticSVGAssets.Guide}
                label={S.shootPage.shootEmptyPrescriptionView.userGuideButtonLabel}
                onClick={onUserGuideButtonClick}
            />
        </CardButtonsLayoutContainer>
        <SizedBox height={"24px"}/>
    </LayoutContainer>;
};

export default ShootEmptyPrescriptionView;
