export default class StringHelper {
    static toStringWithDividers = (value: string): string => {
        const digits = [];
        const sign = value.at(0) === "-" ? "-" : "";
        const string = !!sign ? value.slice(1) : value;

        let count = 0;
        let i = string.length - 1;
        while (i >= 0) {
            if (count > 0 && count % 3 === 0) digits.push(",");
            digits.push(string[i]);
            i--;
            count++;
        }

        i = digits.length - 1;
        while (digits[i] === "0" || digits[i] === ",") {
            digits.pop();
            i--;
        }

        return (
            sign + (digits.length === 0 ? "" : digits.toReversed().join(""))
        );
    };

    static removeAtOrUndefined = (value: string, index: number): optional<string> => {
        if (index < 0 || index >= value.length) return undefined;

        return value.substring(0, index) + value.substring(index + 1);
    }

    static toUndefinedIfEmpty = (value: string): optional<string> => {
        if (!value.trim().length) return undefined;

        return value;
    };
}