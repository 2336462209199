import InputValidationStatusType, {
    InputValidationStatusTypeMap
} from "domain/model/common/input_validation_status_type";
import DateTime from "presentation/utils/class/date_time";

const twentiethCenturyPrefix = 19;
const twentyFirstCenturyPrefix = 20;
const dateFormat = 'yyyy-MM-dd';

const validSSNPrefixLength = 6;
const validHyphenedSsnLength = 14;

export default class InputValidator {
    static checkDecimal = (value: string): InputValidationStatusType => {
        const valid = /^\d*$/g.test(value);
        if (!valid) return InputValidationStatusType.WrongFormat;

        return InputValidationStatusType.Valid;
    }

    static checkSSNPrefix = (prefix: string): InputValidationStatusType => {
        try {
            if (prefix.length < validSSNPrefixLength) return InputValidationStatusType.InsufficientLength;

            const year = prefix.substring(0, 2);
            const month = prefix.substring(2, 4);
            const day = prefix.substring(4, 6);
            const intYear = parseInt(year);
            const intMonth = parseInt(month);
            const intDay = parseInt(day);

            if (isNaN(intYear) || isNaN(intMonth) || isNaN(intDay)) return InputValidationStatusType.WrongFormat;

            const intCurrentAbbreviatedYear = DateTime.minNow().year % 100;
            const paddedYear = (intYear > intCurrentAbbreviatedYear ? twentiethCenturyPrefix : twentyFirstCenturyPrefix) * 100 + intYear;

            const dateString = `${paddedYear}-${month}-${day}`;
            const date = new DateTime(paddedYear, intMonth, intDay);
            const validDate = date.format(dateFormat) === dateString;

            if (!validDate) return InputValidationStatusType.WrongFormat;
            if (date.isAfter(DateTime.minNow())) return InputValidationStatusType.WrongFormat;

            return InputValidationStatusType.Valid;
        } catch {
            return InputValidationStatusType.WrongFormat;
        }
    };

    static checkHyphenedSSN = (ssn: string): InputValidationStatusType => {
        try {
            if (ssn.length < validHyphenedSsnLength) {
                return InputValidationStatusType.InsufficientLength;
            }

            const tokens = ssn.split('-');
            const prefix = tokens.at(0)!;
            const suffix = tokens.at(-1)!;

            if (!InputValidationStatusTypeMap.isValid(this.checkSSNPrefix(prefix))) {
                return InputValidationStatusType.WrongFormat;
            }

            if (suffix.includes('*')) {
                return InputValidationStatusType.Valid;
            }

            return InputValidationStatusType.Valid;
        } catch (_) {
            return InputValidationStatusType.WrongFormat;
        }
    };
};