import {useEffect} from "react";

const useDeviceChange = (callback: () => void) => {
    useEffect(() => {
        navigator.mediaDevices?.addEventListener("devicechange", callback);

        callback();

        return () => {
            navigator.mediaDevices?.removeEventListener("devicechange", callback);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useDeviceChange;
