import styled from "styled-components";
import Palette from "presentation/theme/palette";
import DateTime from "presentation/utils/class/date_time";
import S from "presentation/theme/s";
import Fonts from "presentation/theme/fonts";
import {animated, useSpring} from "@react-spring/web";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import {useMemo, useState} from "react";
import DatePickerDialog from "presentation/components/dialog/date_picker_dialog/date_picker_dialog";

const LayoutContainer = styled(animated.button)`
    width: 100%;
    height: 2.5rem;
    padding: 0 16px;
    border-top-right-radius: 12px;
    border-top: 1px solid ${Palette.gray100};
    border-right: 1px solid ${Palette.gray100};
    border-bottom: 1px solid ${Palette.gray100};
    border-left: none;
    background-color: ${Palette.none};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
`;

const DateLabelContainer = styled(animated.p)`
    min-width: max-content;
    ${Fonts.detail3Medium};
    text-align: center;
    margin-top: 2px;
`;

const ShootPrescriptionDateField = ({
                                        revisionNeeded,
                                        value,
                                        onValueChange,
                                    }: {
    revisionNeeded: boolean;
    value: DateTime;
    onValueChange: (value: DateTime) => void;
}) => {
    const showDialog = useShowDialog();

    const [selecting, setSelecting] = useState(false);

    const {calendarStartDate, calendarEndDate} = useMemo(() => ({
        calendarStartDate: DateTime.now().copyWith({year: DateTime.now().year - 5}).min,
        calendarEndDate: DateTime.now().copyWith({year: DateTime.now().year + 5}).min,
    }), []);

    const label = value.format(S.shootPrescriptionDateField.format);

    const layoutProps = useSpring({
        backgroundColor: selecting ? Palette.gray50 : Palette.white100,
    });
    const {props: labelFadeInProps} = useFadeInTransition(label);
    const labelProps = useSpring({
        color: revisionNeeded ? Palette.red500 : Palette.gray800,
    });

    const onClick = () => {
        setSelecting(true);
        showDialog({
            className: "date-picker-dialog",
            component: <DatePickerDialog
                title={S.shootPage.shootPrescriptionView.issueNumberDateView.issueDatePickerTitle}
                calendarStartDate={calendarStartDate}
                calendarEndDate={calendarEndDate}
                selectedDate={value}
                selectedDateSuffixLabel={S.shootPage.shootPrescriptionView.issueNumberDateView.datePickerSelectedDateSuffixLabel}
                onDateSelect={onValueChange}
            />,
            onDismissed: () => setSelecting(false),
        });
    };

    return <LayoutContainer style={layoutProps} onClick={onClick}>
        <DateLabelContainer style={{
            ...labelFadeInProps,
            ...labelProps,
        }}>
            {label}
        </DateLabelContainer>
    </LayoutContainer>;
};

export default ShootPrescriptionDateField;
