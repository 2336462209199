import BBoxDTO, {BBoxDTOMap} from "data/dto/prescription/bbox_dto";
import PrescriptionDrugField from "domain/model/prescription/prescription_drug_field";

type PrescriptionDrugFieldDTO = {
    value?: string;
    needCheck: boolean;
    bbox?: BBoxDTO;
};

export const PrescriptionDrugFieldDTOMap = {
    toDomainModel: (d: PrescriptionDrugFieldDTO): PrescriptionDrugField => {
        const trimmedValue = d.value?.trim();
        const originalValue = trimmedValue?.length === 0 ?? true ? undefined : trimmedValue;

        return {
            value: originalValue,
            bbox: BBoxDTOMap.toDomainModel(d.bbox),
            originalConfidenceLow: d.needCheck,
            revisionStatus: {
                confidenceLow: d.needCheck,
                needFullSSN: false,
            },
        }; 
    }, 
}

export default PrescriptionDrugFieldDTO;