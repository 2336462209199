import styled from "styled-components";
import ShootPrescriptionRowTitleBox from "presentation/pages/shoot/components/shoot_prescription_row_title_box";
import S from "presentation/theme/s";
import Flex from "presentation/components/common/flex";
import ShootPrescriptionTextField from "presentation/pages/shoot/components/shoot_prescription_text_field";
import {PrescriptionFieldRevisionStatusMap} from "domain/model/prescription/prescription_field_revision_status";
import {useRecoilValue, useSetRecoilState} from "recoil";
import shootPrescriptionsSelector from "presentation/states/shoot/selector/shoot_prescriptions_selector";
import shootState from "presentation/states/shoot/shoot_state";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";
import ShootPrescriptionExtraRemarksField
    from "presentation/pages/shoot/components/shoot_prescription_extra_remarks_field";
import PrescriptionExtraRemarksType from "domain/model/prescription/prescription_extra_remarks_type";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ShootPrescriptionSelfPayCodeExtraRemarksView = () => {
    const {prescription} = useRecoilValue(shootPrescriptionsSelector);
    const setState = useSetRecoilState(shootState);

    const onSelfPayCodeChange = (selfPayCode: string) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        prescriptions[index] = {
            ...prescriptions[index],
            selfPayCode: {
                ...prescriptions[index].selfPayCode,
                value: selfPayCode,
                revisionStatus: {
                    ...prescriptions[index].selfPayCode.revisionStatus,
                    confidenceLow: false,
                },
            },
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    const onExtraRemarksChange = (extraRemarks?: PrescriptionExtraRemarksType) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        prescriptions[index] = {
            ...prescriptions[index],
            extraRemarks: {
                ...prescriptions[index].extraRemarks,
                value: extraRemarks,
                revisionStatus: {
                    ...prescriptions[index].extraRemarks.revisionStatus,
                    confidenceLow: false,
                },
            },
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    return <LayoutContainer>
        <ShootPrescriptionRowTitleBox
            roundBottomLeft={true}
            title={S.shootPage.shootPrescriptionView.selfPayCodeExtraRemarksView.selfPayCodeTitle}/>
        <Flex>
            <ShootPrescriptionTextField
                hasTopBorder={false}
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(prescription.selfPayCode.revisionStatus)}
                value={prescription.selfPayCode.value}
                onValueChange={onSelfPayCodeChange}
                placeholder={S.shootPage.shootPrescriptionView.selfPayCodeExtraRemarksView.selfPayCodePlaceholder}
                maxLength={InputFormatters.selfPayCodeFormatter.maxLength}
                inputFormatter={InputFormatters.selfPayCodeFormatter}
            />
        </Flex>
        <ShootPrescriptionRowTitleBox
            title={S.shootPage.shootPrescriptionView.selfPayCodeExtraRemarksView.extraRemarksTitle}
        />
        <Flex>
            <ShootPrescriptionExtraRemarksField
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(prescription.extraRemarks.revisionStatus)}
                value={prescription.extraRemarks.value}
                onValueChange={onExtraRemarksChange}
            />
        </Flex>
    </LayoutContainer>;
};

export default ShootPrescriptionSelfPayCodeExtraRemarksView;
