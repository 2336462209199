import ViewStatusType from "domain/model/common/view_status_type";
import IconRim from "presentation/components/common/icon_rim";
import Loading from "presentation/components/common/loading";
import SizedBox from "presentation/components/common/sized_box";
import SVG from "presentation/components/common/svg";
import {SVGAsset} from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";
import useViewStatus from "presentation/utils/hooks/use_view_status";
import useWindowResizeObserver from "presentation/utils/hooks/use_window_resize_observer";
import {PropsWithChildren, useLayoutEffect, useRef} from "react";
import styled from "styled-components";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {animated, useSpring} from "@react-spring/web";
import CSSOverflowType from "presentation/utils/types/css/overflow_type";
import useRenderedRef from "presentation/utils/hooks/use_rendered_ref";
import DialogCloseButton from "presentation/components/button/dialog_close_button";

const minHorizontalMarginInPx = 40;
const titleHeightInPx = 36;

const LayoutContainer = styled(animated.div)`
    background-color: ${Palette.white100};
    border-radius: 16px;
    box-shadow: 0 3px 15px 0 rgba(199, 199, 199, 0.25);
`;

const ContentLayoutContainer = styled(animated.div)`
    width: 100%;
    position: relative;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const ContentContainer = styled.div.attrs<{
    $visible: boolean;
    $blockPointer: boolean;
}>((props) => ({
    style: {
        opacity: props.$visible ? 1 : 0,
        pointerEvents: props.$blockPointer ? "none" : "auto",
    },
}))`
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease-in-out;
    overflow: clip;
`;

const TitleLayoutContainer = styled.div.attrs<{ $bottomMarginInPx: number }>((props) => ({
    style: {
        height: `${titleHeightInPx}px`,
        marginBottom: `${props.$bottomMarginInPx}px`,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const TitleContainer = styled.h4<{ $font: string; $color: string }>`
    flex-grow: 1;
    ${(props) => props.$font};
    color: ${(props) => props.$color};
`;

const Dialog = ({
                    widthInPx = 562,
                    status = ViewStatusType.Loaded,
                    contentPaddingInPx = 24,
                    iconAsset,
                    iconColor = Palette.gray600,
                    iconRimColor = Palette.black8,
                    title,
                    titleFont = Fonts.body3,
                    titleColor = Palette.black100,
                    titleToContentSpacingInPx = 24,
                    children,
                }: PropsWithChildren<{
    widthInPx?: number;
    status?: ViewStatusType;
    contentPaddingInPx?: number;
    iconAsset?: SVGAsset;
    iconColor?: string;
    iconRimColor?: string;
    title?: string;
    titleFont?: string;
    titleColor?: string;
    titleToContentSpacingInPx?: number;
}>) => {
    const dismissDialog = useDismissDialog();

    const timeoutIDRef = useRef<optional<NodeJS.Timeout>>(undefined);
    const renderedRef = useRenderedRef();
    const contentRef = useRef<HTMLDivElement>(null);
    const {widthInPx: windowWidthInPx, innerHeightInPx: windowHeightInPx} = useWindowResizeObserver([]);
    const {heightInPx: contentHeightInPx} = useResizeObserver(contentRef, []);

    const {loading, loaded, error} = useViewStatus(status);

    const onClick = () => dismissDialog();

    const scaleFactor =
        windowWidthInPx - widthInPx < minHorizontalMarginInPx
            ? (Math.min(windowWidthInPx, widthInPx) - minHorizontalMarginInPx) /
            Math.max(windowWidthInPx, widthInPx)
            : 1;

    const iconWidth = "24px";
    const iconHeight = "24px";

    const maxContentHeightInPx = windowHeightInPx * 0.8 - contentPaddingInPx * 2 - titleHeightInPx - titleToContentSpacingInPx;
    const minContentHeightInPx = Math.min(contentHeightInPx, maxContentHeightInPx);

    const overflow = contentHeightInPx > maxContentHeightInPx;

    const dialogProps = useSpring({
        width: widthInPx,
        scale: scaleFactor,
        padding: contentPaddingInPx,
    });
    const [contentProps, contentAPI] = useSpring(() => ({
        opacity: 1,
        height: minContentHeightInPx,
        overflowX: "hidden" as CSSOverflowType,
        overflowY: "hidden" as CSSOverflowType,
    }));

    useLayoutEffect(() => {
        if (!renderedRef.current) {
            contentAPI.set({
                height: minContentHeightInPx,
                overflowY: overflow ? "scroll" : "visible" as CSSOverflowType,
            });
            return;
        }

        contentAPI.set({
            overflowY: "hidden" as CSSOverflowType,
        });
        contentAPI.start({
            height: minContentHeightInPx,
        });

        timeoutIDRef.current = setTimeout(() => {
            contentAPI.set({
                overflowY: overflow ? "scroll" : "visible" as CSSOverflowType,
            });
        }, 500);

        return () => {
            clearTimeout(timeoutIDRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minContentHeightInPx]);

    return (
        <LayoutContainer style={dialogProps}>
            {
                iconAsset && <TitleLayoutContainer $bottomMarginInPx={titleToContentSpacingInPx}>
                    <IconRim
                        iconWidth={iconWidth}
                        iconHeight={iconHeight}
                        color={iconRimColor}
                    >
                        <SVG
                            asset={iconAsset}
                            width={iconWidth}
                            height={iconHeight}
                            color={iconColor}
                        />
                    </IconRim>
                    <SizedBox width={"8px"}/>
                    <TitleContainer $font={titleFont} $color={titleColor}>
                        {title ?? ""}
                    </TitleContainer>
                    <DialogCloseButton onClick={onClick}/>
                </TitleLayoutContainer>
            }
            <ContentLayoutContainer style={contentProps}>
                <ContentContainer
                    ref={contentRef}
                    $visible={loaded}
                    $blockPointer={loading || error}
                >
                    {children}
                </ContentContainer>
                <Loading visible={loading}/>
            </ContentLayoutContainer>
        </LayoutContainer>
    );
};

export default Dialog;
