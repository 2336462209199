import S from "presentation/theme/s";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";

const useShowErrorDialog = () => {
    const showNotificationDialog = useShowNotificationDialog();

    return (props?: { title?: string; message?: string; onDismissed?: (() => void); }) =>
        showNotificationDialog({
            type: "negative",
            title: props?.title ?? S.common.unknownErrorTitle,
            message: props?.message ?? S.common.unknownErrorMessage,
            hasNegativeButton: false,
            onDismissed: props?.onDismissed,
        });
};

export default useShowErrorDialog;
