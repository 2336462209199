import CalendarCell from "presentation/components/calendar/common/calendar_cell";
import CalendarWeekdayCell from "presentation/components/calendar/common/calendar_weekday_cell";
import S from "presentation/theme/s";
import DateTime from "presentation/utils/class/date_time";
import {useContext, useMemo} from "react";
import styled from "styled-components";
import Palette from "presentation/theme/palette";
import {DatePickerCalendarContext} from "presentation/components/calendar/date_picker_calendar/date_picker_calendar";

const LayoutContainer = styled.div.attrs<{ $verticalGapInPx: number }>(
    (props) => ({
        style: {
            gap: `${props.$verticalGapInPx}px`,
        },
    })
)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: gap 0.3s ease-in-out;
`;

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const DatePickerCalendarMonth = ({month}: { month: DateTime }) => {
    const context = useContext(DatePickerCalendarContext);

    const {weekdayCellWidth, weekdayCellHeight, weekdays, weeks} =
        useMemo(() => {
            const weekdayCellWidth = `${context.constants.calendarCellWidthInPx}px`;
            const weekdayCellHeight = `${context.constants.weekdayHeaderHeightInPx}px`;

            const weekdays = DateTime.orthodoxWeekdays;
            const weeks = month.getOrthodoxWeeksInMonth().map((week) =>
                week.map((day) => {
                    const enabled = day.isInRange(context.startDate, context.endDate);
                    const selected = day.isSameDay(context.selectedDate);

                    return {
                        key: day.key,
                        visible: day.isSameMonth(month),
                        enabled,
                        selected,
                        holiday: day.isSunday,
                        value: day.format(S.calendarCell.dayFormat),
                        suffix: selected ? context.selectedDateSuffixLabel : undefined,
                        suffixHighlighted: selected && !!context.selectedDateSuffixLabel,
                        onClick: enabled ? () => context.onDateClick(day) : undefined,
                    };
                }),
            );

            return {
                weekdayCellWidth,
                weekdayCellHeight,
                weekdays,
                weeks,
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            context.constants,
            month.key,
            context.startDate.key,
            context.endDate.key,
            context.selectedDate?.key,
        ]);

    return (
        <LayoutContainer $verticalGapInPx={context.constants.calendarRowVerticalGapInPx}>
            <RowContainer>
                {weekdays.map((weekday) => <CalendarWeekdayCell
                        key={weekday}
                        width={weekdayCellWidth}
                        height={weekdayCellHeight}
                        weekday={weekday}
                    />
                )}
            </RowContainer>
            {weeks.map((week, weekIndex) => <RowContainer key={weekIndex}>
                    {week.map((day) => <CalendarCell
                            key={day.key}
                            visible={day.visible}
                            enabled={day.enabled}
                            selected={day.selected}
                            holiday={day.holiday}
                            heightInPx={context.constants.calendarCellHeightInPx}
                            widthInPx={context.constants.calendarCellWidthInPx}
                            valueWidthInPx={context.constants.calendarCellValueWidthInPx}
                            valueHeightInPx={context.constants.calendarCellValueHeightInPx}
                            value={day.value}
                            suffix={day.suffix}
                            suffixHighlighted={day.suffixHighlighted}
                            selectedColor={Palette.primary500}
                            onClick={day.onClick}
                        />
                    )}
                </RowContainer>
            )}
        </LayoutContainer>
    );
};

export default DatePickerCalendarMonth;
