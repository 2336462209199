import PrescriptionHistory from "domain/model/prescription_history/prescription_history";
import DateTimeHelper from "config/helper/date_time_helper";
import MedicationDTO, {MedicationDTOMap} from "data/dto/prescription_history/medication_dto";

type PrescriptionHistoryDTO = {
    no: string;
    createdDate: string;
    hospital: string;
    drugStore: string;
    list: MedicationDTO[];
};

export const PrescriptionHistoryDTOMap = {
    fromDomainModel: (d: PrescriptionHistory): PrescriptionHistoryDTO => {
        return {
            no: d.id,
            createdDate: DateTimeHelper.toServerPrescriptionHistoryDate(d.dispenseDate),
            hospital: d.hospitalName,
            drugStore: d.pharmacyName,
            list: d.medications.map(MedicationDTOMap.fromDomainModel),
        };
    },
    toDomainModel: (d: PrescriptionHistoryDTO): PrescriptionHistory => {
        return {
            id: d.no,
            dispenseDate: DateTimeHelper.fromServerPrescriptionHistoryDate(d.createdDate),
            hospitalName: d.hospital,
            pharmacyName: d.drugStore,
            medications: d.list.map(MedicationDTOMap.toDomainModel),
        };
    },
}

export default PrescriptionHistoryDTO;