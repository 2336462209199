import styled from "styled-components";
import PrescriptionHistory from "domain/model/prescription_history/prescription_history";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import SizedBox from "presentation/components/common/sized_box";
import OutlinedChip from "presentation/components/chip/outlined_chip";
import Palette from "presentation/theme/palette";
import {useMemo} from "react";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

const DateLayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    flex-wrap: wrap;
`;

const DateChipsLayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    flex-wrap: wrap;
`;

const DateLabelContainer = styled.h6`
    ${Fonts.body1};
`;

const MedicationsLayoutContainer = styled.ul`
    width: 100%;
    border-radius: 24px;
    padding: 8px;
    background-color: ${Palette.gray100};
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 8px;
`;

const MedicationLayoutContainer = styled.li`
    width: 100%;
    border-radius: 16px;
    padding: 8px 16px;
    background-color: ${Palette.white100};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const MedicationHeaderLayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const MedicationIndexContainer = styled.p`
    min-width: 34px;
    ${Fonts.detail2};
    color: ${Palette.deepBlue};
`;

const MedicationTitlesLayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;
`;

const MedicationTitleContainer = styled.p`
    ${Fonts.detail3};
    text-align: center;
`;

const MedicationDescriptionContainer = styled.p`
    ${Fonts.detail2Medium};
    color: ${Palette.gray500};
`;

const MedicationTableLayoutContainer = styled.div.attrs<{
    $backgroundColor: string;
    $borderRadius: number;
}>((props) => ({
    style: {
        backgroundColor: props.$backgroundColor,
        borderRadius: `${props.$borderRadius}px`,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const MedicationTableCellContainer = styled.div`
    flex: 1;
    min-width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const MedicationTableHeaderLabelContainer = styled.p`
    padding: 2px 0;
    ${Fonts.detail1};
    color: ${Palette.gray500};
    text-align: center;
`;

const MedicationTableCellLabelContainer = styled.p`
    padding: 2px 0;
    ${Fonts.detail3};
    text-align: center;
`;

const PrescriptionTile = ({
                              data,
                          }: {
    data: PrescriptionHistory;
}) => {
    const headerLabels = useMemo(() => [
        S.prescriptionHistoryPage.singleDosageHeaderTitle,
        S.prescriptionHistoryPage.dailyDosageHeaderTitle,
        S.prescriptionHistoryPage.medicationPeriodInDaysHeaderTitle,
        S.prescriptionHistoryPage.unitHeaderTitle,
    ], []);

    return <LayoutContainer>
        <DateLayoutContainer>
            <DateLabelContainer>
                {data.dispenseDate.format(S.prescriptionHistoryPage.dateFormat)}
            </DateLabelContainer>
            <DateChipsLayoutContainer>
                <OutlinedChip
                    label={data.hospitalName}
                    color={Palette.primary500}
                    backgroundColor={Palette.primary100}
                    borderColor={Palette.primary300}
                />
                <OutlinedChip
                    label={data.pharmacyName}
                    color={Palette.gray500}
                    backgroundColor={Palette.gray100}
                    borderColor={Palette.gray300}
                />
            </DateChipsLayoutContainer>
        </DateLayoutContainer>
        <SizedBox height={"8px"}/>
        <MedicationsLayoutContainer>{
            data.medications.map((m, index) =>
                <MedicationLayoutContainer key={index}>
                    <MedicationHeaderLayoutContainer>
                        <MedicationIndexContainer>{index + 1}</MedicationIndexContainer>
                        <MedicationTitlesLayoutContainer>
                            <MedicationTitleContainer>
                                {m.code}
                            </MedicationTitleContainer>
                            <MedicationTitleContainer>
                                {m.name}
                            </MedicationTitleContainer>
                            <MedicationDescriptionContainer>
                                {[m.effect, m.principalComponent].join(' ')}
                            </MedicationDescriptionContainer>
                        </MedicationTitlesLayoutContainer>
                    </MedicationHeaderLayoutContainer>
                    <SizedBox height={"5px"}/>

                    <MedicationTableLayoutContainer
                        $backgroundColor={Palette.gray100}
                        $borderRadius={8}
                    >{
                        headerLabels.map((l, index) =>
                            <MedicationTableCellContainer key={index}>
                                <MedicationTableHeaderLabelContainer>
                                    {l}
                                </MedicationTableHeaderLabelContainer>
                            </MedicationTableCellContainer>
                        )
                    }</MedicationTableLayoutContainer>
                    <SizedBox height={"2px"}/>

                    <MedicationTableLayoutContainer
                        $backgroundColor={Palette.white100}
                        $borderRadius={0}
                    >
                        <MedicationTableCellContainer>
                            <MedicationTableCellLabelContainer>
                                {m.singleDosage}
                            </MedicationTableCellLabelContainer>
                        </MedicationTableCellContainer>
                        <MedicationTableCellContainer>
                            <MedicationTableCellLabelContainer>
                                {m.dailyDosage}
                            </MedicationTableCellLabelContainer>
                        </MedicationTableCellContainer>
                        <MedicationTableCellContainer>
                            <MedicationTableCellLabelContainer>
                                {m.medicationPeriodInDays}
                            </MedicationTableCellLabelContainer>
                        </MedicationTableCellContainer>
                        <MedicationTableCellContainer>
                            <MedicationTableCellLabelContainer>
                                {m.unit}
                            </MedicationTableCellLabelContainer>
                        </MedicationTableCellContainer>
                    </MedicationTableLayoutContainer>
                </MedicationLayoutContainer>
            )
        }</MedicationsLayoutContainer>
    </LayoutContainer>;
};

export default PrescriptionTile;
