import styled from "styled-components";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";

const LayoutContainer = styled.div.attrs<{ $roundTopLeft: boolean; $roundBottomLeft: boolean; }>((props) => ({
    style: {
        borderTopLeftRadius: props.$roundTopLeft ? "12px" : "0",
        borderBottomLeftRadius: props.$roundBottomLeft ? "12px" : "0",
    },
}))`
    min-width: 3.75rem;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Palette.gray100};
`;

const TitleContainer = styled.h6`
    min-width: max-content;
    ${Fonts.detail1};
    color: ${Palette.gray400};
    text-align: center;
`;

const ShootPrescriptionRowTitleBox = ({
                                          roundTopLeft = false,
                                          roundBottomLeft = false,
                                          title,
                                      }: {
    roundTopLeft?: boolean;
    roundBottomLeft?: boolean;
    title: string;
}) => {
    return <LayoutContainer $roundTopLeft={roundTopLeft} $roundBottomLeft={roundBottomLeft}>
        <TitleContainer>
            {title}
        </TitleContainer>
    </LayoutContainer>;
};

export default ShootPrescriptionRowTitleBox;
