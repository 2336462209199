import IconButton from "presentation/components/button/icon_button";
import Palette from "presentation/theme/palette";
import {ReactElement} from "react";
import styled from "styled-components";

const ButtonContainer = styled(IconButton)`
    box-shadow: 0 0 0 1px ${Palette.gray200} inset;
`;

const ArrowIconButton = ({
                             enabled,
                             asset,
                             onClick,
                         }: {
    enabled: boolean;
    asset: ReactElement;
    onClick: () => void;
}) => {
    const color = enabled ? Palette.gray800 : Palette.gray200;
    const hoverColor = enabled ? "light" : undefined;

    return (
        <ButtonContainer
            enabled={enabled}
            asset={asset}
            width={"32px"}
            height={"32px"}
            color={color}
            hoverColor={hoverColor}
            onClick={onClick}
        />
    );
};

export default ArrowIconButton;
