import ShootPrescriptionMedicationLayout
    from "presentation/pages/shoot/components/shoot_prescription_medication_layout";
import {memo, useMemo} from "react";
import S from "presentation/theme/s";
import ShootPrescriptionMedicationHeaderBox
    from "presentation/pages/shoot/components/shoot_prescription_medication_header_box";

const ShootPrescriptionMedicationHeader = () => {
    const headerLabels = useMemo(() => [
        S.shootPage.shootPrescriptionView.medicationsView.header.drugCodeLabel,
        S.shootPage.shootPrescriptionView.medicationsView.header.drugNameLabel,
        S.shootPage.shootPrescriptionView.medicationsView.header.singleDosageLabel,
        S.shootPage.shootPrescriptionView.medicationsView.header.dailyDosageLabel,
        S.shootPage.shootPrescriptionView.medicationsView.header.medicationPeriodInDaysLabel,
        S.shootPage.shootPrescriptionView.medicationsView.header.deductibleRateLabel,
        "",
    ], []);

    return <ShootPrescriptionMedicationLayout>
        {
            headerLabels.map((l, index) => <ShootPrescriptionMedicationHeaderBox
                key={index}
                roundLeft={index === 0}
                roundRight={index === headerLabels.length - 1}
                label={l}
            />)
        }
    </ShootPrescriptionMedicationLayout>;
};

export default memo(ShootPrescriptionMedicationHeader);
