import {animated, useChain, useSpring, useSpringRef} from "@react-spring/web";
import ViewStatusType from "domain/model/common/view_status_type";
import Loading from "presentation/components/common/loading";
import Palette from "presentation/theme/palette";
import useViewStatus from "presentation/utils/hooks/use_view_status";
import {PropsWithChildren} from "react";
import styled from "styled-components";
import GeneralErrorView from "presentation/components/error/general_error_view";
import CSSVisibilityType from "presentation/utils/types/css/visibility_type";
import CSSPointerEventsType from "presentation/utils/types/css/pointer_events_type";

const PageContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: ${Palette.white100};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    transition: min-width 0.3s ease-in-out, padding 0.3s ease-in-out;
`;

const ContentContainer = styled(animated.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const LoadingContainer = styled(animated.div)`
    position: fixed;
    top: max(50vh, 300px);
    left: 50%;
    z-index: 200000;
    transform: translate(-50%, -50%);
    transition: left 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

const ErrorContainer = styled(animated.div)`
    position: fixed;
    top: max(50vh, 300px);
    left: 50%;
    z-index: 200000;
    transform: translate(-50%, -50%);
    transition: left 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

const Page = ({
                  className,
                  status = ViewStatusType.Loaded,
                  children,
              }: PropsWithChildren<{
    className?: string;
    status?: ViewStatusType;
}>) => {
    const {loaded, loading, error} = useViewStatus(status);

    const contentProps = useSpring({
        opacity: loaded ? 1 : 0,
        pointerEvents: (loaded ? "auto" : "none") as CSSPointerEventsType,
    });

    const errorDisplayPropsRef = useSpringRef();
    const errorOpacityPropsRef = useSpringRef();

    const errorDisplayProps = useSpring({
        ref: errorDisplayPropsRef,
        display: error ? "block" : "none",
    });
    const errorOpacityProps = useSpring({
        ref: errorOpacityPropsRef,
        visibility: (error ? "visible" : "hidden") as CSSVisibilityType,
        opacity: error ? 1 : 0,
    });

    useChain(error ?
        [errorDisplayPropsRef, errorOpacityPropsRef] : [errorOpacityPropsRef, errorDisplayPropsRef]
    );

    const loadingDisplayPropsRef = useSpringRef();
    const loadingOpacityPropsRef = useSpringRef();

    const loadingDisplayProps = useSpring({
        ref: loadingDisplayPropsRef,
        display: loading ? "block" : "none",
    });
    const loadingOpacityProps = useSpring({
        ref: loadingOpacityPropsRef,
        visibility: (loading ? "visible" : "hidden") as CSSVisibilityType,
        opacity: loading ? 1 : 0,
    });

    useChain([loadingOpacityPropsRef, loadingDisplayPropsRef]);

    return (
        <PageContainer className={className}>
            <ContentContainer style={contentProps}>{children}</ContentContainer>
            <ErrorContainer style={{
                ...errorDisplayProps,
                ...errorOpacityProps,
            }}>
                <GeneralErrorView/>
            </ErrorContainer>
            <LoadingContainer
                style={{
                    ...loadingDisplayProps,
                    ...loadingOpacityProps,
                }}
            >
                <Loading visible={true}/>
            </LoadingContainer>
        </PageContainer>
    );
};

export default Page;
