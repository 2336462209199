import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import styled from "styled-components";
import S from "presentation/theme/s";

const LayoutContainer = styled.button`
    width: 100%;
    min-height: max-content;
    padding: 16px 4px;
    border: none;
    background-color: ${Palette.none};
    cursor: pointer;
`;

const LNBLogo = ({onClick}: { onClick: () => void }) => {
    return (
        <LayoutContainer
            onClick={onClick}
            title={S.common.companyName}
        >
            <SVG
                width={"68px"}
                height={"68px"}
                asset={SVGAssets.JeekimLogo}
                color={Palette.primary500}
            />
        </LayoutContainer>
    );
};

export default LNBLogo;
