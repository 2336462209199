import {PropsWithChildren, useEffect, useState} from "react";
import GeneralErrorView from "presentation/components/error/general_error_view";

export const ErrorBoundary = ({children}: PropsWithChildren) => {
    const [error, setError] = useState(false);

    useEffect(() => {
        const onError = () => setError(true);

        window.addEventListener("unhandledrejection", onError);

        return () => window.removeEventListener("unhandledrejection", onError);
    }, []);

    if (error) return <GeneralErrorView/>;

    return <>{children}</>;
};

export default ErrorBoundary;
