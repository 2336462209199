import {useRecoilValue} from "recoil";
import certVerificationSelector from "presentation/states/cert/selector/cert_verification_selector";
import {useLayoutEffect, useMemo} from "react";
import CertVerificationKakaoView from "presentation/pages/cert/verification/kakao/cert_verification_kakao_view";
import CertVerificationSMSView from "presentation/pages/cert/verification/sms/cert_verification_sms_view";
import CertType from "domain/model/cert/cert_type";

const CertVerificationView = () => {
    const {certType} = useRecoilValue(certVerificationSelector);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return useMemo(() => {
        switch (certType) {
            case CertType.Kakao:
                return <CertVerificationKakaoView/>;

            case CertType.SMS:
                return <CertVerificationSMSView/>;

            default:
                throw new Error("Invalid certType");
        }

    }, [certType]);
};

export default CertVerificationView;
