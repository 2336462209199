import styled from "styled-components";
import {animated, useSpring} from "@react-spring/web";
import {useRecoilValue} from "recoil";
import shootPrescriptionRevisionStatusSelector
    from "presentation/states/shoot/selector/shoot_prescription_revision_status_selector";
import Palette from "presentation/theme/palette";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";

const LayoutContainer = styled(animated.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    pointer-events: none;
`;

const BannerLayoutContainer = styled(animated.div)`
    width: 100%;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: ${Palette.red400};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

const BannerMessageContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.white100};
    white-space: pre-wrap;

`;

const ShootPrescriptionRevisionBannerView = () => {
    const {bannerVisible} = useRecoilValue(shootPrescriptionRevisionStatusSelector);

    const layoutProps = useSpring({
        height: bannerVisible ? 58 : 0,
        scale: bannerVisible ? 1 : 0.95,
        transformOrigin: "top center",
    });

    const bannerProps = useSpring({
        opacity: bannerVisible ? 1 : 0,
    });

    return <LayoutContainer style={layoutProps}>
        <BannerLayoutContainer style={bannerProps}>
            <SVG
                asset={SVGAssets.Warning}
                width={"24px"}
                height={"24px"}
                color={Palette.white100}
            />
            <BannerMessageContainer>
                {S.shootPage.shootPrescriptionView.revisionBannerView.message}
            </BannerMessageContainer>
        </BannerLayoutContainer>
    </LayoutContainer>;
};

export default ShootPrescriptionRevisionBannerView;
