import styled from "styled-components";
import ShootPrescriptionRowTitleBox from "presentation/pages/shoot/components/shoot_prescription_row_title_box";
import S from "presentation/theme/s";
import Flex from "presentation/components/common/flex";
import ShootPrescriptionTextField from "presentation/pages/shoot/components/shoot_prescription_text_field";
import {PrescriptionFieldRevisionStatusMap} from "domain/model/prescription/prescription_field_revision_status";
import ShootConstants from "presentation/states/shoot/constants/shoot_constants";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";
import {useRecoilValue, useSetRecoilState} from "recoil";
import shootPrescriptionsSelector from "presentation/states/shoot/selector/shoot_prescriptions_selector";
import shootState from "presentation/states/shoot/shoot_state";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ShootPrescriptionNursingAgencyDoctorLicenseView = () => {
    const {prescription} = useRecoilValue(shootPrescriptionsSelector);
    const setState = useSetRecoilState(shootState);

    const onNursingAgencyNumberChange = (nursingAgencyNumber: string) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        prescriptions[index] = {
            ...prescriptions[index],
            nursingAgencyNumber: {
                ...prescriptions[index].nursingAgencyNumber,
                value: nursingAgencyNumber,
                revisionStatus: {
                    ...prescriptions[index].nursingAgencyNumber.revisionStatus,
                    confidenceLow: false,
                },
            },
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    const onNursingAgencyNameChange = (nursingAgencyName: string) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        prescriptions[index] = {
            ...prescriptions[index],
            nursingAgencyName: {
                ...prescriptions[index].nursingAgencyName,
                value: nursingAgencyName,
                revisionStatus: {
                    ...prescriptions[index].nursingAgencyName.revisionStatus,
                    confidenceLow: false,
                },
            },
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    const onDoctorLicenseNumberChange = (doctorLicenseNumber: string) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        prescriptions[index] = {
            ...prescriptions[index],
            doctorLicenseNumber: {
                ...prescriptions[index].doctorLicenseNumber,
                value: doctorLicenseNumber,
                revisionStatus: {
                    ...prescriptions[index].doctorLicenseNumber.revisionStatus,
                    confidenceLow: false,
                },
            },
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    return <LayoutContainer>
        <ShootPrescriptionRowTitleBox
            title={S.shootPage.shootPrescriptionView.nursingAgencyDoctorLicenseView.nursingAgencyTitle}
        />
        <Flex flex={107}>
            <ShootPrescriptionTextField
                hasTopBorder={false}
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(prescription.nursingAgencyNumber.revisionStatus)}
                highlightPlaceholder={true}
                value={prescription.nursingAgencyNumber.value}
                onValueChange={onNursingAgencyNumberChange}
                maxLength={ShootConstants.prescription.maxPatientNameLength}
                inputFormatter={InputFormatters.decimalOnlyFormatter}
            />
        </Flex>
        <Flex flex={275}>
            <ShootPrescriptionTextField
                hasTopBorder={false}
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(prescription.nursingAgencyName.revisionStatus)}
                value={prescription.nursingAgencyName.value}
                onValueChange={onNursingAgencyNameChange}
                maxLength={ShootConstants.prescription.maxNursingAgencyNameLength}
            />
        </Flex>
        <ShootPrescriptionRowTitleBox
            title={S.shootPage.shootPrescriptionView.nursingAgencyDoctorLicenseView.doctorLicenseTitle}
        />
        <Flex flex={92}>
            <ShootPrescriptionTextField
                hasTopBorder={false}
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(prescription.doctorLicenseNumber.revisionStatus)}
                value={prescription.doctorLicenseNumber.value}
                onValueChange={onDoctorLicenseNumberChange}
                maxLength={ShootConstants.prescription.maxDoctorLicenseNumberLength}
                inputFormatter={InputFormatters.decimalOnlyFormatter}
            />
        </Flex>
    </LayoutContainer>;
};

export default ShootPrescriptionNursingAgencyDoctorLicenseView;
