import styled from "styled-components";
import {animated, useSpring} from "@react-spring/web";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";

const LayoutContainer = styled(animated.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    overflow: hidden;
    pointer-events: none;
`;

const LabelLayoutContainer = styled(animated.div)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const LabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail1};
    color: ${Palette.red500};
`;

const ShootPrescriptionRevisionLabel = ({
                                            visible
                                        }: {
    visible: boolean;
}) => {
    const layoutProps = useSpring({
        height: visible ? 24 : 0,
        scale: visible ? 1 : 0.95,
        transformOrigin: "top left",
    });
    const labelLayoutProps = useSpring({
        opacity: visible ? 1 : 0,
    });

    return <LayoutContainer style={layoutProps}>
        <LabelLayoutContainer style={labelLayoutProps}>
            <SVG
                asset={SVGAssets.Info}
                width={"20px"}
                height={"20px"}
                color={Palette.red500}
            />
            <LabelContainer>
                {S.shootPrescriptionRevisionLabel.label}
            </LabelContainer>
        </LabelLayoutContainer>
    </LayoutContainer>;
};

export default ShootPrescriptionRevisionLabel;
