import styled from "styled-components";
import {animated, useSpring} from "@react-spring/web";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";

const LayoutContainer = styled(animated.div)`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
`;

const NumberContainer = styled(animated.p)`
    ${Fonts.body1Medium};
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const UserGuideHeaderNumber = ({
                                   selected,
                                   number
                               }: {
    selected: boolean;
    number: number;
}) => {
    const layoutProps = useSpring({
        backgroundColor: selected ? Palette.black100 : Palette.white100,
        boxShadow: `0 0 0 1px ${selected ? Palette.black100 : Palette.gray300} inset`,
    });
    const numberProps = useSpring({
        color: selected ? Palette.white100 : Palette.gray300,
    });

    return <LayoutContainer style={layoutProps}>
        <NumberContainer style={numberProps}>{number}</NumberContainer>
    </LayoutContainer>;
};

export default UserGuideHeaderNumber;
