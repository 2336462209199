import {animated, useSpring} from "@react-spring/web";
import SVG from "presentation/components/common/svg";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import Debouncer from "presentation/utils/debouncer/debouncer";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import {ReactElement, useRef, MouseEvent, useState} from "react";
import styled from "styled-components";

type FilledButtonType = "primary" | "gray" | "red" | "black";
type FilledButtonSizeType = "large" | "medium" | "small";
type NativeType = "button" | "submit" | "reset";

const colorPresets: Record<
    FilledButtonType,
    {
        backgroundColor: string;
        hoverBackgroundColor: string;
        disabledBackgroundColor: string;
        color: string;
        hoverColor: string;
        disabledColor: string;
    }
> = {
    primary: {
        backgroundColor: Palette.primary500,
        hoverBackgroundColor: Palette.primary400,
        disabledBackgroundColor: Palette.primary100,
        color: Palette.white100,
        hoverColor: Palette.white100,
        disabledColor: Palette.primary30,
    },
    gray: {
        backgroundColor: Palette.gray100,
        hoverBackgroundColor: Palette.gray200,
        disabledBackgroundColor: Palette.gray100,
        color: Palette.gray800,
        hoverColor: Palette.gray800,
        disabledColor: Palette.gray300,
    },
    red: {
        backgroundColor: Palette.red100,
        hoverBackgroundColor: Palette.red200,
        disabledBackgroundColor: Palette.red100,
        color: Palette.red500,
        hoverColor: Palette.red500,
        disabledColor: Palette.red200,
    },
    black: {
        backgroundColor: Palette.black100,
        hoverBackgroundColor: Palette.gray600,
        disabledBackgroundColor: Palette.black100,
        color: Palette.white100,
        hoverColor: Palette.white100,
        disabledColor: Palette.white100,
    },
};

const sizePresets: Record<
    FilledButtonSizeType,
    {
        font: string;
        padding: string;
        iconSize: string;
    }
> = {
    large: {
        font: Fonts.body1,
        padding: "12px 16px",
        iconSize: "24px",
    },
    medium: {
        font: Fonts.detail2,
        padding: "10px 16px",
        iconSize: "20px",
    },
    small: {
        font: Fonts.detail1,
        padding: "6px 12px",
        iconSize: "16px",
    },
};

const LayoutContainer = styled(animated.button).attrs<{
    $enabled: boolean;
    $width: string;
    $padding: string;
}>((props) => ({
    style: {
        width: props.$width,
        padding: props.$padding,
        cursor: props.$enabled ? "pointer" : "not-allowed",
    },
}))`
    border: none;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
`;

const LabelContainer = styled(animated.p)<{
    $enabled: boolean;
    $font: string;
}>`
    min-width: max-content;
    ${(props) => props.$font};
    text-align: center;
`;

const FilledButton = ({
                          type = "primary",
                          sizeType = "large",
                          enabled = true,
                          width = "auto",
                          nativeType,
                          label,
                          leftIconAsset,
                          rightIconAsset,
                          iconColor,
                          iconHoverColor,
                          iconDisabledColor,
                          debouncerDelayInMS = 50,
                          onClick,
                      }: {
    type?: FilledButtonType;
    sizeType?: FilledButtonSizeType;
    enabled?: boolean;
    width?: string;
    nativeType?: NativeType;
    label: string;
    leftIconAsset?: ReactElement;
    rightIconAsset?: ReactElement;
    iconColor?: string;
    iconHoverColor?: string;
    iconDisabledColor?: string;
    debouncerDelayInMS?: number;
    onClick?: (e: MouseEvent) => void;
}) => {
    const debouncerRef = useRef(new Debouncer());
    const [hovered, setHovered] = useState(false);

    const _onClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!enabled) return;
        debouncerRef.current.run(() => onClick?.(e), debouncerDelayInMS);
    };

    const backgroundColor = colorPresets[type].backgroundColor;
    const hoverBackgroundColor = colorPresets[type].hoverBackgroundColor;
    const disabledBackgroundColor = colorPresets[type].disabledBackgroundColor;
    const color = colorPresets[type].color;
    const hoverColor = colorPresets[type].hoverColor;
    const disabledColor = colorPresets[type].disabledColor;
    const font = sizePresets[sizeType].font;
    const padding = sizePresets[sizeType].padding;
    const iconSize = sizePresets[sizeType].iconSize;

    const buttonProps = useSpring({
        backgroundColor: enabled ? (hovered ? hoverBackgroundColor : backgroundColor) : disabledBackgroundColor,
    });
    const {props: fadeInProps} = useFadeInTransition(label);
    const labelProps = useSpring({
        color: enabled ? (hovered ? hoverColor : color) : disabledColor,
    });

    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    const _iconColor = (
        enabled ?
            hovered ? (iconHoverColor ?? iconColor) :
                iconColor : (iconDisabledColor ?? iconColor)
    ) ?? color;

    return (
        <LayoutContainer
            type={nativeType}
            style={buttonProps}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={_onClick}
            title={label}
            $enabled={enabled}
            $width={width}
            $padding={padding}
        >
            {leftIconAsset && (
                <SVG
                    asset={leftIconAsset}
                    width={iconSize}
                    height={iconSize}
                    color={_iconColor}
                />
            )}
            <LabelContainer
                style={{
                    ...fadeInProps,
                    ...labelProps,
                }}
                $enabled={enabled}
                $font={font}
            >
                {label}
            </LabelContainer>
            {rightIconAsset && (
                <SVG
                    asset={rightIconAsset}
                    width={iconSize}
                    height={iconSize}
                    color={_iconColor}
                />
            )}
        </LayoutContainer>
    );
};

export default FilledButton;
