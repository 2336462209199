import Dialog from "presentation/components/dialog/dialog";
import SVGAssets from "presentation/theme/assets";
import S from "presentation/theme/s";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";
import DrugSearchTextField from "presentation/components/dialog/drug_search_dialog/components/drug_search_text_field";
import {useEffect, useRef, useState} from "react";
import useLifeCycle from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import PrescriptionRepository from "data/repository/prescription_repository";
import Debouncer from "presentation/utils/debouncer/debouncer";
import ViewStatusType from "domain/model/common/view_status_type";
import SizedBox from "presentation/components/common/sized_box";
import DrugSearchResults from "presentation/components/dialog/drug_search_dialog/components/drug_search_results";
import PrescriptionDrugSearch from "domain/model/prescription/prescription_drug_search";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import DrugCustomInputDialog from "presentation/components/dialog/drug_custom_input_dialog/drug_custom_input_dialog";
import ShootConstants from "presentation/states/shoot/constants/shoot_constants";

export type DrugSearchType = "code" | "name";

const DrugSearchDialog = ({
                              searchType,
                              drugCode,
                              drugName,
                              onDrugSelect,
                          }: {
    searchType: DrugSearchType;
    drugCode?: string;
    drugName?: string;
    onDrugSelect: (drugCode: string, drugName: string) => void;
}) => {
    const repository = useRepository(PrescriptionRepository);
    const showDialog = useShowDialog();
    const dismissDialog = useDismissDialog();

    const debouncerRef = useRef(new Debouncer());
    const [status, setStatus] = useState(ViewStatusType.Loading);
    const [focused, setFocused] = useState(true);
    const [input, setInput] = useState((searchType === "code" ? drugCode : searchType === "name" ? drugName : "") ?? "");
    const [search, setSearch] = useState<PrescriptionDrugSearch>({
        query: input,
        searchResults: [],
    });

    const isQueryEmpty = (input: string) => input.length < 3;

    useLifeCycle({
        onInit: () => repository({
            handler: async (repository) => {
                if (isQueryEmpty(input)) return;

                const _search = await repository.searchDrug({
                    drugCode: input,
                    drugName: input,
                });

                setSearch(_search);
            },
            onDomainError: () => {
            },
            onFinally: () => setStatus(ViewStatusType.Loaded),
        }),
    });

    const searchDrug = () => repository({
        handler: async (repository) => {
            if (isQueryEmpty(input) || search.query === input) return;

            const _search = await repository.searchDrug({
                drugCode: input,
                drugName: input,
            });

            setSearch(_search);
        },
        onDomainError: () => {
        },
    });

    useEffect(() => {
        if (status !== ViewStatusType.Loaded) return;

        if (isQueryEmpty(input)) {
            debouncerRef.current.cancel();
            setSearch({
                query: "",
                searchResults: [],
            });
            return;
        }

        debouncerRef.current.run(searchDrug, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    const onEnterPress = () => {
        if (isQueryEmpty(input)) return;
        debouncerRef.current.runImmediately(searchDrug)
    };

    const _onDrugSelect = (drugCode: string, drugName: string) => {
        dismissDialog();
        onDrugSelect(drugCode, drugName);
    }

    const onDrugCustomInputButtonClick = () => showDialog({
        className: "drug-custom-input-dialog",
        component: <DrugCustomInputDialog onDrugAdd={_onDrugSelect}/>
    });

    return <Dialog
        status={status}
        iconAsset={SVGAssets.DocumentSearch}
        title={S.drugSearchDialog.title}
    >
        <DrugSearchTextField
            value={input}
            onValueChange={setInput}
            placeholder={S.drugSearchDialog.placeholder}
            focused={focused}
            onFocusChange={setFocused}
            onEnterPress={onEnterPress}
            maxLength={ShootConstants.prescription.maxDrugNameLength}
        />
        <SizedBox height={"24px"}/>
        <DrugSearchResults
            search={search}
            onDrugSelect={_onDrugSelect}
            onDrugCustomInputButtonClick={onDrugCustomInputButtonClick}
        />
    </Dialog>;
};

export default DrugSearchDialog;
