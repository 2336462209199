import {animated, useSpring} from "@react-spring/web";
import SVG from "presentation/components/common/svg";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import styled from "styled-components";
import {SVGAsset} from "presentation/theme/assets";
import useHovered from "presentation/utils/hooks/use_hovered";

const LayoutContainer = styled(animated.button)`
    width: 100%;
    padding: 5px 8px;
    background-color: ${Palette.none};
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 2px;
`;

const IconContainer = styled(animated.div)`
    padding: 6px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail1};
    text-align: center;
`;

const LNBItem = ({
                     selected,
                     icon,
                     label,
                     onClick,
                 }: {
    selected: boolean;
    icon: SVGAsset;
    label: string;
    onClick: () => void;
}) => {
    const {hovered, onMouseEnter, onMouseLeave} = useHovered();

    const iconProps = useSpring({
        backgroundColor: selected || hovered ? Palette.primary10 : Palette.none,
        boxShadow: selected ? `0 0 0 1.5px ${Palette.primary30}` : `0 0 0 1.5px ${Palette.none}`,
    });
    const iconColor = selected ? Palette.primary500 : Palette.gray800;

    return <LayoutContainer
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        title={label}
    >
        <IconContainer style={iconProps}>
            <SVG
                asset={icon}
                width={"24px"}
                height={"24px"}
                color={iconColor}
            />
        </IconContainer>
        <LabelContainer>{label}</LabelContainer>
    </LayoutContainer>;
};

export default LNBItem;
