import {useRecoilValue} from "recoil";
import shootPrescriptionRevisionStatusSelector
    from "presentation/states/shoot/selector/shoot_prescription_revision_status_selector";
import ShootPrescriptionRevisionLabel from "presentation/pages/shoot/components/shoot_prescription_revision_label";

const ShootPrescriptionMedicationsRevisionLabelView = () => {
    const {medicationsRevisionNeeded} = useRecoilValue(shootPrescriptionRevisionStatusSelector);

    return <ShootPrescriptionRevisionLabel visible={medicationsRevisionNeeded}/>;
};

export default ShootPrescriptionMedicationsRevisionLabelView;
