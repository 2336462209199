import {selector} from "recoil";
import shootState from "presentation/states/shoot/shoot_state";

const shootPrescriptionsVisibleSelector = selector({
    key: "shootPrescriptionsVisibleSelector",
    get: ({get}) => {
        const state = get(shootState);

        return !!state.prescriptions.length;
    },
});

export default shootPrescriptionsVisibleSelector;