import ErrorView from "presentation/components/error/error_view";
import {StaticSVGAssets} from "presentation/theme/assets";
import S from "presentation/theme/s";

const AccessErrorView = () => {
    return <ErrorView
        staticAsset={StaticSVGAssets.ImageErrorOne}
        errorMessage={S.accessErrorView.message}
    />;
};

export default AccessErrorView;
