import {BaseTermsTypeMap} from "domain/model/terms/base_terms_type";
import S from "presentation/theme/s";

enum SMSCertKTTermsType {
    personalInformation = "smsKtPersonalInformation",
    uniqueId = "smsKtUniqueId",
    ispService = "smsKtIspService",
    generalService = "smsKtGeneralService",
}

export const SMSCertKTTermsTypes = Object.values(SMSCertKTTermsType);

export const SMSCertKTTermsTypeMap: BaseTermsTypeMap = {
    title: (type: SMSCertKTTermsType): string => {
        switch (type) {
            case SMSCertKTTermsType.personalInformation:
                return S.smsCertTermsType.personalInformationTitle;
            case SMSCertKTTermsType.uniqueId:
                return S.smsCertTermsType.uniqueIdTitle;
            case SMSCertKTTermsType.ispService:
                return S.smsCertTermsType.ispServiceTitle;
            case SMSCertKTTermsType.generalService:
                return S.smsCertTermsType.generalServiceTitle;
            default:
                throw new Error("Invalid sms cert kt terms type");
        }
    },
    label: (type: SMSCertKTTermsType): string => {
        switch (type) {
            case SMSCertKTTermsType.personalInformation:
                return S.smsCertTermsType.personalInformationLabel;
            case SMSCertKTTermsType.uniqueId:
                return S.smsCertTermsType.uniqueIdLabel;
            case SMSCertKTTermsType.ispService:
                return S.smsCertTermsType.ispServiceLabel;
            case SMSCertKTTermsType.generalService:
                return S.smsCertTermsType.generalServiceLabel;
            default:
                throw new Error("Invalid sms cert kt terms type");
        }
    },
};

export default SMSCertKTTermsType;
