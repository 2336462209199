import Medication from "domain/model/prescription_history/medication";

type MedicationDTO = {
    no: string;
    medicine: string;
    medicinalEffect: string;
    ingredient: string;
    medicineCode: string;
    unit: string;
    oneDose: string;
    numberDoseDay: string;
    totalDoseDay: string;
    stability: string;
};

export const MedicationDTOMap = {
    fromDomainModel: (d: Medication): MedicationDTO => {
        return {
            no: d.id,
            medicine: d.name,
            medicinalEffect: d.effect,
            ingredient: d.principalComponent,
            medicineCode: d.code,
            unit: d.unit,
            oneDose: d.singleDosage,
            numberDoseDay: d.dailyDosage,
            totalDoseDay: d.medicationPeriodInDays,
            stability: d.stability,
        };
    },
    toDomainModel: (d: MedicationDTO): Medication => {
        return {
            id: d.no,
            name: d.medicine,
            effect: d.medicinalEffect,
            principalComponent: d.ingredient,
            code: d.medicineCode,
            unit: d.unit,
            singleDosage: d.oneDose,
            dailyDosage: d.numberDoseDay,
            medicationPeriodInDays: d.totalDoseDay,
            stability: d.stability,
        };
    },
}

export default MedicationDTO;