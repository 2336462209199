import ViewStatusType from "domain/model/common/view_status_type";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {useEffect, useState} from "react";

const useViewStatus = (status: ViewStatusType) => {
    const theme = useThemeContext();

    const [_status, _setStatus] = useState(status);

    useEffect(() => {
        if (status === ViewStatusType.Loading) {
            _setStatus(status);
            return;
        }

        const id = setTimeout(
            () => _setStatus(status),
            theme.animationTheme.loadingDelayMS
        );

        return () => clearTimeout(id);
    }, [status, theme]);

    const loading = _status === ViewStatusType.Loading;
    const loaded = _status === ViewStatusType.Loaded;
    const error = _status === ViewStatusType.Error;

    return {
        loading,
        loaded,
        error,
        status: _status,
        setStatus: _setStatus,
    };
};

export default useViewStatus;
