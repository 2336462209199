import {HTTPClient, httpClientCancelSubject,} from "data/network/http_client/http_client";
import CommonError from "domain/model/common/common_error";
import DomainError from "domain/model/common/domain_error";
import ErrorCode from "domain/model/common/error_code";
import {delayed} from "presentation/utils/functions/delayed";
import {SecureStorage} from "data/local/secure_storage/secure_storage";

export default abstract class BaseRepository {
    private readonly httpClient: HTTPClient;
    private readonly secureStorage: SecureStorage;

    constructor(
        httpClient: HTTPClient,
        secureStorage: SecureStorage
    ) {
        this.httpClient = httpClient;
        this.secureStorage = secureStorage;
    }

    protected handler = async <T>({
                            concurrency = 1,
                            request,
                        }: {
        concurrency?: number;
        request: (
            httpClient: HTTPClient,
            signals: AbortSignal[],
            localStorage: SecureStorage
        ) => Promise<T>;
    }): Promise<T> => {
        const _concurrency = concurrency < 1 ? 1 : concurrency;

        const aborts = Array.from(
            {length: _concurrency},
            () => new AbortController()
        );
        const subscription = httpClientCancelSubject.subscribe(() => {
            aborts.forEach((abort) => abort.abort());
        });

        try {
            return await request(
                this.httpClient,
                aborts.map((abort) => abort.signal),
                this.secureStorage
            );
        } finally {
            subscription.unsubscribe();
        }
    };

    protected delayed = async (delayInMS: number) => await delayed(delayInMS);

    protected throwDomainError = (errorCode: ErrorCode, message?: string) => {
        throw new DomainError(errorCode, message);
    };

    protected throwServerSentError = (title: string, message: string) => {
        throw new CommonError(ErrorCode.ServerSentError, title, message);
    };
}
