const S = {
    accessErrorView: {
        message: "잘못된 접근입니다"
    },

    autoLoginPage: {
        loginErrorDialog: {
            title: "자동 로그인 오류",
            message: "자동 로그인에 실패했습니다. 로그인 화면으로 이동합니다.",
        },
    },

    calendarCell: {
        dayFormat: "d",
    },

    calendarMonthHeader: {
        dateFormat: "yyyy.MM",
    },

    calendarSmallMonthHeader: {
        dateFormat: "yyyy.MM",
    },

    certPage: {
        user: {
            title: "복약 이력 조회를 위해\n정보를 입력해주세요",
            buttonLabel: "인증하기",
            nameSection: {
                title: "이름",
                placeholder: "이름을 입력해주세요",
            },
            ssnSection: {
                title: "주민등록번호",
                prefixPlaceholder: "앞 6자리",
                suffixPlaceholder: "뒤 7자리",
            },
            phoneNumberSection: {
                title: "휴대폰 번호",
                placeholder: "휴대폰 번호를 입력해주세요",
            },
            ispSection: {
                title: "통신사 선택",
            },
            certTypeSection: {
                title: "인증 방식 선택",
            },
            termsSection: {
                title: "약관 동의",
            },
        },
        verification: {
            kakao: {
                title: "카카오톡에서\n인증을 완료해주세요",
                buttonLabel: "인증 완료",
            },
            sms: {
                title: "인증번호\n6자리를 입력해주세요",
                buttonLabel: "확인",
                codeSection: {
                    title: "인증번호",
                    placeholder: "6자리를 입력해주세요",
                },
            },
        },
        error: {
            errorDialogTitle: "인증 오류",
            invalidInputErrorMessage:
                "입력값이 올바르지 않습니다. 확인 후 다시 시도해주세요.",
            webCrawlingErrorMessage:
                "데이터를 불러오는 중에 에러가 발생했습니다. 입력값이 올바른지 확인하신 후 다시 시도해주세요.",
            verificationErrorMessage: "인증번호가 일치하지 않습니다. 확인 후 다시 시도해주세요.",
            verificationIncompleteErrorMessage:
                "인증이 완료되지 않았습니다. 확인 후 다시 시도해주세요.",
            verificationTimeoutErrorMessage:
                "인증 시간이 초과되었습니다. 다시 시도해주세요.",
        },
    },

    certTermsButton: {
        label: "전체 동의하기",
        collapsedButtonCollapsedLabel: "펼치기",
        collapsedButtonExpandedLabel: "접기",
        bulletedLabel: (label: string) => `• ${label}`,
    },

    certType: {
        kakaoLabel: "카카오 인증",
        smsLabel: "문자 인증",
    },

    common: {
        companyName: "지킴",
        close: "닫기",
        confirm: "확인",
        placeholder: "자리매김",
        unknownErrorTitle: "알 수 없는 오류",
        unknownErrorMessage:
            "에러가 발생했습니다. 잠시 뒤에 다시 시도해주세요.",
    },

    datePickerDialog: {
        saveButtonLabel: "저장하기",
    },

    drugCustomInputDialog: {
        title: "약품 직접 추가",
        drugCodeTitle: "약품 코드",
        drugCodePlaceholder: "약품 코드를 입력해주세요",
        drugNameTitle: "약품명",
        drugNamePlaceholder: "약품명을 입력해주세요",
        addButtonLabel: "추가하기",
    },

    drugSearchDialog: {
        title: "약품 검색",
        placeholder: "약품 코드 또는 약품명을 입력해주세요",
        notFoundDrugLabel: (query: string) => `\`${query}\`\n약품을 찾을 수 없습니다...`,
        drugCustomInputButtonLabel: "직접 추가하기",
        queryEmptyLabel: "약품 코드 또는 약품명을\n세 자리 이상 입력해주세요",
    },

    generalErrorView: {
        message: "일시적인 오류입니다\n잠시 후 다시 시도해 주세요"
    },

    insuranceType: {
        healthLabel: "의료보험",
        medicalCareLabel: "의료급여",
        industrialLabel: "산재보험",
        carLabel: "자동차보험",
        othersLabel: "기타",
    },

    ispType: {
        SKTLabel: "SKT",
        KTLabel: "KT",
        LGLabel: "LG U+",
        SMLabel: "SKT 알뜰폰",
        KMLabel: "KT 알뜰폰",
        LMLabel: "LG U+ 알뜰폰",
    },

    kakaoCertTermsType: {
        thirdPartyTitle: "제3자정보제공동의",
        thirdPartyLabel: "제3자정보제공동의(필수)",
        uniqueIdTitle: "고유식별정보처리동의",
        uniqueIdLabel: "고유식별정보처리동의(필수)",
        personalInformationTitle: "개인정보이용동의",
        personalInformationLabel: "개인정보이용동의(필수)",
    },

    lnbItemType: {
        myPageLabel: "내정보",
        shootLabel: "OCR",
    },

    lnbShootButton: {
        label: "처방전 촬영",
        title: "촬영",
    },

    loading: {
        label: "잠시만 기다려주세요",
    },

    loginPage: {
        welcomeLabel: "똑똑한 병원, 여기서 펼치다",
        userIDPlaceholder: "병원 코드를 입력해주세요",
        passwordInputPlaceholder: "비밀번호를 입력해주세요",
        loginButtonStartLabel: "시작하기",
        loginButtonLoginLabel: "로그인",
        invalidPasswordErrorMessage: "비밀번호가 일치하지 않습니다.",
    },

    myPage: {
        logoutButtonLabel: "로그아웃",
    },

    notificationDialog: {
        positiveButtonLabel: "확인",
        negativeButtonLabel: "취소",
    },

    prescriptionDeductibleRateType: {
        fullyDeductibleLabel: "급여",
        nondeductibleLabel: "비급여",
        fullyImposedLabel: "100% 본인",
        eightyPercentImposedLabel: "80% 본인",
        fiftyPercentImposedLabel: "50% 본인",
        thirtyPercentImposedLabel: "30% 본인",
        fullyDeductibleAbbreviatedLabel: "급",
        nondeductibleAbbreviatedLabel: "비급",
        fullyImposedAbbreviatedLabel: "100",
        eightyPercentImposedAbbreviatedLabel: "80",
        fiftyPercentImposedAbbreviatedLabel: "50",
        thirtyPercentImposedAbbreviatedLabel: "30",
    },

    prescriptionExtraRemarksType: {
        powderedMedicineLabel: "가루조제",
    },

    prescriptionHistoryPage: {
        title: "복약 이력",
        dateFormat: "yyyy.MM.dd",
        singleDosageHeaderTitle: "1회량",
        dailyDosageHeaderTitle: "1일 횟수",
        medicationPeriodInDaysHeaderTitle: "총 일수",
        unitHeaderTitle: "단위",
        buttonLabel: "전송하기",
        successDialog: {
            title: "전송 성공",
            message: "복약 이력 전송이 성공했습니다! 초기 화면으로 돌아갑니다.",
        },
    },

    realtimeScreenDialog: {
        message: "스캐너에 처방전을 올려주세요",
    },

    selectDeductibleRateDialog: {
        title: "본인 부담률 코드 선택",
        confirmButtonLabel: "확인",
    },

    shootPage: {
        unsupportedPlatformDialog: {
            title: "지원하지 않는 환경",
            message: "모바일 환경에서는 사용할 수 없습니다.",
        },
        shootLoadingView: {
            message: "촬영 장비를 연결합니다",
        },
        shootEmptyPrescriptionView: {
            cameraSoundTitle: "카메라 촬영음",
            cameraSoundDescription: "카메라 촬영음 소리 켜기/끄기",
            noticeLabel: "스캐너에 처방전을 올려주세요",
            realtimeScreenButtonLabel: "실시간 화면",
            userGuideButtonLabel: "이용 가이드",
        },
        shootPrescriptionsView: {
            realtimeScreenButtonLabel: "실시간 화면",
            deletePrescriptionDialog: {
                title: (patientName: string) => `${patientName}의 처방전을 삭제 하시겠어요?`,
                message: "확인 버튼을 누르면 삭제됩니다.",
            },
            deleteAllPrescriptionsDialog: {
                title: (count: string) => `${count}개 모두 삭제할까요?`,
                message: "다시 복구가 어려워요! 확인 버튼을 누르면 삭제됩니다.",
            },
            noValidForSendingPrescriptionsAvailableDialog: {
                title: "전송 실패",
                message: "전송할 수 있는 처방전이 없습니다.\n필수 항목이 빠진게 없나 확인하고 다시 시도해주세요.",
            },
            sendSuccessToast: {
                title: "전송이 완료되었어요!",
                message: (issueDate: string, patientName: string, nursingAgencyName: string) => `${issueDate} / ${patientName} / ${nursingAgencyName}`,
                issueDateFormat: "yyyy.MM.dd",
            },
            sendErrorDialog: {
                title: "전송 실패",
                message: (count: string) => `전송에 실패한 ${count}개의 처방전이 있습니다.\n잠시 후 다시 시도해주세요.`,
            },
        },
        shootPrescriptionView: {
            revisionBannerView: {
                message: "일부 항목은 검토가 필요해요.\n실제 처방전과 비교 후 수정해주세요.",
            },
            issueNumberDateView: {
                issueNumberTitle: "교부번호",
                issueDatePickerTitle: "교부일자 선택",
                datePickerSelectedDateSuffixLabel: "교부",
            },
            patientNameSSNView: {
                patientTitle: "환자",
            },
            nursingAgencyDoctorLicenseView: {
                nursingAgencyTitle: "기관",
                doctorLicenseTitle: "면허번호",
            },
            diseaseCodesView: {
                diseaseCodeTitle: "질병분류",
            },
            selfPayCodeExtraRemarksView: {
                selfPayCodeTitle: "본인기호",
                selfPayCodePlaceholder: "해당없음",
                extraRemarksTitle: "참고사항",
            },
            medicationsView: {
                headerTitle: (count: string) => `처방의약품 ${count}개`,
                header: {
                    drugCodeLabel: "약품 코드",
                    drugNameLabel: "약품명",
                    singleDosageLabel: "1회",
                    dailyDosageLabel: "1일",
                    medicationPeriodInDaysLabel: "총 투약",
                    deductibleRateLabel: "부담",
                },
            },
        },
    },

    shootPrescriptionDateField: {
        format: "yyyy.MM.dd",
    },

    shootPrescriptionExtraRemarksField: {
        emptyLabel: "해당없음",
    },

    shootPrescriptionHeader: {
        emptyPatientNameLabel: "(이름 없음)",
        indexLabel: (index: string, total: string) => `${index}/${total}`,
        deleteButtonLabel: "삭제",
        sendButtonLabel: "전송하기",
        deleteAllButtonLabel: "모두 삭제",
        sendAllButtonLabel: "모든 처방전 전송",
    },

    shootPrescriptionImage: {
        noticeLabel: "스크롤하면 확대할 수 있습니다",
    },

    shootPrescriptionMedicationDrugField: {
        emptyLabel: "-",
    },

    shootPrescriptionMedicationTextField: {
        placeholder: "-",
    },

    shootPrescriptionRevisionLabel: {
        label: "수정이 필요한 항목",
    },

    shootPrescriptionTextField: {
        placeholder: "입력해주세요",
    },

    successPage: {
        appBarTitle: "복약이력 전송 성공",
    },

    smsCertTermsType: {
        personalInformationTitle: "개인정보이용동의",
        personalInformationLabel: "개인정보이용동의(필수)",
        uniqueIdTitle: "고유식별정보처리동의",
        uniqueIdLabel: "고유식별정보처리동의(필수)",
        ispServiceTitle: "통신사이용약관동의",
        ispServiceLabel: "통신사이용약관동의(필수)",
        generalServiceTitle: "서비스이용약관동의",
        generalServiceLabel: "서비스이용약관동의(필수)",
    },

    smsCertMVNOTermsType: {
        personalInformationTitle: "개인정보이용동의",
        personalInformationLabel: "개인정보이용동의(필수)",
        uniqueIdTitle: "고유식별정보처리동의",
        uniqueIdLabel: "고유식별정보처리동의(필수)",
        thirdPartyTitle: "제3자정보제공동의",
        thirdPartyLabel: "제3자정보제공동의(필수)",
        ispServiceTitle: "통신사이용약관동의",
        ispServiceLabel: "통신사이용약관동의(필수)",
        generalServiceTitle: "서비스이용약관동의",
        generalServiceLabel: "서비스이용약관동의(필수)",
    },

    userGuideDialog: {
        messageOne: "헤드를 아래와 같이 펼치고, 트레이는 정방향으로 놓아주세요.",
        messageTwo: "빈 트레이에 처방전을 올린 후, 손을 떼면 자동촬영됩니다.",
        messageThree: " 오류 발생 시 처방전을 다시 올려주세요.\n카메라 버튼을 눌러 수동 촬영도 가능합니다.",
        nextButtonLabel: "다음",
        completeButtonLabel: "완료",
    },

    weekdayType: {
        monShortLabel: "월",
        tueShortLabel: "화",
        wedShortLabel: "수",
        thuShortLabel: "목",
        friShortLabel: "금",
        satShortLabel: "토",
        sunShortLabel: "일",
        monLabel: "월요일",
        tueLabel: "화요일",
        wedLabel: "수요일",
        thuLabel: "목요일",
        friLabel: "금요일",
        satLabel: "토요일",
        sunLabel: "일요일",
    },
};

export default S;
