import NursingAgencyNumberDataFilter from "data/data_filter/nursing_agency_number_data_filter";
import SSNDataFilter from "data/data_filter/ssn_data_filter";
import IssueNumberDataFilter from "data/data_filter/issue_number_data_filter";
import DoctorLicenseNumberDataFilter from "data/data_filter/doctor_license_number_data_filter";

export default class DataFilters {
    static doctorLicenseNumberDataFilter = new DoctorLicenseNumberDataFilter();

    static issueNumberDataFilter = new IssueNumberDataFilter();

    static nursingAgencyNumberDataFilter = new NursingAgencyNumberDataFilter();

    static ssnDataFilter = new SSNDataFilter();
}