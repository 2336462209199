import {BaseTermsTypeMap} from "domain/model/terms/base_terms_type";
import S from "presentation/theme/s";

enum SMSCertLGTermsType {
    personalInformation = "smsLgPersonalInformation",
    uniqueId = "smsLgUniqueId",
    ispService = "smsLgIspService",
    generalService = "smsLgGeneralService",
}

export const SMSCertLGTermsTypes = Object.values(SMSCertLGTermsType);

export const SMSCertLGTermsTypeMap: BaseTermsTypeMap = {
    title: (type: SMSCertLGTermsType): string => {
        switch (type) {
            case SMSCertLGTermsType.personalInformation:
                return S.smsCertTermsType.personalInformationTitle;
            case SMSCertLGTermsType.uniqueId:
                return S.smsCertTermsType.uniqueIdTitle;
            case SMSCertLGTermsType.ispService:
                return S.smsCertTermsType.ispServiceTitle;
            case SMSCertLGTermsType.generalService:
                return S.smsCertTermsType.generalServiceTitle;
            default:
                throw new Error("Invalid sms cert lg terms type");
        }
    },
    label: (type: SMSCertLGTermsType): string => {
        switch (type) {
            case SMSCertLGTermsType.personalInformation:
                return S.smsCertTermsType.personalInformationLabel;
            case SMSCertLGTermsType.uniqueId:
                return S.smsCertTermsType.uniqueIdLabel;
            case SMSCertLGTermsType.ispService:
                return S.smsCertTermsType.ispServiceLabel;
            case SMSCertLGTermsType.generalService:
                return S.smsCertTermsType.generalServiceLabel;
            default:
                throw new Error("Invalid sms cert lg terms type");
        }
    },
};

export default SMSCertLGTermsType;
