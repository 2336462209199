import styled from "styled-components";
import useLifeCycle from "presentation/utils/hooks/use_life_cycle";
import useSearchParams from "presentation/utils/hooks/use_search_params";
import AppRouter from "presentation/routes/app_router";
import Loading from "presentation/components/common/loading";
import CertType from "domain/model/cert/cert_type";
import AppRoute from "presentation/routes/model/app_route";

const LayoutContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    position: relative;
`;

const SimpleCertPage = () => {
    const searchParam = useSearchParams();

    useLifeCycle({
        onInit: () => {
            const hospital = searchParam("hospital");

            const path = hospital ? `${AppRoute.Cert}?hospital=${hospital}&CertType=${CertType.Kakao}` : `${AppRoute.Cert}?${CertType.Kakao}`;
            AppRouter.navigate(path, {
                replace: true,
            });
        },
    });

    return <LayoutContainer>
        <Loading visible={true}/>
    </LayoutContainer>;
};

export default SimpleCertPage;
