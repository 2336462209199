import FilledButton from "presentation/components/button/filled_button";
import SVGAssets from "presentation/theme/assets";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";
import {ReactElement} from "react";
import styled from "styled-components";
import Dialog from "presentation/components/dialog/dialog";

export type NotificationDialogType = "positive" | "negative";

const config = {
    positive: {
        iconAsset: SVGAssets.CheckCircle,
        iconColor: Palette.lightGreen,
    },
    negative: {
        iconAsset: SVGAssets.Warning,
        iconColor: Palette.red500,
    },
};

const MessageContainer = styled.p`
    width: 100%;
    ${Fonts.detail3Medium};
    color: ${Palette.gray500};
    white-space: pre-wrap;
    word-break: keep-all;
`;

const ButtonsLayoutContainer = styled.div`
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
`;

const NotificationDialog = ({
                                dismissOnButtonClick = true,
                                type = "positive",
                                iconAsset,
                                iconColor,
                                title,
                                message,
                                hasPositiveButton = true,
                                hasNegativeButton = true,
                                positiveButtonLabel = S.notificationDialog.positiveButtonLabel,
                                negativeButtonLabel = S.notificationDialog.negativeButtonLabel,
                                onPositiveButtonClick,
                                onNegativeButtonClick,
                            }: {
    dismissOnButtonClick?: boolean;
    type?: NotificationDialogType;
    iconAsset?: ReactElement;
    iconColor?: string;
    title?: string;
    message: string;
    hasPositiveButton?: boolean;
    hasNegativeButton?: boolean;
    positiveButtonLabel?: string;
    negativeButtonLabel?: string;
    onPositiveButtonClick?: () => void;
    onNegativeButtonClick?: () => void;
}) => {
    const dismissDialog = useDismissDialog();

    const _iconAsset = iconAsset ?? config[type].iconAsset;
    const _iconColor = iconColor ?? config[type].iconColor;

    const hasButtons = hasPositiveButton || hasNegativeButton;

    const _onNegativeButtonClick = () => {
        if (dismissOnButtonClick) dismissDialog();
        onNegativeButtonClick?.();
    };

    const _onPositiveButtonClick = () => {
        if (dismissOnButtonClick) dismissDialog();
        onPositiveButtonClick?.();
    };

    return (
        <Dialog
            widthInPx={446}
            iconAsset={_iconAsset}
            iconColor={_iconColor}
            iconRimColor={Palette.none}
            title={title}
            titleFont={Fonts.body2}
            titleColor={Palette.gray800}
            titleToContentSpacingInPx={12}
        >
            <MessageContainer>{message}</MessageContainer>
            {hasButtons && (
                <ButtonsLayoutContainer>
                    {hasNegativeButton && (
                        <FilledButton
                            type={"gray"}
                            sizeType={"medium"}
                            label={negativeButtonLabel}
                            onClick={_onNegativeButtonClick}
                        />
                    )}
                    {hasPositiveButton && (
                        <FilledButton
                            type={"primary"}
                            sizeType={"medium"}
                            label={positiveButtonLabel}
                            onClick={_onPositiveButtonClick}
                        />
                    )}
                </ButtonsLayoutContainer>
            )}
        </Dialog>
    );
};

export default NotificationDialog;
