import BBox from "domain/model/common/bbox";
import PrescriptionBaseField from "domain/model/prescription/prescription_base_field";
import {PrescriptionFieldRevisionStatusMap} from "domain/model/prescription/prescription_field_revision_status";

type PrescriptionField = {
    value: string;
    bbox?: BBox;
} & PrescriptionBaseField;

export const PrescriptionFieldMap = {
    valueEmptyOrRevisionNeeded: (d: PrescriptionField): boolean => {
        return !d.value.length || PrescriptionFieldRevisionStatusMap.revisionNeeded(d.revisionStatus);
    },
};

export default PrescriptionField;