import uiState from "presentation/states/ui/ui_state";
import {useSetRecoilState} from "recoil";

const useSetLoading = () => {
    const setState = useSetRecoilState(uiState);

    return (loading: boolean, config: {
        darken: boolean;
        spinner: boolean;
    } = {
        darken: false,
        spinner: false,
    }) =>
        setState((prev) => ({
            ...prev,
            loadingState: {
                ...prev.loadingState,
                loading,
                darken: loading ? config.darken : false,
                spinner: loading ? config.spinner : false,
            },
        }));
};

export default useSetLoading;
