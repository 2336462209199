import styled from "styled-components";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import {animated} from "@react-spring/web";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";

const LayoutContainer = styled.div`
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const TitleContainer = styled(animated.h4)`
    ${Fonts.emphasis1};
    color: ${Palette.black100};
    white-space: pre-wrap;
`;

const PageTitle = ({title}: { title: string; }) => {
    const {props} = useFadeInTransition(title);

    return <LayoutContainer>
        <TitleContainer style={props}>
            {title}
        </TitleContainer>
    </LayoutContainer>;
};

export default PageTitle;
