import PrescriptionDrugSearchResult from "domain/model/prescription/prescription_drug_search_result";

type PrescriptionDrugSearchResultDTO = {
    drugCode: string;
    drugName: string;
};

export const PrescriptionDrugSearchResultDTOMap = {
    toDomainModel: (d: PrescriptionDrugSearchResultDTO): PrescriptionDrugSearchResult => {
        return {
            drugCode: d.drugCode,
            drugName: d.drugName,
        };
    },
}

export default PrescriptionDrugSearchResultDTO;