import {useSpring} from "@react-spring/web";
import {useLayoutEffect} from "react";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";

const useAnimatedMount = (key?: string) => {
    const [props, api] = useSpring(() => ({
        opacity: 0,
    }));

    useLayoutEffect(() => {
        api.set({opacity: 0});
        addPostFrameCallback(() => api.start({opacity: 1}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    return {props, api};
};

export default useAnimatedMount;
