import NotificationDialog, {
    NotificationDialogType,
} from "presentation/components/dialog/notification_dialog/notification_dialog";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import {ReactElement} from "react";

const useShowNotificationDialog = () => {
    const showDialog = useShowDialog();

    return ({
                className,
                dismissOnButtonClick = true,
                type,
                iconAsset,
                iconColor,
                title,
                message,
                hasPositiveButton,
                hasNegativeButton,
                positiveButtonLabel,
                negativeButtonLabel,
                onPositiveButtonClick,
                onNegativeButtonClick,
                onDismissed,
            }: {
        className?: string;
        dismissOnButtonClick?: boolean;
        type?: NotificationDialogType;
        iconAsset?: ReactElement;
        iconColor?: string;
        title?: string;
        message: string;
        hasPositiveButton?: boolean;
        hasNegativeButton?: boolean;
        positiveButtonLabel?: string;
        negativeButtonLabel?: string;
        onPositiveButtonClick?: () => void;
        onNegativeButtonClick?: () => void;
        onDismissed?: () => void;
    }) =>
        showDialog({
            className: "notification-dialog" + (className ?? ""),
            component: (
                <NotificationDialog
                    type={type}
                    iconAsset={iconAsset}
                    iconColor={iconColor}
                    title={title}
                    message={message}
                    hasPositiveButton={hasPositiveButton}
                    hasNegativeButton={hasNegativeButton}
                    positiveButtonLabel={positiveButtonLabel}
                    negativeButtonLabel={negativeButtonLabel}
                    onPositiveButtonClick={onPositiveButtonClick}
                    onNegativeButtonClick={onNegativeButtonClick}
                    dismissOnButtonClick={dismissOnButtonClick}
                />
            ),
            onDismissed,
        });
};

export default useShowNotificationDialog;
