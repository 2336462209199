export type PlatformType = "mobile" | "macOS" | "windows";

const getPlatformType = () => {
    const isMobile = () => {
        let isMobile = false;
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                navigator.userAgent
            )
        ) {
            isMobile = true;
        }

        return isMobile;
    };

    const isMacOS = () => navigator.userAgent.indexOf("Mac OS X") !== -1;

    if (isMobile()) return "mobile";
    if (isMacOS()) return "macOS";
    return "windows";
};

export default getPlatformType;
