import S from "presentation/theme/s";

export enum WeekdayType {
    Mon = "mon",
    Tue = "tue",
    Wed = "wed",
    Thu = "thu",
    Fri = "fri",
    Sat = "sat",
    Sun = "sun",
}

export const WeekdayTypes = Object.values(WeekdayType);

export const WeekdayTypeMap = {
    index: (type: WeekdayType): number => {
        switch (type) {
            case WeekdayType.Mon:
                return 0;
            case WeekdayType.Tue:
                return 1;
            case WeekdayType.Wed:
                return 2;
            case WeekdayType.Thu:
                return 3;
            case WeekdayType.Fri:
                return 4;
            case WeekdayType.Sat:
                return 5;
            case WeekdayType.Sun:
                return 6;
            default:
                throw new Error("Invalid WeekdayType");
        }
    },
    label: (type: WeekdayType): string => {
        switch (type) {
            case WeekdayType.Mon:
                return S.weekdayType.monLabel;
            case WeekdayType.Tue:
                return S.weekdayType.tueLabel;
            case WeekdayType.Wed:
                return S.weekdayType.wedLabel;
            case WeekdayType.Thu:
                return S.weekdayType.thuLabel;
            case WeekdayType.Fri:
                return S.weekdayType.friLabel;
            case WeekdayType.Sat:
                return S.weekdayType.satLabel;
            case WeekdayType.Sun:
                return S.weekdayType.sunLabel;
            default:
                throw new Error("Invalid WeekdayType");
        }
    },
    shortLabel: (type: WeekdayType): string => {
        switch (type) {
            case WeekdayType.Mon:
                return S.weekdayType.monShortLabel;
            case WeekdayType.Tue:
                return S.weekdayType.tueShortLabel;
            case WeekdayType.Wed:
                return S.weekdayType.wedShortLabel;
            case WeekdayType.Thu:
                return S.weekdayType.thuShortLabel;
            case WeekdayType.Fri:
                return S.weekdayType.friShortLabel;
            case WeekdayType.Sat:
                return S.weekdayType.satShortLabel;
            case WeekdayType.Sun:
                return S.weekdayType.sunShortLabel;
            default:
                throw new Error("Invalid WeekdayType");
        }
    },
};
