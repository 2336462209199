import ObjectConvertable from "presentation/routes/model/object_convertable";
import {Navigate} from "react-router-dom";

export default class RedirectRouteMap implements ObjectConvertable {
    route: string;

    constructor({route}: { route: string }) {
        this.route = route;
    }

    toObject(): Object {
        return {
            index: true,
            element: <Navigate to={this.route} replace={true}/>,
        };
    }
}
