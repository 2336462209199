import {useSpring} from "@react-spring/web";
import {useLayoutEffect} from "react";
import useRenderedRef from "presentation/utils/hooks/use_rendered_ref";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";

const useFadeInTransition = (key?: string) => {
    const renderedRef = useRenderedRef();

    const [props, api] = useSpring(() => ({
        opacity: 1,
    }));

    useLayoutEffect(() => {
        if (!renderedRef.current) return;

        api.set({opacity: 0});
        addPostFrameCallback(() => api.start({opacity: 1}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    return {props, api};
};

export default useFadeInTransition;
