import {BaseTermsTypeMap} from "domain/model/terms/base_terms_type";
import S from "presentation/theme/s";

enum KakaoCertTermsType {
    ThirdParty = "easyThirdParty",
    UniqueId = "easyUniqueId",
    PersonalInformation = "easyPersonalInformation",
}

export const KakaoCertTermsTypes = Object.values(KakaoCertTermsType);

export const KakaoCertTermsTypeMap: BaseTermsTypeMap = {
    title: (type: KakaoCertTermsType): string => {
        switch (type) {
            case KakaoCertTermsType.ThirdParty:
                return S.kakaoCertTermsType.thirdPartyTitle;
            case KakaoCertTermsType.UniqueId:
                return S.kakaoCertTermsType.uniqueIdTitle;
            case KakaoCertTermsType.PersonalInformation:
                return S.kakaoCertTermsType.personalInformationTitle;
            default:
                throw new Error("Invalid simple cert terms type");
        }
    },
    label: (type: KakaoCertTermsType): string => {
        switch (type) {
            case KakaoCertTermsType.ThirdParty:
                return S.kakaoCertTermsType.thirdPartyLabel;
            case KakaoCertTermsType.UniqueId:
                return S.kakaoCertTermsType.uniqueIdLabel;
            case KakaoCertTermsType.PersonalInformation:
                return S.kakaoCertTermsType.personalInformationLabel;
            default:
                throw new Error("Invalid simple cert terms type");
        }
    },
};

export default KakaoCertTermsType;
