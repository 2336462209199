import BaseRepository from "data/repository/base_repository";
import UserPreferences, {UserPreferencesMap} from "domain/model/user/user_preferences";

export default class UserRepository extends BaseRepository {
    login = async ({
                       userID,
                       password,
                   }: {
        userID: string;
        password: string;
    }): Promise<void> => await this.handler({
        request: async (client, signals, storage) => {
            storage.deleteHospital();

            await client.post("/jph/login", {
                id: userID,
                code: password,
            }, {
                signal: signals.at(0),
            });

            storage.setHospital(userID);
        },
    });

    getUserPreferences = async (): Promise<UserPreferences> => await this.handler({
        request: async (_, __, storage) => {
            let preferences = storage.getUserPreferences();

            if (!preferences) {
                preferences = UserPreferencesMap.default();
                storage.saveUserPreferences(preferences);
            }

            return preferences;
        },
    });

    saveUserPreferences = async ({
                                     userPreferences,
                                 }: {
        userPreferences: UserPreferences;
    }): Promise<void> => await this.handler({
        request: async (_, __, storage) => {
            storage.saveUserPreferences(userPreferences);
        },
    });

    getGuideShown = async (): Promise<boolean> => await this.handler({
        request: async (_, __, storage) => {
            return storage.getGuideShown();
        },
    });

    setGuideShown = async (): Promise<void> => await this.handler({
        request: async (_, __, storage) => {
            storage.setGuideShown();
        },
    });
}