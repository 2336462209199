import styled from "styled-components";
import {useRecoilValue, useSetRecoilState} from "recoil";
import shootPrescriptionsSelector from "presentation/states/shoot/selector/shoot_prescriptions_selector";
import shootState from "presentation/states/shoot/shoot_state";
import S from "presentation/theme/s";
import ShootPrescriptionRowTitleBox from "presentation/pages/shoot/components/shoot_prescription_row_title_box";
import ShootPrescriptionTextField from "presentation/pages/shoot/components/shoot_prescription_text_field";
import {PrescriptionFieldRevisionStatusMap} from "domain/model/prescription/prescription_field_revision_status";
import ShootConstants from "presentation/states/shoot/constants/shoot_constants";
import Flex from "presentation/components/common/flex";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";
import {InsuranceTypeMap} from "domain/model/prescription/insurance_type";
import InputValidator from "presentation/utils/input_validator/input_validator";
import {InputValidationStatusTypeMap} from "domain/model/common/input_validation_status_type";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ShootPrescriptionPatientNameSSNView = () => {
    const {prescription} = useRecoilValue(shootPrescriptionsSelector);
    const setState = useSetRecoilState(shootState);

    const onPatientNameChange = (patientName: string) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        prescriptions[index] = {
            ...prescriptions[index],
            patientName: {
                ...prescriptions[index].patientName,
                value: patientName,
                revisionStatus: {
                    ...prescriptions[index].patientName.revisionStatus,
                    confidenceLow: false,
                },
            },
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    const onPatientSSNChange = (patientSSN: string) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        const status = InputValidator.checkHyphenedSSN(patientSSN);

        prescriptions[index] = {
            ...prescriptions[index],
            patientSSN: {
                ...prescriptions[index].patientSSN,
                value: patientSSN,
                revisionStatus: {
                    ...prescriptions[index].patientSSN.revisionStatus,
                    confidenceLow: false,
                    needFullSSN: InsuranceTypeMap.needFullSSN(prescriptions[index].insuranceType) && !InputValidationStatusTypeMap.isValid(status),
                },
            },
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    return <LayoutContainer>
        <ShootPrescriptionRowTitleBox
            title={S.shootPage.shootPrescriptionView.patientNameSSNView.patientTitle}
        />
        <Flex>
            <ShootPrescriptionTextField
                hasTopBorder={false}
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(prescription.patientName.revisionStatus)}
                highlightPlaceholder={true}
                value={prescription.patientName.value}
                onValueChange={onPatientNameChange}
                maxLength={ShootConstants.prescription.maxPatientNameLength}
            />
        </Flex>
        <Flex>
            <ShootPrescriptionTextField
                hasTopBorder={false}
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(prescription.patientSSN.revisionStatus)}
                highlightPlaceholder={true}
                value={prescription.patientSSN.value}
                onValueChange={onPatientSSNChange}
                maxLength={InputFormatters.ssnFormatter.maxLength}
                inputFormatter={InputFormatters.ssnFormatter}
            />
        </Flex>
    </LayoutContainer>;
};

export default ShootPrescriptionPatientNameSSNView;
