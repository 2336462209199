type UserPreferences = {
    cameraSoundEnabled: boolean;
};

export const UserPreferencesMap = {
    default: (): UserPreferences => ({
        cameraSoundEnabled: true,
    }),
    empty: (): UserPreferences => ({
         cameraSoundEnabled: false,
    }),
};

export default UserPreferences;