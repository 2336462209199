const ShootConstants = Object.freeze({
    camera: {
        width: 3264,
        height: 2448,
        fps: 60,
    },
    prescription: {
        maxIssueNumberLength: 7,
        maxPatientNameLength: 30,
        maxNursingAgencyNumberLength: 8,
        maxNursingAgencyNameLength: 30,
        maxDoctorLicenseNumberLength: 6,
        maxDiseaseCodesInRow: 3,
        maxDiseaseCodesCount: 12,
        maxDrugNameLength: 50,
    },
});

export default ShootConstants;