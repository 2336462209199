import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import Debouncer from "presentation/utils/debouncer/debouncer";
import {MouseEvent, ReactElement, useRef, useState} from "react";
import styled from "styled-components";
import SVG from "presentation/components/common/svg";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import {animated, useSpring} from "@react-spring/web";
import useHovered from "presentation/utils/hooks/use_hovered";

type TextButtonType = "primary" | "black" | "white";
type TextButtonSizeType = "large" | "medium" | "small";

const colorPresets: Record<
    TextButtonType,
    {
        backgroundColor: string;
        hoverBackgroundColor: string;
        disabledBackgroundColor: string;
        color: string;
        hoverColor: string;
        disabledColor: string;
    }
> = {
    primary: {
        backgroundColor: Palette.none,
        hoverBackgroundColor: Palette.gray100,
        disabledBackgroundColor: Palette.none,
        color: Palette.primary500,
        hoverColor: Palette.primary500,
        disabledColor: Palette.gray400,
    },
    black: {
        backgroundColor: Palette.none,
        hoverBackgroundColor: Palette.gray100,
        disabledBackgroundColor: Palette.none,
        color: Palette.gray800,
        hoverColor: Palette.gray800,
        disabledColor: Palette.gray400,
    },
    white: {
        backgroundColor: Palette.none,
        hoverBackgroundColor: Palette.white20,
        disabledBackgroundColor: Palette.none,
        color: Palette.white100,
        hoverColor: Palette.white100,
        disabledColor: Palette.gray400,
    },
};

const sizePresets: Record<
    TextButtonSizeType,
    {
        font: string;
        padding: string;
        iconSize: string;
    }
> = {
    large: {
        font: Fonts.body1,
        padding: "16px",
        iconSize: "24px",
    },
    medium: {
        font: Fonts.detail2Medium,
        padding: "10px 16px",
        iconSize: "20px",
    },
    small: {
        font: Fonts.detail1,
        padding: "6px 12px",
        iconSize: "16px",
    },
};

const LayoutContainer = styled(animated.button).attrs<{
    $enabled: boolean;
    $width: string;
    $padding: string;
}>((props) => ({
    style: {
        width: props.$width,
        padding: props.$padding,
        cursor: props.$enabled ? "pointer" : "not-allowed",
    },
}))`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: none;
    border-radius: 12px;
    transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
`;

const LabelContainer = styled(animated.p)<{
    $enabled: boolean;
    $font: string;
}>`
    min-width: max-content;
    ${(props) => props.$font};
    text-align: center;
`;

const TextButton = ({
                        type = "primary",
                        sizeType = "large",
                        enabled = true,
                        width = "auto",
                        label,
                        leftIconAsset,
                        rightIconAsset,
                        iconColor,
                        iconHoverColor,
                        iconDisabledColor,
                        debouncerDelayInMs = 50,
                        onClick,
                    }: {
    type?: TextButtonType;
    sizeType?: TextButtonSizeType;
    enabled?: boolean;
    width?: string;
    label: string;
    leftIconAsset?: ReactElement;
    rightIconAsset?: ReactElement;
    iconColor?: string;
    iconHoverColor?: string;
    iconDisabledColor?: string;
    debouncerDelayInMs?: number;
    onClick?: (e: MouseEvent) => void;
}) => {
    const debouncerRef = useRef(new Debouncer());
    const {hovered, onMouseEnter, onMouseLeave} = useHovered();

    const _onClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!enabled) return;

        debouncerRef.current.run(() => onClick?.(e), debouncerDelayInMs);
    }

    const backgroundColor = colorPresets[type].backgroundColor;
    const hoverBackgroundColor = colorPresets[type].hoverBackgroundColor;
    const disabledBackgroundColor = colorPresets[type].disabledBackgroundColor;
    const color = colorPresets[type].color;
    const hoverColor = colorPresets[type].hoverColor;
    const disabledColor = colorPresets[type].disabledColor;
    const font = sizePresets[sizeType].font;
    const padding = sizePresets[sizeType].padding;
    const iconSize = sizePresets[sizeType].iconSize;

    const buttonProps = useSpring({
        backgroundColor: enabled ? (hovered ? hoverBackgroundColor : backgroundColor) : disabledBackgroundColor,
    });
    const {props: fadeInProps} = useFadeInTransition(label);
    const labelProps = useSpring({
        color: enabled ? (hovered ? hoverColor : color) : disabledColor,
    });

    const _iconColor = (
        enabled ?
            hovered ? (iconHoverColor ?? iconColor) :
                iconColor : (iconDisabledColor ?? iconColor)
    ) ?? Palette.gray800;

    return (
        <LayoutContainer
            style={buttonProps}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={_onClick}
            $enabled={enabled}
            $width={width}
            $padding={padding}
        >
            {leftIconAsset && (
                <SVG
                    asset={leftIconAsset}
                    width={iconSize}
                    height={iconSize}
                    color={_iconColor}
                />
            )}
            <LabelContainer
                style={{
                    ...fadeInProps,
                    ...labelProps,
                }}
                $enabled={enabled}
                $font={font}
            >
                {label}
            </LabelContainer>
            {rightIconAsset && (
                <SVG
                    asset={rightIconAsset}
                    width={iconSize}
                    height={iconSize}
                    color={_iconColor}
                />
            )}
        </LayoutContainer>
    );
};

export default TextButton;
