import {useState} from "react";

const useHovered = (initialHovered: boolean = false) => {
    const [hovered, setHovered] = useState(initialHovered);

    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    return {
        hovered,
        setHovered,
        onMouseEnter,
        onMouseLeave,
    };
};

export default useHovered;