import {selector} from "recoil";
import certState from "presentation/states/cert/cert_state";

const certUserViewVisibleSelector = selector({
    key: "certUserViewVisibleSelector",
    get: ({get}) => {
        const state = get(certState);

        return !state.verification;
    },
});

export default certUserViewVisibleSelector;