import styled from "styled-components";
import Fonts from "presentation/theme/fonts";
import {PropsWithChildren} from "react";
import SizedBox from "presentation/components/common/sized_box";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const TitleContainer = styled.h6`
    ${Fonts.detail2};
`;

const SectionTitle = ({title, children}: PropsWithChildren<{ title: string; }>) => {
    return <LayoutContainer>
        <TitleContainer>
            {title}
        </TitleContainer>
        <SizedBox height={"8px"}/>
        {children}
    </LayoutContainer>;
};

export default SectionTitle;
