import styled from "styled-components";
import Prescription, {PrescriptionMap} from "domain/model/prescription/prescription";
import Palette from "presentation/theme/palette";
import ArrowIconButton from "presentation/components/button/arrow_icon_button";
import SVGAssets from "presentation/theme/assets";
import SizedBox from "presentation/components/common/sized_box";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import IconButton from "presentation/components/button/icon_button";
import Spacer from "presentation/components/common/spacer";
import FilledButton from "presentation/components/button/filled_button";
import {useState, useRef, useLayoutEffect, useCallback} from "react";
import {animated, useSpring} from "@react-spring/web";
import CSSPointerEventsType from "presentation/utils/types/css/pointer_events_type";
import DropdownButtonItem from "presentation/components/button/dropdown_button/components/dropdown_button_item";

const LayoutContainer = styled.div`
    width: 100%;
    min-width: max-content;
    padding: 8px 16px;
    border-radius: 16px;
    background-color: ${Palette.white100};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: visible;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const PatientNameContainer = styled.h6`
    max-width: 200px;
    ${Fonts.body1};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const IndexContainer = styled.p`
    ${Fonts.detail1};
    color: ${Palette.gray400};
`;

const MorePositionContainer = styled.div`
    position: relative;
    overflow: visible;
`;

const DropdownContainer = styled(animated.ul)`
    min-width: 10.25rem;
    padding: 8px;
    background-color: ${Palette.white100};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
`;

const ShootPrescriptionHeader = ({
                                     selectedPrescriptionID,
                                     prescriptions,
                                     onSelectedPrescriptionIDChange,
                                     onDeleteButtonClick,
                                     onSendButtonClick,
                                     onDeleteAllButtonClick,
                                     onSendAllButtonClick,
                                 }: {
    selectedPrescriptionID: string;
    prescriptions: Prescription[];
    onSelectedPrescriptionIDChange: (selectedPrescriptionID: string) => void;
    onDeleteButtonClick: (selectedPrescriptionID: string) => void;
    onSendButtonClick: (selectedPrescriptionID: string) => void;
    onDeleteAllButtonClick: () => void;
    onSendAllButtonClick: () => void;
}) => {
    const moreButtonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null);
    const deleteAllButtonRef = useRef<HTMLLIElement>(null);
    const sendAllButtonRef = useRef<HTMLLIElement>(null);
    const [moreSelected, setMoreSelected] = useState(false);

    const prescriptionIndex = prescriptions.findIndex((p) => p.id === selectedPrescriptionID);
    const prescription = prescriptions[prescriptionIndex];

    const indexLabel = S.shootPrescriptionHeader.indexLabel(
        (prescriptionIndex + 1).toString(),
        prescriptions.length.toString(),
    );

    const onPreviousButtonClick = () => {
        let previousIndex = prescriptionIndex - 1;
        if (previousIndex < 0) {
            previousIndex = prescriptions.length - 1;
        }
        onSelectedPrescriptionIDChange(prescriptions[previousIndex].id);
    };
    const onNextButtonClick = () => {
        let nextIndex = prescriptionIndex + 1;
        if (nextIndex >= prescriptions.length) {
            nextIndex = 0;
        }
        onSelectedPrescriptionIDChange(prescriptions[nextIndex].id);
    };
    const onMoreButtonClick = useCallback((e: MouseEvent) => {
        if (moreButtonRef.current && moreButtonRef.current.contains(e.target as Node)) {
            setMoreSelected(true);
            return;
        }

        if (sendAllButtonRef.current && sendAllButtonRef.current.contains(e.target as Node)) {
            onSendAllButtonClick();
            setMoreSelected(false);
            return;
        }

        if (deleteAllButtonRef.current && deleteAllButtonRef.current.contains(e.target as Node)) {
            onDeleteAllButtonClick();
            setMoreSelected(false);
            return;
        }

        if (
            dropdownRef.current &&
            dropdownRef.current.contains(e.target as Node)
        ) {
            return;
        }

        setMoreSelected(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moreButtonRef, dropdownRef, deleteAllButtonRef, sendAllButtonRef]);
    const _onDeleteButtonClick = () => onDeleteButtonClick(selectedPrescriptionID);
    const _onSendButtonClick = () => onSendButtonClick(selectedPrescriptionID);

    useLayoutEffect(() => {
        window.addEventListener("mousedown", onMoreButtonClick);

        return () => window.removeEventListener("mousedown", onMoreButtonClick);
    }, [onMoreButtonClick]);

    const dropdownProps = useSpring({
        opacity: moreSelected ? 1 : 0,
        pointerEvents: (moreSelected ? "auto" : "none") as CSSPointerEventsType,
    });

    const patientNameLabel = prescription.patientName.value.length ? prescription.patientName.value : S.shootPrescriptionHeader.emptyPatientNameLabel;

    const sendButtonEnabled = PrescriptionMap.validForSending(prescription);

    return <LayoutContainer>
        <ArrowIconButton
            enabled={true}
            asset={SVGAssets.Back}
            onClick={onPreviousButtonClick}
        />
        <SizedBox width={"28px"}/>
        <PatientNameContainer>{patientNameLabel}</PatientNameContainer>
        <SizedBox width={"8px"}/>
        <IndexContainer>{indexLabel}</IndexContainer>
        <SizedBox width={"28px"}/>
        <ArrowIconButton
            enabled={true}
            asset={SVGAssets.Next}
            onClick={onNextButtonClick}
        />
        <SizedBox width={"8px"}/>
        <MorePositionContainer>
            <IconButton
                ref={moreButtonRef}
                asset={SVGAssets.More}
                width={"24px"}
                height={"24px"}
                color={Palette.black100}
                hoverColor={"light"}
            />
            <DropdownContainer ref={dropdownRef} style={dropdownProps}>
                <DropdownButtonItem
                    ref={sendAllButtonRef}
                    selected={false}
                    heightInPx={36}
                    label={S.shootPrescriptionHeader.sendAllButtonLabel}
                />
                <DropdownButtonItem
                    ref={deleteAllButtonRef}
                    selected={false}
                    heightInPx={36}
                    label={S.shootPrescriptionHeader.deleteAllButtonLabel}
                />
            </DropdownContainer>
        </MorePositionContainer>
        <Spacer/>
        <FilledButton
            type={"red"}
            sizeType={"medium"}
            label={S.shootPrescriptionHeader.deleteButtonLabel}
            onClick={_onDeleteButtonClick}
        />
        <SizedBox width={"8px"}/>
        <FilledButton
            sizeType={"medium"}
            enabled={sendButtonEnabled}
            label={S.shootPrescriptionHeader.sendButtonLabel}
            rightIconAsset={SVGAssets.Next}
            onClick={_onSendButtonClick}
        />
    </LayoutContainer>;
};

export default ShootPrescriptionHeader;
