const addPostFrameCallback = (callback: () => void) => {
    let count = 0;

    const frame = () => {
        if (count < 5) {
            count++;
            requestAnimationFrame(frame);
        } else {
            callback();
        }
    };

    setTimeout(() => frame());
};

export default addPostFrameCallback;
