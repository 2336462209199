import styled from "styled-components";
import {TextField} from "presentation/components/text_field/text_field";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";
import InputFormatter from "presentation/utils/input_formatters/input_formatter";
import S from "presentation/theme/s";

const TextFieldContainer = styled(TextField)<{ $revisionNeeded: boolean; }>`
    width: 100%;
    height: 2rem;
    padding: 6px 4px;
    border-radius: 0;
    box-shadow: none;
    background-color: ${Palette.none};
    color: ${(props) => props.$revisionNeeded ? Palette.red500 : Palette.gray800};
    ${Fonts.detail2Medium};
    line-height: 1;
    text-align: center;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &:focus {
        box-shadow: none;
        color: ${(props) => props.$revisionNeeded ? Palette.red500 : Palette.gray800};
        background-color: ${Palette.gray50};
    }

    &::-webkit-input-placeholder {
        ${Fonts.detail3Medium};
        color: ${(props) => props.$revisionNeeded ? Palette.red500 : Palette.gray800};
    }

    &:focus::-webkit-input-placeholder {
        color: ${Palette.gray400};
        background-color: ${Palette.gray50};
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    }
`;

const ShootPrescriptionMedicationTextField = ({
                                                  enabled = true,
                                                  revisionNeeded,
                                                  value,
                                                  onValueChange,
                                                  focused,
                                                  onFocusChange,
                                                  placeholder,
                                                  maxLength,
                                                  inputFormatter,
                                              }: {
    enabled?: boolean;
    revisionNeeded: boolean;
    value: string;
    onValueChange?: (value: string) => void;
    focused?: boolean;
    onFocusChange?: (focused: boolean) => void;
    placeholder?: string;
    maxLength?: number;
    inputFormatter?: InputFormatter;
}) => {
    return <TextFieldContainer
        enabled={enabled}
        value={value}
        onValueChange={onValueChange}
        focused={focused}
        onFocusChange={onFocusChange}
        placeholder={placeholder ?? S.shootPrescriptionMedicationTextField.placeholder}
        maxLength={maxLength}
        inputFormatter={inputFormatter}
        $revisionNeeded={revisionNeeded}
    />;
};

export default ShootPrescriptionMedicationTextField;
