import PrescriptionDiseaseCodeField from "domain/model/prescription/prescription_disease_code_field";

type PrescriptionDiseaseCodeFieldsDTO = {
    values: string[];
    needCheck: boolean;
};

export const PrescriptionMultiFieldDTOMap = {
    toDomainModel: (d: PrescriptionDiseaseCodeFieldsDTO): PrescriptionDiseaseCodeField[] => {
        let id = 0;

        return d.values.map((e) => ({
            id: id++,
            value: e,
            initialValue: e,
            revisionStatus: {
                confidenceLow: !!d.values.length && d.needCheck,
                needFullSSN: false,
            },
        }));
    },
}

export default PrescriptionDiseaseCodeFieldsDTO;