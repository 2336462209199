export const rgba = (r: number, g: number, b: number, a: number) => {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export default class Palette {
    static readonly none = rgba(255, 255, 255, 0);
    static readonly black100 = rgba(0, 0, 0, 1);
    static readonly black90 = rgba(0, 0, 0, 0.9);
    static readonly black75 = rgba(0, 0, 0, 0.75);
    static readonly black50 = rgba(0, 0, 0, 0.5);
    static readonly black40 = rgba(0, 0, 0, 0.4);
    static readonly black20 = rgba(0, 0, 0, 0.2);
    static readonly black8 = rgba(0, 0, 0, 0.08);
    static readonly black5 = rgba(0, 0, 0, 0.05);
    static readonly black2 = rgba(0, 0, 0, 0.02);
    static readonly white100 = rgba(255, 255, 255, 1);
    static readonly white80 = rgba(255, 255, 255, 0.8);
    static readonly white60 = rgba(255, 255, 255, 0.6);
    static readonly white20 = rgba(255, 255, 255, 0.2);
    static readonly white8 = rgba(255, 255, 255, 0.08);
    static readonly primary600 = rgba(0, 157, 245, 1);
    static readonly primary500 = rgba(4, 169, 255, 1);
    static readonly primary400 = rgba(70, 195, 255, 1);
    static readonly primary300 = rgba(160, 235, 255, 1);
    static readonly primary100 = rgba(239, 250, 255, 1);
    static readonly primary30 = rgba(4, 169, 255, 0.3);
    static readonly primary15 = rgba(4, 169, 255, 0.15);
    static readonly primary10 = rgba(4, 169, 255, 0.1);
    static readonly gray900 = rgba(30, 30, 33, 1);
    static readonly gray800 = rgba(48, 48, 56, 1);
    static readonly gray700 = rgba(50, 58, 74, 1);
    static readonly gray600 = rgba(71, 84, 103, 1);
    static readonly gray500 = rgba(102, 112, 133, 1);
    static readonly gray400 = rgba(152, 162, 179, 1);
    static readonly gray300 = rgba(208, 213, 221, 1);
    static readonly gray200 = rgba(234, 236, 240, 1);
    static readonly gray100 = rgba(242, 244, 247, 1);
    static readonly gray50 = rgba(250, 251, 252, 1);
    static readonly gray25 = rgba(252, 252, 253, 1);
    static readonly deepBlue = rgba(4, 112, 255, 1);
    static readonly green = rgba(35, 204, 7, 1);
    static readonly lightGreen = rgba(98, 223, 0, 1);
    static readonly orange600 = rgba(255, 105, 0, 1);
    static readonly orange500 = rgba(255, 137, 4, 1);
    static readonly orange300 = rgba(255, 210, 165, 1);
    static readonly orange100 = rgba(255, 246, 237, 1);
    static readonly yellow500 = rgba(255, 214, 0, 1);
    static readonly yellow100 = rgba(255, 249, 218, 1);
    static readonly red600 = rgba(255, 33, 22, 1);
    static readonly red500 = rgba(255, 60, 50, 1);
    static readonly red400 = rgba(255, 110, 110, 1);
    static readonly red300 = rgba(255, 155, 155, 1);
    static readonly red200 = rgba(255, 222, 220, 1);
    static readonly red100 = rgba(255, 243, 243, 1);
}
