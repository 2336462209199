import _ShutterSound from "assets/audio/shot_sound.mp3";
import CameraLoading from "assets/json/camera_loading.json";
import Loading from "assets/json/loading.json";
import {ReactComponent as AlertOctagon} from "assets/svg/ic_alert_octagon.svg";
import {ReactComponent as Avatar} from "assets/svg/ic_avatar.svg";
import {ReactComponent as Back} from "assets/svg/ic_back.svg";
import {ReactComponent as CalendarCheck} from "assets/svg/ic_calendar_check.svg";
import {ReactComponent as Camera} from "assets/svg/ic_camera.svg";
import {ReactComponent as CheckCircle} from "assets/svg/ic_check_circle.svg";
import {ReactComponent as Close} from "assets/svg/ic_close.svg";
import {ReactComponent as DeleteCircle} from "assets/svg/ic_delete_circle.svg";
import {ReactComponent as DocumentSearch} from "assets/svg/ic_document_search.svg";
import {ReactComponent as Documents} from "assets/svg/ic_documents.svg";
import {ReactComponent as Down} from "assets/svg/ic_down.svg";
import {ReactComponent as Download} from "assets/svg/ic_download.svg";
import {ReactComponent as Info} from "assets/svg/ic_info.svg";
import {ReactComponent as JeekimLogo} from "assets/svg/ic_jeekim_logo.svg";
import {ReactComponent as LiveDot} from "assets/svg/ic_live_dot.svg";
import {ReactComponent as Logout} from "assets/svg/ic_logout.svg";
import {ReactComponent as More} from "assets/svg/ic_more.svg";
import {ReactComponent as Next} from "assets/svg/ic_next.svg";
import {ReactComponent as OCR} from "assets/svg/ic_ocr.svg";
import {ReactComponent as Plus} from "assets/svg/ic_plus.svg";
import {ReactComponent as PlusCircle} from "assets/svg/ic_plus_circle.svg";
import {ReactComponent as Search} from "assets/svg/ic_search.svg";
import {ReactComponent as SoundControl} from "assets/svg/ic_sound_control.svg";
import {ReactComponent as SoundControlMute} from "assets/svg/ic_sound_control_mute.svg";
import {ReactComponent as Up} from "assets/svg/ic_up.svg";
import {ReactComponent as User} from "assets/svg/ic_user.svg";
import {ReactComponent as Warning} from "assets/svg/ic_warning.svg";
import {ReactComponent as Guide} from "assets/svg/ic_guide.svg";
import {ReactComponent as ImageKakaoLogo} from "assets/svg/img_kakao_logo.svg";
import {ReactComponent as ImageErrorOne} from "assets/svg/img_error_1.svg";
import {ReactComponent as ImageErrorTwo} from "assets/svg/img_error_2.svg";
import {ReactComponent as ImageTextMessage} from "assets/svg/img_text_message.svg";
import {ReactComponent as Live} from "assets/svg/ic_live.svg";
import {ReactComponent as LogoFull} from "assets/svg/logo_full.svg";
import KakaoCert from "assets/png/img_kakao_cert.png";
import {FC, ReactElement, SVGProps} from "react";

export type SVGAsset = ReactElement;

const toReactElement = (Asset: FC<SVGProps<SVGSVGElement>>): ReactElement => <Asset/>;

export class JSONAssets {
    static readonly CameraLoading: Object = CameraLoading;
    static readonly Loading: Object = Loading;
}

export default class SVGAssets {
    static readonly AlertOctagon: SVGAsset = toReactElement(AlertOctagon);
    static readonly Avatar: SVGAsset = toReactElement(Avatar);
    static readonly Back: SVGAsset = toReactElement(Back);
    static readonly CalendarCheck: SVGAsset = toReactElement(CalendarCheck);
    static readonly Camera: SVGAsset = toReactElement(Camera);
    static readonly CheckCircle: SVGAsset = toReactElement(CheckCircle);
    static readonly Close: SVGAsset = toReactElement(Close);
    static readonly DeleteCircle: SVGAsset = toReactElement(DeleteCircle);
    static readonly DocumentSearch: SVGAsset = toReactElement(DocumentSearch);
    static readonly Documents: SVGAsset = toReactElement(Documents);
    static readonly Down: SVGAsset = toReactElement(Down);
    static readonly Download: SVGAsset = toReactElement(Download);
    static readonly Info: SVGAsset = toReactElement(Info);
    static readonly JeekimLogo: SVGAsset = toReactElement(JeekimLogo);
    static readonly LiveDot: SVGAsset = toReactElement(LiveDot);
    static readonly Logout: SVGAsset = toReactElement(Logout);
    static readonly More: SVGAsset = toReactElement(More);
    static readonly Next: SVGAsset = toReactElement(Next);
    static readonly OCR: SVGAsset = toReactElement(OCR);
    static readonly Plus: SVGAsset = toReactElement(Plus);
    static readonly PlusCircle: SVGAsset = toReactElement(PlusCircle);
    static readonly Search: SVGAsset = toReactElement(Search);
    static readonly SoundControl: SVGAsset = toReactElement(SoundControl);
    static readonly SoundControlMute: SVGAsset = toReactElement(SoundControlMute);
    static readonly Up: SVGAsset = toReactElement(Up);
    static readonly User: SVGAsset = toReactElement(User);
    static readonly Warning: SVGAsset = toReactElement(Warning);
};

export class StaticSVGAssets {
    static readonly Guide: SVGAsset = toReactElement(Guide);
    static readonly ImageKakaoLogo: SVGAsset = toReactElement(ImageKakaoLogo);
    static readonly ImageErrorOne: SVGAsset = toReactElement(ImageErrorOne);
    static readonly ImageErrorTwo: SVGAsset = toReactElement(ImageErrorTwo);
    static readonly ImageTextMessage: SVGAsset = toReactElement(ImageTextMessage);
    static readonly Live: SVGAsset = toReactElement(Live);
    static readonly LogoFull: SVGAsset = toReactElement(LogoFull);
}

export class PNGAssets {
    static readonly KakaoCert = KakaoCert;
}

export class AudioAssets {
    static readonly ShutterSound: string = _ShutterSound;
}