import ErrorCode from "domain/model/common/error_code";

class CommonError extends Error {
    errorCode: ErrorCode;
    title?: string;
    message: string;

    constructor(errorCode: ErrorCode, title?: string, message?: string) {
        super(message);
        this.errorCode = errorCode;
        this.title = title;
        this.message = message ?? "";
    }
}

export default CommonError;
