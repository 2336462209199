export default class NumberHelper {
    static toStringWithDividers = (value: number): string => {
        const digits = [];
        const sign = value < 0 ? "-" : "";
        const string = Math.abs(Math.floor(value)).toString();

        let count = 0;
        let i = string.length - 1;
        while (i >= 0) {
            if (count > 0 && count % 3 === 0) digits.push(",");
            digits.push(string[i]);
            i--;
            count++;
        }

        i = digits.length - 1;
        while (digits[i] === "0" || digits[i] === ",") {
            digits.pop();
            i--;
        }

        return (
            sign + (digits.length === 0 ? "0" : digits.toReversed().join(""))
        );
    };

    static degreeToRadian = (degree: number): number => {
        return (degree * Math.PI) / 180;
    };

    static clamp = (value: number, min: number, max: number): number => {
        return Math.min(Math.max(value, min), max);
    };

    static round = (value: number, precision: number): number => {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    };

    static toFixed = (value: number, precision: number): string => {
        return NumberHelper.round(value, precision).toFixed(precision);
    };

    static toFixedIfNotRound = (value: number, precision: number): string => {
        const string = NumberHelper.toFixed(value, precision);

        let pattern = ".";
        for (let i = 0; i < precision; i++) pattern += "0";

        if (string.endsWith(pattern)) return string.replaceAll(pattern, "");

        return string;
    };
}
