import styled from "styled-components";
import {PropsWithChildren} from "react";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import useWindowResizeObserver from "presentation/utils/hooks/use_window_resize_observer";
import {animated, useSpring} from "@react-spring/web";
import useAnimatedMount from "presentation/utils/hooks/use_animated_mount";

const LayoutContainer = styled(animated.div)`
    width: 100%;
    position: relative;
    overflow: hidden;
`;

const PageLayout = ({
                        children
                    }: PropsWithChildren<{
                        verticalPaddingInPx?: number;
                        horizontalPaddingInPx?: number;
                    }>
) => {
    const theme = useThemeContext();

    const {innerHeightInPx: windowHeightInPx} = useWindowResizeObserver([], 0);
    const minHeightInPx = windowHeightInPx - theme.componentTheme.pageVerticalPaddingInPx * 2;

    const props = useSpring({
        width: `calc(100% - ${theme.componentTheme.pageHorizontalPaddingInPx * 2}px)`,
        maxWidth: `calc(${theme.componentTheme.maxPageWidthInRem} - ${theme.componentTheme.pageHorizontalPaddingInPx * 2}px)`,
        minHeight: minHeightInPx,
        margin: `${theme.componentTheme.pageVerticalPaddingInPx}px 0`,
        immediate: true,
    });
    const {props: animatedMountProps} = useAnimatedMount();

    return <LayoutContainer style={{
        ...props,
        ...animatedMountProps,
    }}>
        {children}
    </LayoutContainer>;
};

export default PageLayout;
