import DecimalOnlyFormatter from "presentation/utils/input_formatters/decimal_only_formatter";
import SSNFormatter from "presentation/utils/input_formatters/ssn_formatter";
import SelfPayCodeFormatter from "presentation/utils/input_formatters/self_pay_code_formatter";
import DiseaseCodeFormatter from "presentation/utils/input_formatters/disease_code_formatter";
import DosageFormatter from "presentation/utils/input_formatters/dosage_formatter";
import DrugCodeFormatter from "presentation/utils/input_formatters/drug_code_formatter";
import DrugNameFormatter from "presentation/utils/input_formatters/drug_name_formatter";

export default class InputFormatters {
    static decimalOnlyFormatter = new DecimalOnlyFormatter();

    static diseaseCodeFormatter = new DiseaseCodeFormatter();

    static dosageFormatter = new DosageFormatter();

    static drugCodeFormatter = new DrugCodeFormatter();

    static drugNameFormatter = new DrugNameFormatter();

    static selfPayCodeFormatter = new SelfPayCodeFormatter();

    static ssnFormatter = new SSNFormatter();
}