import styled from "styled-components";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";
import {animated, useSpring} from "@react-spring/web";
import {useEffect, useRef, useState} from "react";
import S from "presentation/theme/s";
import {useGesture} from "@use-gesture/react";
import NumberHelper from "config/helper/number_helper";

const LayoutContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 17px 68px 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
`;

const ImageContainer = styled(animated.img).attrs<{ $maxHeight: string; }>((props) => ({
    style: {
        maxHeight: props.$maxHeight,
    },
}))`
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform-style: preserve-3d;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    transform-origin: center;
`;

const NoticeLayoutContainer = styled(animated.div)`
    padding: 12px 16px;
    border-radius: 16px;
    background-color: ${Palette.black50};
    pointer-events: none;
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translate3d(-50%, 0, 0);
`;

const NoticeContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail2};
    color: ${Palette.white100};
    text-align: center;
`;

const ShootPrescriptionImage = ({
                                    maxHeight,
                                    src,
                                }: {
    maxHeight: string;
    src: string;
}) => {
    const imageRef = useRef<HTMLImageElement>(null);
    const [interacting, setInteracting] = useState(false);

    const [imageProps, imageAPI] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: 1,
        cursor: "auto",
    }));
    const noticeProps = useSpring({
        opacity: interacting ? 0 : 1,
    });

    const reset = () => {
        imageAPI.start({
            x: 0,
            y: 0,
            scale: 1,
            cursor: "auto",
        });
        setInteracting(false);
    };

    useEffect(() => {
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src]);

    const scale = () => imageProps.scale.get();
    const offset = () => [imageProps.x.get(), imageProps.y.get()];

    const updateTranslationBoundaries = (scale: number) => {
        const image = imageRef.current;
        if (!image) return {minX: 0, maxX: 0, minY: 0, maxY: 0};

        const maxX = (image.offsetWidth / 2) * scale * 0.7;
        const maxY = (image.offsetHeight / 2) * scale * 0.7;

        return {
            minX: -maxX,
            maxX: maxX,
            minY: -maxY,
            maxY: maxY,
        };
    };

    useGesture(
        {
            onDrag: ({offset: [ox, oy], memo, initial: [ix, iy]}) => {
                if (!memo) {
                    const {minX, maxX, minY, maxY} = updateTranslationBoundaries(scale());
                    memo = {minX, maxX, minY, maxY, startX: ix, startY: iy};
                }

                if (scale() === 1) return memo;

                const {minX, maxX, minY, maxY} = memo;
                const newOffsetX = NumberHelper.clamp(ox, minX, maxX);
                const newOffsetY = NumberHelper.clamp(oy, minY, maxY);

                imageAPI.start({
                    x: newOffsetX,
                    y: newOffsetY,
                });

                return memo;
            },
            onWheel: ({event: {clientX, clientY}, delta: [, deltaY]}) => {
                const image = imageRef.current;
                if (!image) return;

                const direction = !deltaY ? 0 : deltaY > 0 ? 1 : -1;
                if (!direction) return;

                const scaleChange = deltaY > 0 ? 0.5 : 1.5;
                const _scale = scale();
                const newScale = NumberHelper.clamp(_scale * scaleChange, 1, 3);
                const rect = image.getBoundingClientRect();

                const imageWidth = image.offsetWidth * _scale;
                const imageHeight = image.offsetHeight * _scale;
                const mouseX = (clientX - rect.x);
                const mouseY = (clientY - rect.y);
                const [previousOffsetX, previousOffsetY] = offset();

                const transformOriginX = imageWidth / 2 + previousOffsetX;
                const transformOriginY = imageHeight / 2 + previousOffsetY;
                const mouseOffsetFromOriginX = (mouseX - transformOriginX);
                const mouseOffsetFromOriginY = (mouseY - transformOriginY);

                const {minX, maxX, minY, maxY} = updateTranslationBoundaries(newScale);

                const newOffsetX = direction === 1
                    ? previousOffsetX + direction / _scale * mouseOffsetFromOriginX * -(newScale - _scale)
                    : previousOffsetX + direction / _scale * mouseOffsetFromOriginX * (newScale - _scale);
                const newOffsetY = direction === 1
                    ? previousOffsetY + direction / _scale * mouseOffsetFromOriginY * -(newScale - _scale)
                    : previousOffsetY + direction / _scale * mouseOffsetFromOriginY * (newScale - _scale);

                imageAPI.start({
                    scale: newScale,
                    x: NumberHelper.clamp(newOffsetX, minX, maxX),
                    y: NumberHelper.clamp(newOffsetY, minY, maxY),
                    cursor: newScale === 1 ? "auto" : "grab",
                });
            },
            onWheelEnd: () => {
                if (Math.abs(scale() - 1) > 0.3) {
                    setInteracting(true);
                    return;
                }

                reset();
            },
        },
        {
            target: imageRef,
            drag: {
                from: () => [offset()[0], offset()[1]],
                bounds: () => {
                    const {minX, maxX, minY, maxY} = updateTranslationBoundaries(scale());
                    return {left: minX, right: maxX, top: minY, bottom: maxY};
                },
                rubberband: true,
            },
        }
    );

    return <LayoutContainer>
        <ImageContainer
            ref={imageRef}
            style={imageProps}
            src={src}
            draggable={false}
            $maxHeight={maxHeight}
        />
        <NoticeLayoutContainer style={noticeProps}>
            <NoticeContainer>{S.shootPrescriptionImage.noticeLabel}</NoticeContainer>
        </NoticeLayoutContainer>
    </LayoutContainer>;
};

export default ShootPrescriptionImage;
