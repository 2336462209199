import styled from "styled-components";

const LayoutContainer = styled.div`
    flex-grow: 1;
`;

const Spacer = () => {
    return <LayoutContainer/>;
};

export default Spacer;
