import SVGAssets, {SVGAsset} from "presentation/theme/assets";
import S from "presentation/theme/s";
import AppRoute from "presentation/routes/model/app_route";

export enum LNBItemType {
    Shoot = "shoot",
    MyPage = "myPage",
}

export const LNBItemTypes = Object.values(LNBItemType);

export const LNBItemTypeMap = {
    label: (type: LNBItemType): string => {
        switch (type) {
            case LNBItemType.Shoot:
                return S.lnbItemType.shootLabel;
            case LNBItemType.MyPage:
                return S.lnbItemType.myPageLabel;
            default:
                throw new Error("Invalid LNBItemType");
        }
    },
    icon: (type: LNBItemType): SVGAsset => {
        switch (type) {
            case LNBItemType.Shoot:
                return SVGAssets.OCR;
            case LNBItemType.MyPage:
                return SVGAssets.User;
            default:
                throw new Error("Invalid LNBItemType");
        }
    },
    endpoint: (type: LNBItemType): optional<AppRoute> => {
        switch (type) {
            case LNBItemType.Shoot:
                return AppRoute.Shoot;
            case LNBItemType.MyPage:
                return AppRoute.MyPage;
            default:
                throw new Error("Invalid LNBItemType");
        }
    },
};
