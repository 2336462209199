import Palette from "presentation/theme/palette";
import {FontTheme} from "presentation/theme/theme";

export class FontConfig {
    baseTextColor: string;
    baseFontFamily: string;

    constructor({
                    baseTextColor,
                    baseFontFamily,
                }: {
        baseTextColor: string;
        baseFontFamily: string;
    }) {
        this.baseTextColor = baseTextColor;
        this.baseFontFamily = baseFontFamily;
    }
}

export class FontWeights {
    static readonly medium = 500;
    static readonly semiBold = 600;
    static readonly bold = 700;
}

export class Font {
    fontSize: string;
    fontWeight: FontWeights;
    lineHeight: string;

    constructor({
                    fontSize,
                    fontWeight,
                    lineHeight,
                }: {
        fontSize: string;
        fontWeight: FontWeights;
        lineHeight: string;
    }) {
        this.fontSize = fontSize;
        this.fontWeight = fontWeight;
        this.lineHeight = lineHeight;
    }

    toCSSString(baseConfig: FontConfig) {
        return `
            font-family: ${baseConfig.baseFontFamily};
            font-size: ${this.fontSize};
            font-weight: ${this.fontWeight};
            color: ${baseConfig.baseTextColor};
            line-height: ${this.lineHeight};
            display: inline-block;
            vertical-align: middle;
        `;
    }
}

export class FontsConfig {
    private readonly config: FontConfig;
    private _headline3: Font;
    private _headline2: Font;
    private _headline1: Font;
    private _emphasis4: Font;
    private _emphasis3: Font;
    private _emphasis2: Font;
    private _emphasis1: Font;
    private _title3: Font;
    private _title2: Font;
    private _title1: Font;
    private _body3: Font;
    private _body3Medium: Font;
    private _body2: Font;
    private _body2Medium: Font;
    private _body1: Font;
    private _body1Medium: Font;
    private _detail3: Font;
    private _detail3Medium: Font;
    private _detail2: Font;
    private _detail2Medium: Font;
    private _detail1: Font;

    constructor({
                    config,
                    headline3,
                    headline2,
                    headline1,
                    emphasis4,
                    emphasis3,
                    emphasis2,
                    emphasis1,
                    title3,
                    title2,
                    title1,
                    body3,
                    body3Medium,
                    body2,
                    body2Medium,
                    body1,
                    body1Medium,
                    detail3,
                    detail3Medium,
                    detail2,
                    detail2Medium,
                    detail1,
                }: {
        config: FontConfig;
        headline3: Font;
        headline2: Font;
        headline1: Font;
        emphasis4: Font;
        emphasis3: Font;
        emphasis2: Font;
        emphasis1: Font;
        title3: Font;
        title2: Font;
        title1: Font;
        body3: Font;
        body3Medium: Font;
        body2: Font;
        body2Medium: Font;
        body1: Font;
        body1Medium: Font;
        detail3: Font;
        detail3Medium: Font;
        detail2: Font;
        detail2Medium: Font;
        detail1: Font;
    }) {
        this.config = config;
        this._headline3 = headline3;
        this._headline2 = headline2;
        this._headline1 = headline1;
        this._emphasis4 = emphasis4;
        this._emphasis3 = emphasis3;
        this._emphasis2 = emphasis2;
        this._emphasis1 = emphasis1;
        this._title3 = title3;
        this._title2 = title2;
        this._title1 = title1;
        this._body3 = body3;
        this._body3Medium = body3Medium;
        this._body2 = body2;
        this._body2Medium = body2Medium;
        this._body1 = body1;
        this._body1Medium = body1Medium;
        this._detail3 = detail3;
        this._detail3Medium = detail3Medium;
        this._detail2 = detail2;
        this._detail2Medium = detail2Medium;
        this._detail1 = detail1;
    }

    get headline3() {
        return this._headline3.toCSSString(this.config);
    }

    get headline2() {
        return this._headline2.toCSSString(this.config);
    }

    get headline1() {
        return this._headline1.toCSSString(this.config);
    }

    get emphasis4() {
        return this._emphasis4.toCSSString(this.config);
    }

    get emphasis3() {
        return this._emphasis3.toCSSString(this.config);
    }

    get emphasis2() {
        return this._emphasis2.toCSSString(this.config);
    }

    get emphasis1() {
        return this._emphasis1.toCSSString(this.config);
    }

    get title3() {
        return this._title3.toCSSString(this.config);
    }

    get title2() {
        return this._title2.toCSSString(this.config);
    }

    get title1() {
        return this._title1.toCSSString(this.config);
    }

    get body3() {
        return this._body3.toCSSString(this.config);
    }

    get body3Medium() {
        return this._body3Medium.toCSSString(this.config);
    }

    get body2() {
        return this._body2.toCSSString(this.config);
    }

    get body2Medium() {
        return this._body2Medium.toCSSString(this.config);
    }

    get body1() {
        return this._body1.toCSSString(this.config);
    }

    get body1Medium() {
        return this._body1Medium.toCSSString(this.config);
    }

    get detail3() {
        return this._detail3.toCSSString(this.config);
    }

    get detail3Medium() {
        return this._detail3Medium.toCSSString(this.config);
    }

    get detail2() {
        return this._detail2.toCSSString(this.config);
    }

    get detail2Medium() {
        return this._detail2Medium.toCSSString(this.config);
    }

    get detail1() {
        return this._detail1.toCSSString(this.config);
    }
}

export const fontTheme: FontTheme = {
    fonts: new FontsConfig({
        config: new FontConfig({
            baseTextColor: Palette.gray800,
            baseFontFamily: "Pretendard",
        }),
        headline3: new Font({
            fontSize: "3.5rem",
            fontWeight: FontWeights.bold,
            lineHeight: "130%",
        }),
        headline2: new Font({
            fontSize: "3rem",
            fontWeight: FontWeights.bold,
            lineHeight: "130%",
        }),
        headline1: new Font({
            fontSize: "2.5rem",
            fontWeight: FontWeights.bold,
            lineHeight: "130%",
        }),
        emphasis4: new Font({
            fontSize: "2.25rem",
            fontWeight: FontWeights.bold,
            lineHeight: "130%",
        }),
        emphasis3: new Font({
            fontSize: "2.125rem",
            fontWeight: FontWeights.bold,
            lineHeight: "130%",
        }),
        emphasis2: new Font({
            fontSize: "2rem",
            fontWeight: FontWeights.bold,
            lineHeight: "130%",
        }),
        emphasis1: new Font({
            fontSize: "1.875rem",
            fontWeight: FontWeights.bold,
            lineHeight: "130%",
        }),
        title3: new Font({
            fontSize: "1.75rem",
            fontWeight: FontWeights.bold,
            lineHeight: "130%",
        }),
        title2: new Font({
            fontSize: "1.625rem",
            fontWeight: FontWeights.bold,
            lineHeight: "130%",
        }),
        title1: new Font({
            fontSize: "1.5rem",
            fontWeight: FontWeights.bold,
            lineHeight: "130%",
        }),
        body3: new Font({
            fontSize: "1.375rem",
            fontWeight: FontWeights.bold,
            lineHeight: "140%",
        }),
        body3Medium: new Font({
            fontSize: "1.375rem",
            fontWeight: FontWeights.medium,
            lineHeight: "140%",
        }),
        body2: new Font({
            fontSize: "1.25rem",
            fontWeight: FontWeights.bold,
            lineHeight: "140%",
        }),
        body2Medium: new Font({
            fontSize: "1.25rem",
            fontWeight: FontWeights.medium,
            lineHeight: "140%",
        }),
        body1: new Font({
            fontSize: "1.125rem",
            fontWeight: FontWeights.bold,
            lineHeight: "140%",
        }),
        body1Medium: new Font({
            fontSize: "1.125rem",
            fontWeight: FontWeights.medium,
            lineHeight: "140%",
        }),
        detail3: new Font({
            fontSize: "1rem",
            fontWeight: FontWeights.bold,
            lineHeight: "140%",
        }),
        detail3Medium: new Font({
            fontSize: "1rem",
            fontWeight: FontWeights.medium,
            lineHeight: "140%",
        }),
        detail2: new Font({
            fontSize: "0.875rem",
            fontWeight: FontWeights.bold,
            lineHeight: "140%",
        }),
        detail2Medium: new Font({
            fontSize: "0.875rem",
            fontWeight: FontWeights.medium,
            lineHeight: "140%",
        }),
        detail1: new Font({
            fontSize: "0.75rem",
            fontWeight: FontWeights.semiBold,
            lineHeight: "150%",
        }),
    }),
};

export default class Fonts {
    static get headline3() {
        return fontTheme.fonts.headline3;
    }

    static get headline2() {
        return fontTheme.fonts.headline2;
    }

    static get headline1() {
        return fontTheme.fonts.headline1;
    }

    static get emphasis4() {
        return fontTheme.fonts.emphasis4;
    }

    static get emphasis3() {
        return fontTheme.fonts.emphasis3;
    }

    static get emphasis2() {
        return fontTheme.fonts.emphasis2;
    }

    static get emphasis1() {
        return fontTheme.fonts.emphasis1;
    }

    static get title3() {
        return fontTheme.fonts.title3;
    }

    static get title2() {
        return fontTheme.fonts.title2;
    }

    static get title1() {
        return fontTheme.fonts.title1;
    }

    static get body3() {
        return fontTheme.fonts.body3;
    }

    static get body3Medium() {
        return fontTheme.fonts.body3Medium;
    }

    static get body2() {
        return fontTheme.fonts.body2;
    }

    static get body2Medium() {
        return fontTheme.fonts.body2Medium;
    }

    static get body1() {
        return fontTheme.fonts.body1;
    }

    static get body1Medium() {
        return fontTheme.fonts.body1Medium;
    }

    static get detail3() {
        return fontTheme.fonts.detail3;
    }

    static get detail3Medium() {
        return fontTheme.fonts.detail3Medium;
    }

    static get detail2() {
        return fontTheme.fonts.detail2;
    }

    static get detail2Medium() {
        return fontTheme.fonts.detail2Medium;
    }

    static get detail1() {
        return fontTheme.fonts.detail1;
    }
}
