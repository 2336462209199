import {
    SMSCertKTTermsTypeMap,
    SMSCertKTTermsTypes
} from "domain/model/terms/sms_cert_kt_terms_type";
import S from "presentation/theme/s";
import {
    SMSCertSKTTermsTypeMap,
    SMSCertSKTTermsTypes
} from "domain/model/terms/sms_cert_skt_terms_type";
import {
    SMSCertLGTermsTypeMap,
    SMSCertLGTermsTypes
} from "domain/model/terms/sms_cert_lg_terms_type";
import {
    SMSCertSMTermsTypeMap,
    SMSCertSMTermsTypes
} from "domain/model/terms/sms_cert_sm_terms_type";
import {
    SMSCertKMTermsTypeMap,
    SMSCertKMTermsTypes
} from "domain/model/terms/sms_cert_km_terms_type";
import {
    SMSCertLMTermsTypeMap,
    SMSCertLMTermsTypes
} from "domain/model/terms/sms_cert_lm_terms_type";
import {BaseTermsTypeMap} from "domain/model/terms/base_terms_type";

export enum ISPType {
    SKT = "SK",
    KT = "KT",
    LG = "LG",
    SM = "SM",
    KM = "KM",
    LM = "LM",
}

export const ISPTypes = Object.values(ISPType);

export const ISPTypeMap = {
    isMVNO: (type: ISPType): boolean => {
        switch (type) {
            case ISPType.SKT:
            case ISPType.KT:
            case ISPType.LG:
                return false;
            case ISPType.SM:
            case ISPType.KM:
            case ISPType.LM:
                return true;
            default:
                throw new Error("Invalid isp type");
        }
    },
    label: (type: ISPType): string => {
        switch (type) {
            case ISPType.SKT:
                return S.ispType.SKTLabel;
            case ISPType.KT:
                return S.ispType.KTLabel;
            case ISPType.LG:
                return S.ispType.LGLabel;
            case ISPType.SM:
                return S.ispType.SMLabel;
            case ISPType.KM:
                return S.ispType.KMLabel;
            case ISPType.LM:
                return S.ispType.LMLabel;
            default:
                throw new Error("Invalid isp type");
        }
    },
    terms: (type: ISPType): Array<string> => {
        switch (type) {
            case ISPType.SKT:
                return SMSCertSKTTermsTypes;
            case ISPType.KT:
                return SMSCertKTTermsTypes;
            case ISPType.LG:
                return SMSCertLGTermsTypes;
            case ISPType.SM:
                return SMSCertSMTermsTypes;
            case ISPType.KM:
                return SMSCertKMTermsTypes;
            case ISPType.LM:
                return SMSCertLMTermsTypes;
            default:
                throw new Error("Invalid isp type");
        }
    },
    termsMap: (type: ISPType): BaseTermsTypeMap => {
        switch (type) {
            case ISPType.SKT:
                return SMSCertSKTTermsTypeMap;
            case ISPType.KT:
                return SMSCertKTTermsTypeMap;
            case ISPType.LG:
                return SMSCertLGTermsTypeMap;
            case ISPType.SM:
                return SMSCertSMTermsTypeMap;
            case ISPType.KM:
                return SMSCertKMTermsTypeMap;
            case ISPType.LM:
                return SMSCertLMTermsTypeMap;
            default:
                throw new Error("Invalid isp type");
        }
    },
};
