import styled from "styled-components";
import {useRecoilValue, useSetRecoilState} from "recoil";
import shootPrescriptionsSelector from "presentation/states/shoot/selector/shoot_prescriptions_selector";
import shootState from "presentation/states/shoot/shoot_state";
import ShootPrescriptionRowTitleBox from "presentation/pages/shoot/components/shoot_prescription_row_title_box";
import S from "presentation/theme/s";
import ShootPrescriptionTextField from "presentation/pages/shoot/components/shoot_prescription_text_field";
import {PrescriptionFieldRevisionStatusMap} from "domain/model/prescription/prescription_field_revision_status";
import ShootPrescriptionDateField from "presentation/pages/shoot/components/shoot_prescription_date_field";
import ShootConstants from "presentation/states/shoot/constants/shoot_constants";
import DateTime from "presentation/utils/class/date_time";
import Flex from "presentation/components/common/flex";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ShootPrescriptionIssueNumberDateView = () => {
    const {prescription} = useRecoilValue(shootPrescriptionsSelector);
    const setState = useSetRecoilState(shootState);

    const onIssueNumberChange = (issueNumber: string) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        prescriptions[index] = {
            ...prescriptions[index],
            issueNumber: {
                ...prescriptions[index].issueNumber,
                value: issueNumber,
                revisionStatus: {
                    ...prescriptions[index].issueNumber.revisionStatus,
                    confidenceLow: false,
                },
            },
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    const onIssueDateChange = (issueDate: DateTime) => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        prescriptions[index] = {
            ...prescriptions[index],
            issueDate: {
                ...prescriptions[index].issueDate,
                value: issueDate,
                revisionStatus: {
                    ...prescriptions[index].issueDate.revisionStatus,
                    confidenceLow: false,
                },
            },
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    return <LayoutContainer>
        <ShootPrescriptionRowTitleBox
            roundTopLeft={true}
            title={S.shootPage.shootPrescriptionView.issueNumberDateView.issueNumberTitle}
        />
        <Flex>
            <ShootPrescriptionTextField
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(prescription.issueNumber.revisionStatus)}
                value={prescription.issueNumber.value}
                onValueChange={onIssueNumberChange}
                maxLength={ShootConstants.prescription.maxIssueNumberLength}
                inputFormatter={InputFormatters.decimalOnlyFormatter}
            />
        </Flex>
        <Flex>
            <ShootPrescriptionDateField
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(prescription.issueDate.revisionStatus)}
                value={prescription.issueDate.value}
                onValueChange={onIssueDateChange}
            />
        </Flex>
    </LayoutContainer>;
};

export default ShootPrescriptionIssueNumberDateView;
