import {atom} from "recoil";
import {Subject} from "rxjs";
import {ShootListenableState} from "presentation/states/shoot/shoot_listenable_state";
import Prescription from "domain/model/prescription/prescription";

type ShootState = {
    prescriptions: Prescription[];
    selectedPrescriptionID: optional<string>;
};

export const shootListenableState = new Subject<ShootListenableState>();

const shootState = atom<ShootState>({
    key: "shootState",
    default: {
        prescriptions: [],
        selectedPrescriptionID: undefined,
    },
});

export default shootState;