import {animated, useSpring} from "@react-spring/web";
import SVG from "presentation/components/common/svg";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import Debouncer from "presentation/utils/debouncer/debouncer";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import {ReactElement, useRef, useState, MouseEvent} from "react";
import styled from "styled-components";

type OutlinedButtonType = "primary" | "gray";
type OutlinedButtonSizeType = "large" | "medium" | "small";

const colorPresets: Record<
    OutlinedButtonType,
    {
        borderColor: string;
        hoverBorderColor: string;
        disabledBorderColor: string;
        color: string;
        hoverColor: string;
        disabledColor: string;
    }
> = {
    primary: {
        borderColor: Palette.primary500,
        hoverBorderColor: Palette.primary300,
        disabledBorderColor: Palette.gray200,
        color: Palette.gray800,
        hoverColor: Palette.gray500,
        disabledColor: Palette.gray300,
    },
    gray: {
        borderColor: Palette.gray200,
        hoverBorderColor: Palette.gray300,
        disabledBorderColor: Palette.gray100,
        color: Palette.gray800,
        hoverColor: Palette.gray500,
        disabledColor: Palette.gray300,
    },
};

const sizePresets: Record<
    OutlinedButtonSizeType,
    {
        borderThickness: string;
        font: string;
        padding: string;
        iconSize: string;
    }
> = {
    large: {
        borderThickness: "1.5px",
        font: Fonts.body1Medium,
        padding: "12px 16px",
        iconSize: "24px",
    },
    medium: {
        borderThickness: "1px",
        font: Fonts.detail2Medium,
        padding: "10px 16px",
        iconSize: "20px",
    },
    small: {
        borderThickness: "1px",
        font: Fonts.detail1,
        padding: "6px 12px",
        iconSize: "16px",
    },
};

const LayoutContainer = styled(animated.button).attrs<{
    $enabled: boolean;
    $width: string;
    $padding: string;
}>((props) => ({
    style: {
        width: props.$width,
        padding: props.$padding,
        cursor: props.$enabled ? "pointer" : "not-allowed",
    },
}))`
    min-width: max-content;
    border: none;
    border-radius: 12px;
    background-color: ${Palette.none};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
`;

const LabelContainer = styled(animated.p)<{
    $enabled: boolean;
    $font: string;
}>`
    min-width: max-content;
    ${(props) => props.$font};
    text-align: center;
`;

const OutlinedButton = ({
                            type = "primary",
                            sizeType = "large",
                            enabled = true,
                            width = "auto",
                            label,
                            leftIconAsset,
                            rightIconAsset,
                            iconColor,
                            iconHoverColor,
                            iconDisabledColor,
                            debouncerDelayInMS = 50,
                            onClick,
                        }: {
    type?: OutlinedButtonType;
    sizeType?: OutlinedButtonSizeType;
    enabled?: boolean;
    width?: string;
    label: string;
    leftIconAsset?: ReactElement;
    rightIconAsset?: ReactElement;
    iconColor?: string;
    iconHoverColor?: string;
    iconDisabledColor?: string;
    debouncerDelayInMS?: number;
    onClick?: (e: MouseEvent) => void;
}) => {
    const debouncerRef = useRef(new Debouncer());
    const [hovered, setHovered] = useState(false);

    const _onClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!enabled) return;

        debouncerRef.current.run(() => onClick?.(e), debouncerDelayInMS);
    }

    const borderColor = colorPresets[type].borderColor;
    const hoverBorderColor = colorPresets[type].hoverBorderColor;
    const disabledBorderColor = colorPresets[type].disabledBorderColor;
    const color = colorPresets[type].color;
    const hoverColor = colorPresets[type].hoverColor;
    const disabledColor = colorPresets[type].disabledColor;
    const borderThickness = sizePresets[sizeType].borderThickness;
    const font = sizePresets[sizeType].font;
    const padding = sizePresets[sizeType].padding;
    const iconSize = sizePresets[sizeType].iconSize;

    const buttonProps = useSpring({
        boxShadow: enabled ?
            `0 0 0 ${borderThickness} ${hovered ? hoverBorderColor : borderColor} inset` :
            `0 0 0 ${borderThickness} ${disabledBorderColor} inset`,
    });
    const {props: fadeInProps} = useFadeInTransition(label);
    const labelProps = useSpring({
        color: enabled ? (hovered ? hoverColor : color) : disabledColor,
    });

    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    const _iconColor = (
        enabled ?
            hovered ? (iconHoverColor ?? iconColor) :
                iconColor : (iconDisabledColor ?? iconColor)
    ) ?? Palette.gray800;

    return (
        <LayoutContainer
            style={buttonProps}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={_onClick}
            title={label}
            $enabled={enabled}
            $width={width}
            $padding={padding}
        >
            {leftIconAsset && (
                <SVG
                    asset={leftIconAsset}
                    width={iconSize}
                    height={iconSize}
                    color={_iconColor}
                />
            )}
            <LabelContainer
                style={{
                    ...fadeInProps,
                    ...labelProps,
                }}
                $enabled={enabled}
                $font={font}
            >
                {label}
            </LabelContainer>
            {rightIconAsset && (
                <SVG
                    asset={rightIconAsset}
                    width={iconSize}
                    height={iconSize}
                    color={_iconColor}
                />
            )}
        </LayoutContainer>
    );
};

export default OutlinedButton;
