import {BaseTermsTypeMap} from "domain/model/terms/base_terms_type";
import S from "presentation/theme/s";

enum SMSCertLMTermsType {
    personalInformation = "smsLmPersonalInformation",
    uniqueId = "smsLmUniqueId",
    thirdParty = "smsLmThirdParty",
    ispService = "smsLmIspService",
    generalService = "smsLmGeneralService",
}

export const SMSCertLMTermsTypes = Object.values(SMSCertLMTermsType);

export const SMSCertLMTermsTypeMap: BaseTermsTypeMap = {
    title: (type: SMSCertLMTermsType): string => {
        switch (type) {
            case SMSCertLMTermsType.personalInformation:
                return S.smsCertMVNOTermsType.personalInformationTitle;
            case SMSCertLMTermsType.uniqueId:
                return S.smsCertMVNOTermsType.uniqueIdTitle;
            case SMSCertLMTermsType.thirdParty:
                return S.smsCertMVNOTermsType.thirdPartyTitle;
            case SMSCertLMTermsType.ispService:
                return S.smsCertMVNOTermsType.ispServiceTitle;
            case SMSCertLMTermsType.generalService:
                return S.smsCertMVNOTermsType.generalServiceTitle;
            default:
                throw new Error("Invalid sms cert lm terms type");
        }
    },
    label: (type: SMSCertLMTermsType): string => {
        switch (type) {
            case SMSCertLMTermsType.personalInformation:
                return S.smsCertMVNOTermsType.personalInformationLabel;
            case SMSCertLMTermsType.uniqueId:
                return S.smsCertMVNOTermsType.uniqueIdLabel;
            case SMSCertLMTermsType.thirdParty:
                return S.smsCertMVNOTermsType.thirdPartyLabel;
            case SMSCertLMTermsType.ispService:
                return S.smsCertMVNOTermsType.ispServiceLabel;
            case SMSCertLMTermsType.generalService:
                return S.smsCertMVNOTermsType.generalServiceLabel;
            default:
                throw new Error("Invalid sms cert lm terms type");
        }
    },
};

export default SMSCertLMTermsType;
