import styled from "styled-components";
import {useMemo} from "react";
import {animated, useSpring} from "@react-spring/web";
import Palette from "presentation/theme/palette";
import CSSPointerEventsType from "presentation/utils/types/css/pointer_events_type";

const LayoutContainer = styled(animated.button)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    overflow: hidden;
`;

const InnerBoxContainer = styled(animated.div)`
    position: relative;
    pointer-events: none;
`;

const CircleContainer = styled(animated.div)`
    background-color: ${Palette.white100};
    position: absolute;
`;

const ToggleSwitch = ({
                          enabled = true,
                          widthInPx = 50,
                          on,
                          onClick,
                      }: {
    enabled?: boolean;
    widthInPx?: number;
    on: boolean;
    onClick?: () => void;
}) => {
    const {width, height, borderRadius, radius, verticalPadding, horizontalPadding} = useMemo(() => ({
        width: widthInPx,
        height: widthInPx * 30 / 50,
        borderRadius: widthInPx * (30 / 50) / 2,
        radius: widthInPx * 12 / 50,
        verticalPadding: widthInPx * 3.5 / 50,
        horizontalPadding: widthInPx * 3 / 50,
    }), [widthInPx]);

    const layoutProps = useSpring({
        width,
        height,
        minWidth: width,
        minHeight: height,
        borderRadius,
        backgroundColor: on ? Palette.deepBlue : Palette.gray200,
        pointerEvents: (enabled ? "none" : "none") as CSSPointerEventsType,
    });

    const innerBoxProps = useSpring({
        width: width - horizontalPadding * 2,
        height: height - verticalPadding * 2,
        minWidth: width - horizontalPadding * 2,
        minHeight: height - verticalPadding * 2,
    });

    const circleProps = useSpring({
        width: radius * 2,
        height: radius * 2,
        minWidth: radius * 2,
        minHeight: radius * 2,
        borderRadius: "50%",
        left: 0,
        translateX: on ? width - radius * 2 - horizontalPadding * 2 : 0,
    });

    return <LayoutContainer style={layoutProps} disabled={!enabled} onClick={onClick}>
        <InnerBoxContainer style={innerBoxProps}>
            <CircleContainer style={circleProps}/>
        </InnerBoxContainer>
    </LayoutContainer>;
};

export default ToggleSwitch;
