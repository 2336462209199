enum InputValidationStatusType {
    Valid = "valid",
    InsufficientLength = "insufficientLength",
    WrongFormat = "wrongFormat",
}

export const InputValidationStatusTypes = Object.values(InputValidationStatusType);

export const InputValidationStatusTypeMap = {
    isValid: (d: InputValidationStatusType): boolean => {
        return d === InputValidationStatusType.Valid;
    },
};

export default InputValidationStatusType;