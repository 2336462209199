import axios, {AxiosError} from "axios";
import DomainError from "domain/model/common/domain_error";
import ErrorCode from "domain/model/common/error_code";
import {Subject} from "rxjs";
import secureStorageInstance from "data/local/secure_storage/secure_storage";
import DevLogType from "domain/model/common/dev_log_type";
import CommonError from "domain/model/common/common_error";
import {EnvironmentConstants} from "config/environment_constants";
import devLog from "data/utils/functions/dev_log";

declare module "axios" {
    export interface AxiosRequestConfig {
        retried?: boolean;
    }
}

export type HTTPClient = ReturnType<typeof createHTTPClient>;

export const httpClientCancelSubject = new Subject<void>();

export function createHTTPClient() {
    const httpClient = axios.create({
        baseURL: EnvironmentConstants.apiURL,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Device: "Web",
        },
        withCredentials: true,
        timeout: 60 * 1000,
    });

    httpClient.interceptors.request.use((request) => {
        if (request.retried) {
            devLog({
                logType: DevLogType.Request,
                message: {
                    method: request.method ?? "null",
                    url: request.url ?? "null",
                    headers: request.headers ?? "null",
                    params: request.params ?? "null",
                    data: request.data ?? "null",
                },
            });
            return request;
        }

        const hospital = secureStorageInstance.getHospital();
        if (hospital) {
            request.headers["hospital-key"] = hospital;
        }

        devLog({
            logType: DevLogType.Request,
            message: {
                method: request.method ?? "null",
                url: request.url ?? "null",
                headers: request.headers ?? "null",
                params: request.params ?? "null",
                data: request.data ?? "null",
            },
        });

        return request;
    });

    httpClient.interceptors.response.use(
        (response) => {
            devLog({
                logType: DevLogType.Response,
                message: {
                    method: response.config.method ?? "null",
                    status: response.status ?? "null",
                    headers: response.headers ?? "null",
                    data: response.data ?? "null",
                },
            });
            return response;
        },
        async (error) => {
            devLog({
                logType: DevLogType.Error,
                message: {
                    method: error.response?.config.method ?? "null",
                    status: error.response?.status ?? "null",
                    headers: error.response?.headers ?? "null",
                    data: error.response?.data ?? "null",
                },
            });

            if (
                error instanceof AxiosError &&
                error.code === AxiosError.ERR_CANCELED
            ) {
                const message = "Error cancelled by user";
                devLog({
                    logType: DevLogType.Error,
                    message,
                });

                return Promise.reject(
                    new DomainError(ErrorCode.Cancelled, message)
                );
            }

            const errorData = error.response?.data;
            if (errorData && errorData.code && errorData.message) {
                if (errorData.code === ErrorCode.ServerSentError) {
                    devLog({
                        logType: DevLogType.Error,
                        message: "Server sent error",
                    });

                    const json = JSON.parse(errorData.message);
                    const title = json.title;
                    const message = json.message;

                    return Promise.reject(
                        new CommonError(
                            ErrorCode.ServerSentError,
                            title,
                            message
                        )
                    );
                }

                return Promise.reject(
                    new DomainError(errorData.code, errorData.message)
                );
            }

            if (error instanceof AxiosError) {
                const message = `[Network error] ${error}`;
                devLog({
                    logType: DevLogType.Error,
                    message,
                });

                return Promise.reject(
                    new DomainError(ErrorCode.NetworkError, message)
                );
            }

            return Promise.reject(DomainError.unknown());
        }
    );

    return httpClient;
}

const httpClientInstance = createHTTPClient();

export default httpClientInstance;
