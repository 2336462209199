import BaseRepository from "data/repository/base_repository";

export default class TermsRepository extends BaseRepository {
    getTermsURLs = async ({
                              term
                          }: {
        term: string;
    }): Promise<string[]> => await this.handler({
        request: async (client, signals) => {
            const response = await client.get("/document/terms", {
                params: {
                    serviceType: "JPH",
                    types: term,
                },
                signal: signals.at(0),
            });

            return response.data[0].urls;
        },
    });
};