const useCamera = () => {
    return async ({
                      width,
                      height,
                      fps
                  }: {
        width: number;
        height: number;
        fps: number;
    }) => {
        const labels = ["ET08X", "E012A"];

        try {
            await navigator.mediaDevices.getUserMedia({
                audio: false, video: {
                    width: {ideal: width},
                    height: {ideal: height},
                    frameRate: {ideal: fps},
                }
            });
        } catch {
            return undefined;
        }
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter((device) => device.kind === "videoinput");
        const camera = cameras.find((camera) => labels.some((label) => camera.label.includes(label))) ?? cameras.at(0);

        return !!camera ? {
            data: camera,
            isJeekimScanner: labels.some((label) => camera?.label.includes(label) ?? false),
        } : undefined;
    };
};

export default useCamera;