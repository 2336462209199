import SizedBox from "presentation/components/common/sized_box";
import LoginPasswordView from "presentation/pages/login/view/login_password_view";
import LoginLogoView from "presentation/pages/login/view/login_logo_view";
import LoginUserIDView from "presentation/pages/login/view/login_user_id_view";
import loginState from "presentation/states/login/login_state";
import useLifeCycle from "presentation/utils/hooks/use_life_cycle";
import {useRecoilValue, useResetRecoilState} from "recoil";
import styled from "styled-components";
import {FormEvent} from "react";

const LayoutContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ContentContainer = styled.div`
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const RelativeContainer = styled.form.attrs<{ $height: string }>((props) => ({
    style: {
        height: props.$height,
    },
}))`
    width: 100%;
    position: relative;
    transition: height 0.3s ease-in-out;
`;

export const LoginPage = () => {
    const {passwordInputRequested} = useRecoilValue(loginState);
    const resetState = useResetRecoilState(loginState);

    useLifeCycle({
        onDispose: () => resetState(),
    });

    const onSubmit = (e: FormEvent) => e.preventDefault();

    const height = passwordInputRequested ? "11rem" : "8rem";

    return (
        <LayoutContainer>
            <ContentContainer>
                <LoginLogoView/>
                <SizedBox height={"48px"}/>
                <RelativeContainer
                    onSubmit={onSubmit}
                    $height={height}
                >
                    <LoginUserIDView/>
                    <LoginPasswordView/>
                </RelativeContainer>
            </ContentContainer>
        </LayoutContainer>
    );
};

export default LoginPage;
