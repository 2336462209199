import styled from "styled-components";
import Fonts from "presentation/theme/fonts";
import SVG from "presentation/components/common/svg";
import S from "presentation/theme/s";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import SizedBox from "presentation/components/common/sized_box";
import TextButton from "presentation/components/button/text_button";
import useRenderedRef from "presentation/utils/hooks/use_rendered_ref";
import {useRef} from "react";
import {animated, useSpring} from "@react-spring/web";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import TermsDetailDialog from "presentation/components/dialog/terms_detail_dialog/terms_detail_dialog";

type TermData = {
    term: string;
    title: string;
    label: string;
};

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const RowContainer = styled.div`
    min-width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

const ButtonContainer = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    border: none;
    background-color: ${Palette.none};
`;

const ButtonLabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail2Medium};
    text-align: center;
`;

const TermsCollapseContainer = styled(animated.div)`
    width: 100%;
    overflow: hidden;
`;

const TermsLayoutContainer = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    list-style: none;
`;

const TermsContainer = styled.li`
    ${Fonts.detail1};
    color: ${Palette.gray400};
    cursor: pointer;
`;

const CertTermsButton = ({
                             agreed,
                             collapsed,
                             data,
                             onAgreedClick,
                             onCollapsedClick,
                         }: {
    agreed: boolean;
    collapsed: boolean;
    data: TermData[];
    onAgreedClick: () => void;
    onCollapsedClick: () => void;
}) => {
    const renderedRef = useRenderedRef();
    const showDialog = useShowDialog();

    const termsLayoutRef = useRef<HTMLUListElement>(null);
    const {heightInPx} = useResizeObserver(termsLayoutRef, [], 0);

    const termsCollapseProps = useSpring({
        height: collapsed ? 0 : heightInPx,
        immediate: !renderedRef.current,
    });

    const iconColor = agreed ? Palette.primary500 : Palette.gray200;
    const collapsedButtonLabel = collapsed ?
        S.certTermsButton.collapsedButtonCollapsedLabel :
        S.certTermsButton.collapsedButtonExpandedLabel;
    const collapsedButtonIconAsset = collapsed ? SVGAssets.Down : SVGAssets.Up;
    const onTermClick = (data: TermData) => () => showDialog({
        className: "terms-detail-dialog",
        component: <TermsDetailDialog title={data.title} term={data.term}/>
    });

    return <LayoutContainer>
        <RowContainer>
            <ButtonContainer onClick={onAgreedClick}>
                <SVG
                    asset={SVGAssets.CheckCircle}
                    width={"24px"}
                    height={"24px"}
                    color={iconColor}
                />
                <ButtonLabelContainer>
                    {S.certTermsButton.label}
                </ButtonLabelContainer>
            </ButtonContainer>
            <TextButton
                type={"primary"}
                sizeType={"small"}
                label={collapsedButtonLabel}
                rightIconAsset={collapsedButtonIconAsset}
                iconColor={Palette.primary500}
                onClick={onCollapsedClick}
            />
        </RowContainer>
        <SizedBox height={"8px"}/>
        <TermsCollapseContainer style={termsCollapseProps}>
            <TermsLayoutContainer ref={termsLayoutRef}>{
                data.map((d, index) =>
                    <TermsContainer key={index} onClick={onTermClick(d)}>
                        {S.certTermsButton.bulletedLabel(d.label)}
                    </TermsContainer>
                )
            }</TermsLayoutContainer>
        </TermsCollapseContainer>
    </LayoutContainer>;
};

export default CertTermsButton;
