import styled from "styled-components";
import {SVGAsset} from "presentation/theme/assets";
import SVG from "presentation/components/common/svg";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import {animated, useSpring} from "@react-spring/web";

const LayoutContainer = styled(animated.button)`
    width: 97px;
    height: 97px;
    padding: 12px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border: none;
    cursor: pointer;
`;

const LabelContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail1};
    color: ${Palette.gray600};
    text-align: center;
`;

const CertTypeButton = ({
                            selected,
                            iconAsset,
                            label,
                            onClick,
                        }: {
    selected: boolean;
    iconAsset: SVGAsset;
    label: string;
    onClick: () => void;
}) => {
    const props = useSpring({
        backgroundColor: selected ? Palette.primary10 : Palette.none,
    });

    return <LayoutContainer
        style={props}
        onClick={onClick}
    >
        <SVG
            asset={iconAsset}
            width={"50px"}
            height={"50px"}
        />
        <LabelContainer>
            {label}
        </LabelContainer>
    </LayoutContainer>;
};

export default CertTypeButton;
