import ShootPrescriptionRevisionLabel from "presentation/pages/shoot/components/shoot_prescription_revision_label";
import {useRecoilValue} from "recoil";
import shootPrescriptionRevisionStatusSelector
    from "presentation/states/shoot/selector/shoot_prescription_revision_status_selector";

const ShootPrescriptionInfoRevisionLabelView = () => {
    const {prescriptionInfoRevisionNeeded} = useRecoilValue(shootPrescriptionRevisionStatusSelector);

    return <ShootPrescriptionRevisionLabel visible={prescriptionInfoRevisionNeeded}/>;
};

export default ShootPrescriptionInfoRevisionLabelView;
