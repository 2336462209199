import PrescriptionMedication from "domain/model/prescription/prescription_medication";
import StringHelper from "config/helper/string_helper";

type PrescriptionMedicationRequestDTO = {
    selfPayRateCode: string;
    drugCode: optional<string>;
    drugName: optional<string>;
    oneDose: optional<string>;
    dosingPerDay: optional<string>;
    totalDosingDays: optional<string>;
    isSubstituted: boolean;
};

export const PrescriptionMedicationRequestDTOMap = {
    fromDomainModel: (d: PrescriptionMedication): PrescriptionMedicationRequestDTO => {
        return {
            selfPayRateCode: d.deductibleRate.value,
            drugCode: d.drugCode.value,
            drugName: d.drugName.value,
            oneDose: StringHelper.toUndefinedIfEmpty(d.singleDosage.value),
            dosingPerDay: StringHelper.toUndefinedIfEmpty(d.dailyDosage.value),
            totalDosingDays: StringHelper.toUndefinedIfEmpty(d.medicationPeriodInDays.value),
            isSubstituted: false,
        };
    },
}

export default PrescriptionMedicationRequestDTO;