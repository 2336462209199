import DateTime from "presentation/utils/class/date_time";

export default class DateTimeHelper {
    private static serverDateFormat = "yyyy-MM-dd";
    private static serverPrescriptionHistoryDateFormat = "yyyyMMdd";

    /// 날짜
    /// 서버(yyyy-MM-dd) 형식 -> DateTime 으로 변환
    static fromServerDate(date: string): DateTime {
        const year = parseInt(date.substring(0, 4));
        const month = parseInt(date.substring(5, 7));
        const day = parseInt(date.substring(8));

        return new DateTime(year, month, day);
    }

    /// 날짜
    /// 서버(yyyy-MM-dd) 형식 -> DateTime 으로 변환 (nullable)
    static fromServerDateOrNull(date?: string): optional<DateTime> {
        return date ? DateTimeHelper.fromServerDate(date) : undefined;
    }

    /// 날짜
    /// 서버(yyyyMMdd) 형식 -> DateTime 으로 변환
    static fromServerPrescriptionHistoryDate(date: string): DateTime {
        const year = parseInt(date.substring(0, 4));
        const month = parseInt(date.substring(4, 6));
        const day = parseInt(date.substring(6));

        return new DateTime(year, month, day);
    }

    /// 날짜
    /// DateTime -> 서버(yyyy-MM-dd) 형식으로 변환
    static toServerDate(date: DateTime): string {
        return date.format(this.serverDateFormat);
    }

    /// 날짜
    /// DateTime -> 서버(yyyy-MM-dd) 형식으로 변환
    static toServerPrescriptionHistoryDate(date: DateTime): string {
        return date.format(this.serverPrescriptionHistoryDateFormat);
    }
}
