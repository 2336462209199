export abstract class DataFilter {
    abstract filter(value?: string): optional<string>;
}

export default class BaseDataFilter extends DataFilter {
    filter(value?: string): optional<string> {
        if (!value) return undefined;

        return value?.trim();
    };
};