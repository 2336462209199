import styled from "styled-components";
import Palette from "presentation/theme/palette";
import {animated, useSpring} from "@react-spring/web";
import Fonts from "presentation/theme/fonts";
import PrescriptionExtraRemarksType, {
    PrescriptionExtraRemarksTypeMap, PrescriptionExtraRemarksTypes
} from "domain/model/prescription/prescription_extra_remarks_type";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import S from "presentation/theme/s";
import {useCallback, useLayoutEffect, useMemo, useRef, useState} from "react";
import DropdownButtonItem from "presentation/components/button/dropdown_button/components/dropdown_button_item";
import CSSPointerEventsType from "presentation/utils/types/css/pointer_events_type";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";

const LayoutContainer = styled(animated.button)`
    width: 100%;
    height: 2.5rem;
    padding: 0 16px;
    border-bottom-right-radius: 12px;
    border-top: none;
    border-right: 1px solid ${Palette.gray100};
    border-bottom: 1px solid ${Palette.gray100};
    border-left: none;
    background-color: ${Palette.none};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    position: relative;
`;

const ExtraRemarksLabelContainer = styled(animated.p)`
    min-width: max-content;
    ${Fonts.detail3Medium};
    text-align: center;
    margin-top: 2px;
`;

const IconPositionContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 22px;
    transform: translate3d(0, -50%, 0);
`;

const DropdownContainer = styled(animated.ul)`
    width: 100%;
    padding: 8px;
    background-color: ${Palette.white100};
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 10;
    cursor: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const ShootPrescriptionExtraRemarksField = ({
                                                revisionNeeded,
                                                value,
                                                onValueChange,
                                            }: {
    revisionNeeded: boolean;
    value?: PrescriptionExtraRemarksType;
    onValueChange: (value?: PrescriptionExtraRemarksType) => void;
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null);
    const [selecting, setSelecting] = useState(false);

    const types = useMemo(() => [
        undefined,
        ...PrescriptionExtraRemarksTypes,
    ], []);

    const onClick = useCallback((e: MouseEvent) => {
        if (buttonRef.current && buttonRef.current.contains(e.target as Node)) {
            setSelecting(true);
            return;
        }

        if (
            dropdownRef.current &&
            dropdownRef.current.contains(e.target as Node)
        ) {
            return;
        }

        setSelecting(false);
    }, [buttonRef, dropdownRef]);

    useLayoutEffect(() => {
        window.addEventListener("mousedown", onClick);

        return () => window.removeEventListener("mousedown", onClick);
    }, [onClick]);

    const label = (value?: PrescriptionExtraRemarksType) => value ? PrescriptionExtraRemarksTypeMap.label(value) : S.shootPrescriptionExtraRemarksField.emptyLabel;

    const layoutProps = useSpring({
        backgroundColor: selecting ? Palette.gray50 : Palette.white100,
    });
    const {props: labelFadeInProps} = useFadeInTransition(label(value));
    const labelProps = useSpring({
        color: revisionNeeded ? Palette.red500 : Palette.gray800,
    });

    const dropdownProps = useSpring({
        opacity: selecting ? 1 : 0,
        pointerEvents: (selecting ? "auto" : "none") as CSSPointerEventsType,
    });

    const onItemClick = (type?: PrescriptionExtraRemarksType) => () => {
        onValueChange(type);
        setSelecting(false);
    };

    return <LayoutContainer ref={buttonRef} style={layoutProps} title={label(value)}>
        <ExtraRemarksLabelContainer
            style={{
                ...labelFadeInProps,
                ...labelProps,
            }}
        >
            {label(value)}
        </ExtraRemarksLabelContainer>
        <IconPositionContainer>
            <SVG
                asset={SVGAssets.Down}
                width={"20px"}
                height={"20px"}
                color={Palette.gray800}
            />
        </IconPositionContainer>
        <DropdownContainer ref={dropdownRef} style={dropdownProps}>
            {types.map((t, index) =>
                <DropdownButtonItem
                    key={index}
                    selected={t === value}
                    heightInPx={36}
                    label={label(t)}
                    onClick={onItemClick(t)}
                />
            )}
        </DropdownContainer>
    </LayoutContainer>;
};

export default ShootPrescriptionExtraRemarksField;
