import PrescriptionBaseField from "domain/model/prescription/prescription_base_field";
import BBox from "domain/model/common/bbox";
import {PrescriptionFieldRevisionStatusMap} from "domain/model/prescription/prescription_field_revision_status";

type PrescriptionDrugField = {
    value?: string;
    bbox?: BBox;
    originalConfidenceLow: boolean;
} & PrescriptionBaseField;

export const PrescriptionDrugFieldMap = {
    valueEmptyOrConfidenceLow: (d: PrescriptionDrugField): boolean => {
        return !d.value || PrescriptionFieldRevisionStatusMap.revisionNeeded(d.revisionStatus);
    },
};

export default PrescriptionDrugField;