import styled from "styled-components";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import ToggleSwitch from "presentation/components/button/toggle_switch";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    cursor: pointer;
`;

const TextLayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
`;

const TitleContainer = styled.h6`
    ${Fonts.body2};
`;

const DescriptionContainer = styled.p`
    ${Fonts.body1Medium};
    color: ${Palette.gray500};
    white-space: pre-wrap;
    word-break: keep-all;
`;

const ShootUserPreferencesButton = ({
                                        toggleOn,
                                        title,
                                        description,
                                        onClick,
                                    }: {
    toggleOn: boolean;
    title: string;
    description: string;
    onClick: () => void;
}) => {
    return <LayoutContainer onClick={onClick}>
        <TextLayoutContainer>
            <TitleContainer>{title}</TitleContainer>
            <DescriptionContainer>{description}</DescriptionContainer>
        </TextLayoutContainer>
        <ToggleSwitch on={toggleOn}/>
    </LayoutContainer>;
};

export default ShootUserPreferencesButton;
