import {animated, useSpring} from "@react-spring/web";
import UserRepository from "data/repository/user_repository";
import ErrorCode from "domain/model/common/error_code";
import FilledButton from "presentation/components/button/filled_button";
import {TextField} from "presentation/components/text_field/text_field";
import AppRouter from "presentation/routes/app_router";
import loginState from "presentation/states/login/login_state";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import S from "presentation/theme/s";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";
import useReadRecoilState from "presentation/utils/hooks/use_read_recoil_state";
import useRepository from "presentation/utils/hooks/use_repository";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import CSSPointerEventsType from "presentation/utils/types/css/pointer_events_type";
import {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";
import AppRoute from "presentation/routes/model/app_route";
import LoginConstants from "presentation/states/login/constants/login_constants";

const LayoutContainer = styled(animated.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`;

const ErrorMessageLayoutContainer = styled.div`
    width: 100%;
    min-height: 32px;
    padding: 4px 16px 0 16px;
`;

const ErrorMessageLabelContainer = styled(animated.p)`
    min-width: max-content;
    ${Fonts.detail2Medium};
    color: ${Palette.red500};
`;

const LoginPasswordView = () => {
    const repository = useRepository(UserRepository);
    const setLoading = useSetLoading();

    const {passwordInputRequested} = useRecoilValue(loginState);
    const readState = useReadRecoilState(loginState);

    const [errorMessage, setErrorMessage] = useState("");
    const [password, setPassword] = useState("");
    const [focused, setFocused] = useState(false);

    const [props, api] = useSpring(() => ({
        opacity: passwordInputRequested ? 1 : 0,
        pointerEvents: (passwordInputRequested
            ? "auto"
            : "none") as CSSPointerEventsType,
    }));
    const {props: errorMessageProps} = useFadeInTransition(errorMessage);

    useEffect(() => {
        if (passwordInputRequested) {
            addPostFrameCallback(() => {
                setFocused(true);
                api.start({
                    opacity: 1,
                    pointerEvents: "auto" as CSSPointerEventsType,
                });
            });
            return;
        }

        api.set({
            opacity: passwordInputRequested ? 1 : 0,
            pointerEvents: (passwordInputRequested
                ? "auto"
                : "none") as CSSPointerEventsType,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passwordInputRequested]);

    useEffect(() => {
        setErrorMessage("");
    }, [password]);

    const enabled = !!password.length;

    const onClick = () => repository({
        handler: async (repository) => {
            if (!password.length) return;

            setLoading(true);

            const state = await readState();

            await repository.login({
                userID: state.userID,
                password,
            });

            setFocused(false);

            await AppRouter.navigate(AppRoute.Main, {
                replace: true,
            });
        },
        onDomainError: (errorCode, showUnknownError) => {
            switch (errorCode) {
                case ErrorCode.InvalidPassword:
                    setErrorMessage(S.loginPage.invalidPasswordErrorMessage);
                    setFocused(true);
                    break;

                default:
                    showUnknownError();
                    break;
            }
        },
        onFinally: () => setLoading(false),
    });

    return (
        <LayoutContainer style={props}>
            <TextField
                error={!!errorMessage}
                secure={true}
                value={password}
                onValueChange={setPassword}
                focused={focused}
                onFocusChange={setFocused}
                placeholder={S.loginPage.passwordInputPlaceholder}
                maxLength={LoginConstants.maxPasswordLength}
                inputFormatter={InputFormatters.decimalOnlyFormatter}
                onEnterPress={onClick}
            />
            <ErrorMessageLayoutContainer>
                <ErrorMessageLabelContainer style={errorMessageProps}>
                    {errorMessage}
                </ErrorMessageLabelContainer>
            </ErrorMessageLayoutContainer>
            <FilledButton
                enabled={enabled && !errorMessage}
                width={"100%"}
                label={S.loginPage.loginButtonLoginLabel}
                onClick={onClick}
            />
        </LayoutContainer>
    );
};

export default LoginPasswordView;
