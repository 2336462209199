import DevLogType from "domain/model/common/dev_log_type";
import {EnvironmentConstants} from "config/environment_constants";

const devLog = ({
                    logType,
                    message,
                    format = true,
                }: {
    logType: DevLogType;
    message: any;
    format?: boolean;
}) => {
    if (!EnvironmentConstants.isDevelopment) return;

    if (!format) {
        console.log(message);
        return;
    }

    const logMessage = `${logType}: ${JSON.stringify(message, null, 2)}`;

    console.log(logMessage);
};

export default devLog;
