import styled from "styled-components";
import Loading from "presentation/components/common/loading";
import useLifeCycle from "presentation/utils/hooks/use_life_cycle";
import useSearchParams from "presentation/utils/hooks/use_search_params";
import AppRouter from "presentation/routes/app_router";
import AppRoute from "presentation/routes/model/app_route";
import useRepository from "presentation/utils/hooks/use_repository";
import UserRepository from "data/repository/user_repository";
import useShowNotificationDialog from "presentation/utils/hooks/use_show_notification_dialog";
import ErrorCode from "domain/model/common/error_code";
import S from "presentation/theme/s";

const LayoutContainer = styled.div`
    width: 100%;
    min-width: 18.75rem;
    min-height: 100vh;
    position: relative;
`;

const AutoLoginPage = () => {
    const searchParams = useSearchParams();
    const repository = useRepository(UserRepository);
    const showNotificationDialog = useShowNotificationDialog();

    useLifeCycle({
        onInit: () => {
            const userID = searchParams("user");
            const password = searchParams("pw");

            if (!userID || !password) {
                AppRouter.navigate(AppRoute.Login, {
                    replace: true,
                });
                return;
            }

            repository({
                handler: async (repository) => {
                    await repository.login({userID, password});

                    AppRouter.navigate(AppRoute.Main, {
                        replace: true,
                    });
                },
                onDomainError: (errorCode, showUnknownError) => {
                    switch (errorCode) {
                        case ErrorCode.InvalidPassword:
                        case ErrorCode.InvalidUser:
                            showNotificationDialog({
                                className: "login-error-dialog",
                                type: "negative",
                                title: S.autoLoginPage.loginErrorDialog.title,
                                message: S.autoLoginPage.loginErrorDialog.message,
                                hasNegativeButton: false,
                                onPositiveButtonClick: () => AppRouter.navigate(AppRoute.Login, {
                                    replace: true,
                                }),
                            });
                            break;

                        default:
                            showUnknownError();
                            break;
                    }
                },
            });
        },
    });

    return <LayoutContainer>
        <Loading visible={true}/>
    </LayoutContainer>;
};

export default AutoLoginPage;
