import React from "react";
import {createGlobalStyle} from "styled-components";
import {FontsConfig} from "presentation/theme/fonts";

export type AnimationTheme = {
    defaultSpeedMS: number;
    loadingDelayMS: number;
};

export type FontTheme = {
    fonts: FontsConfig;
}

export type ComponentTheme = {
    lnbMinWidthInPx: number;
    lnbMaxWidthInPx: number;
    tnbHeight: string;
    maxPageWidthInRem: string;
    pageVerticalPaddingInPx: number;
    pageHorizontalPaddingInPx: number;
};

const GlobalThemeContainer = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
    }

    * {
        margin: 0;
        padding: 0;
        font-family: 'Pretendard', serif;
    }

    body {
        overflow-x: clip;
        overflow-y: auto;
    }
`;

const GlobalTheme = () => {
    return <GlobalThemeContainer/>;
};

export default GlobalTheme;
