import secureLocalStorage from "react-secure-storage";
import {EnvironmentConstants} from "config/environment_constants";
import UserPreferences from "domain/model/user/user_preferences";

export class SecureStorage {
    private storage = secureLocalStorage;

    private certEnteredKey = EnvironmentConstants.secureStorageKeys.certEnteredKey;
    private guideShownKey = EnvironmentConstants.secureStorageKeys.guideShownKey;
    private hospitalKey = EnvironmentConstants.secureStorageKeys.hospitalKey;
    private userPreferencesKey = EnvironmentConstants.secureStorageKeys.userPreferencesKey;

    isAuthenticated = (): boolean => {
        return !!this.getHospital();
    };

    setHospital = (hospital: string) => {
        const serializedString = JSON.stringify(hospital);
        this.storage.setItem(this.hospitalKey, serializedString);
    };

    getHospital = (): optional<string> => {
        try {
            const serializedString = this.storage.getItem(this.hospitalKey) as nullable<string>;

            if (!serializedString) return undefined;

            return JSON.parse(serializedString);
        } catch {
            return undefined;
        }
    };

    deleteHospital = () => {
        this.storage.removeItem(this.hospitalKey);
    };

    getCertEntered = (): boolean => {
        try {
            const serializedString = this.storage.getItem(this.certEnteredKey) as nullable<string>;
            
            if (!serializedString) return true;

            return JSON.parse(serializedString);
        } catch {
            return true;
        }
    };

    setCertEntered = ({certEntered}: { certEntered: boolean; }) => {
        const serializedString = JSON.stringify(certEntered);
        this.storage.setItem(this.certEnteredKey, serializedString);
    };

    saveUserPreferences = (userPreferences: UserPreferences) => {
        const serializedString = JSON.stringify(userPreferences);
        this.storage.setItem(this.userPreferencesKey, serializedString);
    };

    getUserPreferences = (): optional<UserPreferences> => {
        try {
            const serializedString = this.storage.getItem(this.userPreferencesKey) as nullable<string>;

            if (!serializedString) return undefined;

            return JSON.parse(serializedString);
        } catch {
            return undefined;
        }
    };

    setGuideShown = () => {
        const serializedString = JSON.stringify(true);
        this.storage.setItem(this.guideShownKey, serializedString);
    };

    getGuideShown = (): boolean => {
        try {
            const serializedString = this.storage.getItem(this.guideShownKey) as nullable<string>;

            if (!serializedString) return false;

            return JSON.parse(serializedString);
        } catch {
            return false;
        }
    };
}

const secureStorageInstance = new SecureStorage();

export default secureStorageInstance;
