import LNB, {LNBContainer} from "presentation/components/navigation/lnb/lnb";
import {
    LNBItemType,
    LNBItemTypeMap, LNBItemTypes,
} from "presentation/components/navigation/lnb/lnb_model";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import {createContext, Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import styled from "styled-components";
import Palette from "presentation/theme/palette";
import {animated, useSpring} from "@react-spring/web";
import useMobileQuery from "presentation/utils/hooks/use_mobile_query";
import useWindowResizeObserver from "presentation/utils/hooks/use_window_resize_observer";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import useRepository from "presentation/utils/hooks/use_repository";
import UserRepository from "data/repository/user_repository";
import UserPreferences, {UserPreferencesMap} from "domain/model/user/user_preferences";

export const MainPageContext = createContext<{
    cameraDeviceID: optional<string>;
    userPreferences: UserPreferences;
    setCameraDeviceID: Dispatch<SetStateAction<optional<string>>>;
    setOnShootButtonClick: Dispatch<SetStateAction<optional<() => void>>>;
    setUserPreferences: Dispatch<SetStateAction<UserPreferences>>;
}>({
    cameraDeviceID: undefined,
    userPreferences: UserPreferencesMap.empty(),
    setCameraDeviceID: () => {
    },
    setOnShootButtonClick: () => {
    },
    setUserPreferences: () => {
    },
});

const RowContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: ${Palette.gray100};
`;

const ColumnContainer = styled(animated.div)`
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const OutletContainer = styled.div`
    flex-grow: 1;
    min-height: max-content;
`;

const MainPage = () => {
    const theme = useThemeContext();
    const {pathname} = useLocation();
    const isMobile = useMobileQuery();
    const repository = useRepository(UserRepository);

    const outletRef = useRef<HTMLDivElement>(null);
    const [selectedItem, setSelectedItem] = useState<optional<LNBItemType>>(undefined);
    const [cameraDeviceID, setCameraDeviceID] = useState<optional<string>>(undefined);
    const [onShootButtonClick, setOnShootButtonClick] = useState<optional<() => void>>(undefined);
    const [userPreferencesLoaded, setUserPreferencesLoaded] = useState(false);
    const [userPreferences, setUserPreferences] = useState<UserPreferences>(UserPreferencesMap.empty());

    useLayoutEffect(() => {
        repository({
            handler: async (repository) => {
                const preferences = await repository.getUserPreferences();
                setUserPreferences(preferences);
                setUserPreferencesLoaded(true);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!userPreferencesLoaded) return;

        repository({
            handler: async (repository) => {
                if (!userPreferences) return;

                await repository.saveUserPreferences({userPreferences});
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPreferences]);

    useLayoutEffect(() => {
        const item = LNBItemTypes.find((item) => {
            const endpoint = LNBItemTypeMap.endpoint(item);

            return endpoint && pathname.includes(endpoint);
        });
        setSelectedItem(item);

        if (item !== LNBItemType.Shoot) {
            setCameraDeviceID(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const lnbWidthInPx = isMobile ? theme.componentTheme.lnbMinWidthInPx : theme.componentTheme.lnbMaxWidthInPx;

    const lnbProps = useSpring({
        minWidth: lnbWidthInPx,
    });

    const {widthInPx: outletWidthInPx} = useResizeObserver(outletRef, [], 0);
    const {innerWidthInPx} = useWindowResizeObserver([], 0);

    const columnWidthInPx = innerWidthInPx - lnbWidthInPx;
    const overflow = columnWidthInPx < outletWidthInPx;

    const columnProps = useSpring({
        maxWidth: overflow ? outletWidthInPx : columnWidthInPx,
        alignItems: (overflow ? "flex-start" : "center") as "flex-start" | "center",
        overflowX: (overflow ? "scroll" : "clip") as "scroll" | "clip",
        immediate: true,
    });

    const shootButtonEnabled = !!cameraDeviceID && selectedItem === LNBItemType.Shoot;

    return (
        <MainPageContext.Provider value={{
            cameraDeviceID,
            userPreferences,
            setCameraDeviceID,
            setOnShootButtonClick,
            setUserPreferences,
        }}>
            <RowContainer>
                <LNB
                    shootButtonEnabled={shootButtonEnabled}
                    selectedItem={selectedItem}
                    onShootButtonClick={onShootButtonClick}
                />
                <LNBContainer style={lnbProps}/>
                <ColumnContainer style={columnProps}>
                    <OutletContainer ref={outletRef}>
                        <Outlet/>
                    </OutletContainer>
                </ColumnContainer>
            </RowContainer>
        </MainPageContext.Provider>
    );
};

export default MainPage;
