import styled from "styled-components";
import {animated} from "@react-spring/web";
import useAnimatedMount from "presentation/utils/hooks/use_animated_mount";
import S from "presentation/theme/s";
import {JSONAssets} from "presentation/theme/assets";
import {useMemo} from "react";
import Lottie from "react-lottie";
import Fonts from "presentation/theme/fonts";

const LayoutContainer = styled(animated.div)`
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
`;

const MessageContainer = styled.p`
    min-width: max-content;
    ${Fonts.body2};
    text-align: center;
`;

const ShootLoadingView = () => {
    const {props} = useAnimatedMount();

    const options = useMemo(() => ({
        animationData: JSONAssets.CameraLoading,
        loop: true,
    }), []);

    return <LayoutContainer style={props}>
        <Lottie
            width={100}
            height={100}
            options={options}
            isClickToPauseDisabled={true}
        />
        <MessageContainer>
            {S.shootPage.shootLoadingView.message}
        </MessageContainer>
    </LayoutContainer>;
};

export default ShootLoadingView;
