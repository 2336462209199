import {useEffect, useRef} from "react";
import addPostFrameCallback from "presentation/utils/functions/add_post_frame_callback";

const useRenderedRef = () => {
    const ref = useRef(false);

    useEffect(() => {
        addPostFrameCallback(() => (ref.current = true));
    }, []);

    return ref;
};

export default useRenderedRef;