import InputFormatter, {TextEditingValue} from "presentation/utils/input_formatters/input_formatter";
import StringHelper from "config/helper/string_helper";

export default class SSNFormatter extends InputFormatter {
    maxLength = 14;

    formatEditUpdate(oldValue: TextEditingValue, newValue: TextEditingValue): TextEditingValue {
        let value = {
            ...newValue,
            text: newValue.text.replaceAll(/[!@#$%^&()=_+]\[{}\\|'";:?\/><.,]/g, "*"),
        };
        if (this.isEraseOperation(oldValue, newValue)) {
            const deleteCharacterIndex = value.selection.start;

            if (0 <= deleteCharacterIndex && oldValue.text.at(deleteCharacterIndex) === "-") {
                const extraDeleteCharacterIndex = deleteCharacterIndex - 1;
                const extraDeletedText = StringHelper.removeAtOrUndefined(value.text, extraDeleteCharacterIndex);

                if (extraDeletedText !== undefined) {
                    value = {
                        text: extraDeletedText,
                        selection: {
                            start: extraDeleteCharacterIndex,
                            end: extraDeleteCharacterIndex,
                        }
                    }
                }
            }
        }

        const newText = value.text;
        if (!!newText.length &&
            (!this.hasOnlyDigitsAndHyphensAndAsterisks(newText) ||
                this.doesDigitsExceedMaxLength(newText) ||
                this.getNumberOfHyphens(newText) > 1)) {
            return oldValue;
        }

        if (newText.length === 6) {
            return {
                text: newText + "-",
                selection: {
                    start: 7,
                    end: 7,
                },
            };
        }

        const tokenLengthLimits = [6, 13];
        const tokens = ["", ""];
        const strippedText = newText.replace("-", "");

        for (let i = 0, limitIndex = 0; i < strippedText.length; i++) {
            for (; tokenLengthLimits[limitIndex] <= i; limitIndex++) {
            }
            tokens[limitIndex] += strippedText[i];
        }

        let selection = newValue.selection;
        let resultText = tokens.filter((e) => !!e.length).join("-");

        const hyphenCountDiff = this.getNumberOfHyphens(resultText) - this.getNumberOfHyphens(newText);

        const cursorPosition = selection.start;
        if (hyphenCountDiff > 0) {
            if (cursorPosition === resultText.length - 1) {
                selection = {
                    start: cursorPosition + 1,
                    end: cursorPosition + 1,
                };
            }
        } else if (hyphenCountDiff < 0 && cursorPosition === oldValue.text.length - 1) {
            selection = {
                start: cursorPosition - 1,
                end: cursorPosition - 1,
            };
        }

        if (this.isAddOperation(oldValue, value)) {
            if (resultText.at(cursorPosition - 1) === "-") {
                selection = {
                    start: cursorPosition + 1,
                    end: cursorPosition + 1,
                };
            }

            if (resultText.at(cursorPosition) === "-") {
                selection = {
                    start: cursorPosition + 1,
                    end: cursorPosition + 1,
                };
            }
        } else {
            if (resultText.at(cursorPosition - 1) === "-") {
                selection = {
                    start: cursorPosition - 1,
                    end: cursorPosition - 1,
                };
            }
        }

        return {
            text: resultText,
            selection,
        };
    }

    private getNumberOfHyphens(text: string): number {
        return text.match(/-/g)?.length ?? 0;
    }

    private doesDigitsExceedMaxLength(newText: string): boolean {
        const digits = newText.replaceAll("-", "");
        return digits.length > this.maxLength - 1;
    }

    private hasOnlyDigitsAndHyphensAndAsterisks(newText: string): boolean {
        return /^(([\d*]+)(-?)([\d*]*))*$/g.test(newText);
    }
}