import styled from "styled-components";
import Dialog from "presentation/components/dialog/dialog";
import DialogCloseButton from "presentation/components/button/dialog_close_button";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";
import Spacer from "presentation/components/common/spacer";
import {Fragment, useEffect, useMemo, useState} from "react";
import UserGuideHeaderNumber
    from "presentation/components/dialog/user_guide_dialog/components/user_guide_header_number";
import UserGuideHeaderNumberDivider
    from "presentation/components/dialog/user_guide_dialog/components/user_guide_header_number_divider";
import SizedBox from "presentation/components/common/sized_box";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import FilledButton from "presentation/components/button/filled_button";
import {animated, useSpring, useTransition} from "@react-spring/web";
import CSSPositionType from "presentation/utils/types/css/position_type";
import {EnvironmentConstants} from "config/environment_constants";

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const BodyLayoutContainer = styled(animated.div)`
    width: 100%;
    height: max-content;
    position: relative;
`;

const ContentContainer = styled(animated.div)`
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    overflow: hidden;
`;

const MessageContainer = styled.p`
    height: max-content;
    ${Fonts.body1Medium};
    text-align: center;
    white-space: pre-wrap;
`;

const VideoContainer = styled.video`
    height: 300px;
`;

const ButtonLayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const UserGuideDialog = () => {
    const dismissDialog = useDismissDialog();
    const content = useMemo(() => [
        {
            fileKey: "resources/guide_video_1.mp4",
            message: S.userGuideDialog.messageOne,
        },
        {
            fileKey: "resources/guide_video_2.mp4",
            message: S.userGuideDialog.messageTwo,
        },
        {
            fileKey: "resources/guide_video_3.mp4",
            message: S.userGuideDialog.messageThree
        },
    ].map((e) => ({
        url: EnvironmentConstants.bucketBaseURL + e.fileKey,
        message: e.message,
    })), []);

    const refMap = useMemo(() => ({}) as Record<number, HTMLDivElement>, []);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [bodyLayoutProps, bodyLayoutAPI] = useSpring(() => ({
        height: refMap[selectedIndex]?.offsetHeight,
    }));

    useEffect(() => {
        const height = refMap[selectedIndex]?.offsetHeight;
        if (!height) return;

        bodyLayoutAPI.start({
            height,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex]);

    const transition = useTransition(selectedIndex, {
        initial: {
            opacity: 0,
            translateX: "0%",
            position: "relative" as CSSPositionType,
            zIndex: 1,
        },
        from: {
            opacity: 0,
            translateX: "100%",
            position: "relative" as CSSPositionType,
            zIndex: 1,
        },
        enter: {
            opacity: 1,
            translateX: "0%",
            position: "relative" as CSSPositionType,
            zIndex: 1,
        },
        leave: {
            opacity: 0,
            translateX: "-100%",
            position: "absolute" as CSSPositionType,
            zIndex: 0,
        },
    });

    const onDismissButtonClick = () => dismissDialog();
    const onNextButtonClick = () => setSelectedIndex((prev) => (prev + 1) % content.length);

    const assignRef = (ref: nullable<HTMLDivElement>) => {
        if (!ref) return;
        refMap[selectedIndex] = ref;
    }

    return <Dialog>
        <HeaderContainer>
            <Spacer/>
            {
                content.map((_, index) =>
                    <Fragment key={index}>
                        <UserGuideHeaderNumber
                            selected={index === selectedIndex}
                            number={index + 1}
                        />
                        {(index !== content.length - 1) && <UserGuideHeaderNumberDivider/>}
                    </Fragment>
                )
            }
            <Spacer/>
            <DialogCloseButton onClick={onDismissButtonClick}/>
        </HeaderContainer>
        <SizedBox height={"24px"}/>
        <BodyLayoutContainer style={bodyLayoutProps}>
            {transition((style, selectedIndex) => (
                <ContentContainer ref={assignRef} style={style}>
                    <MessageContainer>
                        {content[selectedIndex].message}
                    </MessageContainer>
                    <VideoContainer
                        src={content[selectedIndex].url}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                    />
                </ContentContainer>
            ))}
        </BodyLayoutContainer>
        <SizedBox height={"24px"}/>
        <ButtonLayoutContainer>
            <FilledButton
                sizeType={"medium"}
                label={selectedIndex === content.length - 1 ? S.userGuideDialog.completeButtonLabel : S.userGuideDialog.nextButtonLabel}
                onClick={selectedIndex === content.length - 1 ? onDismissButtonClick : onNextButtonClick}
            />
        </ButtonLayoutContainer>
    </Dialog>;
};

export default UserGuideDialog;
