import {selector} from "recoil";
import shootState from "presentation/states/shoot/shoot_state";

const shootPrescriptionsSelector = selector({
    key: "shootPrescriptionsSelector",
    get: ({get}) => {
        const state = get(shootState);

        return {
            prescriptions: state.prescriptions,
            selectedPrescriptionID: state.selectedPrescriptionID!,
            prescription: state.prescriptions.find((p) => p.id === state.selectedPrescriptionID)!,
        };
    },
});

export default shootPrescriptionsSelector;