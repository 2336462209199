import Palette from "presentation/theme/palette";
import {Children, PropsWithChildren} from "react";
import styled from "styled-components";

const LayoutContainer = styled.div.attrs<{
    $width: string;
    $height: string;
    $rimColor: string;
}>((props) => ({
    style: {
        width: props.$width,
        height: props.$height,
        boxShadow: `0 0 0 2px ${props.$rimColor} inset`,
    },
}))`
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
        box-shadow 0.3s ease-in-out;
`;

const IconRim = ({
                     iconWidth,
                     iconHeight,
                     color = Palette.black8,
                     children,
                 }: PropsWithChildren<{
    iconWidth: string;
    iconHeight: string;
    color?: string;
}>) => {
    const width = `calc(${iconWidth} + 12px)`;
    const height = `calc(${iconHeight} + 12px)`;

    return (
        <LayoutContainer $width={width} $height={height} $rimColor={color}>
            {Children.only(children)}
        </LayoutContainer>
    );
};

export default IconRim;
