import styled from "styled-components";
import DateTime from "presentation/utils/class/date_time";
import {createContext, useMemo, useState} from "react";
import {animated, useTransition} from "@react-spring/web";
import CalendarMonthHeader from "presentation/components/calendar/common/calendar_month_header";
import SizedBox from "presentation/components/common/sized_box";
import DatePickerCalendarMonth
    from "presentation/components/calendar/date_picker_calendar/components/date_picker_calendar_month";

type YearMonthState = {
    value: DateTime;
    direction: number;
};

const constants = {
    monthHeaderHeightInPx: 36,
    monthHeaderToCalendarGapInPx: 24,
    weekdayHeaderHeightInPx: 20,
    calendarRowVerticalGapInPx: 5,
    calendarCellWidthInPx: 68,
    calendarCellHeightInPx: 68,
    calendarCellValueWidthInPx: 40,
    calendarCellValueHeightInPx: 40,
};

export const DatePickerCalendarContext = createContext<{
    constants: typeof constants;
    startDate: DateTime;
    endDate: DateTime;
    selectedDate?: DateTime;
    selectedDateSuffixLabel?: string;
    onDateClick: (date: DateTime) => void;
}>({
    constants,
    startDate: DateTime.now(),
    endDate: DateTime.now(),
    selectedDate: undefined,
    selectedDateSuffixLabel: undefined,
    onDateClick: () => {
    },
});

const LayoutContainer = styled.div.attrs<{ $heightInPx: number }>((props) => ({
    style: {
        height: `${props.$heightInPx}px`,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: height 0.3s ease-in-out;
`;

const CalendarLayoutContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
`;

const CalendarContainer = styled(animated.div)`
    width: 100%;
    height: max-content;
    position: absolute;
    top: 0;
    left: 0;
`;

const DatePickerCalendar = ({
                                startDate,
                                endDate,
                                selectedDate,
                                selectedDateSuffixLabel,
                                onDateClick,
                            }: {
    startDate: DateTime;
    endDate: DateTime;
    selectedDate?: DateTime;
    selectedDateSuffixLabel?: string;
    onDateClick: (date: DateTime) => void;
}) => {
    const [yearMonth, setYearMonth] = useState<YearMonthState>({
        value: selectedDate?.getFirstDayInMonth() ?? DateTime.now().getFirstDayInMonth(),
        direction: 0,
    });

    const {heightInPx} = useMemo(
        () => {
            const weekdays = DateTime.orthodoxWeekdays;
            const weeks = yearMonth.value.getOrthodoxWeeksInMonth();

            const heightInPx =
                constants.monthHeaderHeightInPx +
                constants.monthHeaderToCalendarGapInPx +
                constants.weekdayHeaderHeightInPx +
                (constants.calendarRowVerticalGapInPx +
                    constants.calendarCellHeightInPx) *
                weeks.length;

            return {heightInPx, weekdays, weeks};
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [constants, yearMonth]
    );

    const transitions = useTransition(yearMonth, {
        from: {
            translateX: `${100 * yearMonth.direction}%`,
            zIndex: 10,
            opacity: 1,
        },
        enter: {
            translateX: "0%",
            zIndex: 10,
            opacity: 1,
        },
        leave: {
            translateX: `${-100 * yearMonth.direction}%`,
            zIndex: 9,
            opacity: 0,
        },
    });

    const onYearMonthChange = (yearMonth: DateTime, direction: number) => setYearMonth({
        value: yearMonth,
        direction,
    });

    const monthHeaderToCalendarGap = `${constants.monthHeaderToCalendarGapInPx}px`;

    return <DatePickerCalendarContext.Provider value={{
        constants,
        startDate,
        endDate,
        selectedDate,
        selectedDateSuffixLabel,
        onDateClick,
    }}>
        <LayoutContainer $heightInPx={heightInPx}>
            <CalendarMonthHeader
                calendarStartDate={startDate}
                calendarEndDate={endDate}
                yearMonth={yearMonth.value}
                onYearMonthChange={onYearMonthChange}
            />
            <SizedBox height={monthHeaderToCalendarGap}/>
            <CalendarLayoutContainer>
                {transitions((props, month) => (
                    <CalendarContainer style={props}>
                        <DatePickerCalendarMonth month={month.value}/>
                    </CalendarContainer>
                ))}
            </CalendarLayoutContainer>
        </LayoutContainer>
    </DatePickerCalendarContext.Provider>;
};

export default DatePickerCalendar;
