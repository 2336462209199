import styled from "styled-components";
import Palette from "presentation/theme/palette";
import SizedBox from "presentation/components/common/sized_box";
import ShootPrescriptionInsuranceView
    from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_insurance_view";
import ShootPrescriptionIssueNumberDateView
    from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_issue_number_date_view";
import ShootPrescriptionPatientNameSSNView
    from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_patient_name_ssn_view";
import ShootPrescriptionNursingAgencyDoctorLicenseView
    from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_nursing_agency_doctor_license_view";
import ShootPrescriptionSelfPayCodeExtraRemarksView
    from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_self_pay_code_extra_remarks_view";
import ShootPrescriptionDiseaseCodesView
    from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_disease_codes_view";
import ShootPrescriptionRevisionBannerView
    from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_revision_banner_view";
import ShootPrescriptionInfoRevisionLabelView
    from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_info_revision_label_view";
import ShootPrescriptionMedicationsView
    from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_medications_view";
import ShootPrescriptionMedicationsRevisionLabelView
    from "presentation/pages/shoot/view/shoot_prescription/shoot_prescription_medications_revision_label_view";

const LayoutContainer = styled.div`
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    background-color: ${Palette.white100};
`;

const ShootPrescriptionView = () => {
    return <LayoutContainer>
        <ShootPrescriptionRevisionBannerView/>
        <ShootPrescriptionInsuranceView/>
        <SizedBox height={"16px"}/>
        <ShootPrescriptionIssueNumberDateView/>
        <ShootPrescriptionPatientNameSSNView/>
        <ShootPrescriptionNursingAgencyDoctorLicenseView/>
        <ShootPrescriptionDiseaseCodesView/>
        <ShootPrescriptionSelfPayCodeExtraRemarksView/>
        <ShootPrescriptionInfoRevisionLabelView/>
        <SizedBox height={"16px"}/>
        <ShootPrescriptionMedicationsView/>
        <ShootPrescriptionMedicationsRevisionLabelView/>
    </LayoutContainer>;
};

export default ShootPrescriptionView;
