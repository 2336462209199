
export default class Debouncer {
    timeout?: NodeJS.Timeout;

    cancel = () => {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    };

    run = (fn: Function, delay: number) => {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            fn();
        }, delay);
    };

    runImmediately = (fn: Function) => {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        fn();
    };
}
