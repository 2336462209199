import styled from "styled-components";
import Dialog from "presentation/components/dialog/dialog";
import SVGAssets from "presentation/theme/assets";
import S from "presentation/theme/s";
import RadioButton from "presentation/components/button/radio_button";
import PrescriptionDeductibleRateType, {
    PrescriptionDeductibleRateTypeMap,
    PrescriptionDeductibleRateTypes
} from "domain/model/prescription/prescription_deductible_rate_type";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
`;

const SelectDeductibleRateDialog = ({
                                        selectedDeductibleRate,
                                        onDeductibleRateChange,
                                    }: {
    selectedDeductibleRate: PrescriptionDeductibleRateType;
    onDeductibleRateChange: (rate: PrescriptionDeductibleRateType) => void;
}) => {
    const dismissDialog = useDismissDialog();

    const onRadioButtonClick = (rate: PrescriptionDeductibleRateType) => () => {
        dismissDialog();
        onDeductibleRateChange(rate);
    };

    return <Dialog
        widthInPx={446}
        iconAsset={SVGAssets.AlertOctagon}
        title={S.selectDeductibleRateDialog.title}
    >
        <LayoutContainer>
            {
                PrescriptionDeductibleRateTypes.map(
                    (t) => <RadioButton
                        key={t}
                        selected={t === selectedDeductibleRate}
                        label={PrescriptionDeductibleRateTypeMap.label(t)}
                        onClick={onRadioButtonClick(t)}
                    />
                )
            }
        </LayoutContainer>
    </Dialog>;
};

export default SelectDeductibleRateDialog;
