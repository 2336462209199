import S from "presentation/theme/s";
import {StaticSVGAssets, SVGAsset} from "presentation/theme/assets";

enum CertType {
    Kakao = "KAKAO",
    SMS = "SMS",
}

export const CertTypes = Object.values(CertType);

export const CertTypeMap = {
    label: (type: CertType): string => {
        switch (type) {
            case CertType.Kakao:
                return S.certType.kakaoLabel;
            case CertType.SMS:
                return S.certType.smsLabel;
            default:
                throw new Error("Invalid cert type");
        }
    },
    iconAsset: (type: CertType): SVGAsset => {
        switch (type) {
            case CertType.Kakao:
                return StaticSVGAssets.ImageKakaoLogo;
            case CertType.SMS:
                return StaticSVGAssets.ImageTextMessage;
            default:
                throw new Error("Invalid cert type");
        }
    },
};

export default CertType;