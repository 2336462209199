import S from "presentation/theme/s";

enum InsuranceType {
    Health = "의료보험",
    MedicalCare = "의료급여",
    Industrial = "산재보험",
    Car = "자동차보험",
    Others = "기타",
}

export const InsuranceTypes = Object.values(InsuranceType);

export const InsuranceTypeMap = {
    isFullyDeductible: (type: InsuranceType): boolean => {
        return type === InsuranceType.Health || type === InsuranceType.MedicalCare || type === InsuranceType.Industrial;
    },
    isNondeductible: (type: InsuranceType): boolean => {
        return type === InsuranceType.Car || type === InsuranceType.Others;
    },
    label: (type: InsuranceType): string => {
        switch (type) {
            case InsuranceType.Health:
                return S.insuranceType.healthLabel;
            case InsuranceType.MedicalCare:
                return S.insuranceType.medicalCareLabel;
            case InsuranceType.Industrial:
                return S.insuranceType.industrialLabel;
            case InsuranceType.Car:
                return S.insuranceType.carLabel;
            case InsuranceType.Others:
                return S.insuranceType.othersLabel;
            default:
                throw new Error("Invalid InsuranceType");
        }
    },
    needFullSSN: (type: InsuranceType): boolean => {
        switch (type) {
            case InsuranceType.Health:
            case InsuranceType.MedicalCare:
            case InsuranceType.Industrial:
                return true;

            case InsuranceType.Others:
            case InsuranceType.Car:
                return false;

            default:
                throw new Error('Invalid InsuranceType');
        }
    },
};

export default InsuranceType;