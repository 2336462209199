import styled from "styled-components";
import PageTitle from "presentation/components/common/page_title";
import S from "presentation/theme/s";
import {PNGAssets} from "presentation/theme/assets";
import SizedBox from "presentation/components/common/sized_box";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import FilledButton from "presentation/components/button/filled_button";
import {FormEvent, useEffect, useState} from "react";
import PageLayout from "presentation/components/common/page_layout";
import useRepository from "presentation/utils/hooks/use_repository";
import CertRepository from "data/repository/cert_repository";
import useReadRecoilState from "presentation/utils/hooks/use_read_recoil_state";
import certState, {CertKakaoVerificationState} from "presentation/states/cert/cert_state";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import ErrorCode from "domain/model/common/error_code";
import useShowErrorDialog from "presentation/utils/hooks/use_show_error_dialog";
import {useSetRecoilState} from "recoil";
import AppRouter from "presentation/routes/app_router";
import {PrescriptionHistoryPageArgs} from "presentation/pages/prescription_history/prescription_history_page";
import AppRoute from "presentation/routes/model/app_route";

const LayoutContainer = styled.form`
    width: 100%;
    min-height: max(600px, 90vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;

const ImageContainer = styled.img`
    width: 90%;
    object-fit: contain;
`;

const SpacerContainer = styled.div.attrs<{ $flex: number }>((props) => ({
    style: {
        flex: props.$flex,
    },
}))``;

const FixedContainer = styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 100;
`;

const CertVerificationKakaoView = () => {
    const theme = useThemeContext();
    const repository = useRepository(CertRepository);
    const setLoading = useSetLoading();
    const showErrorDialog = useShowErrorDialog();

    const setState = useSetRecoilState(certState);
    const readState = useReadRecoilState(certState);

    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        const id = setTimeout(() => {
            setEnabled(true);
        }, 10 * 1000);

        return () => {
            clearTimeout(id);
        };
    }, []);

    const onSubmit = (e: FormEvent) => e.preventDefault();
    const resetVerification = () => setState((prev) => ({
        ...prev,
        verification: undefined,
    }));
    const buttonWidth = `calc(min(${theme.componentTheme.maxPageWidthInRem}, 100vw) - ${theme.componentTheme.pageHorizontalPaddingInPx * 2}px)`;
    const onClick = () => repository({
        handler: async (repository) => {
            setLoading(true, {
                darken: true,
                spinner: true,
            });

            const state = await readState();

            const userInfo = {
                name: state.user.name,
                ssnPrefix: state.user.ssnPrefix,
                ssnSuffix: state.user.ssnSuffix,
                phoneNumber: state.user.phoneNumber,
            };

            const prescriptions = await repository.verifyKakaoCert({
                name: state.user.name,
                ssnPrefix: state.user.ssnPrefix,
                ssnSuffix: state.user.ssnSuffix,
                phoneNumber: state.user.phoneNumber,
                stepInfo: (state.verification as CertKakaoVerificationState).stepInfo,
            });

            AppRouter.navigate(AppRoute.PrescriptionHistory, {
                state: {
                    userInfo,
                    prescriptions,
                } satisfies PrescriptionHistoryPageArgs,
            });
        },
        onDomainError: async (errorCode, showUnknownError) => {
            switch (errorCode) {
                case ErrorCode.SimpleCertVerificationIncomplete:
                    showErrorDialog({
                        title: S.certPage.error.errorDialogTitle,
                        message: S.certPage.error.verificationIncompleteErrorMessage,
                    });
                    break;

                case ErrorCode.SimpleCertExpired:
                case ErrorCode.InvalidInput:
                case ErrorCode.WebCrawlingError:
                    showErrorDialog({
                        title: S.certPage.error.errorDialogTitle,
                        message: S.certPage.error.verificationTimeoutErrorMessage,
                        onDismissed: () => resetVerification(),
                    });
                    break;

                default:
                    showUnknownError();
                    break;
            }
        },
        onFinally: () => setLoading(false),
    });

    return <PageLayout>
        <LayoutContainer onSubmit={onSubmit}>
            <PageTitle title={S.certPage.verification.kakao.title}/>
            <SpacerContainer $flex={1}/>
            <ImageContainer src={PNGAssets.KakaoCert}/>
            <SpacerContainer $flex={1}/>
            <SizedBox height={"100px"}/>
            <FixedContainer>
                <FilledButton
                    enabled={enabled}
                    width={buttonWidth}
                    label={S.certPage.verification.kakao.buttonLabel}
                    onClick={onClick}
                />
            </FixedContainer>
        </LayoutContainer>
    </PageLayout>;
};

export default CertVerificationKakaoView;
