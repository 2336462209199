const CertConstants = Object.freeze({
    maxNameLength: 30,
    maxSsnPrefixLength: 6,
    maxSsnSuffixLength: 7,
    maxPhoneNumberLength: 11,
    maxCodeLength: 6,
    maxCountdownSeconds: 180,
});

export default CertConstants;
