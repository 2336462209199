const isDevelopment = () => {
    const value = process.env.REACT_APP_IS_DEVELOPMENT;
    return value === "true";
};

const apiURL = () => {
    return process.env.REACT_APP_API_URL;
};

const bucketBaseURL = () => {
    return process.env.REACT_APP_BUCKET_BASE_URL;
};

const secureStorageKeys = () => {
    return {
        certEnteredKey: process.env.REACT_APP_CERT_ENTERED_KEY as string,
        guideShownKey: process.env.REACT_APP_GUIDE_SHOWN_KEY as string,
        hospitalKey: process.env.REACT_APP_HOSPITAL_KEY as string,
        userPreferencesKey: process.env.REACT_APP_USER_PREFERENCES as string,
    };
};

export const EnvironmentConstants = {
    isDevelopment: isDevelopment(),
    apiURL: apiURL(),
    bucketBaseURL: bucketBaseURL(),
    secureStorageKeys: secureStorageKeys(),
};
