import Debouncer from "presentation/utils/debouncer/debouncer";
import {RefObject, useEffect, useRef, useState} from "react";

const useResizeObserver = (
    ref: RefObject<HTMLElement>,
    deps: readonly unknown[],
    debounce: number = 200
) => {
    const debouncerRef = useRef(new Debouncer());
    const [size, setSize] = useState({width: 0, height: 0});

    useEffect(() => {
        const element = ref.current;
        if (!element) return;

        const debouncer = debouncerRef.current;

        const onResize = () => {
            const width = ref.current?.offsetWidth ?? 0;
            const height = ref.current?.offsetHeight ?? 0;

            setSize({width, height});
        };

        const resizeObserver = new ResizeObserver(() => {
            debouncer.run(onResize, debounce);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.unobserve(element);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    useEffect(() => {
        const width = ref.current?.offsetWidth ?? 0;
        const height = ref.current?.offsetHeight ?? 0;

        setSize({width, height});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, ...deps, debounce]);

    return {
        height: `${size.height}px`,
        width: `${size.width}px`,
        heightInPx: size.height,
        widthInPx: size.width,
    };
};

export default useResizeObserver;
