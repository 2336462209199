import styled from "styled-components";
import Palette from "presentation/theme/palette";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";
import {animated, useSpring} from "@react-spring/web";
import useHovered from "presentation/utils/hooks/use_hovered";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import {useEffect} from "react";

const LayoutContainer = styled(animated.button)`
    width: 100%;
    padding: 5px 8px;
    background-color: ${Palette.none};
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const IconContainer = styled(animated.div)`
    width: 100%;
    padding: 6px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelContainer = styled(animated.p)`
    min-width: max-content;
    ${Fonts.detail1};
    text-align: center;
`;

const LNBShootButton = ({
                            enabled,
                            onClick
                        }: {
    enabled: boolean;
    onClick?: () => void;
}) => {
    useEffect(() => {
        const onKeyUp = (e: KeyboardEvent) => {
            e.stopPropagation();
            e.preventDefault();
            if (!enabled) return;
            if (e.code !== "Insert") return;
            onClick?.();
        };

        window.addEventListener("keyup", onKeyUp);

        return () => {
            window.removeEventListener("keyup", onKeyUp);
        };
    }, [enabled, onClick]);

    const {hovered, onMouseEnter, onMouseLeave} = useHovered();

    const buttonProps = useSpring({
        cursor: enabled ? "pointer" : "not-allowed",
    });

    const iconProps = useSpring({
        backgroundColor: hovered ? Palette.primary10 : Palette.none,
    });

    const iconColor = enabled ? Palette.gray800 : Palette.gray200;

    const labelProps = useSpring({
        color: enabled ? Palette.gray800 : Palette.gray300,
    });

    return <LayoutContainer
        style={buttonProps}
        disabled={!enabled}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        title={S.lnbShootButton.title}
    >
        <IconContainer style={iconProps}>
            <SVG
                asset={SVGAssets.Camera}
                width={"48px"}
                height={"48px"}
                color={iconColor}
            />
        </IconContainer>
        <LabelContainer style={labelProps}>
            {S.lnbShootButton.label}
        </LabelContainer>
    </LayoutContainer>;
};

export default LNBShootButton;
