import {WeekdayType, WeekdayTypeMap,} from "domain/model/common/weekday_type";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import {memo, useMemo} from "react";
import styled from "styled-components";

const LayoutContainer = styled.p.attrs<{
    $width: string;
    $height: string;
}>((props) => ({
    style: {
        width: props.$width,
        height: props.$height,
    },
}))`
    ${Fonts.detail2Medium};
    color: ${Palette.gray500};
    text-align: center;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
`;

const CalendarWeekdayCell = ({
                                 width,
                                 height,
                                 weekday,
                             }: {
    width: string;
    height: string;
    weekday: WeekdayType;
}) => {
    const label = useMemo(() => WeekdayTypeMap.shortLabel(weekday), [weekday]);

    return (
        <LayoutContainer $width={width} $height={height}>
            {label}
        </LayoutContainer>
    );
};

export default memo(CalendarWeekdayCell);
