import {animated, useSpring} from "@react-spring/web";
import FilledButton from "presentation/components/button/filled_button";
import SizedBox from "presentation/components/common/sized_box";
import {TextField} from "presentation/components/text_field/text_field";
import loginState from "presentation/states/login/login_state";
import S from "presentation/theme/s";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import CSSPointerEventsType from "presentation/utils/types/css/pointer_events_type";
import {useState} from "react";
import {useSetRecoilState} from "recoil";
import styled from "styled-components";
import LoginConstants from "presentation/states/login/constants/login_constants";

const LayoutContainer = styled(animated.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`;

const LoginUserIDView = () => {
    const setLoading = useSetLoading();

    const setState = useSetRecoilState(loginState);

    const [userID, setUserID] = useState("");
    const [focused, setFocused] = useState(true);

    const [props, api] = useSpring(() => ({
        opacity: 1,
        pointerEvents: "auto" as CSSPointerEventsType,
    }));

    const enabled = !!userID.length;

    const onClick = () => {
        if (!userID.length) return;

        setLoading(true);

        setFocused(false);

        setState((prev) => ({
            ...prev,
            passwordInputRequested: true,
            userID: userID.trim(),
        }));

        api.set({
            opacity: 0,
            pointerEvents: "none" as CSSPointerEventsType,
        });

        setLoading(false);
    }

    return (
        <LayoutContainer style={props}>
            <TextField
                value={userID}
                onValueChange={setUserID}
                focused={focused}
                onFocusChange={setFocused}
                placeholder={S.loginPage.userIDPlaceholder}
                maxLength={LoginConstants.maxUserIDLength}
                onEnterPress={onClick}
            />
            <SizedBox height={"32px"}/>
            <FilledButton
                type={"gray"}
                enabled={enabled}
                width={"100%"}
                label={S.loginPage.loginButtonStartLabel}
                onClick={onClick}
            />
        </LayoutContainer>
    );
};

export default LoginUserIDView;
