import styled from "styled-components";
import {ToastContainer as ToastifyContainer, Zoom} from "react-toastify";
import Palette from "presentation/theme/palette";
import "react-toastify/dist/ReactToastify.css";
import {PropsWithChildren} from "react";

const ToastContainer = styled(ToastifyContainer)`
    --toastify-toast-min-width: 320px;
    --toastify-z-index: 2147483647;

    .Toastify__toast {
        min-width: 320px;
        padding: 0;
        background-color: ${Palette.none};
        box-shadow: none;
    }

    .Toastify__close-button {
        display: none;
    }
`;

const ToastProvider = ({children}: PropsWithChildren) => {
    return <>
        {children}
        <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={true}
            transition={Zoom}
        />
    </>;
};

export default ToastProvider;
