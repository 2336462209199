import styled from "styled-components";
import {Children, PropsWithChildren, useMemo} from "react";
import Flex from "presentation/components/common/flex";

const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    overflow: hidden;
`;

const ShootPrescriptionMedicationLayout = ({
                                               children,
                                           }: PropsWithChildren) => {
        const flex = useMemo(() => [100, 271, 46, 46, 46, 49, 36], []);

        return <LayoutContainer>
            {
                Children.toArray(children).slice(0, flex.length).map(
                    (c, index) => <Flex key={index} flex={flex.at(index) ?? 0}>{c}</Flex>
                )
            }
        </LayoutContainer>;
    }
;

export default ShootPrescriptionMedicationLayout;
