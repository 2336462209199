import PrescriptionFieldDTO, {PrescriptionFieldDTOMap} from "data/dto/prescription/prescription_field_dto";
import PrescriptionMedicationType from "domain/model/prescription/prescription_medication_type";
import PrescriptionDeductibleRateFieldDTO, {
    PrescriptionDeductibleRateFieldDTOMap
} from "data/dto/prescription/prescription_deductible_rate_field_dto";
import PrescriptionMedication from "domain/model/prescription/prescription_medication";
import PrescriptionDrugFieldDTO, {PrescriptionDrugFieldDTOMap} from "data/dto/prescription/prescription_drug_field_dto";

type PrescriptionMedicationResponseDTO = {
    selfPayRateCode: PrescriptionDeductibleRateFieldDTO;
    drugCode: PrescriptionDrugFieldDTO;
    drugName: PrescriptionDrugFieldDTO;
    oneDose: PrescriptionFieldDTO;
    dosingPerDay: PrescriptionFieldDTO;
    totalDosingDays: PrescriptionFieldDTO;
};

export const PrescriptionMedicationResponseDTOMap = {
    toDomainModel: (
        d: PrescriptionMedicationResponseDTO,
        id: number,
        type: PrescriptionMedicationType,
    ): PrescriptionMedication => {
        return {
            id,
            type,
            deductibleRate: PrescriptionDeductibleRateFieldDTOMap.toDomainModel(d.selfPayRateCode),
            drugCode: PrescriptionDrugFieldDTOMap.toDomainModel(d.drugCode),
            drugName: PrescriptionDrugFieldDTOMap.toDomainModel(d.drugName),
            singleDosage: PrescriptionFieldDTOMap.toDomainModel(d.oneDose),
            dailyDosage: PrescriptionFieldDTOMap.toDomainModel(d.dosingPerDay),
            medicationPeriodInDays: PrescriptionFieldDTOMap.toDomainModel(d.totalDosingDays),
        };
    },
}

export default PrescriptionMedicationResponseDTO;