import InsuranceType from "domain/model/prescription/insurance_type";

type PrescriptionInsuranceTypeFieldDTO = {
    value: InsuranceType;
    needCheck: boolean;
};

export const PrescriptionInsuranceTypeFieldDTOMap = {
    toDomainModel: (d: PrescriptionInsuranceTypeFieldDTO): InsuranceType => {
        return d.value;
    },
}

export default PrescriptionInsuranceTypeFieldDTO;