import styled from "styled-components";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";
import {MutableRefObject, useState} from "react";
import useCamera from "presentation/utils/hooks/use_camera";
import useMediaStream from "presentation/utils/hooks/use_media_stream";
import useDeviceChange from "presentation/utils/hooks/use_device_change";
import IconButton from "presentation/components/button/icon_button";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import useResizeObserver from "presentation/utils/hooks/use_resize_observer";
import {animated, useSpring} from "@react-spring/web";
import ShootConstants from "presentation/states/shoot/constants/shoot_constants";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";

const LayoutContainer = styled(animated.div)`
    border-radius: 16px;
    transform: rotate(270deg);
    position: relative;
`;

const VideoContainer = styled.video`
    width: min(70vw, 90vh);
    min-width: 300px;
    transform: translateZ(0);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 3px 15px 0 rgba(199, 199, 199, 0.25);
`;

const IconButtonPositionContainer = styled.div`
    position: absolute;
    right: 24px;
    bottom: 24px;
`;

const MessageLayoutContainer = styled.div`
    min-width: max-content;
    padding: 12px 16px;
    border-radius: 16px;
    background-color: ${Palette.black50};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 48px;
    transform: translate3D(-50%, -50%, 0) rotate(90deg);
`;

const MessageContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail2};
    color: ${Palette.white100};
    text-align: center;
`;

const RealtimeScreenDialog = ({
                                  videoRef
                              }: {
    videoRef: MutableRefObject<nullable<HTMLVideoElement>>;
}) => {
    const dismissDialog = useDismissDialog();
    const getCamera = useCamera();
    const getMediaStream = useMediaStream();

    const [cameraEnabled, setCameraEnabled] = useState(false);
    const onClick = () => dismissDialog();

    const {widthInPx, heightInPx} = useResizeObserver(videoRef, [], 0);

    useDeviceChange(async () => {
        const camera = await getCamera(ShootConstants.camera);

        if (!camera) {
            setCameraEnabled(false);
            return;
        }

        const stream = await getMediaStream({
            deviceID: camera.data.deviceId,
            ...ShootConstants.camera,
        });

        const video = videoRef.current;
        if (!video) return;
        video.srcObject = stream;
        video.muted = true;
        video.play()
            .then(() => setCameraEnabled(true))
            .catch(() => setCameraEnabled(false));
    });

    const layoutStaticProps = useSpring({
        width: widthInPx,
        height: heightInPx,
        immediate: true,
    });
    const layoutAnimationProps = useSpring({
        opacity: cameraEnabled ? 1 : 0,
    });

    return <LayoutContainer style={{...layoutStaticProps, ...layoutAnimationProps}}>
        <VideoContainer ref={videoRef}/>
        <IconButtonPositionContainer>
            <IconButton
                asset={SVGAssets.DeleteCircle}
                width={"36px"}
                height={"36px"}
                color={Palette.black20}
                hoverColor={"dark"}
                onClick={onClick}
            />
        </IconButtonPositionContainer>
        <MessageLayoutContainer>
            <MessageContainer>{S.realtimeScreenDialog.message}</MessageContainer>
        </MessageLayoutContainer>
    </LayoutContainer>;
};

export default RealtimeScreenDialog;
