import styled from "styled-components";
import {TextField} from "presentation/components/text_field/text_field";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import {animated, useSpring} from "@react-spring/web";
import Flex from "presentation/components/common/flex";
import SVG from "presentation/components/common/svg";
import SVGAssets from "presentation/theme/assets";

const LayoutContainer = styled(animated.div)`
    width: 100%;
    padding: 8px 16px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

const IconLayoutContainer = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TextFieldContainer = styled(TextField)`
    width: 100%;
    min-height: 1.375rem;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    border: none;
    transition: color 0.2s ease-in-out;

    &:focus {
        box-shadow: none;
    }

    &::-webkit-input-placeholder {
        ${Fonts.detail3Medium};
        color: ${Palette.gray400};
    }

    &:focus::-webkit-input-placeholder {
        color: ${Palette.gray400};
        transition: color 0.2s ease-in-out;
    }
`;

const DrugSearchTextField = ({
                                 value,
                                 onValueChange,
                                 focused,
                                 onFocusChange,
                                 placeholder,
                                 maxLength,
                                 onEnterPress,
                             }: {
    value: string;
    onValueChange?: (value: string) => void;
    focused?: boolean;
    onFocusChange?: (focused: boolean) => void;
    placeholder?: string;
    maxLength?: number;
    onEnterPress?: () => void;
}) => {

    const layoutProps = useSpring({
        boxShadow: focused ? `0 0 0 2px ${Palette.primary500} inset` : `0 0 0 1.5px ${Palette.gray200} inset`,
    });

    return <LayoutContainer style={layoutProps}>
        <IconLayoutContainer>
            <SVG
                asset={SVGAssets.Search}
                width={"20px"}
                height={"20px"}
                color={Palette.gray400}
            />
        </IconLayoutContainer>
        <Flex>
            <TextFieldContainer
                value={value}
                onValueChange={onValueChange}
                focused={focused}
                onFocusChange={onFocusChange}
                placeholder={placeholder}
                maxLength={maxLength}
                onEnterPress={onEnterPress}
            />
        </Flex>
    </LayoutContainer>;
};

export default DrugSearchTextField;
