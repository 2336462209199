import InputFormatter, {TextEditingValue} from "presentation/utils/input_formatters/input_formatter";

export default class DiseaseCodeFormatter extends InputFormatter {
    maxLength = 30;

    formatEditUpdate(oldValue: TextEditingValue, newValue: TextEditingValue): TextEditingValue {
        if (this.isEraseOperation(oldValue, newValue)) return newValue;

        const newText = newValue.text;
        if (!!newText.length && (!/^[\da-zA-Z]*$/g.test(newText) || newText.length > this.maxLength)) {
            return oldValue;
        }

        return newValue;
    }
};