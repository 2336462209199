import BaseDataFilter from "data/data_filter/base_data_filter";
import InputValidator from "presentation/utils/input_validator/input_validator";
import InputValidationStatusType from "domain/model/common/input_validation_status_type";

export default class SSNDataFilter extends BaseDataFilter {
    filter(value?: string): optional<string> {
        try {
            const filtered = super.filter(value)?.replaceAll(/[^\d\-*]/g, "");

            if (!filtered) return undefined;

            if (filtered.length >= 6 && filtered.includes("-")) {
                const ssnPrefix = filtered.split("-")[0];
                const validationStatus = InputValidator.checkSSNPrefix(ssnPrefix);

                if (validationStatus !== InputValidationStatusType.Valid) return "";
            }

            return filtered;
        } catch {
            return "";
        }
    }
};