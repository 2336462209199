import BaseRepository from "data/repository/base_repository";
import UserInfo from "domain/model/user/user_info";
import PrescriptionHistory from "domain/model/prescription_history/prescription_history";
import {PrescriptionHistoryDTOMap} from "data/dto/prescription_history/prescription_history_dto";

export default class HospitalRepository extends BaseRepository {
    setHospital = async ({
                             hospital
                         }: {
        hospital: string
    }): Promise<void> => await this.handler({
        request: async (_, __, storage) => {
            storage.setHospital(hospital);
        },
    });

    getHospital = async (): Promise<optional<string>> => await this.handler({
        request: async (_, __, storage) => {
            return storage.getHospital();
        },
    });

    deleteHospital = async (): Promise<void> => await this.handler({
        request: async (_, __, storage) => {
            storage.deleteHospital();
        },
    });

    sendToHospital = async ({
                                userInfo,
                                prescriptions,
                            }: {
        userInfo: UserInfo,
        prescriptions: PrescriptionHistory[],
    }): Promise<void> => await this.handler({
        request: async (client, signals) => {
            await client.post("/jph/api/send", {
                userInfo: {
                    name: userInfo.name,
                    rrnFirst: userInfo.ssnPrefix,
                    rrnSecond: userInfo.ssnSuffix,
                },
                drugHistories: prescriptions.map(PrescriptionHistoryDTOMap.fromDomainModel),
            }, {
                signal: signals.at(0),
            });
        },
    });
};