import styled from "styled-components";
import PageTitle from "presentation/components/common/page_title";
import S from "presentation/theme/s";
import SizedBox from "presentation/components/common/sized_box";
import SectionTitle from "presentation/components/common/section_title";
import {TextField} from "presentation/components/text_field/text_field";
import {useRecoilValue, useSetRecoilState} from "recoil";
import certUserSelector from "presentation/states/cert/selector/cert_user_selector";
import certState, {CertSMSVerificationState} from "presentation/states/cert/cert_state";
import {FormEvent, useLayoutEffect, useMemo, useState} from "react";
import CertConstants from "presentation/states/cert/constants/cert_constants";
import DropdownButton from "presentation/components/button/dropdown_button/dropdown_button";
import {ISPType, ISPTypeMap, ISPTypes} from "domain/model/cert/isp_type";
import CertTypeButton from "presentation/pages/cert/components/cert_type_button";
import CertType, {CertTypeMap, CertTypes} from "domain/model/cert/cert_type";
import {animated, useSprings} from "@react-spring/web";
import CertTermsButton from "presentation/pages/cert/components/cert_terms_button";
import {KakaoCertTermsTypeMap, KakaoCertTermsTypes} from "domain/model/terms/kakao_cert_terms_type";
import CSSPositionType from "presentation/utils/types/css/position_type";
import CSSPointerEventsType from "presentation/utils/types/css/pointer_events_type";
import FilledButton from "presentation/components/button/filled_button";
import useThemeContext from "presentation/utils/hooks/use_theme_context";
import useRepository from "presentation/utils/hooks/use_repository";
import CertRepository from "data/repository/cert_repository";
import useSetLoading from "presentation/utils/hooks/use_set_loading";
import useReadRecoilState from "presentation/utils/hooks/use_read_recoil_state";
import ErrorCode from "domain/model/common/error_code";
import useShowErrorDialog from "presentation/utils/hooks/use_show_error_dialog";
import PageLayout from "presentation/components/common/page_layout";
import DateTime from "presentation/utils/class/date_time";
import SVGAssets from "presentation/theme/assets";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";

const LayoutContainer = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const RowContainer = styled.div.attrs<{ $gapInPx?: number }>((props) => ({
    style: {
        gap: `${props.$gapInPx ?? 12}px`,
    },
}))`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const TermsRelativeContainer = styled.div`
    width: 100%;
    height: max-content;
    position: relative;
`;

const TermsContainer = styled(animated.div)`
    width: max-content;
    min-height: max-content;
    top: 0;
    left: 0;
`;

const FixedContainer = styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 100;
`;

const CertUserView = () => {
    const theme = useThemeContext();
    const repository = useRepository(CertRepository);
    const setLoading = useSetLoading();
    const showErrorDialog = useShowErrorDialog();

    const {
        name,
        ssnPrefix,
        ssnSuffix,
        phoneNumber,
        isp,
        certType,
        termsCollapsed,
        kakaoTermsAllAgreed,
        ispTermsAllAgreed,
    } = useRecoilValue(certUserSelector);
    const setState = useSetRecoilState(certState);
    const readState = useReadRecoilState(certState);

    const [ssnPrefixFocused, setSSNPrefixFocused] = useState(false);
    const [ssnSuffixFocused, setSSNSuffixFocused] = useState(false);
    const [phoneNumberFocused, setPhoneNumberFocused] = useState(false);

    const {termsData, ispLabels} = useMemo(() => {
        const terms = ["kakao", ...ISPTypes];
        const termsData = terms.map((t) => {
            const ispType = ISPTypes.find((e) => e === t as ISPType);

            return {
                type: t,
                data: !ispType ? KakaoCertTermsTypes.map((e) => ({
                    term: e,
                    title: KakaoCertTermsTypeMap.title(e),
                    label: KakaoCertTermsTypeMap.label(e),
                })) : ISPTypeMap.terms(ispType).map((e) => ({
                    term: e,
                    title: ISPTypeMap.termsMap(ispType).title(e),
                    label: ISPTypeMap.termsMap(ispType).label(e),
                })),
            }
        });
        const ispLabels = ISPTypes.map((e) => ISPTypeMap.label(e));

        return {
            terms,
            termsData,
            ispLabels,
        };
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ispIndex = ISPTypes.indexOf(isp);

    const onNameChange = (name: string) => setState((prev) => ({
        ...prev,
        user: {
            ...prev.user,
            name,
        },
    }));

    const onSSNPrefixChange = (ssnPrefix: string) => {
        setState((prev) => ({
            ...prev,
            user: {
                ...prev.user,
                ssnPrefix,
            },
        }));

        if (ssnPrefix.length === CertConstants.maxSsnPrefixLength) {
            setSSNSuffixFocused(true);
        }
    };

    const onSSNSuffixChange = (ssnSuffix: string) => {
        setState((prev) => ({
            ...prev,
            user: {
                ...prev.user,
                ssnSuffix,
            },
        }));

        if (ssnSuffix.length === CertConstants.maxSsnSuffixLength) {
            setPhoneNumberFocused(true);
        }
    };

    const onPhoneNumberChange = (phoneNumber: string) => setState((prev) => ({
        ...prev,
        user: {
            ...prev.user,
            phoneNumber,
        },
    }));

    const onISPChange = (index: number) => setState((prev) => ({
        ...prev,
        user: {
            ...prev.user,
            isp: ISPTypes[index],
        },
    }));

    const onCertTypeButtonClick = (certType: CertType) => () => setState((prev) => ({
        ...prev,
        user: {
            ...prev.user,
            certType,
        },
    }));

    const onCollapsedClick = () => setState((prev) => ({
        ...prev,
        user: {
            ...prev.user,
            termsCollapsed: !prev.user.termsCollapsed,
        },
    }));

    const onSubmit = (e: FormEvent) => e.preventDefault();

    const [termsProps, termsAPIs] = useSprings(termsData.length, (index) => ({
        opacity: index === 0 ? (certType === CertType.Kakao ? 1 : 0) : (certType === CertType.SMS && isp === ISPTypes[index - 1] ? 1 : 0),
        position: (index === 0 ? (certType === CertType.Kakao ? "relative" : "absolute") : (certType === CertType.SMS && isp === ISPTypes[index - 1] ? "relative" : "absolute")) as CSSPositionType,
        pointerEvents: (index === 0 ? (certType === CertType.Kakao ? "auto" : "none") : (certType === CertType.SMS && isp === ISPTypes[index - 1] ? "auto" : "none")) as CSSPointerEventsType,
        immediate: true,
    }));

    useLayoutEffect(() => {
        termsAPIs.start((index) => ({
            opacity: index === 0 ? (certType === CertType.Kakao ? 1 : 0) : (certType === CertType.SMS && isp === ISPTypes[index - 1] ? 1 : 0),
            position: (index === 0 ? (certType === CertType.Kakao ? "relative" : "absolute") : (certType === CertType.SMS && isp === ISPTypes[index - 1] ? "relative" : "absolute")) as CSSPositionType,
            pointerEvents: (index === 0 ? (certType === CertType.Kakao ? "auto" : "none") : (certType === CertType.SMS && isp === ISPTypes[index - 1] ? "auto" : "none")) as CSSPointerEventsType,
            immediate: true,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certType, isp]);

    const buttonEnabled = !!name.length &&
        ssnPrefix.length === CertConstants.maxSsnPrefixLength &&
        ssnSuffix.length === CertConstants.maxSsnSuffixLength &&
        phoneNumber.length === CertConstants.maxPhoneNumberLength &&
        (certType === CertType.Kakao ? kakaoTermsAllAgreed : ispTermsAllAgreed[isp]);
    const buttonWidth = `calc(min(${theme.componentTheme.maxPageWidthInRem}, 100vw) - ${theme.componentTheme.pageHorizontalPaddingInPx * 2}px)`;

    const resetVerification = () => setState((prev) => ({
        ...prev,
        verification: undefined,
    }));
    const onClick = () => repository({
        handler: async (repository) => {
            if (!buttonEnabled) return;

            const state = await readState();

            if (state.user.certType === CertType.Kakao) {
                setLoading(true, {
                    darken: true,
                    spinner: true,
                });

                const stepInfo = await repository.requestKakaoCert({
                    name: state.user.name,
                    ssnPrefix: state.user.ssnPrefix,
                    ssnSuffix: state.user.ssnSuffix,
                    phoneNumber: state.user.phoneNumber,
                });

                setLoading(false);

                setState((prev) => ({
                    ...prev,
                    verification: {
                        certType: state.user.certType,
                        stepInfo,
                    },
                }));
            }

            if (state.user.certType === CertType.SMS) {
                setState((prev) => ({
                    ...prev,
                    verification: {
                        certType: state.user.certType,
                        startTime: DateTime.now(),
                        code: "",
                    },
                }));

                const userData = await repository.requestSMSCert({
                    name: state.user.name,
                    ssnPrefix: state.user.ssnPrefix,
                    ssnSuffix: state.user.ssnSuffix,
                    phoneNumber: state.user.phoneNumber,
                    isp: state.user.isp,
                });

                setState((prev) => {
                    if (!prev.verification) return prev;

                    return {
                        ...prev,
                        verification: {
                            ...(prev.verification as CertSMSVerificationState),
                            userData,
                        },
                    };
                });
            }
        },
        onDomainError: async (errorCode, showUnknownError) => {
            switch (errorCode) {
                case ErrorCode.SimpleCertInputError:
                case ErrorCode.InvalidInput:
                    showErrorDialog({
                        title: S.certPage.error.errorDialogTitle,
                        message: S.certPage.error.invalidInputErrorMessage,
                        onDismissed: () => resetVerification(),
                    });
                    break;

                case ErrorCode.WebCrawlingError:
                    showErrorDialog({
                        title: S.certPage.error.errorDialogTitle,
                        message: S.certPage.error.webCrawlingErrorMessage,
                        onDismissed: () => resetVerification(),
                    });
                    break;

                default:
                    showUnknownError({
                        onDismissed: () => resetVerification(),
                    });
                    break;
            }
            setLoading(false);
        },
    });

    return <PageLayout>
        <LayoutContainer onSubmit={onSubmit}>
            <PageTitle title={S.certPage.user.title}/>
            <SizedBox height={"36px"}/>

            <SectionTitle title={S.certPage.user.nameSection.title}>
                <TextField
                    value={name}
                    onValueChange={onNameChange}
                    placeholder={S.certPage.user.nameSection.placeholder}
                    maxLength={CertConstants.maxNameLength}
                    onEnterPress={onClick}
                />
            </SectionTitle>
            <SizedBox height={"18px"}/>

            <SectionTitle title={S.certPage.user.ssnSection.title}>
                <RowContainer>
                    <TextField
                        value={ssnPrefix}
                        onValueChange={onSSNPrefixChange}
                        focused={ssnPrefixFocused}
                        onFocusChange={setSSNPrefixFocused}
                        placeholder={S.certPage.user.ssnSection.prefixPlaceholder}
                        inputMode={"numeric"}
                        maxLength={CertConstants.maxSsnPrefixLength}
                        inputFormatter={InputFormatters.decimalOnlyFormatter}
                        onEnterPress={onClick}
                    />
                    <TextField
                        secure={true}
                        value={ssnSuffix}
                        onValueChange={onSSNSuffixChange}
                        focused={ssnSuffixFocused}
                        onFocusChange={setSSNSuffixFocused}
                        placeholder={S.certPage.user.ssnSection.suffixPlaceholder}
                        inputMode={"numeric"}
                        maxLength={CertConstants.maxSsnSuffixLength}
                        inputFormatter={InputFormatters.decimalOnlyFormatter}
                        onEnterPress={onClick}
                    />
                </RowContainer>
            </SectionTitle>
            <SizedBox height={"18px"}/>

            <RowContainer>
                <SectionTitle title={S.certPage.user.phoneNumberSection.title}>
                    <TextField
                        value={phoneNumber}
                        onValueChange={onPhoneNumberChange}
                        focused={phoneNumberFocused}
                        onFocusChange={setPhoneNumberFocused}
                        placeholder={S.certPage.user.phoneNumberSection.placeholder}
                        inputMode={"numeric"}
                        maxLength={CertConstants.maxPhoneNumberLength}
                        inputFormatter={InputFormatters.decimalOnlyFormatter}
                        onEnterPress={onClick}
                    />
                </SectionTitle>
                <SectionTitle title={S.certPage.user.ispSection.title}>
                    <DropdownButton
                        buttonHeightInPx={48}
                        itemHeightInPx={40}
                        selectedIndex={ispIndex}
                        labels={ispLabels}
                        maxIndexVisible={4}
                        onSelectedIndexChange={onISPChange}
                    />
                </SectionTitle>
            </RowContainer>
            <SizedBox height={"18px"}/>

            <SectionTitle title={S.certPage.user.certTypeSection.title}>
                <RowContainer $gapInPx={24}>{
                    CertTypes.map((t) =>
                        <CertTypeButton
                            key={t}
                            selected={certType === t}
                            iconAsset={CertTypeMap.iconAsset(t)}
                            label={CertTypeMap.label(t)}
                            onClick={onCertTypeButtonClick(t)}
                        />
                    )
                }</RowContainer>
            </SectionTitle>
            <SizedBox height={"24px"}/>

            <SectionTitle title={S.certPage.user.termsSection.title}>
                <TermsRelativeContainer>{
                    termsProps.map((props, index) => {
                        const data = termsData[index];
                        const agreed = index === 0 ? kakaoTermsAllAgreed : ispTermsAllAgreed[ISPTypes[index - 1]];
                        const onAgreedClick = () => setState((prev) => ({
                            ...prev,
                            user: {
                                ...prev.user,
                                kakaoTermsAllAgreed: index === 0 ? !kakaoTermsAllAgreed : kakaoTermsAllAgreed,
                                ispTermsAllAgreed: {
                                    ...prev.user.ispTermsAllAgreed,
                                    [ISPTypes[index - 1]]: index === 0 ? ispTermsAllAgreed[ISPTypes[index - 1]] : !ispTermsAllAgreed[ISPTypes[index - 1]],
                                }
                            },
                        }));

                        return <TermsContainer key={index} style={props}>
                            <CertTermsButton
                                agreed={agreed}
                                collapsed={termsCollapsed}
                                data={data.data}
                                onAgreedClick={onAgreedClick}
                                onCollapsedClick={onCollapsedClick}
                            />
                        </TermsContainer>;
                    })
                }</TermsRelativeContainer>
            </SectionTitle>

            <SizedBox height={"150px"}/>
            <FixedContainer>
                <FilledButton
                    enabled={buttonEnabled}
                    width={buttonWidth}
                    label={S.certPage.user.buttonLabel}
                    onClick={onClick}
                    rightIconAsset={SVGAssets.Next}
                />
            </FixedContainer>
        </LayoutContainer>
    </PageLayout>;
};

export default CertUserView;
