import {selector} from "recoil";
import certState from "presentation/states/cert/cert_state";

const certVerificationSelector = selector({
    key: "certVerificationSelector",
    get: ({get}) => {
        const state = get(certState);

        return {
            certType: state.user.certType,
            verification: state.verification,
        };
    },
});

export default certVerificationSelector;