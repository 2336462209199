import styled from "styled-components";
import Dialog from "presentation/components/dialog/dialog";
import SVGAssets from "presentation/theme/assets";
import S from "presentation/theme/s";
import SizedBox from "presentation/components/common/sized_box";
import Fonts from "presentation/theme/fonts";
import {TextField} from "presentation/components/text_field/text_field";
import {useState} from "react";
import FilledButton from "presentation/components/button/filled_button";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";

const TitleContainer = styled.div`
    width: 100%;
    ${Fonts.detail2};
`;

const ButtonLayoutContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const DrugCustomInputDialog = ({
                                   onDrugAdd,
                               }: {
    onDrugAdd: (drugCode: string, drugName: string) => void;
}) => {
    const dismissDialog = useDismissDialog();

    const [drugCode, setDrugCode] = useState("");
    const [drugName, setDrugName] = useState("");
    const [drugCodeFocused, setDrugCodeFocused] = useState(true);
    const [drugNameFocused, setDrugNameFocused] = useState(false);

    const enabled = !!drugCode.length && !!drugName.length;
    const onClick = () => {
        dismissDialog();
        onDrugAdd(drugCode, drugName)
    };
    const onEnterPress = () => {
        if (!enabled) return;

        onClick();
    };

    return <Dialog
        iconAsset={SVGAssets.PlusCircle}
        title={S.drugCustomInputDialog.title}
    >
        <TitleContainer>{S.drugCustomInputDialog.drugCodeTitle}</TitleContainer>
        <SizedBox height={"8px"}/>
        <TextField
            value={drugCode}
            onValueChange={setDrugCode}
            focused={drugCodeFocused}
            onFocusChange={setDrugCodeFocused}
            placeholder={S.drugCustomInputDialog.drugCodePlaceholder}
            inputFormatter={InputFormatters.drugCodeFormatter}
            onEnterPress={onEnterPress}
        />
        <SizedBox height={"36px"}/>
        <TitleContainer>{S.drugCustomInputDialog.drugNameTitle}</TitleContainer>
        <SizedBox height={"8px"}/>
        <TextField
            value={drugName}
            onValueChange={setDrugName}
            focused={drugNameFocused}
            onFocusChange={setDrugNameFocused}
            placeholder={S.drugCustomInputDialog.drugNamePlaceholder}
            inputFormatter={InputFormatters.drugNameFormatter}
            onEnterPress={onEnterPress}
        />
        <SizedBox height={"24px"}/>
        <ButtonLayoutContainer>
            <FilledButton
                sizeType={"medium"}
                enabled={enabled}
                label={S.drugCustomInputDialog.addButtonLabel}
                onClick={onClick}
            />
        </ButtonLayoutContainer>
    </Dialog>;
};

export default DrugCustomInputDialog;
