import styled from "styled-components";
import {animated, useSpring} from "@react-spring/web";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";
import {MouseEvent} from "react";

const LayoutContainer = styled(animated.button)`
    min-width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: none;
    background-color: ${Palette.none};
`;

const RadioContainer = styled(animated.div)`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
`;

const RadioCircleContainer = styled(animated.div)`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const LabelContainer = styled(animated.p)`
    min-width: max-content;
    ${Fonts.detail2Medium};
    text-align: center;
`;

const RadioButton = ({
                         enabled = true,
                         selected,
                         label,
                         onClick,
                     }: {
    enabled?: boolean;
    selected: boolean;
    label: string;
    onClick: (e: MouseEvent) => void;
}) => {
    const buttonProps = useSpring({
        cursor: enabled ? "pointer" : "not-allowed",
    });
    const radioProps = useSpring({
        boxShadow: `0 0 0 2px ${enabled ? (selected ? Palette.primary500 : Palette.gray300) : Palette.gray200} inset`,
    });
    const radioCircleProps = useSpring({
        backgroundColor: selected ? (enabled ? Palette.primary500 : Palette.gray200) : Palette.none,
    });
    const labelProps = useSpring({
        color: enabled ? Palette.gray800 : Palette.gray200,
    });

    const _onClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!enabled) return;

        onClick(e);
    };

    return <LayoutContainer style={buttonProps} onClick={_onClick} title={label}>
        <RadioContainer style={radioProps}>
            <RadioCircleContainer style={radioCircleProps}/>
        </RadioContainer>
        <LabelContainer style={labelProps}>{label}</LabelContainer>
    </LayoutContainer>;
};

export default RadioButton;
