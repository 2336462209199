import {BaseTermsTypeMap} from "domain/model/terms/base_terms_type";
import S from "presentation/theme/s";

enum SMSCertSMTermsType {
    personalInformation = "smsSmPersonalInformation",
    uniqueId = "smsSmUniqueId",
    thirdParty = "smsSmThirdParty",
    ispService = "smsSmIspService",
    generalService = "smsSmGeneralService",
}

export const SMSCertSMTermsTypes = Object.values(SMSCertSMTermsType);

export const SMSCertSMTermsTypeMap: BaseTermsTypeMap = {
    title: (type: SMSCertSMTermsType): string => {
        switch (type) {
            case SMSCertSMTermsType.personalInformation:
                return S.smsCertMVNOTermsType.personalInformationTitle;
            case SMSCertSMTermsType.uniqueId:
                return S.smsCertMVNOTermsType.uniqueIdTitle;
            case SMSCertSMTermsType.thirdParty:
                return S.smsCertMVNOTermsType.thirdPartyTitle;
            case SMSCertSMTermsType.ispService:
                return S.smsCertMVNOTermsType.ispServiceTitle;
            case SMSCertSMTermsType.generalService:
                return S.smsCertMVNOTermsType.generalServiceTitle;
            default:
                throw new Error("Invalid sms cert sm terms type");
        }
    },
    label: (type: SMSCertSMTermsType): string => {
        switch (type) {
            case SMSCertSMTermsType.personalInformation:
                return S.smsCertMVNOTermsType.personalInformationLabel;
            case SMSCertSMTermsType.uniqueId:
                return S.smsCertMVNOTermsType.uniqueIdLabel;
            case SMSCertSMTermsType.thirdParty:
                return S.smsCertMVNOTermsType.thirdPartyLabel;
            case SMSCertSMTermsType.ispService:
                return S.smsCertMVNOTermsType.ispServiceLabel;
            case SMSCertSMTermsType.generalService:
                return S.smsCertMVNOTermsType.generalServiceLabel;
            default:
                throw new Error("Invalid sms cert sm terms type");
        }
    },
};

export default SMSCertSMTermsType;
