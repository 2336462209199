import ShootConstants from "presentation/states/shoot/constants/shoot_constants";

const useMediaStream = () => {
    return async ({
                      deviceID,
                      width,
                      height,
                      fps,
                  }: {
        deviceID: string;
        width: number;
        height: number;
        fps: number;
    }) => {
        return await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                aspectRatio: ShootConstants.camera.width / ShootConstants.camera.height,
                deviceId: {
                    exact: !!deviceID ? deviceID : undefined,
                },
                width: {ideal: width},
                height: {ideal: height},
                frameRate: {ideal: fps},
            },
        });
    };
};

export default useMediaStream;
