import {selector} from "recoil";
import shootState from "presentation/states/shoot/shoot_state";
import {PrescriptionFieldRevisionStatusMap} from "domain/model/prescription/prescription_field_revision_status";
import {PrescriptionFieldMap} from "domain/model/prescription/prescription_field";
import {PrescriptionDrugFieldMap} from "domain/model/prescription/prescription_drug_field";

const shootPrescriptionRevisionStatusSelector = selector({
    key: "shootPrescriptionRevisionStatusSelector",
    get: ({get}) => {
        const state = get(shootState);

        const p = state.prescriptions.find((p) => p.id === state.selectedPrescriptionID)!;
        const prescriptionInfoRevisionNeeded = PrescriptionFieldRevisionStatusMap.revisionNeeded(p.issueNumber.revisionStatus)
            || PrescriptionFieldRevisionStatusMap.revisionNeeded(p.issueDate.revisionStatus)
            || PrescriptionFieldMap.valueEmptyOrRevisionNeeded(p.patientName)
            || PrescriptionFieldRevisionStatusMap.revisionNeeded(p.patientSSN.revisionStatus)
            || PrescriptionFieldMap.valueEmptyOrRevisionNeeded(p.nursingAgencyNumber)
            || PrescriptionFieldRevisionStatusMap.revisionNeeded(p.nursingAgencyName.revisionStatus)
            || PrescriptionFieldRevisionStatusMap.revisionNeeded(p.doctorLicenseNumber.revisionStatus)
            || p.diseaseCodes.some((d) => PrescriptionFieldRevisionStatusMap.revisionNeeded(d.revisionStatus))
            || PrescriptionFieldRevisionStatusMap.revisionNeeded(p.selfPayCode.revisionStatus)
            || PrescriptionFieldRevisionStatusMap.revisionNeeded(p.extraRemarks.revisionStatus);
        const medicationsRevisionNeeded = p.medications.some((m) =>
            PrescriptionDrugFieldMap.valueEmptyOrConfidenceLow(m.drugCode)
            || PrescriptionDrugFieldMap.valueEmptyOrConfidenceLow(m.drugName)
            || PrescriptionFieldMap.valueEmptyOrRevisionNeeded(m.singleDosage)
            || PrescriptionFieldMap.valueEmptyOrRevisionNeeded(m.dailyDosage)
            || PrescriptionFieldMap.valueEmptyOrRevisionNeeded(m.medicationPeriodInDays)
            || PrescriptionFieldRevisionStatusMap.revisionNeeded(m.deductibleRate.revisionStatus),
        );

        return {
            bannerVisible: prescriptionInfoRevisionNeeded || medicationsRevisionNeeded,
            prescriptionInfoRevisionNeeded,
            medicationsRevisionNeeded,
        };
    },
});

export default shootPrescriptionRevisionStatusSelector;