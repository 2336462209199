import styled from "styled-components";
import PrescriptionDeductibleRateType, {
    PrescriptionDeductibleRateTypeMap
} from "domain/model/prescription/prescription_deductible_rate_type";
import OutlinedChip from "presentation/components/chip/outlined_chip";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";

const LayoutContainer = styled.button`
    width: 100%;
    min-width: 2rem;
    background-color: ${Palette.none};
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const ShootPrescriptionMedicationDeductibleRateField = ({
                                                            revisionNeeded,
                                                            value,
                                                            onClick,
                                                        }: {
    revisionNeeded: boolean;
    value: PrescriptionDeductibleRateType;
    onClick: () => void;
}) => {
    return <LayoutContainer onClick={onClick}>
        <OutlinedChip
            label={PrescriptionDeductibleRateTypeMap.abbreviatedLabel(value)}
            color={revisionNeeded ? Palette.red500 : Palette.gray500}
            backgroundColor={revisionNeeded ? Palette.red100 : Palette.gray100}
            borderColor={revisionNeeded ? Palette.red200 : Palette.gray300}
            font={Fonts.detail2}
        />
    </LayoutContainer>;
};

export default ShootPrescriptionMedicationDeductibleRateField;
