import styled from "styled-components";
import Palette from "presentation/theme/palette";

const LayoutContainer = styled.div`
    margin: 0 4px;
    width: 8px;
    height: 0;
    border-top: 1px solid ${Palette.gray300};
`;

const UserGuideHeaderNumberDivider = () => {
    return <LayoutContainer/>;
};

export default UserGuideHeaderNumberDivider;
