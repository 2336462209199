import styled from "styled-components";
import InsuranceType, {InsuranceTypeMap, InsuranceTypes} from "domain/model/prescription/insurance_type";
import OptionButton from "presentation/components/button/option_button";
import {useRecoilValue, useSetRecoilState} from "recoil";
import shootPrescriptionsSelector from "presentation/states/shoot/selector/shoot_prescriptions_selector";
import shootState from "presentation/states/shoot/shoot_state";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";

const LayoutContainer = styled.div`
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const ShootPrescriptionInsuranceView = () => {
    const {prescription} = useRecoilValue(shootPrescriptionsSelector);
    const setState = useSetRecoilState(shootState);

    const onClick = (insuranceType: InsuranceType) => () => setState((prev) => {
        const prescriptions = [...prev.prescriptions];
        const index = prescriptions.findIndex((p) => p.id === prescription.id);
        if (index < 0) return prev;

        prescriptions[index] = {
            ...prescriptions[index],
            insuranceType,
        };
        prescriptions[index] = {
            ...prescriptions[index],
            patientSSN: {
                ...prescriptions[index].patientSSN,
                revisionStatus: {
                    ...prescriptions[index].patientSSN.revisionStatus,
                    needFullSSN: InsuranceTypeMap.needFullSSN(insuranceType)
                        && prescriptions[index].patientSSN.value.length < InputFormatters.ssnFormatter.maxLength,
                },
            },
        };

        return {
            ...prev,
            prescriptions,
        };
    });

    return <LayoutContainer>
        {InsuranceTypes.map((t) =>
            <OptionButton
                key={t}
                label={InsuranceTypeMap.label(t)}
                selected={prescription.insuranceType === t}
                onClick={onClick(t)}
            />
        )}
    </LayoutContainer>;
};
export default ShootPrescriptionInsuranceView;
