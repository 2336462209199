import styled from "styled-components";
import Fonts from "presentation/theme/fonts";
import Palette from "presentation/theme/palette";
import {useMemo} from "react";

const LayoutContainer = styled.li`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: ${Palette.none};
    border: none;
    cursor: pointer;
    overflow-x: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const DrugCodeContainer = styled.h6`
    min-width: max-content;
    ${Fonts.body1};
`;

const DrugNameContainer = styled.p`
    min-width: max-content;
    ${Fonts.detail3Medium};
    color: ${Palette.gray500};
`;

const DrugSearchResultTile = ({
                                  drugCode,
                                  drugName,
                                  onClick,
                              }: {
    drugCode: string;
    drugName: string;
    onClick: () => void;
}) => {
    const title = useMemo(() => [drugCode, drugName].join("\n"), [drugCode, drugName]);

    return <LayoutContainer onClick={onClick} title={title}>
        <DrugCodeContainer>{drugCode}</DrugCodeContainer>
        <DrugNameContainer>{drugName}</DrugNameContainer>
    </LayoutContainer>;
};

export default DrugSearchResultTile;
