import {selector} from "recoil";
import certState from "presentation/states/cert/cert_state";

const certUserSelector = selector({
    key: "certUserSelector",
    get: ({get}) => {
        const state = get(certState);

        return {
            name: state.user.name,
            ssnPrefix: state.user.ssnPrefix,
            ssnSuffix: state.user.ssnSuffix,
            phoneNumber: state.user.phoneNumber,
            isp: state.user.isp,
            certType: state.user.certType,
            termsCollapsed: state.user.termsCollapsed,
            kakaoTermsAllAgreed: state.user.kakaoTermsAllAgreed,
            ispTermsAllAgreed: state.user.ispTermsAllAgreed,
        };
    },
});

export default certUserSelector;