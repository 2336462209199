import InputFormatter, {TextEditingValue} from "presentation/utils/input_formatters/input_formatter";

export default class DrugNameFormatter extends InputFormatter {
    maxLength = 50;

    formatEditUpdate(oldValue: TextEditingValue, newValue: TextEditingValue): TextEditingValue {
        if (this.isEraseOperation(oldValue, newValue)) return newValue;

        const newText = newValue.text;
        if (!/^\S+.*$/g.test(newText) || newText.length > this.maxLength) {
            return oldValue;
        }

        return newValue;
    }
}