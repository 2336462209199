import PrescriptionHistoryDTO, {PrescriptionHistoryDTOMap} from "data/dto/prescription_history/prescription_history_dto";
import PrescriptionHistory from "domain/model/prescription_history/prescription_history";

type PrescriptionHistoriesDTO = {
    userInfo: {
        rrnFirst: string;
        rrnSecond: string;
        name: string;
    };
    drugHistories: PrescriptionHistoryDTO[];
};

export const PrescriptionHistoriesDTOMap = {
    toDomainModel: (d: PrescriptionHistoriesDTO): PrescriptionHistory[] => {
        return d.drugHistories.map(PrescriptionHistoryDTOMap.toDomainModel);
    },
}

export default PrescriptionHistoriesDTO;