import styled from "styled-components";
import SVG from "presentation/components/common/svg";
import Fonts from "presentation/theme/fonts";
import {ReactElement} from "react";

const LayoutContainer = styled.div`
    width: 100%;
    min-width: max-content;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

const ErrorMessageContainer = styled.p`
    min-width: max-content;
    ${Fonts.body2Medium};
    text-align: center;
    white-space: pre-wrap;
`;

const ErrorView = ({
                       staticAsset,
                       errorMessage,
                   }: {
    staticAsset: ReactElement,
    errorMessage: string,
}) => {
    return <LayoutContainer>
        <SVG asset={staticAsset}/>
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
    </LayoutContainer>;
};

export default ErrorView;
