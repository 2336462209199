import styled from "styled-components";
import PrescriptionMedication from "domain/model/prescription/prescription_medication";
import ShootPrescriptionMedicationLayout
    from "presentation/pages/shoot/components/shoot_prescription_medication_layout";
import ShootPrescriptionMedicationDrugField
    from "presentation/pages/shoot/components/shoot_prescription_medication_drug_field";
import {PrescriptionFieldRevisionStatusMap} from "domain/model/prescription/prescription_field_revision_status";
import ShootPrescriptionMedicationTextField
    from "presentation/pages/shoot/components/shoot_prescription_medication_text_field";
import ShootPrescriptionMedicationDeductibleRateField
    from "presentation/pages/shoot/components/shoot_prescription_medication_deductible_rate_field";
import Palette from "presentation/theme/palette";
import {animated} from "@react-spring/web";
import useHovered from "presentation/utils/hooks/use_hovered";
import IconButton from "presentation/components/button/icon_button";
import SVGAssets from "presentation/theme/assets";
import InputFormatters from "presentation/utils/input_formatters/input_formatters";
import useShowDialog from "presentation/utils/hooks/use_show_dialog";
import SelectDeductibleRateDialog
    from "presentation/components/dialog/select_deductible_rate_dialog/select_deductible_rate_dialog";
import DrugSearchDialog, {DrugSearchType} from "presentation/components/dialog/drug_search_dialog/drug_search_dialog";

const LayoutContainer = styled(animated.div).attrs<{
    $hovered: boolean;
    $hasBottomMargin: boolean;
}>((props) => ({
    style: {
        backgroundColor: props.$hovered ? Palette.primary10 : Palette.white100,
        marginBottom: props.$hasBottomMargin ? "2px" : "0",
    },
}))`
    transition: background-color 0.3s ease-in-out;
`;

const ShootPrescriptionMedicationRow = ({
                                            hasBottomMargin,
                                            medication,
                                            onMedicationChange,
                                            onDeleteButtonClick,
                                        }: {
    hasBottomMargin: boolean;
    medication: PrescriptionMedication;
    onMedicationChange: (medication: PrescriptionMedication) => void;
    onDeleteButtonClick: (medication: PrescriptionMedication) => void;
}) => {
    const showDialog = useShowDialog();

    const {hovered, onMouseEnter, onMouseLeave} = useHovered();

    const onDrugSearchClick = (searchType: DrugSearchType) => () => showDialog({
        className: "drug-search-dialog",
        component: <DrugSearchDialog
            searchType={searchType}
            drugCode={medication.drugCode.value}
            drugName={medication.drugName.value}
            onDrugSelect={(drugCode: string, drugName: string) => onMedicationChange({
                ...medication,
                drugCode: {
                    ...medication.drugCode,
                    value: drugCode,
                    revisionStatus: {
                        ...medication.drugCode.revisionStatus,
                        confidenceLow: false,
                    },
                },
                drugName: {
                    ...medication.drugName,
                    value: drugName,
                    revisionStatus: {
                        ...medication.drugName.revisionStatus,
                        confidenceLow: false,
                    },
                },
            })}
        />,
    });

    const onSingleDosageChange = (singleDosage: string) => onMedicationChange({
        ...medication,
        singleDosage: {
            ...medication.singleDosage,
            value: singleDosage,
            revisionStatus: {
                ...medication.singleDosage.revisionStatus,
                confidenceLow: false,
            },
        },
    });

    const onDailyDosageChange = (dailyDosage: string) => onMedicationChange({
        ...medication,
        dailyDosage: {
            ...medication.dailyDosage,
            value: dailyDosage,
            revisionStatus: {
                ...medication.dailyDosage.revisionStatus,
                confidenceLow: false,
            },
        },
    });

    const onMedicationPeriodInDaysChange = (medicationPeriodInDays: string) => onMedicationChange({
        ...medication,
        medicationPeriodInDays: {
            ...medication.medicationPeriodInDays,
            value: medicationPeriodInDays,
            revisionStatus: {
                ...medication.medicationPeriodInDays.revisionStatus,
                confidenceLow: false,
            },
        },
    });

    const onDeductibleRateButtonClick = () => showDialog({
        className: "select-deductible-rate-dialog",
        component: <SelectDeductibleRateDialog
            selectedDeductibleRate={medication.deductibleRate.value}
            onDeductibleRateChange={(deductibleRate) => onMedicationChange({
                ...medication,
                deductibleRate: {
                    ...medication.deductibleRate,
                    value: deductibleRate,
                    revisionStatus: {
                        ...medication.deductibleRate.revisionStatus,
                        confidenceLow: false,
                    },
                },
            })}
        />,
    });

    const _onDeleteButtonClick = () => onDeleteButtonClick(medication);

    return <LayoutContainer
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        $hovered={hovered}
        $hasBottomMargin={hasBottomMargin}
    >
        <ShootPrescriptionMedicationLayout>
            <ShootPrescriptionMedicationDrugField
                center={true}
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(medication.drugCode.revisionStatus)}
                value={medication.drugCode.value}
                onClick={onDrugSearchClick("code")}
            />
            <ShootPrescriptionMedicationDrugField
                center={!medication.drugName.value}
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(medication.drugName.revisionStatus)}
                value={medication.drugName.value}
                onClick={onDrugSearchClick("name")}
            />
            <ShootPrescriptionMedicationTextField
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(medication.singleDosage.revisionStatus)}
                value={medication.singleDosage.value}
                onValueChange={onSingleDosageChange}
                inputFormatter={InputFormatters.dosageFormatter}
            />
            <ShootPrescriptionMedicationTextField
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(medication.dailyDosage.revisionStatus)}
                value={medication.dailyDosage.value}
                onValueChange={onDailyDosageChange}
                inputFormatter={InputFormatters.dosageFormatter}
            />
            <ShootPrescriptionMedicationTextField
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(medication.medicationPeriodInDays.revisionStatus)}
                value={medication.medicationPeriodInDays.value}
                onValueChange={onMedicationPeriodInDaysChange}
                inputFormatter={InputFormatters.dosageFormatter}
            />
            <ShootPrescriptionMedicationDeductibleRateField
                revisionNeeded={PrescriptionFieldRevisionStatusMap.revisionNeeded(medication.deductibleRate.revisionStatus)}
                value={medication.deductibleRate.value}
                onClick={onDeductibleRateButtonClick}
            />
            <IconButton
                asset={SVGAssets.DeleteCircle}
                width={"20px"}
                height={"20px"}
                color={Palette.black20}
                onClick={_onDeleteButtonClick}
            />
        </ShootPrescriptionMedicationLayout>
    </LayoutContainer>;
};

export default ShootPrescriptionMedicationRow;
