import InputFormatter, {TextEditingValue} from "presentation/utils/input_formatters/input_formatter";

export default class SelfPayCodeFormatter extends InputFormatter {
    maxLength = 4;

    formatEditUpdate(oldValue: TextEditingValue, newValue: TextEditingValue): TextEditingValue {
        if (this.isEraseOperation(oldValue, newValue)) return newValue;

        const newText = newValue.text;
        if (!!newText.length && (!/^([a-zA-Z]?\d{0,3})$/g.test(newText) || newText.length > this.maxLength)) {
            return oldValue;
        }

        return newValue;
    }
};