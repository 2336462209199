import {ReactElement} from "react";
import {useSetRecoilState} from "recoil";
import uiState from "presentation/states/ui/ui_state";

const useShowDialog = () => {
    const setState = useSetRecoilState(uiState);

    return ({
                className,
                component,
                dismissible = true,
                onDismissed,
            }: {
        className: string;
        component: ReactElement;
        dismissible?: boolean;
        onDismissed?: () => void;
    }) => {
        setState((prev) => {
            const newDialogState = Array.from(prev.dialogState);
            const duplicateExists = newDialogState.some(
                (state) => state.className === className
            );

            if (duplicateExists) return prev;

            newDialogState.push({
                className,
                dismissible,
                onDismissed,
                component,
            });

            return {
                ...prev,
                dialogState: newDialogState,
            };
        });
    };
};

export default useShowDialog;
