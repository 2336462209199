import BaseRepository from "data/repository/base_repository";
import PrescriptionHistory from "domain/model/prescription_history/prescription_history";
import PrescriptionHistoriesDTO, {
    PrescriptionHistoriesDTOMap
} from "data/dto/prescription_history/prescription_histories_dto";
import {ISPType} from "domain/model/cert/isp_type";

export default class CertRepository extends BaseRepository {
    requestKakaoCert = async ({
                                  name,
                                  ssnPrefix,
                                  ssnSuffix,
                                  phoneNumber,
                              }: {
        name: string;
        ssnPrefix: string;
        ssnSuffix: string;
        phoneNumber: string;
    }): Promise<string> => await this.handler({
        request: async (client, signals) => {
            const response = await client.post("/jph/easy/send", {
                name,
                channel: "kakao",
                rrnFirst: ssnPrefix,
                rrnSecond: ssnSuffix,
                phoneNumber,
            }, {
                signal: signals.at(0),
            });

            return response.data.stepInfo;
        },
    });

    verifyKakaoCert = async ({
                                 name,
                                 ssnPrefix,
                                 ssnSuffix,
                                 phoneNumber,
                                 stepInfo,
                             }: {
        name: string;
        ssnPrefix: string;
        ssnSuffix: string;
        phoneNumber: string;
        stepInfo: string;
    }): Promise<PrescriptionHistory[]> => await this.handler({
        request: async (client, signals) => {
            const response = await client.post<PrescriptionHistoriesDTO>("/jph/easy", {
                name,
                channel: "kakao",
                rrnFirst: ssnPrefix,
                rrnSecond: ssnSuffix,
                phoneNumber,
                stepInfo,
            }, {
                signal: signals.at(0),
            });

            return PrescriptionHistoriesDTOMap.toDomainModel(response.data);
        },
    });

    requestSMSCert = async ({
                                name,
                                ssnPrefix,
                                ssnSuffix,
                                phoneNumber,
                                isp,
                            }: {
        name: string;
        ssnPrefix: string;
        ssnSuffix: string;
        phoneNumber: string;
        isp: ISPType;
    }): Promise<string> => await this.handler({
        request: async (client, signals) => {
            const response = await client.post("/jph/sms/send", {
                name,
                rrnFirst: ssnPrefix,
                rrnSecond: ssnSuffix,
                phoneNumber,
                mobileCo: isp,
            }, {
                signal: signals.at(0),
            });

            return response.data.out.userData;
        },
    });

    verifySMSCert = async ({
                               name,
                               ssnPrefix,
                               ssnSuffix,
                               phoneNumber,
                               isp,
                               code,
                               userData,
                           }: {
        name: string;
        ssnPrefix: string;
        ssnSuffix: string;
        phoneNumber: string;
        isp: ISPType;
        code: string;
        userData: string;
    }): Promise<PrescriptionHistory[]> => await this.handler({
        request: async (client, signals) => {
            const response = await client.post<PrescriptionHistoriesDTO>("/jph/sms", {
                name,
                rrnFirst: ssnPrefix,
                rrnSecond: ssnSuffix,
                phoneNumber,
                mobileCo: isp,
                stepInput: code,
                userData,
            }, {
                signal: signals.at(0),
            });

            return PrescriptionHistoriesDTOMap.toDomainModel(response.data);
        },
    });
}