import DateTime from "presentation/utils/class/date_time";
import Dialog from "presentation/components/dialog/dialog";
import DatePickerCalendar from "presentation/components/calendar/date_picker_calendar/date_picker_calendar";
import SVGAssets from "presentation/theme/assets";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";

const DatePickerDialog = ({
                              title,
                              calendarStartDate,
                              calendarEndDate,
                              selectedDate,
                              selectedDateSuffixLabel,
                              onDateSelect,
                          }: {
    title: string;
    calendarStartDate: DateTime;
    calendarEndDate: DateTime;
    selectedDate?: DateTime;
    selectedDateSuffixLabel?: string;
    onDateSelect: (date: DateTime) => void;
}) => {
    const dismissDialog = useDismissDialog();

    const onDateClick = (date: DateTime) => {
        dismissDialog();
        onDateSelect(date);
    };

    return <Dialog iconAsset={SVGAssets.CalendarCheck} title={title}>
        <DatePickerCalendar
            startDate={calendarStartDate}
            endDate={calendarEndDate}
            selectedDate={selectedDate}
            selectedDateSuffixLabel={selectedDateSuffixLabel}
            onDateClick={onDateClick}
        />
    </Dialog>;
};

export default DatePickerDialog;
