import PrescriptionDeductibleRateType from "domain/model/prescription/prescription_deductible_rate_type";
import PrescriptionDeductibleRateField from "domain/model/prescription/prescription_deductible_rate_field";

type PrescriptionDeductibleRateFieldDTO = {
    value: PrescriptionDeductibleRateType;
    needCheck: boolean;
};

export const PrescriptionDeductibleRateFieldDTOMap = {
    toDomainModel: (d: PrescriptionDeductibleRateFieldDTO): PrescriptionDeductibleRateField => {
        return {
            value: d.value,
            initialValue: d.value,
            revisionStatus: {
                confidenceLow: d.needCheck,
                needFullSSN: false,
            },
        };
    },
}

export default PrescriptionDeductibleRateFieldDTO;