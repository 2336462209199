import styled from "styled-components";
import {SVGAsset} from "presentation/theme/assets";
import {animated, useSpring} from "@react-spring/web";
import SVG from "presentation/components/common/svg";
import useHovered from "presentation/utils/hooks/use_hovered";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";
import useFadeInTransition from "presentation/utils/hooks/use_fade_in_transition";

const LayoutContainer = styled(animated.button).attrs<{ $backgroundColor: string; }>((props) => ({
    style: {
        backgroundColor: props.$backgroundColor,
    },
}))`
    width: 280px;
    height: 135px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: none;
    transition: background-color 0.3s ease-in-out;
`;

const SVGContainer = styled(animated.div)``;

const LabelContainer = styled(animated.p)`
    ${Fonts.detail3};
    text-align: center;
`;

const CardButton = ({
                        enabled = true,
                        iconAsset,
                        disabledIconAsset,
                        label,
                        onClick,
                    }: {
    enabled?: boolean;
    iconAsset: SVGAsset;
    disabledIconAsset?: SVGAsset;
    label: string;
    onClick: () => void;
}) => {
    const {hovered, onMouseEnter, onMouseLeave} = useHovered();

    const buttonBackgroundColor = enabled ? (hovered ? Palette.primary10 : Palette.white100) : Palette.gray200;
    const buttonProps = useSpring({
        cursor: enabled ? "pointer" : "not-allowed",
    });
    const {props: svgFadeInProps} = useFadeInTransition(`${enabled}`);
    const labelProps = useSpring({
        color: enabled ? Palette.gray800 : Palette.gray400,
    });

    return <LayoutContainer
        style={buttonProps}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        $backgroundColor={buttonBackgroundColor}
    >
        <SVGContainer style={svgFadeInProps}>
            <SVG
                asset={enabled ? iconAsset : (disabledIconAsset ?? iconAsset)}
                width={"48px"}
                height={"48px"}
            />
        </SVGContainer>
        <LabelContainer style={labelProps}>{label}</LabelContainer>
    </LayoutContainer>;
};

export default CardButton;
