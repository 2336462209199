import styled from "styled-components";
import {animated} from "@react-spring/web";
import useAnimatedMount from "presentation/utils/hooks/use_animated_mount";
import MyPageUserInfoCard from "presentation/pages/my_page/components/my_page_user_info_card";
import useRepository from "presentation/utils/hooks/use_repository";
import HospitalRepository from "data/repository/hospital_repository";
import useLifeCycle from "presentation/utils/hooks/use_life_cycle";
import {useState} from "react";
import SizedBox from "presentation/components/common/sized_box";
import TextButton from "presentation/components/button/text_button";
import AppRouter from "presentation/routes/app_router";
import AppRoute from "presentation/routes/model/app_route";
import S from "presentation/theme/s";
import SVGAssets from "presentation/theme/assets";
import Palette from "presentation/theme/palette";
import {useResetRecoilState} from "recoil";
import shootState from "presentation/states/shoot/shoot_state";

const LayoutContainer = styled(animated.div).attrs<{ $minWidth: string; }>((props) => ({
    style: {
        minWidth: props.$minWidth,
    },
}))`
    padding: 60px 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
`;

const MyPage = () => {
    const repository = useRepository(HospitalRepository);

    const resetShootState = useResetRecoilState(shootState);

    const [hospital, setHospital] = useState("");

    const {props} = useAnimatedMount();

    useLifeCycle({
        onInit: () => repository({
            handler: async (repository) => {
                const hospital = await repository.getHospital();
                setHospital(hospital ?? "");
            },
        }),
    });

    const onLogoutClick = () => repository({
        handler: async (repository) => {
            await repository.deleteHospital();

            resetShootState();

            AppRouter.navigate(AppRoute.Login, {
                replace: true,
            });
        },
    });

    return <LayoutContainer style={props} $minWidth={"540px"}>
        <MyPageUserInfoCard name={hospital}/>
        <SizedBox height={"24px"}/>
        <TextButton
            type={"black"}
            sizeType={"medium"}
            label={S.myPage.logoutButtonLabel}
            leftIconAsset={SVGAssets.Logout}
            iconColor={Palette.gray800}
            onClick={onLogoutClick}
        />
    </LayoutContainer>;
};

export default MyPage;
