const componentTheme = {
    lnbMinWidthInPx: 92,
    lnbMaxWidthInPx: 92,
    tnbHeight: "4.625rem",
    maxPageWidthInRem: "43.75rem",
    pageVerticalPaddingInPx: 36,
    pageHorizontalPaddingInPx: 24,
};

export default componentTheme;
