import {BaseTermsTypeMap} from "domain/model/terms/base_terms_type";
import S from "presentation/theme/s";

enum SMSCertKMTermsType {
    personalInformation = "smsKmPersonalInformation",
    uniqueId = "smsKmUniqueId",
    thirdParty = "smsKmThirdParty",
    ispService = "smsKmIspService",
    generalService = "smsKmGeneralService",
}

export const SMSCertKMTermsTypes = Object.values(SMSCertKMTermsType);

export const SMSCertKMTermsTypeMap: BaseTermsTypeMap = {
    title: (type: SMSCertKMTermsType): string => {
        switch (type) {
            case SMSCertKMTermsType.personalInformation:
                return S.smsCertMVNOTermsType.personalInformationTitle;
            case SMSCertKMTermsType.uniqueId:
                return S.smsCertMVNOTermsType.uniqueIdTitle;
            case SMSCertKMTermsType.thirdParty:
                return S.smsCertMVNOTermsType.thirdPartyTitle;
            case SMSCertKMTermsType.ispService:
                return S.smsCertMVNOTermsType.ispServiceTitle;
            case SMSCertKMTermsType.generalService:
                return S.smsCertMVNOTermsType.generalServiceTitle;
            default:
                throw new Error("Invalid sms cert km terms type");
        }
    },
    label: (type: SMSCertKMTermsType): string => {
        switch (type) {
            case SMSCertKMTermsType.personalInformation:
                return S.smsCertMVNOTermsType.personalInformationLabel;
            case SMSCertKMTermsType.uniqueId:
                return S.smsCertMVNOTermsType.uniqueIdLabel;
            case SMSCertKMTermsType.thirdParty:
                return S.smsCertMVNOTermsType.thirdPartyLabel;
            case SMSCertKMTermsType.ispService:
                return S.smsCertMVNOTermsType.ispServiceLabel;
            case SMSCertKMTermsType.generalService:
                return S.smsCertMVNOTermsType.generalServiceLabel;
            default:
                throw new Error("Invalid sms cert km terms type");
        }
    },
};

export default SMSCertKMTermsType;
