export type TextEditingValue = {
    text: string;
    selection: {
        start: number;
        end: number;
    };
};

export default abstract class InputFormatter {
    abstract formatEditUpdate(oldValue: TextEditingValue, newValue: TextEditingValue): TextEditingValue;

    protected isEraseOperation(oldValue: TextEditingValue, newValue: TextEditingValue): boolean {
        return oldValue.text.length > newValue.text.length;
    }

    protected isAddOperation(oldValue: TextEditingValue, newValue: TextEditingValue): boolean {
        return !this.isEraseOperation(oldValue, newValue);
    }
};

