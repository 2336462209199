import InputFormatter, {TextEditingValue} from "presentation/utils/input_formatters/input_formatter";

export default class DecimalOnlyFormatter extends InputFormatter {
    formatEditUpdate(oldValue: TextEditingValue, newValue: TextEditingValue): TextEditingValue {
        if (this.isEraseOperation(oldValue, newValue)) return newValue;

        const newText = newValue.text;
        if (!!newText.length && !/^\d+$/.test(newText)) return oldValue;

        return newValue;
    }
};