import styled from "styled-components";
import {TextField} from "presentation/components/text_field/text_field";
import Palette from "presentation/theme/palette";
import Fonts from "presentation/theme/fonts";
import S from "presentation/theme/s";
import InputFormatter from "presentation/utils/input_formatters/input_formatter";

const TextFieldContainer = styled(TextField)<{
    $roundTopRight: boolean;
    $roundBottomRight: boolean;
    $hasTopBorder: boolean;
    $hasRightBorder: boolean;
    $hasBottomBorder: boolean;
    $hasLeftBorder: boolean;
    $revisionNeeded: boolean;
    $highlightPlaceholder: boolean;
}>`
    height: 2.5rem;
    padding: 0 16px;
    border-radius: ${(props) => `0 ${props.$roundTopRight ? "12px" : "0"} ${props.$roundBottomRight ? "12px" : "0"} 0`};
    box-shadow: none;
    border-top: ${(props) => props.$hasTopBorder ? `1px solid ${Palette.gray100}` : "0"};
    border-right: ${(props) => props.$hasRightBorder ? `1px solid ${Palette.gray100}` : "0"};
    border-bottom: ${(props) => props.$hasBottomBorder ? `1px solid ${Palette.gray100}` : "0"};
    border-left: ${(props) => props.$hasLeftBorder ? `1px solid ${Palette.gray100}` : "0"};
    background-color: ${Palette.white100};
    color: ${(props) => props.$revisionNeeded ? Palette.red500 : Palette.gray800};
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &:focus {
        box-shadow: none;
        color: ${(props) => props.$revisionNeeded ? Palette.red500 : Palette.gray800};
        background-color: ${Palette.gray50};
    }

    &::-webkit-input-placeholder {
        ${Fonts.detail3Medium};
        color: ${(props) => props.$revisionNeeded || props.$highlightPlaceholder ? Palette.red500 : Palette.gray800};
    }

    &:focus::-webkit-input-placeholder {
        color: ${Palette.gray400};
        background-color: ${Palette.gray50};
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    }
`;

const ShootPrescriptionTextField = ({
                                        roundTopRight = false,
                                        roundBottomRight = false,
                                        hasTopBorder = true,
                                        hasRightBorder = true,
                                        hasBottomBorder = true,
                                        hasLeftBorder = false,
                                        enabled = true,
                                        revisionNeeded,
                                        highlightPlaceholder = false,
                                        value,
                                        onValueChange,
                                        focused,
                                        onFocusChange,
                                        placeholder,
                                        maxLength,
                                        inputFormatter,
                                    }: {
    roundTopRight?: boolean;
    roundBottomRight?: boolean;
    hasTopBorder?: boolean;
    hasRightBorder?: boolean;
    hasBottomBorder?: boolean;
    hasLeftBorder?: boolean;
    enabled?: boolean;
    revisionNeeded: boolean;
    highlightPlaceholder?: boolean;
    value: string;
    onValueChange?: (value: string) => void;
    focused?: boolean;
    onFocusChange?: (focused: boolean) => void;
    placeholder?: string;
    maxLength?: number;
    inputFormatter?: InputFormatter;
}) => {
    return <TextFieldContainer
        enabled={enabled}
        value={value}
        onValueChange={onValueChange}
        focused={focused}
        onFocusChange={onFocusChange}
        placeholder={placeholder ?? S.shootPrescriptionTextField.placeholder}
        maxLength={maxLength}
        inputFormatter={inputFormatter}
        $roundTopRight={roundTopRight}
        $roundBottomRight={roundBottomRight}
        $hasTopBorder={hasTopBorder}
        $hasRightBorder={hasRightBorder}
        $hasBottomBorder={hasBottomBorder}
        $hasLeftBorder={hasLeftBorder}
        $revisionNeeded={revisionNeeded}
        $highlightPlaceholder={highlightPlaceholder}
    />;
};

export default ShootPrescriptionTextField;
