import styled from "styled-components";
import Dialog from "presentation/components/dialog/dialog";
import SVGAssets from "presentation/theme/assets";
import useLifeCycle from "presentation/utils/hooks/use_life_cycle";
import useRepository from "presentation/utils/hooks/use_repository";
import TermsRepository from "data/repository/terms_repository";
import {useState} from "react";
import ViewStatusType from "domain/model/common/view_status_type";
import useDismissDialog from "presentation/utils/hooks/use_dismiss_dialog";

const ContentContainer = styled.div`
    min-height: 500px;
`;

const ImageContainer = styled.img`
    width: 100%;
    height: auto;
`;

const TermsDetailDialog = ({
                               title,
                               term,
                           }: {
    title: string;
    term: string;
}) => {
    const repository = useRepository(TermsRepository);
    const dismissDialog = useDismissDialog();

    const [status, setStatus] = useState(ViewStatusType.Loading);
    const [data, setData] = useState<string[]>([]);

    useLifeCycle({
        onInit: () => repository({
            handler: async (repository) => {
                const urls = await repository.getTermsURLs({term});

                setData(urls);

                setStatus(ViewStatusType.Loaded);
            },
            onDomainError: async (_, showUnknownError) => showUnknownError({
                onDismissed: () => dismissDialog(),
            }),
        }),
    });

    return <Dialog
        widthInPx={652}
        status={status}
        iconAsset={SVGAssets.Documents}
        title={title}
    >
        <ContentContainer>
            {data.length && data.map((d, index) => <ImageContainer key={index} src={d}/>)}
        </ContentContainer>
    </Dialog>;
};

export default TermsDetailDialog;
