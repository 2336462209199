import {BaseTermsTypeMap} from "domain/model/terms/base_terms_type";
import S from "presentation/theme/s";

enum SMSCertSKTTermsType {
    personalInformation = "smsSktPersonalInformation",
    uniqueId = "smsSktUniqueId",
    ispService = "smsSktIspService",
    generalService = "smsSktGeneralService",
}

export const SMSCertSKTTermsTypes = Object.values(SMSCertSKTTermsType);

export const SMSCertSKTTermsTypeMap: BaseTermsTypeMap = {
    title: (type: SMSCertSKTTermsType): string => {
        switch (type) {
            case SMSCertSKTTermsType.personalInformation:
                return S.smsCertTermsType.personalInformationTitle;
            case SMSCertSKTTermsType.uniqueId:
                return S.smsCertTermsType.uniqueIdTitle;
            case SMSCertSKTTermsType.ispService:
                return S.smsCertTermsType.ispServiceTitle;
            case SMSCertSKTTermsType.generalService:
                return S.smsCertTermsType.generalServiceTitle;
            default:
                throw new Error("Invalid sms cert skt terms type");
        }
    },
    label: (type: SMSCertSKTTermsType): string => {
        switch (type) {
            case SMSCertSKTTermsType.personalInformation:
                return S.smsCertTermsType.personalInformationLabel;
            case SMSCertSKTTermsType.uniqueId:
                return S.smsCertTermsType.uniqueIdLabel;
            case SMSCertSKTTermsType.ispService:
                return S.smsCertTermsType.ispServiceLabel;
            case SMSCertSKTTermsType.generalService:
                return S.smsCertTermsType.generalServiceLabel;
            default:
                throw new Error("Invalid sms cert skt terms type");
        }
    },
};

export default SMSCertSKTTermsType;