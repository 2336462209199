import {selector} from "recoil";
import uiState from "presentation/states/ui/ui_state";

const uiDialogStateSelector = selector({
    key: "uiDialogStateSelector",
    get: ({get}) => {
        const state = get(uiState);
        return state.dialogState;
    },
});

export default uiDialogStateSelector;
